import {
  Tab as TabChakra,
  TabProps as TabPropsChakra,
  Tag,
} from '@chakra-ui/react';

import OperationalSystemIcons from '../OperationalSystemIcons';

interface TabProps extends TabPropsChakra {
  hideIOSSystem?: boolean;
  showOperationalSystems?: boolean;
}

const Tab = ({
  children,
  hideIOSSystem,
  showOperationalSystems,
  ...rest
}: TabProps) => {
  const hasOperationalSystemToShow = hideIOSSystem || showOperationalSystems;

  return (
    <TabChakra {...rest}>
      {children}
      {hasOperationalSystemToShow && (
        <Tag
          backgroundColor="transparent"
          variant="subtle"
          color="gray.300"
          m="5px"
          fontSize="xs"
        >
          <OperationalSystemIcons size="lg" hideIOS={hideIOSSystem} />
        </Tag>
      )}
    </TabChakra>
  );
};

export default Tab;
