import {
  Button as ButtonChakra,
  ButtonProps as ButtonPropsChakra,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ButtonProps extends ButtonPropsChakra {
  color?: string;
  children: ReactNode;
  testId?: string;
}

const Button = ({
  color = 'primary',
  children,
  testId,
  ...rest
}: ButtonProps) => (
  <ButtonChakra
    data-testid={testId}
    colorScheme={color}
    _hover={{ opacity: '70%' }}
    {...rest}
  >
    {children}
  </ButtonChakra>
);

export default Button;
