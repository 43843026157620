import { Flex } from '@chakra-ui/layout';

import { RecommendedGlobalProxyType } from '../../../../../../../../types/policy';
import Automatic from './Automatic';
import Manual from './Manual';

interface Props {
  type: RecommendedGlobalProxyType;
}

export default function RecommendedGlobalProxy({ type }: Props) {
  const componentByType: Record<RecommendedGlobalProxyType, JSX.Element> = {
    [RecommendedGlobalProxyType.AUTOMATIC]: <Automatic />,
    [RecommendedGlobalProxyType.MANUAL]: <Manual />,
    [RecommendedGlobalProxyType.NONE]: null,
  };

  if (type === RecommendedGlobalProxyType.NONE) return null;

  return (
    <Flex w="full" direction="column" gridGap={4}>
      <Flex margin="0 auto" w="full" maxW="45rem">
        {componentByType[type]}
      </Flex>
    </Flex>
  );
}
