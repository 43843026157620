import { ActionCreatorWithOptionalPayload, Action } from '@reduxjs/toolkit';
import { select, apply, call, put } from 'redux-saga/effects';

import { ListMetadata, ListPayload } from '../../types/generic_list';
import Api from '../api/Api';
import { ApiError } from '../api/ApiError';
import ApiMaps from '../api/ApiMaps';
import ApiRefreshToken from '../api/ApiRefreshToken';
import { genericError } from '../api/ApiUtil';
import { logout } from '../auth';
import { startReportPolling } from '../event';
import {
  makeUiAddMetadataSpecific,
  makeUiAddSuccessSpecific,
  uiAddEmpty,
  uiAddError,
  uiAddLoading,
  uiRemoveEmpty,
  uiRemoveError,
  uiRemoveLoading,
  uiRemoveSuccess,
} from '../ui';

// Api helpers functions
export function* api(method: keyof ReturnType<typeof Api>, ...args: unknown[]) {
  try {
    const apiInstance = Api(yield select());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return yield apply(apiInstance, method, args as any);
  } catch (err) {
    throw new ApiError(
      err?.response?.status || 0,
      err?.response?.data || genericError
    );
  }
}

export function* apiMaps(
  method: keyof ReturnType<typeof ApiMaps>,
  ...args: unknown[]
) {
  try {
    const apiInstance = ApiMaps(yield select());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return yield apply(apiInstance, method, args as any);
  } catch (err) {
    throw new ApiError(
      err?.response?.status || 0,
      err?.response?.data || genericError
    );
  }
}
export function* apiRefreshToken(
  method: keyof ReturnType<typeof ApiRefreshToken>,
  ...args: unknown[]
) {
  try {
    const apiInstance = ApiRefreshToken(yield select());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return yield apply(apiInstance, method, args as any);
  } catch (err) {
    if (err?.response?.status === 401) {
      yield put(logout({ fromExpiration: true }));
    }
    throw new ApiError(
      err?.response?.status || 0,
      err?.response?.data || genericError
    );
  }
}

// Prepare actions for each request
export const safe = (saga, ...args) =>
  function* errorAndLoading(action: Action) {
    try {
      yield put(uiAddLoading(action?.type));
      yield call(saga, action, ...args);
      yield put(uiRemoveError(action?.type));
    } catch (e) {
      yield put(uiAddError({ [action?.type]: e.body }));
      yield put(uiRemoveSuccess(action?.type));
    } finally {
      yield put(uiRemoveLoading(action?.type));
    }
  };

interface actionToRequestList {
  success?: ActionCreatorWithOptionalPayload<unknown>;
  metadata?: (newMetadata: ListMetadata) => Action;
  uiSuccess?: () => Action;
  uiLoading?: () => Action;
}

// Request Functions
export function* requestSimple(
  api,
  apiMethod: keyof ReturnType<typeof Api>,
  type?: string,
  fnSuccess?: ActionCreatorWithOptionalPayload<unknown>,
  ...args: unknown[]
) {
  const actions = makeActions(fnSuccess, type);
  const data = yield call(api, apiMethod, ...args);
  yield call(runActionsSuccess, data, actions);
  if (data.length === 0) {
    yield put(uiAddEmpty(type));
  } else {
    yield put(uiRemoveEmpty(type));
  }
  return data;
}

export function* requestList(
  api,
  apiMethod: keyof ReturnType<typeof Api>,
  type?: string,
  fnSuccess?: ActionCreatorWithOptionalPayload<unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) {
  const actions = makeActions(fnSuccess, type);
  const indexQueryParameters = args.findIndex((arg) => arg.queryParameters);
  if (indexQueryParameters !== -1) {
    yield call(
      runActionsMetadata,
      args[indexQueryParameters].queryParameters,
      actions
    );
  }
  const { items, ...pagination }: ListPayload<unknown> = yield call(
    api,
    apiMethod,
    ...args
  );

  yield call(runActionsMetadata, pagination, actions);
  yield call(runActionsSuccess, items, actions);
  if (items.length === 0) {
    yield put(uiAddEmpty(type));
  } else {
    yield put(uiRemoveEmpty(type));
  }
}

function makeActions(
  fnSuccess: ActionCreatorWithOptionalPayload<unknown, string>,
  type: string
): actionToRequestList {
  return {
    success: fnSuccess,
    uiSuccess: makeUiAddSuccessSpecific(type),
    metadata: makeUiAddMetadataSpecific(type),
  };
}

/**
 * @deprecated use requestList
 */
function* runActionsLoading(actions?: actionToRequestList) {
  if (actions.uiLoading) {
    yield put(actions.uiLoading());
  }
}

function* runActionsSuccess(data: unknown, actions?: actionToRequestList) {
  if (actions.success) {
    yield put(actions.success(data));
  }
  if (actions.uiSuccess) {
    yield put(actions.uiSuccess());
  }
}

function* runActionsMetadata(
  pagination: ListMetadata,
  actions?: actionToRequestList
) {
  if (actions.metadata) {
    yield put(actions.metadata(pagination));
  }
}

/**
 * @deprecated use requestSimple
 */
export function* apiRequestAndSuccess(
  apiRoute: keyof ReturnType<typeof Api>,
  actions?: actionToRequestList,
  ...args: unknown[]
) {
  yield call(runActionsLoading, actions);
  const data = yield call(api, apiRoute, ...args);
  yield call(runActionsSuccess, data, actions);
}

/**
 * @deprecated use requestReport
 */
export function* apiRequestReportAndSuccess(
  apiRoute: keyof ReturnType<typeof Api>,
  actions?: actionToRequestList,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) {
  yield call(runActionsLoading, actions);
  const idReport = yield call(api, apiRoute, ...args);
  yield call(runActionsSuccess, idReport, actions);
  yield put(startReportPolling());
}

/**
 * @deprecated use requestList
 */
export function* apiRequestListSuccess(
  apiRoute: keyof ReturnType<typeof Api>,
  actions?: actionToRequestList,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) {
  yield call(runActionsLoading, actions);
  const argWithQueryParameters = args.findIndex((arg) => arg.queryParameters);
  if (actions.metadata && argWithQueryParameters !== -1) {
    yield put(actions.metadata(args[argWithQueryParameters].queryParameters));
  }

  const { items, ...pagination }: ListPayload<unknown> = yield call(
    api,
    apiRoute,
    ...args
  );

  if (actions.metadata) {
    yield put(actions.metadata(pagination));
  }
  yield call(runActionsSuccess, items, actions);
}
