import { Flex, Text, useTheme } from '@chakra-ui/react';
import { linearGradientDef } from '@nivo/core';
import { formatBytesTo } from 'portal-lib';
import { FormattedMessage, useIntl } from 'react-intl';

import { ApplicationConsumptionHistoryType } from '../../../../types/application';
import ChartLine from '../../../ChartLine';
import ChartLoading from '../../../ChartLoading';
import ChartTooltip from '../../../ChartTooltip';

interface MonthlyClusterChart {
  data: ApplicationConsumptionHistoryType[];
  isLoading?: boolean;
}

const MonthlyClusterChart = ({ data, isLoading }: MonthlyClusterChart) => {
  const intl = useIntl();
  const { colors } = useTheme();
  const higherValue =
    Math.max(...data.map((value) => value.consumption)) || 100;
  const hasData = data?.length > 0;

  const chartData = [
    {
      id: 'consumption',
      data: data.map(({ day, consumption }) => {
        return {
          x: day,
          y: consumption,
        };
      }),
    },
  ];

  if (isLoading) {
    return <ChartLoading color="primary.500" />;
  }

  if (!hasData) {
    return (
      <Flex align="center" p="4">
        <Text>
          <FormattedMessage id="history_consumption.chart_legend.empty" />
        </Text>
      </Flex>
    );
  }

  return (
    <ChartLine
      chartData={chartData}
      yScale={{ type: 'linear', stacked: false, min: 0, max: higherValue }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -65,
        format: (consumption: number) =>
          formatBytesTo({
            bytes: consumption,
          }),
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 15,
        tickRotation: 0,
        legend: intl.formatMessage({
          id: 'history_consumption.chart_legend.day',
        }),
        legendPosition: 'middle',
        legendOffset: 50,
      }}
      yFormat={(consumption: number) =>
        formatBytesTo({
          bytes: consumption,
        })
      }
      colors={colors['primary']['500']}
      tooltip={(datum) => (
        <ChartTooltip color={'primary.500'}>
          <Flex gridColumnGap={2} direction="column">
            <Text>
              <FormattedMessage id={'history_consumption.chart_legend.day'} />
              {`: ${datum.point.data.xFormatted}`}
            </Text>
            <Text>
              <FormattedMessage
                id={'history_consumption.chart_legend.consumption'}
              />
              <strong>{`: ${datum.point.data.yFormatted}`}</strong>
            </Text>
          </Flex>
        </ChartTooltip>
      )}
      defs={[
        linearGradientDef(
          'white_blue',
          [
            { offset: 0, color: colors['white'], opacity: 1 },
            { offset: 30, color: colors['chart']['400'], opacity: 1 },
            { offset: 100, color: colors['chart']['500'], opacity: 1 },
          ],
          {
            gradientTransform: 'rotate(180 0.5 0.5)',
          }
        ),
      ]}
    />
  );
};

export default MonthlyClusterChart;
