import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FAQType, GuideType } from '../../types/help';

// Action Types

export const Types = {
  FAQ: 'help/FAQ',
  GUIDES: 'help/Guides',
};

// Reducer

interface FaqState {
  faqs: FAQType[];
  guides: GuideType[];
}

const initialState: FaqState = {
  faqs: [],
  guides: [],
};

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    helpFaqSuccess: (state, action: PayloadAction<FAQType[]>) => {
      state.faqs = action.payload;
    },
    helpGuidesSuccess: (state, action: PayloadAction<GuideType[]>) => {
      state.guides = action.payload;
    },
  },
});

export default helpSlice.reducer;

// Action Creators

export const { helpFaqSuccess, helpGuidesSuccess } = helpSlice.actions;

export const listFaq = createAction(Types.FAQ);

export const listGuides = createAction(Types.GUIDES);
