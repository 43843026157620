import { Flex, Checkbox } from '@chakra-ui/react';
import _ from 'lodash';

import { EntriesOptionsType } from '../../../../../../../../../types/policy';
import { CommonProps } from './InputForDynamic';

interface Props extends CommonProps {
  entries?: EntriesOptionsType[];
}

export default function MultiSelectForDynamic({
  entries,
  onChange,
  value,
  name,
  isDisabled,
}: Props) {
  const handleChange = (checked: boolean, optionValue: EntriesOptionsType) => {
    let newValue = _.isArray(value) ? (value as (string | number)[]) : [];

    if (checked) {
      newValue = [...newValue, optionValue.value];
    } else {
      newValue = newValue.filter((val) => val !== optionValue.value);
    }

    onChange({ name, value: newValue });
  };

  return (
    <Flex flexDirection="column" justify="space-around" gridGap={4}>
      {entries?.map((entry) => (
        <Checkbox
          key={entry.name}
          checked={Boolean(entry?.value)}
          isDisabled={isDisabled}
          onChange={(event) => handleChange(event.target.checked, entry)}
          defaultChecked={(_.isArray(value) ? value : [])?.some(
            (val) => val === entry.value
          )}
        >
          {entry?.name}
        </Checkbox>
      ))}
    </Flex>
  );
}
