import React from 'react';

import { CardItemType } from '../../../../../CardContent';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getBlockScreenSettings: GetterSettingFn = ({
  handleChange,
  policy,
}) => {
  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.show_control_center_while_locked.title`,
      description: `${intlPolicySettingsKey}.show_control_center_while_locked.description`,
      children: (
        <SwitchButton
          name="allowLockScreenControlCenter"
          isChecked={policy?.allowLockScreenControlCenter}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.show_notification_center_while_locked.title`,
      description: `${intlPolicySettingsKey}.show_notification_center_while_locked.description`,
      children: (
        <SwitchButton
          name="allowLockScreenNotificationsView"
          isChecked={policy?.allowLockScreenNotificationsView}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.allow_voice_dialing.title`,
      description: `${intlPolicySettingsKey}.allow_voice_dialing.description`,
      children: (
        <SwitchButton
          name="allowVoiceDialing"
          isChecked={policy?.allowVoiceDialing}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.show_today_view_while_locked.title`,
      description: `${intlPolicySettingsKey}.show_today_view_while_locked.description`,
      children: (
        <SwitchButton
          name="allowLockScreenTodayView"
          isChecked={policy?.allowLockScreenTodayView}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.allow_touch_id_face_id.title`,
      description: `${intlPolicySettingsKey}.allow_touch_id_face_id.description`,
      children: (
        <SwitchButton
          name="allowFingerprintForUnlock"
          isChecked={policy?.allowFingerprintForUnlock}
          onChange={handleChange}
        />
      ),
    },
  ];

  return listSettings;
};
