import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';

import Text from '../Text';

interface Props extends Omit<ComponentProps<typeof Text>, 'children'> {
  description?: string;
}

export const PlanWarning = ({ description, ...props }: Props) => {
  const intl = useIntl();

  return (
    <Text m={0} fontSize="md" fontStyle="italic" {...props}>
      {description || intl.formatMessage({ id: 'global.plan_warning' })}
    </Text>
  );
};
