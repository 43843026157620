import { IntlShape } from 'react-intl';

import { profileFileErroToIntl } from './IOS/components/ProfileFileInput/profileFileErrors';

export const policyErrorToIntl = {
  ...profileFileErroToIntl,
};

export function handleFilterAccordionSettingsIntlKeys(
  intlKeys: string[],
  filterToCompare: string,
  intl: IntlShape
) {
  const lowerCaseFilter = filterToCompare.toLowerCase();

  return intlKeys.some((key) => {
    if (!key) return;
    const translatedKey = intl.formatMessage({ id: key }).toLowerCase();
    return translatedKey.includes(lowerCaseFilter);
  });
}
