import { IntlShape } from 'react-intl';

import { ListMetadata } from '../../types/generic_list';
import { Header, Body } from '../Table/TableInterfaces';
import {
  exportObjectToExcel,
  tableToExportObject,
} from './exportObjectConverts';

export const convertToExcel = (
  intl: IntlShape,
  name: string,
  data: Body[],
  columns: Header[],
  metadata?: ListMetadata
): unknown[] => {
  const exportObject = tableToExportObject(data, columns);
  const dataToExcel = exportObjectToExcel(exportObject);

  const legendToExcel = [
    {},
    {},
    {
      [exportObject[0][0].title]: intl.formatMessage({
        id: 'generate_report_excel.legend.report',
      }),
      [exportObject[0][1].title]: name,
    },
    {
      [exportObject[0][0].title]: intl.formatMessage({
        id: 'generate_report_excel.legend.current_date',
      }),
      [exportObject[0][1].title]: new Date().toLocaleDateString(),
    },
    {
      [exportObject[0][0].title]: intl.formatMessage({
        id: 'generate_report_excel.legend.page',
      }),
      [exportObject[0][1].title]: metadata?.page,
    },
    {
      [exportObject[0][0].title]: intl.formatMessage({
        id: 'generate_report_excel.legend.total_pages',
      }),
      [exportObject[0][1].title]: metadata?.totalPages,
    },
  ];

  return [...dataToExcel, ...legendToExcel];
};
