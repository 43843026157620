import { Icon } from '@chakra-ui/react';
import React from 'react';

const SendIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        className="lucide lucide-send"
      >
        <path d="m22 2-7 20-4-9-9-4ZM22 2 11 13" />
      </svg>
    </Icon>
  );
};

export default SendIcon;
