import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { QuerysWithFilters } from '../../types/generic_list';
import {
  Types,
  applicationListSuccess,
  applicationDeviceUsersListSuccess,
  applicationConsumptionHistoryListSuccess,
  applicationListForSelectSuccess,
} from '../application';
import { api, safe, requestList, requestSimple } from './util';

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'applicationsList',
    type,
    applicationListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleListForSelect({
  type,
  payload: { filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'applicationsListForSelect',
    type,
    applicationListForSelectSuccess,
    {
      filters,
    }
  );
}

function* handleListApplicationDeviceUsers({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'applicationDeviceUserList',
    type,
    applicationDeviceUsersListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleListApplicationConsumptionHistory({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'applicationConsumptionHistoryList',
    type,
    applicationConsumptionHistoryListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

export default function* applicationSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.LIST_FOR_SELECT, safe(handleListForSelect));
  yield takeLatest(
    Types.LIST_APPLICATION_DEVICE_USERS,
    safe(handleListApplicationDeviceUsers)
  );
  yield takeLatest(
    Types.LIST_APPLICATION_CONSUMPTION_HISTORY,
    safe(handleListApplicationConsumptionHistory)
  );
}
