import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { ListQueryParameters } from '../../types/generic_list';
import { NetworkSettingsData, NetworkSettingsType } from '../../types/network';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  LIST: 'networks/LIST',
  LIST_OPTIONS_FOR_SELECT: 'networks/LIST_OPTIONS_FOR_SELECT',
  GET: 'networks/GET',
  CREATE: 'networks/CREATE',
  UPDATE: 'networks/UPDATE',
  DELETE: 'networks/DELETE',
};

// Reducer

interface NetworkState {
  networkSettings: NetworkSettingsType[];
  networkSetting: NetworkSettingsType;
  networksOptionsForSelect: NetworkSettingsType[];
}

const initialState: NetworkState = {
  networkSettings: [],
  networkSetting: null,
  networksOptionsForSelect: [],
};

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    networkSettingsListSuccess: (
      state,
      action: PayloadAction<NetworkSettingsType[]>
    ) => {
      state.networkSettings = action.payload;
    },
    networkSettingsGetSuccess: (
      state,
      action: PayloadAction<Partial<NetworkSettingsType>>
    ) => {
      state.networkSetting = action.payload;
    },
    networkSettingsUpdateSuccess: (
      state,
      action: PayloadAction<Partial<NetworkSettingsType>>
    ) => {
      state.networkSetting = action.payload;
    },
    setNetworkSettings: (
      state,
      action: PayloadAction<Partial<NetworkSettingsType>>
    ) => {
      state.networkSetting = action.payload;
    },

    networkSettingsClear: (state) => {
      state.networkSetting = initialState.networkSetting;
    },
    networkOptionsForSelectSuccess: (
      state,
      action: PayloadAction<NetworkSettingsType[]>
    ) => {
      state.networksOptionsForSelect = action.payload;
    },
  },
});

export default networksSlice.reducer;

// Action Creators

export const {
  networkSettingsListSuccess,
  networkSettingsGetSuccess,
  networkSettingsUpdateSuccess,
  setNetworkSettings,
  networkSettingsClear,
  networkOptionsForSelectSuccess,
} = networksSlice.actions;

export function listNetworkSettings(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listNetworksOptionsForSelect(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_OPTIONS_FOR_SELECT,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function getNetworkSetting(id: ID) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function updateNetworkSetting(data: NetworkSettingsData) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function createNetworkSetting(data: NetworkSettingsType) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}

export function deleteNetworkSetting(id: ID) {
  return {
    type: Types.DELETE,
    payload: id,
  };
}
