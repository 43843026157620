import {
  SimpleGrid,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';

import Skeleton from '../../../../Skeleton';
import Tab from '../../../../Tab';

const SkeletonTabs = () => {
  return (
    <Tabs mt="16">
      <TabList mb="8">
        <Tab _selected={{ color: 'gray.300' }}>
          <Skeleton h="35px" w="174px" />
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel gridGap={6}>
          <SimpleGrid gridGap={5}>
            <Skeleton h="45px" w="350px" />
            {[...Array(4)].map((_, index) => (
              <Skeleton key={index} h="81px" w="full" />
            ))}
          </SimpleGrid>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SkeletonTabs;
