import routes from '../routes';
import { Plan } from '../types/loginAdminUser';

type KeyofPlan = keyof Plan | (keyof Plan)[];

export const featurePlanKeys: Partial<
  Record<keyof Omit<Plan, 'name'>, keyof Plan>
> = {
  allowLocalization: 'allowLocalization',
  allowMessage: 'allowMessage',
  allowDocument: 'allowDocument',
  allowUnlimitedKiosk: 'allowUnlimitedKiosk',
  allowBlockSimPolicy: 'allowBlockSimPolicy',
  allowExternalApps: 'allowExternalApps',
  allowIOSSupervisedPolicy: 'allowIOSSupervisedPolicy',
  allowAppRestrictionsByTime: 'allowAppRestrictionsByTime',
  allowNetworkSettings: 'allowNetworkSettings',
  allowWorkSettings: 'allowWorkSettings',
  allowRoaming: 'allowRoaming',
};

type PlanFeatureKeyByRoute = Record<string, KeyofPlan>;

export const planFeatureKeyByRoute: PlanFeatureKeyByRoute = {
  [routes.geolocation]: featurePlanKeys.allowLocalization,
  [routes.informes.deviceLocation]: featurePlanKeys.allowLocalization,
  [routes.informes.locationHistory]: featurePlanKeys.allowLocalization,
  [routes.messages.list]: featurePlanKeys.allowMessage,
  [routes.messages.details]: featurePlanKeys.allowMessage,
  [routes.messages.register]: featurePlanKeys.allowMessage,
  [routes.documents.list]: featurePlanKeys.allowDocument,
  [routes.documents.details]: featurePlanKeys.allowDocument,
  [routes.documents.register]: featurePlanKeys.allowDocument,
  [routes.externalApps.list]: featurePlanKeys.allowExternalApps,
  [routes.externalApps.details]: featurePlanKeys.allowExternalApps,
  [routes.externalApps.register]: featurePlanKeys.allowExternalApps,
  [routes.generalConfig.networkSettings.list]:
    featurePlanKeys.allowNetworkSettings,
  [routes.generalConfig.networkSettings.edit]:
    featurePlanKeys.allowNetworkSettings,
  [routes.generalConfig.networkSettings.register]:
    featurePlanKeys.allowNetworkSettings,
};
