import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { DeviceUserType } from '../../types/deviceUser';
import { ListQueryParameters } from '../../types/generic_list';
import { GroupType } from '../../types/group';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  CREATE: 'group/CREATE',
  TOASTER: 'group/TOASTER',
  LIST: 'group/LIST',
  LIST_FILTER: 'group/LIST_FILTER',
  GET: 'group/GET',
  UPDATE: 'group/UPDATE',
  LIST_LINKED_USER: 'group/LIST_LINKED_USER',
  DELETE: 'group/DELETE',
  IMPORT_GROUP: 'group/IMPORT_GROUP',
};

// Reducer

interface GroupsState {
  groups: GroupType[];
  group: GroupType;
  groupsToFilter: GroupType[];
  linkedDevices: DeviceUserType[];
}

const initialState: GroupsState = {
  groups: [],
  group: {},
  groupsToFilter: [],
  linkedDevices: [],
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    groupSelected: (state, action: PayloadAction<Partial<GroupType>>) => {
      state.group = { ...state.group, ...action.payload };
    },
    groupSelectedClear: (state) => {
      state.group = initialState.group;
    },
    groupListSuccess: (state, action: PayloadAction<GroupType[]>) => {
      state.groups = action.payload;
    },
    groupCreateSuccess: (state, action: PayloadAction<GroupType>) => {
      state.group = action.payload;
    },
    groupGetSuccess: (state, action: PayloadAction<GroupType>) => {
      state.group = action.payload;
    },
    groupUpdateSuccess: (state, action: PayloadAction<GroupType>) => {
      state.group = action.payload;
    },
    groupListFilterSuccess: (state, action: PayloadAction<GroupType[]>) => {
      state.groupsToFilter = action.payload;
    },
    groupDevicesListSuccess: (
      state,
      action: PayloadAction<DeviceUserType[]>
    ) => {
      state.linkedDevices = action.payload.map((item) => ({
        ...item,
        phoneNumber: item.device.phoneNumber,
      }));
    },
    groupDevicesClean: (state) => {
      state.linkedDevices = initialState.linkedDevices;
    },
  },
});

export default groupsSlice.reducer;

// Action Creators

export const {
  groupSelected,
  groupSelectedClear,
  groupListSuccess,
  groupCreateSuccess,
  groupGetSuccess,
  groupUpdateSuccess,
  groupListFilterSuccess,
  groupDevicesListSuccess,
  groupDevicesClean,
} = groupsSlice.actions;

export function listGroups(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listGroupsToFilter(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_FILTER,
    payload: { filters: sanitizeFilter(filters) },
  };
}

export function getGroup(id: number) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function createGroup(data: GroupType) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}

export function updateGroup(data: GroupType) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function deleteGroup(id: GroupType['id']) {
  return {
    type: Types.DELETE,
    payload: id,
  };
}

export function listLinkedDevice(
  queryParameters: ListQueryParameters,
  groupId?: ID,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_LINKED_USER,
    payload: {
      queryParameters,
      filters: sanitizeFilter({ ...filters, groupId }),
    },
  };
}

export function importGroups(formData: FormData) {
  return {
    type: Types.IMPORT_GROUP,
    payload: formData,
  };
}
