import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  handleTranslateFieldByCategory,
  handleTransformValueByField,
  replaceDetailsTranslate,
} from 'portal-lib';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import ListDrillDown from '../../../components/pages/Companies/Audit/ListDrillDown';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { listAudits, listUsersToFilter, Types } from '../../../store/audit';
import { generateExcel } from '../../../store/event';
import { selectorMetadata } from '../../../store/ui';
import {
  ActivityEnum,
  CategoryEnum,
  disableActivityByCategory,
  disableCategoryByActivity,
  FieldsResponseType,
} from '../../../types/audit';
import { ListMetadata } from '../../../types/generic_list';

interface SelectOptionType {
  id?: string;
  label: string;
  value: string;
}

interface AuditFiltersType {
  startAt?: Date;
  endAt?: Date;
  user?: SelectOptionType;
  activity?: SelectOptionType;
  category?: SelectOptionType;
}

const Audit = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { handleFormatDateByUserLogged } = useFormatDate();

  const { audits, usersToFilter } = useAppSelector((state) => state.audit);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const auditMetadata = useAppSelector(selectorMetadata(Types.GET_AUDIT_LIST));
  const [filters, setFilters] = useState<AuditFiltersType>({
    startAt: null,
    endAt: null,
  });
  const TODAY = new Date();

  const verifyActivityDisabledByCategory = (activity: ActivityEnum) => {
    return disableActivityByCategory[activity].includes(
      filters.category.value as CategoryEnum
    );
  };

  const activitiesOptions: SelectOptionType[] = Object.values(ActivityEnum)
    .map((activity) => {
      return {
        id: activity,
        label: intl.formatMessage({ id: `audit.activity.${[activity]}` }),
        value: activity,
        isDisabled: filters.category
          ? verifyActivityDisabledByCategory(activity)
          : false,
        isHidden:
          !portalSettings?.showDisabledAuditFilters && filters.category
            ? verifyActivityDisabledByCategory(activity)
            : false,
      };
    })
    .filter((activityMap) => !activityMap.isHidden);

  const verifyCategoryDisabledByActivity = (category: CategoryEnum) => {
    return disableCategoryByActivity[category].includes(
      filters.activity.value as ActivityEnum
    );
  };

  const categoriesOptions: SelectOptionType[] = Object.values(CategoryEnum)
    .map((category) => {
      return {
        id: category,
        label: intl.formatMessage({ id: `audit.category.${[category]}` }),
        value: category,
        isDisabled: filters.activity
          ? verifyCategoryDisabledByActivity(category)
          : false,
        isHidden:
          !portalSettings?.showDisabledAuditFilters && filters.activity
            ? verifyCategoryDisabledByActivity(category)
            : false,
      };
    })
    .filter((categoryMap) => !categoryMap.isHidden);

  const usersOptions: SelectOptionType[] = usersToFilter.map((user, index) => {
    return {
      id: String(index),
      label: user?.name,
      value: user?.name,
    };
  });

  const allFilters = {
    startAt: filters?.startAt,
    endAt: filters?.endAt,
    user: filters?.user?.value,
    activity: filters?.activity?.value,
    category: filters?.category?.value,
  };

  useEffect(() => {
    dispatch(listAudits(inFirstPage(auditMetadata), allFilters));
  }, [filters]);

  useEffect(() => {
    dispatch(listUsersToFilter());
  }, []);

  const columns = useSorting(
    [
      {
        isExportHidden: true,
        header: '',
        accessor: 'handle_expander',
      },
      {
        header: intl.formatMessage({ id: 'audit.column.activity' }),
        accessor: 'activity',
      },
      {
        header: intl.formatMessage({ id: 'audit.column.category' }),
        accessor: 'category',
      },
      {
        header: intl.formatMessage({
          id: 'audit.column.admin_user_name',
        }),
        accessor: 'adminUserName',
      },
      {
        header: intl.formatMessage({
          id: 'audit.column.value',
        }),
        accessor: 'value',
      },
      {
        header: intl.formatMessage({
          id: 'audit.column.created_at',
        }),
        accessor: 'createdAt',
        canSort: true,
      },
      {
        isExportHidden: true,
        header: '',
        accessor: 'expander',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({
          id: 'audit.fields_modified',
        }),
        accessor: 'fieldsModified',
      },
    ],
    auditMetadata
  );

  function handleCopyFieldsModified(
    category: CategoryEnum,
    fields: Partial<FieldsResponseType>
  ) {
    const fieldsModifiedList = Object?.entries(fields)?.map(
      ([fieldUpdated, value]) => {
        const translateId = `${handleTranslateFieldByCategory(
          category,
          fieldUpdated
        )}`;

        const fieldTranslated = translateId
          ? intl.formatMessage({
              id: translateId,
            })
          : '';

        const transformValueObject = handleTransformValueByField(
          fieldUpdated,
          value
        );

        return {
          [fieldTranslated]: transformValueObject?.translateId
            ? intl.formatMessage({
                id: `${transformValueObject.translateId}`,
              })
            : transformValueObject?.value,
        };
      }
    );
    const fieldsModified = Object.assign({}, ...fieldsModifiedList);
    return JSON.stringify(fieldsModified);
  }

  const rows = audits.map((audit) => ({
    cells: [
      {
        isExportHidden: true,
        field: 'handle_expander',
        value: null,
        transform: ({ isExpanded, toggleExpanded }) => (
          <span>
            {isExpanded ? (
              <ChevronDownIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            ) : (
              <ChevronRightIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            )}
          </span>
        ),
        chackraProps: { width: 0 },
      },
      {
        field: 'activity',
        value: intl.formatMessage({
          id: audit?.activity && `audit.activity.${[audit?.activity]}`,
        }),
      },
      {
        field: 'category',
        value: intl.formatMessage({
          id: audit?.category && `audit.category.${[audit?.category]}`,
        }),
      },
      {
        field: 'adminUserName',
        value: audit?.adminUserName,
      },
      {
        field: 'value',
        value: replaceDetailsTranslate(audit.value || '', (translateId) =>
          intl.formatMessage({ id: translateId })
        ),
      },
      {
        field: 'createdAt',
        value: handleFormatDateByUserLogged(audit?.createdAt),
      },
      {
        isExportHidden: true,
        value: null,
        field: 'expander',
        transform: () => (
          <ListDrillDown
            fieldsModified={audit?.entityFieldsUpdated}
            transformField={(field) => {
              const translateId = `${handleTranslateFieldByCategory(
                audit.category,
                field
              )}`;
              return translateId
                ? intl.formatMessage({
                    id: translateId,
                  })
                : '';
            }}
            transformValue={(field, value) => {
              const transformValueObject = handleTransformValueByField(
                field,
                value
              );

              return transformValueObject?.translateId
                ? intl.formatMessage({
                    id: `${transformValueObject.translateId}`,
                  })
                : transformValueObject.value;
            }}
          />
        ),
      },
      {
        isExpandedField: true,
        value:
          audit?.entityFieldsUpdated &&
          handleCopyFieldsModified(audit.category, audit?.entityFieldsUpdated),
        field: 'fieldsModified',
      },
    ],
  }));

  const handleFilterUsers = (newFilter: SelectOptionType) => {
    setFilters({ ...filters, user: newFilter });
    dispatch(listUsersToFilter());
  };

  const handleFilterUsersChange = (value) => {
    dispatch(listUsersToFilter({ search: value }));
  };

  const handleFilterActivity = (option: SelectOptionType) => {
    setFilters({ ...filters, activity: option });
  };

  const handleFilterCategory = (option: SelectOptionType) => {
    setFilters({ ...filters, category: option });
  };

  const handlePeriodFilterChange = (date: Date, field: string) => {
    setFilters({ ...filters, [field]: date });
  };

  const handleNewMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(listAudits({ ...auditMetadata, ...newMetadata }, allFilters));
  };

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.GET_AUDIT_LIST,
        metadata: auditMetadata,
        filters: allFilters,
      })
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="audit.title" />}
        description={<FormattedMessage id="audit.description" />}
      />
      <FormContainer>
        <PageFilter gridGap={2}>
          <FormControl
            w="176px"
            textLabel={<FormattedMessage id="audit.start_date" />}
            flex="1"
          >
            <DatePicker
              maxDate={filters.endAt || TODAY}
              hasValue={filters.startAt}
              selected={filters.startAt}
              onChange={(e) => {
                handlePeriodFilterChange(e, 'startAt');
              }}
            />
          </FormControl>

          <FormControl
            w="176px"
            flex="1"
            textLabel={<FormattedMessage id="audit.last_date" />}
          >
            <DatePicker
              minDate={filters.startAt}
              maxDate={TODAY}
              hasValue={filters.endAt}
              selected={filters.endAt}
              onChange={(e) => {
                handlePeriodFilterChange(e, 'endAt');
              }}
            />
          </FormControl>

          <FormControl
            flex="2"
            textLabel={<FormattedMessage id="audit.users" />}
          >
            <SelectAutocomplete
              options={usersOptions}
              value={filters?.user}
              getOptionLabel={(option) => option?.label}
              onChange={handleFilterUsers}
              onInputChange={handleFilterUsersChange}
            />
          </FormControl>

          <FormControl
            flex="2"
            textLabel={<FormattedMessage id="audit.activity" />}
          >
            <SelectAutocomplete
              options={activitiesOptions}
              value={filters?.activity}
              getOptionLabel={(option) => option?.label}
              onChange={handleFilterActivity}
            />
          </FormControl>

          <FormControl
            flex="2"
            textLabel={<FormattedMessage id="audit.category" />}
          >
            <SelectAutocomplete
              options={categoriesOptions}
              value={filters?.category}
              getOptionLabel={(option) => option?.label}
              onChange={handleFilterCategory}
            />
          </FormControl>
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={columns}
        rows={rows}
        handleSort={handleNewMetadata}
        metadata={auditMetadata}
        pageActionsProps={{
          handleExportAllToExcel,
        }}
        keyProp={Types.GET_AUDIT_LIST}
        nameTable={`${intl.formatMessage({ id: 'audit.title' })}`}
      />
    </>
  );
};

export default Audit;
