import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableProps as EditableChakraProps,
  Flex,
  Input,
  FlexProps,
} from '@chakra-ui/react';
import React from 'react';

import IconButton from '../Icons/IconButton';
interface EditableFieldProps extends EditableChakraProps {
  editButtonContainerProps?: FlexProps;
  disableEdit?: boolean;
  maxCharacterLength?: number;
}

const EditableField = ({
  editButtonContainerProps,
  disableEdit,
  maxCharacterLength,
  ...rest
}: EditableFieldProps) => {
  function EditableControls({
    isEditing,
    onSubmit,
    onCancel,
    onEdit,
    disableEdit,
  }) {
    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label={rest?.defaultValue}
          size="sm"
          data-testid="submitButton"
          icon={<CheckIcon color="primary.500" />}
          isDisabled={rest?.isDisabled}
          onClick={onSubmit}
        />
        <IconButton
          aria-label={rest?.defaultValue}
          size="sm"
          data-testid="cancelButton"
          icon={<CloseIcon color="warning.500" />}
          onClick={onCancel}
        />
      </ButtonGroup>
    ) : (
      !disableEdit && (
        <Flex justifyContent="center" {...editButtonContainerProps}>
          <IconButton
            aria-label={rest?.defaultValue}
            size="sm"
            data-testid="editButton"
            icon={<EditIcon color="primary.500" />}
            onClick={onEdit}
          />
        </Flex>
      )
    );
  }

  return (
    <Editable
      d="flex"
      alignItems="center"
      isPreviewFocusable={false}
      submitOnBlur={false}
      {...rest}
    >
      {(props) => (
        <>
          <EditablePreview />
          <Input as={EditableInput} maxLength={maxCharacterLength} />
          <EditableControls {...props} disableEdit={disableEdit} />
        </>
      )}
    </Editable>
  );
};

export default EditableField;
