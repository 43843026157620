import { Table as TableChakra } from '@chakra-ui/react';
import React from 'react';

import { useAppSelector } from '../../hooks/useRedux';
import { selectorLoading } from '../../store/ui';
import AlertEmptyTable from '../AlertEmptyTable';
import TableBodyComponent from './TableBody';
import TableBodyLoading from './TableBodyLoading';
import TableHeaderComponent from './TableHeader';
import { Header, TableProps } from './TableInterfaces';

const Table: React.FC<TableProps> = ({
  id,
  headerColumns,
  rows,
  handleSort,
  keyProp,
  isEmpty,
}: TableProps) => {
  const isLoading = useAppSelector(selectorLoading(keyProp));

  const onSort = (header: Header) => {
    handleSort &&
      handleSort({
        sortingDirection: !header.isSorted
          ? 'ASC'
          : header.isSortedDesc
          ? 'ASC'
          : 'DESC',
        sortingProperty: header.accessor,
        page: 1,
      });
  };

  return (
    <TableChakra id={id}>
      {isLoading ? (
        <TableBodyLoading columns={headerColumns} rows={rows} />
      ) : isEmpty ? (
        <AlertEmptyTable isEmpty={isEmpty} keyProp={keyProp} />
      ) : (
        <>
          <TableHeaderComponent headers={headerColumns} onSort={onSort} />
          <TableBodyComponent rows={rows} />
        </>
      )}
    </TableChakra>
  );
};

export default Table;
