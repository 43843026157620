import { PrivilegeEnum } from '../helper';
import {
  handleAuthorizationByRoute,
  hasAuthorizationResponse,
} from '../helper/authorization';
import { useAppSelector } from './useRedux';

interface AuthorizationResponse {
  hasAuthorization?: hasAuthorizationResponse;
}
export const useAuthorization = ({ currentPage }): AuthorizationResponse => {
  const { user } = useAppSelector((state) => state.auth);

  const privilege: PrivilegeEnum = user?.privilege;

  return {
    hasAuthorization: handleAuthorizationByRoute(privilege, currentPage),
  };
};
