import {
  ModalBody as ModalBodyChakra,
  ModalBodyProps as ChakraModalBodyProps,
} from '@chakra-ui/react';
import React from 'react';

interface ModalBodyProps extends ChakraModalBodyProps {
  children?: React.ReactNode;
}

const ModalBody = ({ children, ...rest }: ModalBodyProps) => {
  return (
    <ModalBodyChakra
      d="flex"
      flexDirection="column"
      alignItems="center"
      {...rest}
    >
      {children}
    </ModalBodyChakra>
  );
};

export default ModalBody;
