import { ChangeEvent, memo, useState } from 'react';

import { useAppSelector } from '../../../../../../../../../hooks/useRedux';
import {
  EntriesOptionsType,
  ManageConfigurationInputType,
  ManageConfigurationInputTypeEnum,
  ManagedConfigurationsBundleValueType,
  ManagedConfigurationsType,
} from '../../../../../../../../../types/policy';
import { BundleArrayForDynamic } from './BundleArrayForDynamic';
import { BundleForDynamic } from './BundleForDynamic';
import ColorInput from './ColorInput';
import InputForDynamic, {
  CommonProps,
  CustomOnChangeValue,
  OnChangeParam,
} from './InputForDynamic';
import MultiSelectForDynamic from './MultiSelectForDynamic';
import SelectForDynamic from './SelectForDynamic';
import SwitchForDynamic from './SwitchForDynamic';
import UploadKioskWallpaper from './UploadKioskWallpaper';

interface DynamicInputApplicationsProps {
  name: string;
  value?: boolean | string | (string | number)[] | ManagedConfigurationsType[];
  handleChange?: CommonProps['onChange'];
  type: ManageConfigurationInputType;
  entries?: EntriesOptionsType[];
  nestedProperties?: ManagedConfigurationsType[];
  inputContainerWidth?: string;
  bundlePath?: string;
  firstLevel?: boolean;
  isDisabled?: boolean;
}

type ComponentByType = {
  [key in ManageConfigurationInputType]?: JSX.Element;
} & {
  default: JSX.Element;
};

const DynamicInputApplicationsComponent = ({
  handleChange,
  name,
  value,
  type = ManageConfigurationInputTypeEnum.STRING,
  entries,
  nestedProperties,
  inputContainerWidth,
  bundlePath,
  firstLevel,
  isDisabled,
}: DynamicInputApplicationsProps) => {
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const [internalValue, setInternalValue] = useState<
    boolean | string | ManagedConfigurationsType[] | (string | number)[]
  >(value || '');

  const isInteger = type === ManageConfigurationInputTypeEnum.INTEGER;
  const isHidden = type === ManageConfigurationInputTypeEnum.HIDDEN;
  const isMulti = type === ManageConfigurationInputTypeEnum.MULTISELECT;

  const commonProps = {
    name: name,
    value: internalValue,
    isHidden: isHidden,
    isInteger: isInteger,
    onChange: onChangeInterceptor,
    type,
    isDisabled,
  };

  const componentByType: ComponentByType = {
    default: <InputForDynamic {...commonProps} />,
    [ManageConfigurationInputTypeEnum.STRING]: (
      <InputForDynamic {...commonProps} />
    ),
    [ManageConfigurationInputTypeEnum.BOOL]: (
      <SwitchForDynamic {...commonProps} />
    ),
    [ManageConfigurationInputTypeEnum.CHOICE]: (
      <SelectForDynamic {...commonProps} entries={entries} />
    ),
    [ManageConfigurationInputTypeEnum.MULTISELECT]: (
      <MultiSelectForDynamic {...commonProps} entries={entries} />
    ),
    [ManageConfigurationInputTypeEnum.BUNDLE]: (
      <BundleForDynamic
        {...commonProps}
        nestedProperties={nestedProperties}
        inputContainerWidth={inputContainerWidth}
        bundlePath={bundlePath}
        onChange={handleChange}
        value={value as ManagedConfigurationsType[]}
      />
    ),
    [ManageConfigurationInputTypeEnum.BUNDLE_ARRAY]: (
      <BundleArrayForDynamic
        {...commonProps}
        nestedProperties={nestedProperties}
        inputContainerWidth={inputContainerWidth}
        bundlePath={bundlePath}
        onChange={handleChange}
        value={value as ManagedConfigurationsBundleValueType[]}
        firstLevel={firstLevel}
      />
    ),
  };

  function onChangeInterceptor(event: OnChangeParam) {
    if (isMulti) {
      setInternalValue((event as CustomOnChangeValue).value);
      handleChange(event, type);

      return;
    }

    const {
      value,
      checked,
      type: inputType,
    } = (event as ChangeEvent<HTMLInputElement>).target;

    setInternalValue(inputType === 'checkbox' ? checked.toString() : value);
    handleChange(event, type);
  }

  if (
    type === ManageConfigurationInputTypeEnum.STRING &&
    name === portalSettings.appKioskIconFontColorKey
  ) {
    return <ColorInput {...commonProps} />;
  }

  if (
    type === ManageConfigurationInputTypeEnum.STRING &&
    name === portalSettings.appKioskWallpaperKey
  ) {
    return <UploadKioskWallpaper {...commonProps} />;
  }

  return componentByType[type] || componentByType.default;
};

export const DynamicInputApplications = memo(DynamicInputApplicationsComponent);
