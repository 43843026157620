import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl as FormControlChakra,
  FormControlProps as FormControlPropsChakra,
  FormLabel,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

import Text from '../Text';
import Tooltip from '../Tooltip';

interface FormControlProps extends FormControlPropsChakra {
  children: React.ReactNode;
  textLabel?: string | JSX.Element;
  textInfo?: string | JSX.Element;
  error?: Error | null;
  required?: boolean;
}

const FormControl: (props: FormControlProps) => JSX.Element = ({
  children,
  textLabel,
  error,
  textInfo,
  required,
  ...rest
}) => {
  const style = useStyleConfig('FormControl');

  return (
    <FormControlChakra
      w="100%"
      mr={{ xl: '24px', lg: '0px' }}
      _last={{ marginRight: 0 }}
      {...rest}
    >
      {textLabel && (
        <Flex align="center" gridGap={1} pb={2}>
          <FormLabel
            m={0}
            color={error ? 'warning.500' : 'gray.500'}
            __css={{ ...style['FormLabel'] }}
          >
            {textLabel}
          </FormLabel>
          {required && <Text m="0">*</Text>}
          {textInfo && (
            <Tooltip
              placement="auto"
              label={textInfo}
              children={<InfoOutlineIcon boxSize={6} mr="5px" />}
            />
          )}
        </Flex>
      )}

      {children}
      {error && <Text __css={{ ...style['Text'] }}>{error.message}</Text>}
    </FormControlChakra>
  );
};

export default FormControl;
