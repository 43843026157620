import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { DocumentType, DocumentDetailsType } from '../../types/document';
import { ListQueryParameters } from '../../types/generic_list';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  CREATE: 'document/CREATE',
  TOASTER: 'document/TOASTER',
  LIST: 'document/LIST',
  GET: 'document/GET',
  LIST_DETAILS: 'document/LIST_DETAILS',
  UPDATE: 'document/UPDATE',
};

// Reducer

interface DocumentsState {
  documents: DocumentType[];
  document: DocumentType;
  documentsDetail: DocumentDetailsType[];
}

const initialState: DocumentsState = {
  document: {},
  documents: [],
  documentsDetail: [],
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    documentSelected: (state, action: PayloadAction<Partial<DocumentType>>) => {
      state.document = { ...state.document, ...action.payload };
    },
    documentSelectedClear: (state) => {
      state.document = initialState.document;
    },
    documentListSuccess: (state, action: PayloadAction<DocumentType[]>) => {
      state.documents = action.payload;
    },
    documentListFilterSuccess: (
      state,
      action: PayloadAction<DocumentType[]>
    ) => {
      state.documents = action.payload;
    },
    documentCreateSuccess: (state, action: PayloadAction<DocumentType>) => {
      state.document = action.payload;
    },
    documentGetSuccess: (state, action: PayloadAction<DocumentType>) => {
      state.document = action.payload;
    },
    documentDetailListSuccess: (
      state,
      action: PayloadAction<DocumentDetailsType[]>
    ) => {
      state.documentsDetail = action.payload;
    },
  },
});
export default documentsSlice.reducer;

// Action Creators

export const {
  documentSelected,
  documentSelectedClear,
  documentListSuccess,
  documentListFilterSuccess,
  documentCreateSuccess,
  documentGetSuccess,
  documentDetailListSuccess,
} = documentsSlice.actions;

export function listDocument(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function getDocument(id: number) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function createDocument(data: DocumentType) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}

export function listDocumentDetails(
  id: ID,
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_DETAILS,
    payload: { queryParameters, filters: sanitizeFilter(filters), id },
  };
}
