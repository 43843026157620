import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Text from '../../../Text';

interface ListDrillDownProps {
  fieldsModified?: Record<string, unknown>;
  transformField?: (field: string) => string | number;
  transformValue?: (field, value) => string | number;
}

function ListDrillDown({
  fieldsModified,
  transformField,
  transformValue,
}: ListDrillDownProps) {
  if (!fieldsModified) {
    return (
      <Text color="gray" my={1}>
        <FormattedMessage id="audit.accordion_empty" />
      </Text>
    );
  }

  return (
    <Flex flexDirection="column" gridGap={1}>
      <Text color="gray.500" fontWeight="bold" my={1}>
        <FormattedMessage id="audit.fields_modified" />:
      </Text>
      {fieldsModified &&
        Object.entries(fieldsModified)?.map(([fieldModified, value], index) => (
          <Text key={index} m={0}>
            {transformField(fieldModified)}:{' '}
            {transformValue(fieldModified, value)}
          </Text>
        ))}
    </Flex>
  );
}

export default ListDrillDown;
