import routes from '../routes';
import { useAppSelector } from './useRedux';

type EntityType = { [entity: string]: boolean };

interface FeatureResponse {
  handleCheckEnableFeature?: (entity?: EntityType) => EntityType;
  featureEntity?: EntityType;
}
export const useFeature = (): FeatureResponse => {
  const allowZeroTouchReseller = useAppSelector(
    (state) => state.portalSettings.portalSettings.allowZeroTouchReseller
  );
  const allowDeviceIOS = useAppSelector(
    (state) => state.portalSettings.portalSettings.allowDeviceIOS
  );

  const allowCarrierCreateCompany = useAppSelector(
    (state) => state.portalSettings.portalSettings.allowCarrierCreateCompany
  );

  const allowExternalApps = useAppSelector(
    (state) => state.portalSettings.portalSettings.allowExternalApps
  );

  const featureByEntity: EntityType = {
    // routes and menus
    // IOS
    registerPolicyIOS: allowDeviceIOS,
    [routes.policies.registerPoliceIOS]: allowDeviceIOS,

    //invoices
    [routes.zeroTouch.invoices.manage]: allowZeroTouchReseller,
    [routes.zeroTouch.invoices.edit]: allowZeroTouchReseller,

    //manage companies
    companies: allowCarrierCreateCompany,
    [routes.carrier.manage]: allowCarrierCreateCompany,
    [routes.carrier.register]: allowCarrierCreateCompany,
    [routes.carrier.edit]: allowCarrierCreateCompany,

    // external apps
    [routes.externalApps.list]: allowExternalApps,
    [routes.externalApps.details]: allowExternalApps,
    [routes.externalApps.register]: allowExternalApps,
  };

  function handleCheckEnableFeature(entityWithPrivilege: EntityType) {
    const entitiesWithFeaturesList = Object?.entries(entityWithPrivilege).map(
      ([entity, privilege]) => {
        return {
          [entity]: featureByEntity?.hasOwnProperty(entity)
            ? featureByEntity[entity] && privilege
            : privilege,
        };
      }
    );
    return Object.assign({}, ...entitiesWithFeaturesList);
  }

  return {
    handleCheckEnableFeature,
    featureEntity: featureByEntity,
  };
};
