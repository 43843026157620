import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put } from 'redux-saga/effects';

import { sanitizeFilter } from '../../helper/filter';
import { DevicesLocationTuple } from '../../types/dashboard';
import {
  listDashboardUsers,
  listDevicesModeKiosk,
  listDevicesWithoutCommunication,
  listDashboardLicenses,
  listUsageTimeByApplications,
  listMostVisitedWebsites,
  listDevicesInventory,
  listDashboardSmsConsumption,
  listDashboardDataConsumption,
  listConsumptionSemester,
  listDevicesLocation,
  dashboardListDevicesStatusSuccess,
  dashboardListDevicesWithoutCommunicationSuccess,
  dashboardUsageTimeByApplicationsSuccess,
  dashboardListUsersSuccess,
  dashboardListLicensesSuccess,
  dashboardListMostVisitedWebsitesSuccess,
  dashboardListDevicesInventorySuccess,
  dashboardListDataConsumptionAppSuccess,
  dashboardListDataConsumptionUserSuccess,
  dashboardListDevicesLocationSuccess,
  dashboardListDataConsumptionSuccess,
  Types,
  dashboardListConsumptionSemesterSuccess,
  dashboardListSmsConsumptionSuccess,
  dashboardListNewUsersSemesterSuccess,
  DashboardLevelSelected,
  listNewUsersSemester,
  listDashboardDataConsumptionUser,
  listDashboardDataConsumptionApp,
  dashboardListTotalLicenses,
  listDashboardTotalLicenses,
  listDevicesActive,
  listDevicesWithoutCompliance,
} from '../dashboard';
import { uiAddLoading } from '../ui';
import { api, safe } from './util';

function* handleListDevicesModeKiosk({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const modeKiosk = yield call(api, 'dashboardListDevicesModeKiosk', payload);
  yield put(dashboardListDevicesStatusSuccess({ modeKiosk }));
}

function* handleListDevicesWithoutCompliance({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const noCompilation = yield call(
    api,
    'dashboardListDevicesWithoutCompliance',
    payload
  );
  yield put(dashboardListDevicesStatusSuccess({ noCompilation }));
}

function* handleListDevicesActive({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const active = yield call(api, 'dashboardListDevicesActive', payload);
  yield put(dashboardListDevicesStatusSuccess({ active }));
}

function* handleListDevicesWithoutCommunication({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(
    api,
    'dashboardListDevicesWithoutCommunication',
    payload
  );
  yield put(dashboardListDevicesWithoutCommunicationSuccess(returnData));
}

function* handleListUsers({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(api, 'dashboardListUsers', payload);
  yield put(dashboardListUsersSuccess(returnData));
}

function* handleListLicenses({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(api, 'dashboardListLicenses', payload);
  yield put(dashboardListLicensesSuccess(returnData));
}

function* handleListTotalLicenses({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(api, 'dashboardListTotalLicenses', payload);
  yield put(dashboardListTotalLicenses(returnData));
}

function* handleListUsageTimeByApplications({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(
    api,
    'dashboardListUsageTimeByApplications',
    payload
  );
  yield put(dashboardUsageTimeByApplicationsSuccess(returnData));
}

function* handleListMostVisitedWebsites({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(
    api,
    'dashboardListMostVisitedWebsites',
    payload
  );
  yield put(dashboardListMostVisitedWebsitesSuccess(returnData));
}

function* handleListDevicesInventory({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(api, 'dashboardListDevicesInventory', payload);
  yield put(dashboardListDevicesInventorySuccess(returnData));
}

function* handleListConsumptionDataApp({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(
    api,
    'dashboardListDataConsumptionApp',
    payload
  );
  yield put(dashboardListDataConsumptionAppSuccess(returnData));
}

function* handleListSmsConsumption({
  payload,
}: PayloadAction<Record<string, unknown>>) {
  const returnData = yield call(api, 'dashBoardListSmsConsumption', payload);
  yield put(dashboardListSmsConsumptionSuccess(returnData));
}

function* handleListConsumptionDataUser({
  payload,
}: PayloadAction<Record<string, unknown>>) {
  const returnData = yield call(
    api,
    'dashboardListDataConsumptionUser',
    payload
  );
  yield put(dashboardListDataConsumptionUserSuccess(returnData));
}

function* handleListDataConsumption({
  payload,
}: PayloadAction<Record<string, unknown>>) {
  const returnData = yield call(api, 'dashboardListDataConsumpion', payload);
  yield put(dashboardListDataConsumptionSuccess(returnData));
}

function* handleListConsumptionSemester({
  payload,
}: PayloadAction<Record<string, unknown>>) {
  const returnData = yield call(
    api,
    'dashboardListConsumptionSemester',
    payload
  );
  yield put(dashboardListConsumptionSemesterSuccess(returnData));
}
function* handleListDevicesLocation({
  payload,
}: PayloadAction<Record<string, unknown>>) {
  const returnData: DevicesLocationTuple[] = yield call(
    api,
    'dashboardListDevicesLocation',
    payload
  );
  yield put(dashboardListDevicesLocationSuccess(returnData));
}

function* handleLisNewUsersSemester({
  type,
  payload,
}: PayloadAction<Record<string, unknown>>) {
  yield put(uiAddLoading(type));
  const returnData = yield call(api, 'dashboardListNewUsersSemester', payload);
  yield put(dashboardListNewUsersSemesterSuccess(returnData));
}

function* handleDashboard({ payload }: PayloadAction<DashboardLevelSelected>) {
  const filters = sanitizeFilter(convertToBackendFilters(payload));

  yield put(listDashboardUsers(filters));
  yield put(listDevicesModeKiosk(filters));
  yield put(listDevicesActive(filters));
  yield put(listDevicesWithoutCompliance(filters));
  yield put(listDevicesWithoutCommunication(filters));
  yield put(listDashboardLicenses(filters));
  yield put(listUsageTimeByApplications(filters));
  yield put(listMostVisitedWebsites(filters));
  yield put(listDevicesInventory(filters));
  yield put(listDashboardDataConsumptionApp(filters));
  yield put(listDashboardDataConsumptionUser(filters));
  yield put(listDashboardSmsConsumption(filters));
  yield put(listDashboardDataConsumption(filters));
  yield put(listConsumptionSemester(filters));
  yield put(listNewUsersSemester(filters));
  yield put(listDevicesLocation(filters));
  yield put(listDashboardTotalLicenses(filters));
}

const convertToBackendFilters = (payload: DashboardLevelSelected) => {
  return {
    [payload.nameId]: payload.value?.id ?? undefined,
    isRoaming: payload.roaming ?? false,
  };
};

export default function* dashboardSaga() {
  yield takeLatest(Types.DASHBOARD, safe(handleDashboard));
  yield takeLatest(Types.LIST_USERS, safe(handleListUsers));
  yield takeLatest(Types.LIST_LICENSES, safe(handleListLicenses));
  yield takeLatest(
    Types.LIST_DEVICES_MODE_KIOSK,
    safe(handleListDevicesModeKiosk)
  );
  yield takeLatest(Types.LIST_DEVICES_ACTIVE, safe(handleListDevicesActive));
  yield takeLatest(
    Types.LIST_DEVICES_WITHOUT_COMPLIANCE,
    safe(handleListDevicesWithoutCompliance)
  );
  yield takeLatest(
    Types.LIST_DEVICE_WITHOUT_COMMUNICATION,
    safe(handleListDevicesWithoutCommunication)
  );
  yield takeLatest(
    Types.LIST_USAGE_TIME_APPLICATIONS,
    safe(handleListUsageTimeByApplications)
  );
  yield takeLatest(Types.LIST_INVENTORY, safe(handleListDevicesInventory));
  yield takeLatest(
    Types.LIST_CONSUMPTION_SEMESTER,
    safe(handleListConsumptionSemester)
  );
  yield takeLatest(
    Types.LIST_DEVICES_LOCATION,
    safe(handleListDevicesLocation)
  );
  yield takeLatest(
    Types.LIST_DATA_CONSUMPTION_APP,
    safe(handleListConsumptionDataApp)
  );
  yield takeLatest(
    Types.LIST_DATA_COMSUNPTION_USER,
    safe(handleListConsumptionDataUser)
  );
  yield takeLatest(
    Types.LIST_MOST_VISITED_WEBSITES,
    safe(handleListMostVisitedWebsites)
  );
  yield takeLatest(Types.LIST_SMS_CONSUMPTION, safe(handleListSmsConsumption));
  yield takeLatest(
    Types.LIST_DATA_CONSUMPTION,
    safe(handleListDataConsumption)
  );
  yield takeLatest(
    Types.LIST_NEW_USERS_SEMESTER,
    safe(handleLisNewUsersSemester)
  );
  yield takeLatest(Types.LIST_TOTAL_LICENSES, safe(handleListTotalLicenses));
}
