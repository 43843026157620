import React from 'react';

import { CardItemType } from '../../../../../../CardContent';
import Input from '../../../../../../Input';
import { intlPolicyPassword, PasswordPolicyChildrenProps } from './index';

export const getComplexPasswordSettings = ({
  passwordPolicies,
  handleChange,
}: PasswordPolicyChildrenProps) => {
  const complexPasswordList: CardItemType[] = [
    {
      title: `${intlPolicyPassword}.passwordMinimumLength`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumLength',
            value: passwordPolicies?.passwordMinimumLength || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.passwordMinimumLetters`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumLetters',
            value: passwordPolicies?.passwordMinimumLetters || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.passwordMinimumLowerCase`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumLowerCase',
            value: passwordPolicies?.passwordMinimumLowerCase || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.passwordMinimumNonLetter`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumNonLetter',
            value: passwordPolicies?.passwordMinimumNonLetter || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.passwordMinimumNumeric`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumNumeric',
            value: passwordPolicies?.passwordMinimumNumeric || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.passwordMinimumSymbols`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumSymbols',
            value: passwordPolicies?.passwordMinimumSymbols || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.passwordMinimumUpperCase`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumUpperCase',
            value: passwordPolicies?.passwordMinimumUpperCase || '',
            onChange: handleChange,
          }}
        />
      ),
    },
  ];

  return complexPasswordList;
};
