import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { ListQueryParameters } from '../../types/generic_list';
import {
  ReportSiteDateType,
  ReportSiteUrlType,
  SiteType,
} from '../../types/reports';

// Action Types

export const Types = {
  SITES_DATE_FIRST: 'reports/SitesListFirst',
  SITES_DATE: 'reports/SitesDate',
  SITES_LIST: 'reports/SitesList',
};

interface SitesDateState {
  sitesDateHistory: SiteType[];
  sitesDate: ReportSiteDateType[];
  sitesUrl: ReportSiteUrlType[];
}

const initialState: SitesDateState = {
  sitesDateHistory: [],
  sitesDate: [],
  sitesUrl: [],
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    reportsSitesDateListSuccess: (
      state,
      action: PayloadAction<ReportSiteDateType[]>
    ) => {
      state.sitesDate = action.payload;
    },
    reportsSitesDateHistoryListSuccess: (
      state,
      action: PayloadAction<SiteType[]>
    ) => {
      state.sitesDateHistory = action.payload;
    },
    reportsSitesDateListClean: (state) => {
      state.sitesDate = initialState.sitesDate;
    },
    reportsSitesUrlListClean: (state) => {
      state.sitesUrl = initialState.sitesUrl;
    },
    reportsSitesUrlListSuccess: (
      state,
      action: PayloadAction<ReportSiteUrlType[]>
    ) => {
      state.sitesUrl = action.payload;
    },
  },
});

export default reportsSlice.reducer;

// Action Creators

export const {
  reportsSitesDateListSuccess,
  reportsSitesDateHistoryListSuccess,
  reportsSitesDateListClean,
  reportsSitesUrlListSuccess,
  reportsSitesUrlListClean,
} = reportsSlice.actions;

export function listDataFirst(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.SITES_DATE_FIRST,
    payload: {
      queryParameters: { ...queryParameters, page: 1 },
      filters: sanitizeFilter(filters),
    },
  };
}

export function listDataMore(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.SITES_DATE,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listSites(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.SITES_LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}
