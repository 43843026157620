import { Flex } from '@chakra-ui/layout';
import { FormattedMessage } from 'react-intl';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import Card from '../../../../../Card';
import FormControl from '../../../../../FormControl';
import ProfileFileInput from '../components/ProfileFileInput';

export const Profile = () => {
  const dispatch = useAppDispatch();
  const { policy } = useAppSelector((state) => state.policy);

  const handleChangeProfileFile = (file: File) => {
    dispatch(
      policySelected({
        profileFile: file,
      })
    );
  };

  const handleChangeProfileFileName = (newProfileFileName: string) => {
    dispatch(
      policySelected({
        displayName: newProfileFileName,
      })
    );
  };

  return (
    <Card p="6" borderRadius="10px">
      <Flex maxW="45rem">
        <FormControl
          textLabel={
            <FormattedMessage id="edit_policy.ios.profile.profile_file_input" />
          }
        >
          <ProfileFileInput
            profileName={policy?.displayName}
            onChange={handleChangeProfileFile}
            onChangeName={handleChangeProfileFileName}
          />
        </FormControl>
      </Flex>
    </Card>
  );
};
