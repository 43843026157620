import { Box, useStyleConfig } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Skeleton from '../Skeleton';

interface SelectAutocompleteProps<T> {
  options: T[];
  value: T | T[];
  onChange?: (value: T | T[]) => void;
  onInputChange?: (value: string) => void;
  getOptionValue?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  placeholder?: React.ReactNode;
  isMulti?: boolean;
  disabled?: boolean;
  labelControl?: string;
  name?: string;
  customStyles?: { [key: string]: string };
  isLoading?: boolean;
  isClearable?: boolean;
}

const SelectAutocomplete: <T>(
  props: SelectAutocompleteProps<T>
) => JSX.Element = ({
  value,
  options,
  onChange,
  onInputChange,
  getOptionValue,
  getOptionLabel,
  placeholder,
  isMulti,
  disabled,
  labelControl,
  name,
  customStyles,
  isLoading,
  isClearable = true,
}) => {
  const style = useStyleConfig('ChakraReactSelect');

  return isLoading ? (
    <Skeleton h="45px" />
  ) : (
    <Select
      chakraStyles={{
        ...style,
        container: (provided) => ({
          ...provided,
          ...customStyles,
        }),
      }}
      size={{ base: 'lg', height: '100%' }}
      name={name}
      options={options}
      isClearable={isClearable}
      isDisabled={disabled}
      components={{
        DropdownIndicator: null,
        NoOptionsMessage: () => (
          <Box m="0 8px">
            <FormattedMessage id="global.not_option_found" />
          </Box>
        ),
      }}
      onInputChange={onInputChange}
      onChange={onChange}
      // onSelectResetsInput={false}
      // closeMenuOnSelect={false}
      getOptionValue={(option) =>
        (getOptionValue && getOptionValue(option)) || `${option['id']}`
      }
      getOptionLabel={(option) =>
        (getOptionLabel && getOptionLabel(option)) || `${option['name']}`
      }
      isMulti={isMulti}
      placeholder={
        placeholder || <FormattedMessage id="global.select_placeholder" />
      }
      labelControl={labelControl}
      value={value}
    />
  );
};

export default SelectAutocomplete;
