import {
  Accordion as ChakraAccordion,
  AccordionProps as ChakraAccordionProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface AccordionProps extends ChakraAccordionProps {
  children: ReactNode;
}

const Accordion = ({ children, ...rest }: AccordionProps) => {
  return (
    <ChakraAccordion allowMultiple allowToggle {...rest}>
      {children}
    </ChakraAccordion>
  );
};

export default Accordion;
