import { Checkbox, Flex, Image } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionCreator, AnyAction } from 'redux';

import { DEBOUNCE_TIME, iconImgToRender } from '../../../../../../helper';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useRedux';
import {
  listEnterpriseAppsToModal,
  listEnterpriseDevices,
  listEnterpriseManufacturers,
  listEnterpriseSystemApps,
  Types,
} from '../../../../../../store/enterpriseApps';
import { uiRemoveMetadata } from '../../../../../../store/ui';
import { DeviceType, ManufacturerType } from '../../../../../../types/device';
import {
  EnterpriseAppsType,
  EnterpriseOriginEnum,
} from '../../../../../../types/enterpriseApps';
import {
  PolicyApplicationType,
  PolicyCompanyApplicationType,
} from '../../../../../../types/policy';
import FormControl from '../../../../../FormControl';
import Heading from '../../../../../Heading';
import Modal from '../../../../../Modal';
import SelectAutocomplete from '../../../../../SelectAutocomplete';
import Table from '../../../../../Table';

export enum ModalEnterpriseAppsModeEnum {
  ENTERPRISE_APPS = 'ENTERPRISE_APPS',
  ENTERPRISE_SYSTEM_APPS = 'ENTERPRISE_SYSTEM_APPS',
}

interface ModalListEnterpriseAppsProps {
  isOpen?: boolean;
  onClose?: () => void;
  policyId?: number;
  handleAddApplicationsToList?: (
    enterpriseApps: PolicyCompanyApplicationType[]
  ) => void;
  applications?: PolicyApplicationType[];
  modalMode?: ModalEnterpriseAppsModeEnum;
}

interface RenderModeProps {
  title: string;
  applicationsToSelect: EnterpriseAppsType[];
  listAction: ActionCreator<AnyAction>;
  listFilterDevices?: ActionCreator<AnyAction>;
  listFilterManufacturer?: ActionCreator<AnyAction>;
  type: string;
}

interface Filters {
  search: string;
  device?: DeviceType;
  manufacturer?: ManufacturerType;
}

const ModalListEnterpriseApps = ({
  isOpen,
  onClose,
  handleAddApplicationsToList,
  applications,
  modalMode = ModalEnterpriseAppsModeEnum.ENTERPRISE_APPS,
}: ModalListEnterpriseAppsProps) => {
  const dispatch = useAppDispatch();
  const {
    enterpriseAppsModal,
    enterpriseSystemApps,
    enterpriseDevices,
    enterpriseManufacturers,
  } = useAppSelector((state) => state.enterpriseApps);
  const [companyApplications, setCompanyApplications] = useState<
    PolicyCompanyApplicationType[]
  >([]);
  const [filters, setFilters] = useState<Filters>({
    search: '',
  });

  const intl = useIntl();

  const allFilters = {
    search: filters.search,
    deviceIds: filters?.device?.id,
    manufacturer: filters?.manufacturer?.manufacturer,
  };

  const entityByMode: Record<ModalEnterpriseAppsModeEnum, RenderModeProps> = {
    [ModalEnterpriseAppsModeEnum.ENTERPRISE_APPS]: {
      title: 'update_policies.modal_enterprise_apps.title',
      applicationsToSelect: enterpriseAppsModal,
      listAction: listEnterpriseAppsToModal,
      type: Types.LIST_MODAL,
    },
    [ModalEnterpriseAppsModeEnum.ENTERPRISE_SYSTEM_APPS]: {
      title: 'update_policies.modal_enterprise_system_apps.title',
      applicationsToSelect: enterpriseSystemApps,
      listAction: listEnterpriseSystemApps,
      listFilterDevices: listEnterpriseDevices,
      listFilterManufacturer: listEnterpriseManufacturers,
      type: Types.LIST_ENTERPRISE_SYSTEM_APPS,
    },
  };

  const listApplications = entityByMode[modalMode].applicationsToSelect?.filter(
    (app) =>
      !applications?.find(
        (application) =>
          application?.companyApplication?.id === app.id ||
          (modalMode === ModalEnterpriseAppsModeEnum.ENTERPRISE_SYSTEM_APPS &&
            application.companyApplication.origin !==
              EnterpriseOriginEnum.MANUAL &&
            application?.companyApplication?.packageName === app.packageName)
      )
  );

  useEffect(() => {
    dispatch(entityByMode[modalMode].listAction(allFilters));
    return () => {
      // type of apps list in apps tab
      dispatch(uiRemoveMetadata(Types.LIST));
    };
  }, [filters]);

  useEffect(() => {
    if (entityByMode[modalMode]?.listFilterDevices) {
      dispatch(entityByMode[modalMode]?.listFilterDevices());
    }

    if (entityByMode[modalMode]?.listFilterManufacturer) {
      dispatch(entityByMode[modalMode]?.listFilterManufacturer());
    }
  }, [modalMode]);

  const handleAddApplications = () => {
    handleAddApplicationsToList(companyApplications);
    setCompanyApplications([]);
    onClose();
  };

  const handleCheckboxChange = (
    companyApplication: PolicyCompanyApplicationType,
    event
  ) => {
    if (event.target.checked) {
      setCompanyApplications([
        ...companyApplications,
        { ...companyApplication },
      ]);
    } else {
      const newList = companyApplications.filter(
        (application) => application.id !== companyApplication.id
      );
      setCompanyApplications(newList);
    }
  };

  function isEnterpriseAppChecked(enterpriseApp: EnterpriseAppsType) {
    return !!companyApplications?.find(
      (companyApplication) =>
        companyApplication.packageName === enterpriseApp.packageName
    );
  }

  const handleFilterEnterpriseDeviceChange = (
    search?: string,
    manufacturer?: string
  ) => {
    dispatch(
      listEnterpriseDevices({
        search,
        manufacturer:
          !filters.device &&
          (manufacturer || filters?.manufacturer?.manufacturer),
      })
    );
  };

  const handleFilterEnterpriseManufacturerChange = (search?: string) => {
    dispatch(listEnterpriseManufacturers({ search }));
  };

  const handleSetFilter = (
    field: keyof Filters,
    value: Filters[keyof Filters]
  ) => {
    setFilters((prevState) => ({ ...prevState, [field]: value }));
  };

  const columns = [
    {
      header: '',
      accessor: 'icon',
    },
    {
      header: intl.formatMessage({
        id: 'enterprise_applications.column.name_app',
      }),
      accessor: 'name',
    },
    {
      header: intl.formatMessage({
        id: 'enterprise_applications.column.package_name',
      }),
      accessor: 'packageName',
    },
    {
      header: intl.formatMessage({
        id: 'enterprise_applications.column.origin',
      }),
      accessor: 'origin',
    },
    {
      header: '',
      accessor: 'checkbox',
    },
  ];

  const data = listApplications?.map((enterpriseApp) => ({
    key: String(enterpriseApp.id),
    cells: [
      {
        field: 'icon',
        value: (
          <Image
            minW="28px"
            maxW="28px"
            src={iconImgToRender(enterpriseApp?.iconBase64)}
          />
        ),
        isExportHidden: true,
      },
      {
        field: 'name',
        value: enterpriseApp.name,
      },
      {
        field: 'packageName',
        value: enterpriseApp.packageName,
      },
      {
        field: 'origin',
        value: intl.formatMessage({
          id: `update_policies.applications.table.origin.${enterpriseApp?.origin}`,
        }),
      },
      {
        field: 'checkbox',
        value: enterpriseApp.origin,
        transform: () => {
          return (
            <Checkbox
              id={enterpriseApp.name}
              name={enterpriseApp.name}
              isChecked={isEnterpriseAppChecked(enterpriseApp)}
              onChange={(event) => handleCheckboxChange(enterpriseApp, event)}
            />
          );
        },
      },
    ],
  }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      modalContentProps={{ bgColor: 'gray.400', py: 4 }}
      preserveScrollBarGap
      modalWidth="950px"
    >
      <Flex
        direction="column"
        width="full"
        px="10"
        maxH="calc(100vh - 150px)"
        overflowY="auto"
      >
        <Heading ml="12px" fontSize="32px" mb="8">
          <FormattedMessage id={entityByMode[modalMode].title} />
        </Heading>
        {modalMode === ModalEnterpriseAppsModeEnum.ENTERPRISE_SYSTEM_APPS && (
          <Flex px="1.5%">
            <FormControl
              textLabel={
                <FormattedMessage id="update_policies.modal_enterprise_apps.manufacturer_filter" />
              }
            >
              <SelectAutocomplete
                options={enterpriseManufacturers}
                value={filters?.manufacturer}
                disabled={!!filters?.device}
                onChange={(manufacturer: ManufacturerType) => {
                  handleSetFilter('manufacturer', manufacturer);
                  handleFilterEnterpriseDeviceChange(
                    undefined,
                    manufacturer?.manufacturer
                  );
                }}
                onInputChange={debounce(
                  handleFilterEnterpriseManufacturerChange,
                  DEBOUNCE_TIME
                )}
                placeholder={
                  <FormattedMessage id="update_policies.modal_enterprise_apps.manufacturer_filter.placeholder" />
                }
                getOptionValue={(option) => option.manufacturer}
                getOptionLabel={(option) => option.manufacturer}
                customStyles={{
                  backgroundColor: 'white',
                  borderRadius: 'md',
                }}
              />
            </FormControl>

            <FormControl
              textLabel={
                <FormattedMessage id="update_policies.modal_enterprise_apps.device_filter" />
              }
            >
              <SelectAutocomplete
                options={enterpriseDevices}
                value={filters.device}
                onChange={(newDevice: DeviceType) => {
                  handleSetFilter('device', newDevice);

                  newDevice &&
                    handleSetFilter('manufacturer', {
                      manufacturer: newDevice?.manufacturer,
                    });
                }}
                onInputChange={debounce(
                  (search) => handleFilterEnterpriseDeviceChange(search),
                  DEBOUNCE_TIME
                )}
                placeholder={
                  <FormattedMessage id="update_policies.modal_enterprise_apps.device_filter.placeholder" />
                }
                getOptionLabel={(option) =>
                  `${option.name || ''} ${option.phoneNumber || ''}`
                }
                customStyles={{
                  backgroundColor: 'white',
                  borderRadius: 'md',
                }}
              />
            </FormControl>
          </Flex>
        )}

        <Table
          headerColumns={columns}
          rows={data}
          keyProp={entityByMode[modalMode].type}
          pageActionsProps={{
            onSearch: (newSearch) => handleSetFilter('search', newSearch),
            disabledAllExportsActions: true,
            labelPrimaryButton: intl.formatMessage({
              id: 'update_policies.modal_enterprise_apps.button_label',
            }),
            onClickPrimaryButton: handleAddApplications,
            disabledPrimaryButton: companyApplications.length === 0,
          }}
        />
      </Flex>
    </Modal>
  );
};

export default ModalListEnterpriseApps;
