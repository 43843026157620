import { Grid } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '../../../../../../../../hooks/useRedux';
import { setPolicyKioskInfos } from '../../../../../../../../store/policy';
import {
  KioskBarStatusEnum,
  KioskNavigateButtonEnum,
  PolicyType,
} from '../../../../../../../../types/policy';
import Card from '../../../../../../../Card';
import FormControl from '../../../../../../../FormControl';
import Select from '../../../../../../../Select';
import {
  barStatusOptions,
  configAccessOptions,
  errorMessagesOptions,
  navigateButtonsOptions,
  powerButtonOptions,
  telephoneServicesOptions,
} from '../.././translates';

interface Props {
  policy: PolicyType;
}

export default function CustomLauncherConfig({ policy }: Props) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const isNavigationDisable =
    policy?.kioskCustomization?.systemNavigation ===
    KioskNavigateButtonEnum.NAVIGATION_DISABLED;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setPolicyKioskInfos({
        name: 'kioskCustomization',
        value: {
          ...policy.kioskCustomization,
          [e.target.name]: e.target.value,
        },
      })
    );
  };

  const handleNavigationButtonsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;

    dispatch(
      setPolicyKioskInfos({
        name: 'kioskCustomization',
        value: {
          ...policy.kioskCustomization,
          [name]: value,
          statusBar:
            policy?.kioskCustomization?.statusBar ===
            KioskBarStatusEnum.NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED
              ? KioskBarStatusEnum.NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED
              : KioskBarStatusEnum.SYSTEM_INFO_ONLY,
        },
      })
    );
  };

  return (
    <Card p="6" borderRadius="10px" mt="6" w="100%">
      <Grid templateColumns="repeat(3, 1fr)" gap={6} w="100%">
        <FormControl
          textLabel={intl.formatMessage({
            id: 'edit_policy.kiosk.navigate_button',
          })}
        >
          <Select
            w="100%"
            name="systemNavigation"
            value={policy?.kioskCustomization?.systemNavigation || ''}
            onChange={handleNavigationButtonsChange}
          >
            {navigateButtonsOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl
          textLabel={intl.formatMessage({
            id: 'edit_policy.kiosk.power_button',
          })}
        >
          <Select
            w="100%"
            name="powerButtonActions"
            value={policy?.kioskCustomization?.powerButtonActions || ''}
            onChange={handleInputChange}
          >
            {powerButtonOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl
          textLabel={intl.formatMessage({
            id: 'edit_policy.kiosk.message_error_exibition',
          })}
        >
          <Select
            w="100%"
            name="systemErrorWarnings"
            value={policy?.kioskCustomization?.systemErrorWarnings || ''}
            onChange={handleInputChange}
          >
            {errorMessagesOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl
          textLabel={intl.formatMessage({
            id: 'edit_policy.kiosk.status_bar_exibition',
          })}
        >
          <Select
            w="100%"
            name="statusBar"
            value={policy?.kioskCustomization?.statusBar || ''}
            onChange={handleInputChange}
          >
            {barStatusOptions
              .filter((option) =>
                isNavigationDisable
                  ? option.value !==
                    KioskBarStatusEnum.NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED
                  : option
              )
              .map((option) => (
                <option key={option.value} value={option.value}>
                  {intl.formatMessage({ id: option.label })}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl
          textLabel={intl.formatMessage({
            id: 'edit_policy.kiosk.config_access',
          })}
        >
          <Select
            w="100%"
            name="deviceSettings"
            value={policy?.kioskCustomization?.deviceSettings || ''}
            onChange={handleInputChange}
          >
            {configAccessOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl
          textLabel={intl.formatMessage({
            id: 'edit_policy.kiosk.telephone_services',
          })}
        >
          <Select
            w="100%"
            name="telephoneServices"
            value={policy?.kioskCustomization?.telephoneServices || ''}
            onChange={handleInputChange}
          >
            {telephoneServicesOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Card>
  );
}
