import { Box, Flex } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../../../../../../hooks/useRedux';
import {
  policyDeleteSiteUrl,
  policyToggleBlockSiteUrl,
} from '../../../../../../../../store/policy';
import { BlockKeywordsType } from '../../../../../../../../types/policy';
import { AlertDelete } from '../../../../../../../Alert';
import Delete from '../../../../../../../Icons/Delete';
import Input from '../../../../../../../Input';
import SwitchButton from '../../../../../../../SwitchButton';
import Text from '../../../../../../../Text';

interface ListUrlType {
  blockKeywords: BlockKeywordsType;
}

const ListUrl = ({ blockKeywords }: ListUrlType) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  return (
    <Flex gridGap={6}>
      <Flex>
        <Input
          inputProps={{
            name: blockKeywords?.keyword,
            value: blockKeywords?.keyword,
            disabled: true,
            type: 'text',
            w: '460px',
          }}
        />
      </Flex>

      <Flex align="center">
        <Flex align="center">
          <SwitchButton
            name="isBlocked"
            isChecked={blockKeywords?.blocked}
            onChange={() => {
              dispatch(
                policyToggleBlockSiteUrl({
                  ...blockKeywords,
                  blocked: !blockKeywords.blocked,
                })
              );
            }}
          />
          <Text ml="15px" w="68.5px">
            <FormattedMessage
              id={blockKeywords?.blocked ? 'global.blocked' : 'global.released'}
            />
          </Text>
        </Flex>
        <Box ml="5%">
          <Delete
            boxSize={6}
            color="warning.500"
            mr="5px"
            onClick={() =>
              AlertDelete(intl, {
                onConfirm: () =>
                  dispatch(policyDeleteSiteUrl(blockKeywords.keyword)),
              })
            }
            cursor="pointer"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ListUrl;
