import { InvoiceDeviceStatusEnum } from '../../../types/invoice';

export const invoiceDeviceStatusToIntl = {
  [InvoiceDeviceStatusEnum.WAITING]: 'invoice_details.device_status.waiting',
  [InvoiceDeviceStatusEnum.REJECT]: 'invoice_details.device_status.reject',
  [InvoiceDeviceStatusEnum.DISAPPROVE]:
    'invoice_details.device_status.disapprove',
  [InvoiceDeviceStatusEnum.REGISTERED]:
    'invoice_details.device_status.approved_zero_touch',
  [InvoiceDeviceStatusEnum.APPROVED]: 'invoice_details.device_status.approved',
  [InvoiceDeviceStatusEnum.NOT_REGISTERED]:
    'invoice_details.device_status.not_registered',
};

export const backofficeInvoiceDeviceStatusToIntl = {
  [InvoiceDeviceStatusEnum.WAITING]: 'invoice_details.device_status.waiting',
  [InvoiceDeviceStatusEnum.REJECT]: 'invoice_details.device_status.reject',
  [InvoiceDeviceStatusEnum.REGISTERED]:
    'invoice_details.device_status.approved_zero_touch',
  [InvoiceDeviceStatusEnum.DISAPPROVE]:
    'invoice_details.device_status.disapprove',
};
