import { Flex, Skeleton } from '@chakra-ui/react';
import React from 'react';

const AppActionAccordionSkeleton = () => {
  const ROWS = 6;

  return (
    <Flex flexDirection="column" gridGap={1}>
      {[...Array(ROWS)].map((_value, rowI) => (
        <Skeleton key={rowI} h="60px" w="full" />
      ))}
    </Flex>
  );
};

export default AppActionAccordionSkeleton;
