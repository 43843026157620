import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PrivilegeEnum } from '../../helper';
import { sanitizeFilter } from '../../helper/filter';
import { AdminUserType } from '../../types/adminUser';
import { ListQueryParameters } from '../../types/generic_list';

// Action Types

export const Types = {
  LIST: 'adminUser/LIST',
  GET: 'adminUser/GET',
  CREATE: 'adminUser/CREATE',
  EDIT: 'adminUser/EDIT',
  EDIT_WITH_EMAIL: 'adminUser/EDIT_WITH_EMAIL',
  LIST_PERMISSIONS: ' adminUser/PERMISSIONS',
  CHANGE_PASSWORD: ' adminUser/CHANGE_PASSWORD',
  DELETE: ' adminUser/DELETE',
};

// Reducer

interface UserAdminState {
  adminUsers: AdminUserType[];
  adminUser: AdminUserType;
  adminUserCopy: AdminUserType;
}

const initialState: UserAdminState = {
  adminUsers: [],
  adminUser: {},
  adminUserCopy: {},
};

export const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    adminUserSelected: (
      state,
      action: PayloadAction<Partial<AdminUserType>>
    ) => {
      state.adminUser = { ...state.adminUser, ...action.payload };
    },
    adminUserSelectedClear: (state) => {
      state.adminUser = initialState.adminUser;
    },
    adminUserListSuccess: (state, action: PayloadAction<AdminUserType[]>) => {
      state.adminUsers = action.payload;
    },
    adminUserGetSuccess: (state, action: PayloadAction<AdminUserType>) => {
      state.adminUser = action.payload;
      state.adminUserCopy = action.payload;
    },
    adminUserCreateSuccess: (state, action: PayloadAction<AdminUserType>) => {
      state.adminUser = action.payload;
    },
    adminUserEditSuccess: (state, action: PayloadAction<AdminUserType>) => {
      state.adminUser = action.payload;
    },
  },
});

export default adminUserSlice.reducer;

// Action Creators

export const {
  adminUserSelected,
  adminUserSelectedClear,
  adminUserListSuccess,
  adminUserGetSuccess,
  adminUserCreateSuccess,
  adminUserEditSuccess,
} = adminUserSlice.actions;

export function listAdminUsers(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function getAdminUsers(id: UserAdminState['adminUser']['id']) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function createAdminUser(data) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}

export function editAdminUser(data) {
  return {
    type: Types.EDIT,
    payload: data,
  };
}

export function editAdminUserWithEmail(data) {
  return {
    type: Types.EDIT_WITH_EMAIL,
    payload: data,
  };
}

export function changePasswordAdminUser(password: string, token: string) {
  return {
    type: Types.CHANGE_PASSWORD,
    payload: { password, token },
  };
}

export function deleteAdminUser(data: {
  id: AdminUserType['id'];
  privilege: PrivilegeEnum;
}) {
  return {
    type: Types.DELETE,
    payload: data,
  };
}
