const routes = {
  login: '/login',
  passwordRecovery: '/password-recovery',
  passwordChange: '/password-change',
  unblock: '/unblock',
  loginConfirmation: '/login-confirmation',
  confirmWelcomeEmail: '/confirm_welcome_email',
  home: '/',
  dashboard: '/dashboard',
  company: {
    info: '/company-info',
    consumption: '/company-consumption',
    license: '/company-license',
    licenseNotActivated: '/company-license/notActivated',
    register: '/company-register',
    callback: '/callback',
    validEmail: '/company-confirm-email',
    audit: '/audit',
  },
  groups: {
    manage: '/manage-groups',
    register: '/edit-group',
    edit: '/edit-group/:id',
  },
  adminUsers: {
    manage: '/manage-admin-users',
    register: '/edit-admin-users',
    edit: '/edit-admin-users/:id',
  },
  sites: {
    reportUrl: '/report-url',
    reportDate: '/report-date',
  },
  informes: {
    deviceLocation: '/device-location',
    locationHistory: '/location-history',
  },
  application: {
    manage: '/manage-applications',
    applicationDeviceUsers: '/application-device-users/:applicationName',
    consumptionHistory: '/consumption-history/:applicationName',
    consumptionHistoryByDeviceUser:
      '/consumption-history/:applicationName/:deviceUserId',
  },
  messages: {
    list: '/messages-list',
    register: '/messages-register',
    details: '/message-details/:id',
  },

  enterpriseApps: {
    manage: '/managed-applications',
    googlePlayManaged: '/google-play-managed',
  },
  documents: {
    list: '/documents-list',
    register: '/document-register',
    details: '/document-details/:id',
  },
  device: {
    manage: '/manage-device',
    battery: '/device-battery/:id',
    storage: '/device-storage/:id',
    infos: '/device-infos/:id',
    withoutCommunication: '/device-without-communication',
    nonCompliance: '/device-non-compliance/:id',
  },
  zeroTouch: {
    manageZeroTouch: '/manage-zero-touch',
    invoices: {
      manage: '/manage-invoices',
      edit: '/manage-invoices/edit/:id',
    },
    backoffice: {
      manage: '/manage-zero-touch-register',
      edit: '/manage-zero-touch-register/edit/:id',
    },
  },
  policies: {
    register: '/register-policy',
    manage: '/manage-policy',
    edit: '/edit-policy/:id',
    registerPoliceIOS: '/edit-policy-register-ios',
  },
  geolocation: '/geolocation',
  profileConsumption: {
    manage: '/consumption-profile',
    register: '/consumption-profile/register/:type',
    edit: '/consumption-profile/edit/:type/:id',
    groups: {
      register: '/consumption-profile/register/groups',
      edit: '/consumption-profile/edit/groups/:id',
    },
    users: {
      register: '/consumption-profile/register/users',
      edit: '/consumption-profile/edit/users/:id',
    },
  },
  generalConfig: {
    manage: '/general-config',
    register: '/general-config/register/:type',
    edit: '/general-config/edit/:type/:id',
    networkSettings: {
      list: '/manage-network-settings',
      edit: '/manage-network-settings/edit/:id',
      register: '/manage-network-settings/register',
    },
  },
  applicationConfig: {
    manage: '/android/manage-application',
    register: '/android/register-application',
    send: '/android/send-application',
    history: '/android/history-application/:id',
    historyStatus: '/android/history-application/:id/status/:history',
  },
  terms: {
    privacyPolicy: '/terms/privacy-policy',
    termsOfUsage: '/terms/terms-of-usage',
  },
  support: '/support',
  supportRequest: '/support-request',
  audit: '/audit',
  faq: '/faq',
  helpDesk: {
    companiesList: '/companies-list',
  },
  remoteView: '/remote-view/:id',
  externalApps: {
    list: '/external-apps-list',
    register: '/external-apps-register',
    details: '/external-apps-details/:id',
  },
  carrier: {
    manage: '/manage-companies',
    edit: '/manage-companies/edit/:id',
    register: '/manage-companies/register',
  },
  remoteApps: {
    list: '/remote-apps-list',
    register: '/remote-apps-register',
    details: '/remote-apps-details/:id',
  },
};

export default routes;
