export enum DayOfWeekEnum {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}
export const dayOfWeekOptions = [
  {
    value: DayOfWeekEnum.MONDAY,
    label: 'general_config.values.dayOfWeek.MONDAY',
  },
  {
    value: DayOfWeekEnum.TUESDAY,
    label: 'general_config.values.dayOfWeek.TUESDAY',
  },
  {
    value: DayOfWeekEnum.WEDNESDAY,
    label: 'general_config.values.dayOfWeek.WEDNESDAY',
  },
  {
    value: DayOfWeekEnum.THURSDAY,
    label: 'general_config.values.dayOfWeek.THURSDAY',
  },
  {
    value: DayOfWeekEnum.FRIDAY,
    label: 'general_config.values.dayOfWeek.FRIDAY',
  },
  {
    value: DayOfWeekEnum.SATURDAY,
    label: 'general_config.values.dayOfWeek.SATURDAY',
  },
  {
    value: DayOfWeekEnum.SUNDAY,
    label: 'general_config.values.dayOfWeek.SUNDAY',
  },
];

export const daysOfWeekList = [
  DayOfWeekEnum.MONDAY,
  DayOfWeekEnum.TUESDAY,
  DayOfWeekEnum.WEDNESDAY,
  DayOfWeekEnum.THURSDAY,
  DayOfWeekEnum.FRIDAY,
  DayOfWeekEnum.SATURDAY,
  DayOfWeekEnum.SUNDAY,
];

export const initialWorkTimeHours = {
  start: '00:00',
  end: '23:59',
};
