import {
  Input as InputChakra,
  InputProps as InputPropsChakra,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

import Skeleton from '../Skeleton';

export type InputProps = InputPropsChakra & {
  inputProps: Record<string, unknown>;
  leftElement?: unknown;
  rightElement?: unknown;
  validation?: Record<string, unknown>;
  isLoading?: boolean;
  testId?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { inputProps, leftElement, rightElement, validation, isLoading, testId },
    ref
  ) => (
    <>
      {isLoading ? (
        <Skeleton h="40px" w={`${inputProps?.width}}`} />
      ) : (
        <InputGroup>
          {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
          <InputChakra
            data-testid={testId || inputProps?.name}
            ref={ref}
            {...inputProps}
            {...validation}
          />
          {rightElement && (
            <InputRightElement>{rightElement}</InputRightElement>
          )}
        </InputGroup>
      )}
    </>
  )
);

export default Input;
