import { Flex } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import { policySetFreezePeriod } from '../../../../../../../store/policy';
import { FreezePeriodType } from '../../../../../../../types/policy';
import Button from '../../../../../../Button';
import FreezePeriod from './FreezePeriod';
import { intlPolicyOperationalSystem } from './index';

interface FreezeScheduleProps {
  freezePeriods: FreezePeriodType[];
}

const FreezeSchedule = ({ freezePeriods }: FreezeScheduleProps) => {
  const dispatch = useAppDispatch();

  const handleAddFreeze = () => {
    const newFreezePeriod = {
      startDate: null,
      endDate: null,
      id: _.uniqueId(),
    };
    dispatch(policySetFreezePeriod([...freezePeriods, newFreezePeriod]));
  };

  const handleRemoveFreeze = (indexOfPeriod) => {
    const filterFreezePeriods = freezePeriods.filter(
      (_freeze, iFreeze) => iFreeze !== indexOfPeriod
    );
    dispatch(policySetFreezePeriod(filterFreezePeriods));
  };

  return (
    <Flex flexDir="column" justifyContent="center" align="center">
      {freezePeriods?.map((freeze, iFreeze) => (
        <FreezePeriod
          key={freeze?.id}
          freezePeriod={freeze}
          indexOfFreeze={iFreeze}
          handleRemovePeriod={() => handleRemoveFreeze(iFreeze)}
        />
      ))}
      {freezePeriods?.length < 4 && (
        <Button variant="link" onClick={handleAddFreeze} my="6">
          <FormattedMessage
            id={`${intlPolicyOperationalSystem}.add_freeze_period`}
          />
        </Button>
      )}
    </Flex>
  );
};

export default FreezeSchedule;
