import {
  ActionCreatorWithoutPayload,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { getNameEntity, isSelected, isSelectedClear } from '../../helper/redux';
import { useAppSelector } from '../../hooks/useRedux';
import {
  actionsCreatorsCRUDType,
  ConsumptionProfileMode,
  ConsumptionProfileTab,
  GeneralConsumptionType,
  GroupConsumptionType,
  RuleType,
  UserConsumptionType,
} from '../../types/consumptionProfile';
import { ListQueryParameters } from '../../types/generic_list';

// Action Types

export const Types = {
  UPDATE_TAB: 'consumption_profile/UPDATE_TAB',
  GET_GENERAL: 'consumption_profile/GET_GENERAL',
  UPDATE_GENERAL: 'consumption_profile/UPDATE_GENERAL',
  LIST_GROUP: 'consumption_profile/LIST_GROUP',
  GET_GROUP: 'consumption_profile/GET_GROUP',
  CREATE_GROUP: 'consumption_profile/CREATE_GROUP',
  UPDATE_GROUP: 'consumption_profile/UPDATE_GROUP',
  DELETE_GROUP: 'consumption_profile/DELETE_GROUP',
  LIST_USER: 'consumption_profile/LIST_USER',
  GET_USER: 'consumption_profile/GET_USER',
  CREATE_USER: 'consumption_profile/CREATE_USER',
  UPDATE_USER: 'consumption_profile/UPDATE_USER',
  DELETE_USER: 'consumption_profile/DELETE_USER',
};

export const TypesConsumptionProfile = {
  list: {
    [ConsumptionProfileTab.GROUP]: Types.LIST_GROUP,
    [ConsumptionProfileTab.USER]: Types.LIST_USER,
  },
  create: {
    [ConsumptionProfileTab.GROUP]: Types.CREATE_GROUP,
    [ConsumptionProfileTab.USER]: Types.CREATE_USER,
  },
  update: {
    [ConsumptionProfileTab.GENERAL]: Types.UPDATE_GENERAL,
    [ConsumptionProfileTab.GROUP]: Types.UPDATE_GROUP,
    [ConsumptionProfileTab.USER]: Types.UPDATE_USER,
  },
  delete: {
    [ConsumptionProfileTab.GROUP]: Types.DELETE_GROUP,
    [ConsumptionProfileTab.USER]: Types.DELETE_USER,
  },
};

// Reducer

interface ConsumptionProfileState {
  selectedTab: ConsumptionProfileTab;
  general: {
    selected: GeneralConsumptionType;
  };
  groups: {
    list: GroupConsumptionType[];
    selected: GroupConsumptionType;
  };
  users: {
    list: UserConsumptionType[];
    selected: UserConsumptionType;
  };
}

export const initialState: ConsumptionProfileState = {
  selectedTab: ConsumptionProfileTab.GENERAL,
  general: {
    selected: null,
  },
  groups: {
    list: [],
    selected: null,
  },
  users: {
    list: [],
    selected: null,
  },
};

const getRuleMode = (rule: RuleType): RuleType => {
  if (rule.mode === ConsumptionProfileMode.ASSIGNED && rule.limit === null) {
    return {
      ...rule,
      mode: ConsumptionProfileMode.LEVEL_UP,
    };
  }

  return rule;
};

const consumptionProfileSlice = createSlice({
  name: 'consumptionProfile',
  initialState,
  reducers: {
    consumptionProfileUpdateTab: (
      state,
      action: PayloadAction<ConsumptionProfileTab>
    ) => {
      state.selectedTab = action.payload;
    },
    consumptionProfileGeneralSelected: (
      state,
      action: PayloadAction<GeneralConsumptionType>
    ) => {
      state.general.selected = { ...state.general.selected, ...action.payload };
    },
    consumptionProfileGroupSelected: (
      state,
      action: PayloadAction<GeneralConsumptionType>
    ) => {
      state.groups.selected = { ...state.groups.selected, ...action.payload };
    },
    consumptionProfileUserSelected: (
      state,
      action: PayloadAction<GeneralConsumptionType>
    ) => {
      state.users.selected = { ...state.users.selected, ...action.payload };
    },
    consumptionProfileGeneralGetSuccess: (
      state,
      action: PayloadAction<GeneralConsumptionType>
    ) => {
      state.general.selected = action.payload;
    },
    consumptionProfileGeneralUpdateSuccess: (
      state,
      action: PayloadAction<GeneralConsumptionType>
    ) => {
      state.general.selected = action.payload;
    },
    consumptionProfileGroupListSuccess: (
      state,
      action: PayloadAction<GroupConsumptionType[]>
    ) => {
      state.groups.list = action.payload;
    },
    consumptionProfileGroupGetSuccess: (
      state,
      action: PayloadAction<GroupConsumptionType>
    ) => {
      state.groups.selected = action.payload;
    },
    consumptionProfileGroupCreateSuccess: (
      state,
      action: PayloadAction<GroupConsumptionType>
    ) => {
      state.groups.selected = action.payload;
    },
    consumptionProfileGroupUpdateSuccess: (
      state,
      action: PayloadAction<GroupConsumptionType>
    ) => {
      state.groups.selected = action.payload;
    },
    consumptionProfileUserListSuccess: (
      state,
      action: PayloadAction<UserConsumptionType[]>
    ) => {
      state.users.list = action.payload;
    },
    consumptionProfileUserGetSuccess: (
      state,
      action: PayloadAction<UserConsumptionType>
    ) => {
      state.users.selected = {
        ...action.payload,
        data: getRuleMode(action.payload.data),
        dataRoaming: getRuleMode(action.payload.dataRoaming),
        sms: getRuleMode(action.payload.sms),
        smsRoaming: getRuleMode(action.payload.smsRoaming),
      };
    },
    consumptionProfileUserCreateSuccess: (
      state,
      action: PayloadAction<UserConsumptionType>
    ) => {
      state.users.selected = action.payload;
    },
    consumptionProfileUserUpdateSuccess: (
      state,
      action: PayloadAction<UserConsumptionType>
    ) => {
      state.users.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isSelected, (state, action) => {
        const { type, payload: newEntity } = action;
        const nameEntity = getNameEntity(type);
        const { selected } = state[nameEntity];
        state[nameEntity].selected = selected
          ? { ...selected, ...newEntity }
          : newEntity;
      })
      .addMatcher(isSelectedClear, (state, action) => {
        const { type } = action;
        const nameEntity = getNameEntity(type);
        state[nameEntity].selected = initialState[nameEntity].selected;
      });
  },
});

export default consumptionProfileSlice.reducer;

// Action Creators

export const {
  consumptionProfileUpdateTab,
  consumptionProfileGeneralSelected,
  consumptionProfileGroupSelected,
  consumptionProfileUserSelected,
  consumptionProfileGeneralGetSuccess,
  consumptionProfileGeneralUpdateSuccess,
  consumptionProfileGroupListSuccess,
  consumptionProfileGroupGetSuccess,
  consumptionProfileGroupCreateSuccess,
  consumptionProfileGroupUpdateSuccess,
  consumptionProfileUserListSuccess,
  consumptionProfileUserGetSuccess,
  consumptionProfileUserCreateSuccess,
  consumptionProfileUserUpdateSuccess,
} = consumptionProfileSlice.actions;

export const consumptionGeneralSelected = createAction<
  Partial<GeneralConsumptionType>
>(`${consumptionProfileSlice.name}/general/selected`);
export const consumptionGroupSelected = createAction<
  Partial<GroupConsumptionType>
>(`${consumptionProfileSlice.name}/groups/selected`);
export const consumptionUserSelected = createAction<
  Partial<UserConsumptionType>
>(`${consumptionProfileSlice.name}/users/selected`);

export const consumptionGeneralSelectedClear: ActionCreatorWithoutPayload =
  createAction(`${consumptionProfileSlice.name}/general/selectedClear`);
export const consumptionGroupSelectedClear: ActionCreatorWithoutPayload =
  createAction(`${consumptionProfileSlice.name}/groups/selectedClear`);
export const consumptionUserSelectedClear: ActionCreatorWithoutPayload =
  createAction(`${consumptionProfileSlice.name}/users/selectedClear`);

export const getConsumptionGeneral = createAction(Types.GET_GENERAL);

export const updateConsumptionGeneral = createAction<GeneralConsumptionType>(
  Types.UPDATE_GENERAL
);

// Consumption Group

export const listConsumptionGroup = createAction(
  Types.LIST_GROUP,
  (
    queryParameters: ListQueryParameters,
    filters?: Record<string, unknown>
  ) => ({
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  })
);

export const createConsumptionGroup = createAction<GroupConsumptionType>(
  Types.CREATE_GROUP
);

export const getConsumptionGroup = createAction<GroupConsumptionType['ruleId']>(
  Types.GET_GROUP
);

export const updateConsumptionGroup = createAction<GroupConsumptionType>(
  Types.UPDATE_GROUP
);

export const deleteConsumptionGroup = createAction<
  GroupConsumptionType['ruleId']
>(Types.DELETE_GROUP);

// Consumption User

export const listConsumptionUser = createAction(
  Types.LIST_USER,
  (
    queryParameters: ListQueryParameters,
    filters?: Record<string, unknown>
  ) => ({
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  })
);

export const createConsumptionUser = createAction<UserConsumptionType>(
  Types.CREATE_USER
);

export const getConsumptionUser = createAction<UserConsumptionType['ruleId']>(
  Types.GET_USER
);

export const updateConsumptionUser = createAction<UserConsumptionType>(
  Types.UPDATE_USER
);

export const deleteConsumptionUser = createAction<
  UserConsumptionType['ruleId']
>(Types.DELETE_USER);

export const actionsCreatorsConsumptionProfile: actionsCreatorsCRUDType = {
  list: {
    [ConsumptionProfileTab.GENERAL]: () => createAction(''),
    [ConsumptionProfileTab.GROUP]: listConsumptionGroup,
    [ConsumptionProfileTab.USER]: listConsumptionUser,
  },
  get: {
    [ConsumptionProfileTab.GENERAL]: getConsumptionGeneral,
    [ConsumptionProfileTab.GROUP]: getConsumptionGroup,
    [ConsumptionProfileTab.USER]: getConsumptionUser,
  },
  create: {
    [ConsumptionProfileTab.GENERAL]: () => createAction(''),
    [ConsumptionProfileTab.GROUP]: createConsumptionGroup,
    [ConsumptionProfileTab.USER]: createConsumptionUser,
  },
  update: {
    [ConsumptionProfileTab.GENERAL]: updateConsumptionGeneral,
    [ConsumptionProfileTab.GROUP]: updateConsumptionGroup,
    [ConsumptionProfileTab.USER]: updateConsumptionUser,
  },
  delete: {
    [ConsumptionProfileTab.GENERAL]: () => createAction(''),
    [ConsumptionProfileTab.GROUP]: deleteConsumptionGroup,
    [ConsumptionProfileTab.USER]: deleteConsumptionUser,
  },
  selected: {
    [ConsumptionProfileTab.GENERAL]: consumptionGeneralSelected,
    [ConsumptionProfileTab.GROUP]: consumptionGroupSelected,
    [ConsumptionProfileTab.USER]: consumptionUserSelected,
  },
  clear: {
    [ConsumptionProfileTab.GENERAL]: consumptionGeneralSelectedClear,
    [ConsumptionProfileTab.GROUP]: consumptionGroupSelectedClear,
    [ConsumptionProfileTab.USER]: consumptionUserSelectedClear,
  },
};

// Selectors

export const consumptionProfile = (type: ConsumptionProfileTab) => {
  return useAppSelector((state) => state.consumptionProfile)?.[type];
};
