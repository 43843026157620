import { Tooltip, TooltipProps } from '@chakra-ui/react';

type Props = TooltipProps;

export const CustomTooltip = (props: Props) => {
  return (
    <Tooltip
      placement="top"
      hasArrow
      arrowSize={8}
      padding="2"
      borderRadius="5"
      {...props}
    />
  );
};
