import { Checkbox } from '@chakra-ui/checkbox';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, GridItem } from '@chakra-ui/layout';
import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/slider';
import _ from 'lodash';
import { ChangeEventHandler, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { blockTimeModalIntlKey } from '.';
import { DEBOUNCE_TIME } from '../../../../../../../helper';
import { AppWorkTime } from '../../../../../../../types/policy';
import Input from '../../../../../../Input';
import Text from '../../../../../../Text';
import { initialWorkTimeHours } from '../../PolicySettings/utils';

interface Props {
  workTime: AppWorkTime;
  disabled: boolean;
  onChange: (workTime: AppWorkTime) => void;
}

const formatTimeByMinutes = (value: number) => {
  let minutes: number | string = value % 60;
  let hours: number | string = (value - minutes) / 60;

  if (hours === 24) {
    hours = 23;
    minutes = 59;
  }

  minutes = `${minutes}`.padStart(2, '0');
  hours = `${hours}`.padStart(2, '0');

  return `${hours}:${minutes}`;
};

const transformHoursToSeconds = (value: string) => {
  if (!value) return 0;

  const times = value.split(':').map(Number);

  if (!times[1]) times[1] = 0;

  if (!times[0]) times[0] = 0;

  return times[1] + times[0] * 60;
};

export const WorkTimeDay = ({ workTime, disabled, onChange }: Props) => {
  const intl = useIntl();
  const [restricted, setRestricted] = useState(workTime?.blocked || false);
  const [sliderValue, setSliderValue] = useState([
    transformHoursToSeconds(workTime?.startTime) || 0,
    transformHoursToSeconds(workTime?.endTime) || 1440,
  ]);
  const startSliderHours = useMemo<string>(
    () => formatTimeByMinutes(sliderValue[0]),
    [sliderValue[0]]
  );
  const endSliderHours = useMemo<string>(
    () => formatTimeByMinutes(sliderValue[1]),
    [sliderValue[1]]
  );

  const handleSetWorkTimeState = useCallback(
    _.debounce((workTimeToSpread: Partial<AppWorkTime>) => {
      onChange({
        ...workTime,
        ...workTimeToSpread,
      });
    }, DEBOUNCE_TIME),
    [workTime]
  );

  const handleChange = (value: [number, number]) => {
    if (value[0] === value[1]) return;

    setSliderValue(value);

    handleSetWorkTimeState({
      startTime: formatTimeByMinutes(value[0]),
      endTime: formatTimeByMinutes(value[1]),
    });
  };

  const handleChangeRestricted: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setRestricted(event.target.checked);

    handleSetWorkTimeState({ blocked: event.target.checked });
  };

  const handleClean = () => {
    setSliderValue([0, 1440]);
    setRestricted(false);

    handleSetWorkTimeState({
      blocked: false,
      endTime: initialWorkTimeHours.end,
      startTime: initialWorkTimeHours.start,
    });
  };

  return (
    <>
      <GridItem>
        <Text m={0} color="gray.900">
          {intl.formatMessage({
            id: `${blockTimeModalIntlKey}.dayOfWeek.${workTime.dayOfWeek}`,
          })}
        </Text>
      </GridItem>

      <GridItem placeItems="center">
        <Checkbox
          isDisabled={disabled}
          isChecked={restricted}
          onChange={handleChangeRestricted}
        />
      </GridItem>

      <GridItem>
        <Flex gridGap={4}>
          <Flex width="10rem">
            <Input inputProps={{ disabled: true, value: startSliderHours }} />
          </Flex>

          <RangeSlider
            aria-label={['min', 'max']}
            colorScheme="primary"
            size="lg"
            min={0}
            max={1440}
            step={15}
            value={sliderValue}
            onChange={handleChange}
            isDisabled={disabled || restricted}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>

            <RangeSliderThumb index={0} bg="gray.200" />

            <RangeSliderThumb index={1} bg="gray.200" />
          </RangeSlider>

          <Flex width="10rem">
            <Input inputProps={{ disabled: true, value: endSliderHours }} />
          </Flex>
        </Flex>
      </GridItem>

      <GridItem display="flex" alignItems="center" justifyContent="end">
        <CloseIcon
          cursor="pointer"
          ml="auto"
          boxSize={3}
          color="gray.900"
          onClick={handleClean}
        />
      </GridItem>
    </>
  );
};
