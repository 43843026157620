import { loadAll } from 'js-yaml';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import { RootState } from '..';

import { FAQType } from '../../types/help';
import { LoginAdminUserType } from '../../types/loginAdminUser';
import { PortalSettingsType } from '../../types/portalSettings';
import { helpFaqSuccess, helpGuidesSuccess, Types } from '../help';
import { api, requestSimple, safe } from './util';

function* handleFaq() {
  const { language }: LoginAdminUserType = yield select(
    (state: RootState) => state.auth.user
  );
  const { defaultLanguage }: PortalSettingsType = yield select(
    (state: RootState) => state.portalSettings.portalSettings
  );
  const data = yield call(api, 'faq', language || defaultLanguage || 'pt-br');
  const faqs = loadAll(data) as FAQType[];
  yield put(helpFaqSuccess(faqs));
}

function* handleGuides({ type }) {
  yield call(requestSimple, api, 'guides', type, helpGuidesSuccess);
}

export default function* helpSaga() {
  yield takeLatest(Types.FAQ, safe(handleFaq));
  yield takeLatest(Types.GUIDES, safe(handleGuides));
}
