import { takeLatest, call } from 'redux-saga/effects';

import {
  Types,
  termPrivacyPolicySuccess,
  termTermsOfUsageSuccess,
} from '../term';
import { api, requestSimple, safe } from './util';

function* handlePrivacyPolicy({ type }) {
  yield call(
    requestSimple,
    api,
    'termGetPrivacyPolicy',
    type,
    termPrivacyPolicySuccess
  );
}

function* handleTermsOfUsage({ type }) {
  yield call(
    requestSimple,
    api,
    'termTermsOfUsage',
    type,
    termTermsOfUsageSuccess
  );
}

export default function* termSaga() {
  yield takeLatest(Types.PRIVACY_POLICY, safe(handlePrivacyPolicy));
  yield takeLatest(Types.TERMS_OF_USAGE, safe(handleTermsOfUsage));
}
