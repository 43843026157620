import axios from 'axios';

import { authRefreshToken, logout } from '../auth';
import { store } from '../index';
import { portalSettingsGetSuccess } from '../portalSettings';
import { VER1 } from './Api';
import { postRefreshToken } from './ApiRefreshToken';
import { endpoint } from './endpoint';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3003/api';

const axiosApi = axios.create({
  baseURL: BASE_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { dispatch, getState } = store;

    if (
      originalRequest.url === `${VER1}${endpoint.logout}` ||
      originalRequest.url === `${VER1}${endpoint.login}` ||
      originalRequest.url === `${VER1}${endpoint.loginConfirmation}` ||
      originalRequest.url === `${VER1}${endpoint.companyValidateEmailToken}` ||
      originalRequest.url === `${VER1}${endpoint.refresh}`
    ) {
      //
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = getState().auth.refreshToken;

        const response = await postRefreshToken(refreshToken);
        const newResponse = response.data;

        dispatch(
          authRefreshToken({
            expiresIn: newResponse?.expiresIn,
            accessToken: newResponse?.accessToken,
            refreshToken: newResponse?.refreshToken,
            accessTokenCreateAt: newResponse?.accessTokenCreateAt,
          })
        );
        dispatch(portalSettingsGetSuccess(newResponse.user.settings || {}));

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newResponse?.accessToken}`;
        return axios(originalRequest);
      } catch (error) {
        if (error?.response?.status === 401) {
          dispatch(logout({ fromExpiration: true }));
        }
      }
    }

    return Promise.reject(error);
  }
);

export { axiosApi };
