import { Flex } from '@chakra-ui/layout';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { intlPolicySettingsKey } from '../..';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../../../store/policy';
import { ChoosePrivateKeyRuleType } from '../../../../../../../../types/policy';
import { AlertDelete } from '../../../../../../../Alert';
import Button from '../../../../../../../Button';
import Delete from '../../../../../../../Icons/Delete';
import Edit from '../../../../../../../Icons/Edit';
import TableActions from '../../../../../../../TableActions';
import MenuItem from '../../../../../../../TableActions/MenuItem';
import AddPrivateKeyModal from './AddPrivateKeyModal';

export default function ChoosePrivateKeyRule() {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { policy } = useAppSelector((state) => state.policy);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [editRule, setEditRule] = useState<
    ChoosePrivateKeyRuleType | undefined
  >();

  const handleAddRule = (newRule: ChoosePrivateKeyRuleType) => {
    dispatch(
      policySelected({
        choosePrivateKeyRules: [
          newRule,
          ...(policy?.choosePrivateKeyRules || []),
        ],
      })
    );
  };

  const handleEditRule = (editedRule: ChoosePrivateKeyRuleType) => {
    dispatch(
      policySelected({
        choosePrivateKeyRules: policy?.choosePrivateKeyRules?.map((rule) =>
          rule.privateKeyAlias === editRule.privateKeyAlias ? editedRule : rule
        ),
      })
    );
  };

  const handleDeleteRule = (deleteRule: ChoosePrivateKeyRuleType) => {
    dispatch(
      policySelected({
        choosePrivateKeyRules: policy?.choosePrivateKeyRules?.filter(
          (rule) => rule.privateKeyAlias !== deleteRule.privateKeyAlias
        ),
      })
    );
  };

  const onClickEditRule = (rule: ChoosePrivateKeyRuleType) => {
    setEditRule(rule);
    setIsOpenAdd(true);
  };

  return (
    <Flex w="full" maxW="45rem" direction="column" gridGap={4} marginX="auto">
      {policy.choosePrivateKeyRules?.map((rule) => (
        <Flex
          width="100%"
          alignItems="center"
          p={2}
          borderRadius="md"
          boxShadow="base"
          key={rule.privateKeyAlias}
        >
          <Flex w="full" marginRight={4}>
            {rule.privateKeyAlias}
          </Flex>

          <TableActions
            moreItems={
              <>
                <MenuItem
                  text={intl.formatMessage({
                    id: `${intlPolicySettingsKey}.safety.choose_private_key_rule.edit_button`,
                  })}
                  onClick={() => onClickEditRule(rule)}
                  icon={<Edit boxSize={5} />}
                />
                <MenuItem
                  text={intl.formatMessage({
                    id: `${intlPolicySettingsKey}.safety.choose_private_key_rule.delete_button`,
                  })}
                  onClick={() =>
                    AlertDelete(intl, {
                      onConfirm: () => handleDeleteRule(rule),
                    })
                  }
                  icon={<Delete boxSize={5} color="warning.500" />}
                />
              </>
            }
          />
        </Flex>
      ))}

      <Flex w="full" justify="center">
        <Button
          onClick={() => setIsOpenAdd(true)}
          variant="link"
          w="min-content"
        >
          {intl.formatMessage({
            id: `${intlPolicySettingsKey}.safety.choose_private_key_rule.add_button`,
          })}
        </Button>
      </Flex>

      <AddPrivateKeyModal
        isOpen={isOpenAdd}
        editRule={editRule}
        onClose={() => {
          setIsOpenAdd(false);
          setEditRule(undefined);
        }}
        onAdd={handleAddRule}
        onEdit={handleEditRule}
      />
    </Flex>
  );
}
