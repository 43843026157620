import { ID } from './util';

export interface NetworkSettingsType {
  id?: number;
  name?: string;
  ssid?: string;
  security?: NetworkSecurityEnum;
  autoConnect?: boolean;
  passphrase?: string;
  eapInner?: EAPInnerEnum;
  eapOuter?: EAPOuterEnum;
  certificateServerType?: CertificateTypeEnum;
  certificateServer?: CertificateFormatType;
  certificateClientType?: CertificateTypeEnum;
  certificateClient?: CertificateFormatType;
  policies?: string[];
}

export enum NetworkSecurityEnum {
  NONE = 'None',
  WEP_PSK = 'WEP-PSK',
  WPA_PSK = 'WPA-PSK',
  WPA_EAP = 'WPA-EAP',
}

export enum EAPOuterEnum {
  EAP_AKA = 'EAP-AKA',
  EAP_TLS = 'EAP-TLS',
  EAP_TTLS = 'EAP-TTLS',
  EAP_SIM = 'EAP-SIM',
  PEAP = 'PEAP',
}

export enum EAPInnerEnum {
  MSCHAPv2 = 'MSCHAPv2',
  PAP = 'PAP',
}

export enum CertificateTypeEnum {
  PKCS12 = 'PKCS12',
  X509 = 'X509',
}

export interface NetworkSettingsData {
  id: ID;
  networkSetting: NetworkSettingsType;
}

export interface CertificateFormatType {
  certificate?: string;
  name?: string;
  type?: CertificateTypeEnum;
}
