import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PortalSettingsType } from '../../types/portalSettings';

// Action Types

// Reducer
export interface PortalSettingsState {
  portalSettings: PortalSettingsType;
}

const initialState: PortalSettingsState = {
  portalSettings: {
    backendVersion: '0',
    productVersion: '0',
  },
};

export const portalSettingsSlice = createSlice({
  name: 'portalSettings',
  initialState,
  reducers: {
    portalSettingsGetSuccess: (
      state,
      action: PayloadAction<PortalSettingsType>
    ) => {
      state.portalSettings = action.payload;
    },
  },
});
export default portalSettingsSlice.reducer;

// Action Creators

export const { portalSettingsGetSuccess } = portalSettingsSlice.actions;
