import { IconProps as ChakraIconProps, Tag, useTheme } from '@chakra-ui/react';
import React from 'react';

import AndroidIcon from '../Icons/Android';
import IOS from '../Icons/IOS';

type OperationalSystemIconsSize = 'sm' | 'md' | 'lg' | 'xl';

interface OperationalSystemIconsProps {
  hideIOS?: boolean;
  hideAndroid?: boolean;
  iconProps?: ChakraIconProps;
  size?: OperationalSystemIconsSize;
}

const OperationalSystemIcons = ({
  hideIOS,
  hideAndroid,
  iconProps,
  size = 'md',
}: OperationalSystemIconsProps) => {
  const { operationalSystemIcons: operationalSystemIconsStyle } =
    useTheme().components;

  return (
    <Tag
      bgColor="transparent"
      size="sm"
      variant="subtle"
      color="gray.300"
      fontSize="xs"
    >
      <AndroidIcon
        color="gray.300"
        mr="1"
        display={hideAndroid && 'none'}
        {...operationalSystemIconsStyle.variants[size]}
        {...iconProps}
      />
      <IOS
        color="gray.300"
        display={hideIOS && 'none'}
        {...operationalSystemIconsStyle.variants[size]}
        {...iconProps}
      />
    </Tag>
  );
};

export default OperationalSystemIcons;
