import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { ErrorResponse, ErrorToaster } from '../../helper/error';
import { useAppSelector } from '../../hooks/useRedux';
import { ListMetadata } from '../../types/generic_list';

// Action Types

export const Types = {
  ERROR: 'ui/ERROR',
  SUCCESS: 'ui/SUCCESS',
  LOADING: 'ui/LOADING',
  REDIRECT: 'ui/REDIRECT',
  EMPTY: 'ui/EMPTY',
};

interface ErrorsType {
  [actionType: string]: Error & ErrorToaster & ErrorResponse;
}

type SuccessType = {
  [actionType: string]: boolean;
};

type LoadingType = {
  [actionType: string]: boolean;
};

type EmptyType = {
  [actionType: string]: boolean;
};

type IdentifyMetadataType = {
  [actionType: string]: ListMetadata;
};

type ShowTableType = {
  [actionType: string]: boolean;
};

// Reducer

interface UiState {
  errors: ErrorsType;
  success: SuccessType;
  metadata: IdentifyMetadataType;
  loading: LoadingType;
  empty: EmptyType;
  showTable: ShowTableType;
  scrollToTop: boolean;
}

const initialState: UiState = {
  errors: {},
  success: {},
  metadata: {},
  loading: {},
  empty: {},
  showTable: {},
  scrollToTop: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    uiAddError: (state, action: PayloadAction<ErrorsType>) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    uiRemoveError: (state, action: PayloadAction<keyof ErrorsType>) => {
      state.errors = { ...state.errors, [action.payload]: null };
    },
    uiAddSuccess: (state, action: PayloadAction<string>) => {
      state.success = { ...state.success, [action.payload]: true };
    },
    uiRemoveSuccess: (state, action: PayloadAction<string>) => {
      state.success = { ...state.success, [action.payload]: false };
    },
    uiAddMetadata: (state, action: PayloadAction<IdentifyMetadataType>) => {
      const { payload: newMetadata } = action;
      for (const key in newMetadata) {
        state.metadata[key] = { ...state.metadata[key], ...newMetadata[key] };
      }
    },
    uiRemoveMetadata: (
      state,
      action: PayloadAction<keyof IdentifyMetadataType>
    ) => {
      state.metadata = { ...state.metadata, [action.payload]: null };
    },
    uiAddLoading: (state, action: PayloadAction<string>) => {
      state.loading = { ...state.loading, [action.payload]: true };
    },
    uiRemoveLoading: (state, action: PayloadAction<string>) => {
      state.loading = { ...state.loading, [action.payload]: false };
    },
    uiRequestSuccess: (state, action: PayloadAction<string>) => {
      state.success = { ...state.success, [action.payload]: true };
    },
    uiAddEmpty: (state, action: PayloadAction<string>) => {
      state.empty = { ...state.empty, [action.payload]: true };
    },
    uiRemoveEmpty: (state, action: PayloadAction<string>) => {
      state.empty = { ...state.empty, [action.payload]: false };
    },
    uiShowTable: (state, action: PayloadAction<string>) => {
      state.showTable = { ...state.showTable, [action.payload]: true };
    },
    uiRemoveTable: (state, action: PayloadAction<string>) => {
      state.showTable = { ...state.showTable, [action.payload]: false };
    },
    uiScrollToTop: (state, action: PayloadAction<boolean>) => {
      state.scrollToTop = action.payload;
    },
    uiScrollToTopClear: (state) => {
      state.scrollToTop = initialState.scrollToTop;
    },
  },
});

export default uiSlice.reducer;

// Action Creators

export const {
  uiAddError,
  uiRemoveError,
  uiAddSuccess,
  uiRemoveSuccess,
  uiAddMetadata,
  uiRemoveMetadata,
  uiAddLoading,
  uiRemoveLoading,
  uiRequestSuccess,
  uiAddEmpty,
  uiRemoveEmpty,
  uiShowTable,
  uiRemoveTable,
  uiScrollToTop,
  uiScrollToTopClear,
} = uiSlice.actions;

// Selectors

export const hasSuccess = (key: string) => {
  return useAppSelector((state) => state.ui.success)[key];
};

export const hasTable = (key: string) => {
  return useAppSelector((state) => state.ui.showTable)[key];
};

export const hasSomeSuccess = (key: string[]) => {
  const success = useAppSelector((state) => state.ui.success);
  return key.some((keyItem) => success[keyItem]);
};

type HasErrorTypeReturn<T> = ErrorsType['anyKey'] & T;

export const hasError = <T>(key: string): HasErrorTypeReturn<T> => {
  return useAppSelector((state) => state.ui.errors)?.[
    key
  ] as HasErrorTypeReturn<T>;
};

export const hasEmpty = (key: string) => {
  return useAppSelector((state) => state.ui.empty)?.[key];
};

export const hasMetadata = (key: string) => {
  return useAppSelector((state) => state.ui.metadata)?.[key] || {};
};

export const hasLoading = (key: string) => {
  return useAppSelector((state) => state.ui.loading)[key];
};

export const hasSomeLoading = (key: string[]) => {
  const loadings = useAppSelector((state) => state.ui.loading);
  return key.some((keyItem) => loadings[keyItem]);
};

export const hasSomeEmpty = (key: string[]) => {
  const empties = useAppSelector((state) => state.ui.empty);
  return key.some((keyItem) => empties[keyItem]);
};

export const selectorSuccess = (key: string) => {
  return (state: RootState) => state.ui.success[key];
};

export const selectorError = (key: string) => {
  return (state: RootState) => state.ui.errors?.[key];
};

export const selectorMetadata = (key: string) => {
  return (state: RootState) => state.ui.metadata?.[key] || {};
};

export const selectorLoading = (key: string) => {
  return (state: RootState) => state.ui.loading?.[key] || false;
};

// Action Handlers

export const makeUiAddMetadataSpecific = (key: string) => {
  return (newMetadata: ListMetadata) =>
    uiAddMetadata({ [key]: newMetadata }) as Action<unknown>;
};
export const makeUiAddSuccessSpecific = (key: string) => {
  return () => uiAddSuccess(key) as Action<unknown>;
};
export const makeUiAddLoadingSpecific = (key: string) => {
  return () => uiAddLoading(key) as Action<unknown>;
};
