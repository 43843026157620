import { ChangeEventHandler } from 'react';
import { useDispatch } from 'react-redux';

import { intlPolicySettingsKey } from '.';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import {
  getRemoteViewApplication,
  policyApplicationRemove,
} from '../../../../../../store/policy';
import { CardItemType } from '../../../../../CardContent';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';

export const getRemoteViewSettings: GetterSettingFn = ({
  policy,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const handleChangeEnableRemoteView: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    handleChange(e);

    if (e.target.checked) {
      dispatch(getRemoteViewApplication());
    } else {
      const remoteViewApplication = policy.applications.find(
        (app) =>
          app.companyApplication.packageName ===
          portalSettings.appRemoteViewPackageName
      );

      dispatch(policyApplicationRemove(remoteViewApplication));
    }
  };

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.remote_view.allow_remote_view.title`,
      description: `${intlPolicySettingsKey}.remote_view.allow_remote_view.description`,
      children: (
        <SwitchButton
          name="remoteViewEnabled"
          isChecked={policy?.remoteViewEnabled}
          onChange={handleChangeEnableRemoteView}
        />
      ),
    },
  ];

  return listSettings;
};
