import { Box, Divider, Flex } from '@chakra-ui/layout';
import React, { ChangeEvent } from 'react';

import { DynamicInputApplications } from '.';
import {
  ManageConfigurationInputTypeEnum,
  ManagedConfigurationsType,
} from '../../../../../../../../../types/policy';
import Text from '../../../../../../../../Text';

interface Props {
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  nestedProperties?: ManagedConfigurationsType[];
  inputContainerWidth?: string;
  value?: ManagedConfigurationsType[];
  bundlePath?: string;
}

export const BundleForDynamic = ({
  name,
  onChange,
  nestedProperties,
  inputContainerWidth,
  value,
  bundlePath,
}: Props) => {
  return (
    <Flex direction="row">
      <Divider mx={3} orientation="vertical" height="auto" />

      <Box w="full">
        {nestedProperties?.map((prop, index) => {
          const isRadio =
            prop.type === ManageConfigurationInputTypeEnum.BOOL ||
            prop.type === ManageConfigurationInputTypeEnum.MULTISELECT;
          const hasNestedProperties = !!prop.nestedProperties;

          return (
            <React.Fragment key={prop.key}>
              <Text fontSize="md" textAlign="left" m={0}>
                {prop.title}
              </Text>

              <Flex
                flexDirection={isRadio ? 'row' : 'column'}
                justifyContent={isRadio ? 'space-between' : 'flex-start'}
                py="6"
              >
                <Box mb={!isRadio && '6'} color="gray.500">
                  {prop.description}
                </Box>

                <Box
                  ml={isRadio && '6'}
                  w={
                    !hasNestedProperties &&
                    (isRadio ? 'auto' : inputContainerWidth || '50%')
                  }
                >
                  <DynamicInputApplications
                    key={prop.key}
                    type={prop.type}
                    handleChange={onChange}
                    name={(name.includes('value')
                      ? `${name}.value[${index}]`
                      : `value[${index}]`
                    ).concat(prop?.nestedProperties?.length ? '' : '.value')}
                    entries={prop?.entries}
                    nestedProperties={prop.nestedProperties}
                    value={value?.[index]?.value}
                    bundlePath={`${bundlePath}.value[${index}]`}
                  />
                </Box>
              </Flex>
            </React.Fragment>
          );
        })}
      </Box>
    </Flex>
  );
};
