import ApiUtil from './ApiUtil';

export default function ApiMaps(state) {
  const { request } = ApiUtil(
    state,
    process.env.REACT_APP_API_OPEN_STREET_MAPS_URL
  );
  return {
    listLocationGeolocationReverse,
  };

  // REVERSE GEOLOCATION
  function listLocationGeolocationReverse(location) {
    return request('', 'reverse', {
      data: { ...location, format: 'json' },
      method: 'GET',
    });
  }
}
