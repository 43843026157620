import { RootState } from '..';

import {
  ListQueryParameters,
  QuerysWithFilters,
  TypeAndQuerysWithFilters,
} from '../../types/generic_list';
import { ID } from '../../types/util';
import { Types as TypesCompany } from '../company';
import { Types as TypesGroup } from '../group';
import { Types as TypesDeviceUser } from '../deviceUser';
import { Types as TypesDevice } from '../device';
import { Types as TypesDeviceInfo } from '../deviceInfo';
import { Types as TypesApplication } from '../application';
import { Types as TypesMessage } from '../message';
import { Types as TypesNetwork } from '../network';
import { Types as TypesDocument } from '../document';
import { Types as TypesAdminUser } from '../adminUser';
import { Types as TypesReports } from '../reports';
import { Types as TypesConsumptionProfile } from '../consumptionProfile';
import { Types as TypesLocalization } from '../location';
import { Types as TypesPolicy } from '../policy';
import { Types as TypesRemoteApp } from '../remoteApps';
import { Types as TypesEnterpriseApps } from '../enterpriseApps';
import { Types as TypesAudit } from '../audit';
import { Types as TypesExternalApp } from '../externalApps';
import ApiUtil from './ApiUtil';
import { routeWithParameters } from '../../helper';
import { Types as TypesInvoice } from '../invoice';
import {
  RegisterCustomerData,
  RegisterDevicesImeiData,
  RegisterZeroTouchData,
} from '../../types/invoice';
import { NetworkSettingsData, NetworkSettingsType } from '../../types/network';
import { LocationType } from '../../types/locations';
import { endpoint } from './endpoint';
import { BatchDevicesActionsForm } from '../../types/device';

export const VER1 = '/v1';
const NO_VER = '';

export default function Api(state: RootState) {
  const { request } = ApiUtil(state);

  const endpoints = {
    [TypesCompany.LICENSE_LIST]: '/license_devices',
    [TypesCompany.CONSUMPTION_DATA]: '/companies/consumption/data',
    [TypesCompany.CONSUMPTION_SMS]: '/companies/consumption/sms',
    [TypesCompany.CARRIER_LIST]: '/portal_carrier/companies',
    [TypesGroup.LIST]: '/groups',
    [TypesMessage.LIST]: '/messages',
    [TypesMessage.LIST_DETAILS]: `/messages/:id/recipients`,
    [TypesNetwork.LIST]: '/networks',
    [TypesDocument.LIST]: '/documents',
    [TypesDocument.LIST_DETAILS]: `/documents/:id/recipients`,
    [TypesAdminUser.LIST]: `/admin/users`,
    [TypesDeviceUser.LIST]: `/device/users`,
    [TypesDevice.LIST]: `/devices`,
    [TypesDevice.LIST_NO_COMMUNICATION]: `/dashboards/devices/without_communication/details`,
    [TypesDeviceInfo.LIST_BATTERY]: `/devices/:id/history/battery`,
    [TypesDeviceInfo.LIST_STORAGE]: `/devices/:id/history/storage`,
    [TypesApplication.LIST]: '/applications',
    [TypesApplication.LIST_APPLICATION_DEVICE_USERS]: `/applications/users`,
    [TypesApplication.LIST_APPLICATION_CONSUMPTION_HISTORY]: `/applications/daily_consumptions`,
    [TypesReports.SITES_LIST]: '/sites',
    [TypesReports.SITES_DATE]: '/sites/history',
    [TypesConsumptionProfile.LIST_GROUP]: `/rules/consumption_limits/groups`,
    [TypesConsumptionProfile.LIST_USER]: `/rules/consumption_limits/users`,
    [TypesLocalization.LIST_LAST]: '/devices/last_locations',
    [TypesLocalization.LIST_HISTORIC]: '/localizations/history',
    [TypesLocalization.LIST_GEOLOCALIZATION]: '/localizations',
    [TypesPolicy.LIST]: '/policies',
    [TypesEnterpriseApps.LIST]: '/enterprise_company_applications',
    [TypesGroup.LIST_LINKED_USER]: '/groups/users',
    [TypesInvoice.LIST]: '/invoices',
    [TypesAudit.GET_AUDIT_LIST]: '/audits',
    [TypesAudit.GET_AUDIT_LIST]: '/audits',
    [TypesDeviceInfo.LIST_NON_COMPLIANCE]: '/devices/:id/non_compliance',
    [TypesExternalApp.LIST]: '/external_applications',
    [TypesRemoteApp.LIST]: '/remote_applications',
    [TypesRemoteApp.LIST_DETAILS]: '/remote_applications/:id/recipients',
  };

  return {
    generateReport: (data: TypeAndQuerysWithFilters & { id: string }) => {
      const endpointWithReport = (endPoint: string) =>
        endPoint.concat(`/report`);
      return request(
        VER1,
        routeWithParameters(endpointWithReport(endpoints[data.type] || ''), {
          id: data.id,
        }),
        {
          data: { ...data.queryParameters, ...data.filters },
          method: 'POST',
        }
      );
    },

    login: function login(data) {
      return request(VER1, endpoint.login, { data, method: 'POST' });
    },

    authChangeSession: function authChangeSession(data) {
      return request(VER1, '/auth/change_session', { data, method: 'POST' });
    },

    logout: function logout() {
      return request(VER1, endpoint.logout, { method: 'GET' });
    },

    loginConfirmation: function loginConfirmation(data) {
      return request(VER1, endpoint.loginConfirmation, {
        data,
        method: 'POST',
      });
    },

    loginResendToken: function loginResendToken(data) {
      return request(VER1, '/auth/login_confirmation/token', {
        data,
        method: 'POST',
      });
    },

    authRefreshSession: function authRefreshSession() {
      return request(VER1, endpoint.refresh, { method: 'POST' });
    },

    // helpDesk

    helpDeskListCompanies: function helpDeskListCompanies(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/portal_admin/companies', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    // carrier
    carrierListCompanies: function carrierListCompanies(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/portal_carrier/companies', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    carrierRegisterCompany: function carrierRegisterCompany(data) {
      return request(VER1, '/portal_carrier/companies', {
        data,
        method: 'POST',
      });
    },

    carrierEditCompany: function carrierEditCompany(data) {
      return request(VER1, `/portal_carrier/companies/${data?.id}`, {
        data,
        method: 'PUT',
      });
    },

    carrierCancelCompany: function carrierCancelCompany(id) {
      return request(VER1, `/portal_carrier/companies/${id}/cancel`, {
        method: 'PUT',
      });
    },

    carrierGetCompany: function carrierGetCompany(id) {
      return request(VER1, `/portal_carrier/companies/${id}`, {
        method: 'GET',
      });
    },

    carrierResendWelcomeEmail: function (id) {
      return request(
        VER1,
        `/portal_carrier/companies/${id}/send_welcome_email`,
        {
          method: 'POST',
        }
      );
    },

    // company
    sendUrlCallback: function sendUrlCallback(data) {
      return request(VER1, '/companies/sign_up', { data, method: 'POST' });
    },

    companyValidateEmailSend: (data) => {
      return request(VER1, '/companies/email_token', { data, method: 'POST' });
    },

    companyValidateEmailToken: (data) => {
      return request(VER1, endpoint.companyValidateEmailToken, {
        data,
        method: 'PUT',
      });
    },

    companyUpdateSettings: (data) => {
      return request(VER1, '/companies/settings', {
        data,
        method: 'PUT',
      });
    },

    sendRegisterToken: function sendRegisterToken(data) {
      return request(VER1, '/companies/register', { data, method: 'POST' });
    },

    editCompany: function editCompany(data) {
      return request(VER1, '/companies', { data, method: 'PUT' });
    },
    newEmailToken: function newEmailToken(data) {
      return request(VER1, '/companies/new_email_token', {
        data,
        method: 'POST',
      });
    },
    confirmInfoCompany: function confirmInfoCompany(data) {
      return request(VER1, '/companies/confirmation', { data, method: 'PUT' });
    },
    deleteCompany: function deleteCompany() {
      return request(VER1, '/companies/reset', { method: 'PUT' });
    },
    companyGetInfo: function companyGetInfo() {
      return request(VER1, '/companies', { method: 'GET' });
    },
    companyConsumptionData: function companyConsumptionData(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/companies/consumption/data', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    companyConsumptionSms: function companyConsumptionSms(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/companies/consumption/sms', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    companyLicenseList: function companyLicenseList(meta: QuerysWithFilters) {
      return request(VER1, '/license_devices', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    companyGetLicensesStates: function companyGetLicensesStates() {
      return request(VER1, '/license_devices/states', { method: 'GET' });
    },
    companyGetEnterpriseWebToken: function companyGetEnterpriseWebToken(data) {
      return request(VER1, `/companies/web_token`, {
        data: data,
        method: 'POST',
      });
    },
    companySendWelcomeEmail: function companySendWelcomeEmail(data) {
      return request(VER1, '/companies/send_welcome_email', {
        data,
        method: 'POST',
      });
    },
    companyPlansFilter: function companyPlansFilter(meta: QuerysWithFilters) {
      return request(VER1, '/plans/options_for_select', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    //audit
    auditsList: function auditsList(meta: QuerysWithFilters) {
      return request(VER1, '/audits', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    auditGetUsersToFilter: function auditGetUsersToFilter(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/audits/users', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    // group
    groupList: function groupList(meta: QuerysWithFilters) {
      return request(VER1, '/groups', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    groupGet: function groupGet(id: string) {
      return request(VER1, `/groups/${id}`, { method: 'GET' });
    },
    groupCreation: function groupCreation(data) {
      return request(VER1, '/groups', { data: data, method: 'POST' });
    },
    groupUpdate: function groupUpdate(id, data) {
      return request(VER1, `/groups/${id}`, { data: data, method: 'PUT' });
    },
    groupDelete: function groupDelete(id: string) {
      return request(VER1, `/groups/${id}`, { method: 'DELETE' });
    },
    groupListFilter: function groupListFilter(meta: QuerysWithFilters) {
      return request(VER1, '/groups/options_for_select', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    groupLinkedDeviceList: function groupLinkedDeviceList(
      meta: QuerysWithFilters
    ) {
      return request(VER1, `/groups/users`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    groupImportGroups: function groupImportGroups(data) {
      return request(VER1, `/groups/import`, {
        data,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },

    // Device Locations
    locationLastList: function locationLastList(meta: QuerysWithFilters) {
      return request(VER1, '/devices/last_locations', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    listLocationHistoric: function listLocationHistoric(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/localizations/history', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    locationGeolocationList: function locationGeolocationList(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/localizations', {
        data: meta,
        method: 'GET',
      });
    },
    localizationUpdateAddress: function localizationUpdateAddress(
      partialLocation
    ) {
      return request(
        VER1,
        `/localizations/${partialLocation.id}/${partialLocation.date}/history`,
        {
          data: { address: partialLocation.address },
          method: 'PUT',
        }
      );
    },
    localizationDeviceUpdateAddress: function localizationDeviceUpdateAddress(
      partialLocation: Partial<LocationType>
    ) {
      return request(VER1, `/devices/${partialLocation.id}/address`, {
        data: { address: partialLocation.address },
        method: 'PUT',
      });
    },
    // enterprise applications
    enterpriseApps: function enterpriseApps(meta: QuerysWithFilters) {
      return request(VER1, '/enterprise_company_applications', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    enterpriseAppDelete: function enterpriseAppDelete(id: ID) {
      return request(VER1, `/enterprise_company_applications/${id}`, {
        method: 'DELETE',
      });
    },
    enterpriseAppSelect: function enterpriseAppSelect(data) {
      return request(VER1, `/enterprise_company_applications`, {
        data: data,
        method: 'post',
      });
    },

    enterpriseAppsListModal: function enterpriseAppsListModal(
      meta: QuerysWithFilters
    ) {
      return request(
        VER1,
        '/enterprise_company_applications/options_for_select',
        {
          data: { ...meta.queryParameters, ...meta.filters },
          method: 'GET',
        }
      );
    },

    enterpriseSystemAppsList: function enterpriseSystemAppsList(
      meta: QuerysWithFilters
    ) {
      return request(
        VER1,
        '/enterprise_device_applications/options_for_select',
        {
          data: { ...meta.queryParameters, ...meta.filters },
          method: 'GET',
        }
      );
    },

    enterpriseDevicesList: function enterpriseDevicesList(
      meta: QuerysWithFilters
    ) {
      return request(
        VER1,
        '/enterprise_device_applications/device_application/options_for_select',
        {
          data: { ...meta.queryParameters, ...meta.filters },
          method: 'GET',
        }
      );
    },
    // policies
    policyList: function policyList(meta: QuerysWithFilters) {
      return request(VER1, '/policies', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    getEnrollmentToken: function getEnrollmentToken(id: ID) {
      return request(VER1, `/policies/${id}/enrollment_token`, {
        method: 'GET',
      });
    },
    policyCreate: function policyCreate(data) {
      return request(VER1, '/policies', { data: data, method: 'POST' });
    },
    policyCreateIOSSupervised: function policyCreateIOSSupervised(data) {
      return request(VER1, '/policies/IOS_supervised', {
        data: data,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    policyCopy: function policyCopy(data) {
      return request(VER1, `/policies/${data?.id}/copy`, {
        data: data?.data,
        method: 'PUT',
      });
    },

    policyDelete: function policyDelete(id: ID) {
      return request(VER1, `/policies/${id}`, {
        method: 'DELETE',
      });
    },
    policyUpdate: function policyUpdate(id, data) {
      return request(VER1, `/policies/${id}`, { data: data, method: 'PUT' });
    },
    policyIOSSupervisedUpdate: function policyUpdate(id, data) {
      return request(VER1, `/policies/${id}/IOS_supervised`, {
        data: data,
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    policyGet: function policyGet(id: number) {
      return request(VER1, `/policies/${id}`, { method: 'GET' });
    },
    policyAppSettings: function policyAppSettings(data) {
      return request(VER1, `/policies/${data.id}/application_settings`, {
        data: data,
        method: 'GET',
      });
    },
    policyListFilter: function policyListFilter(meta: QuerysWithFilters) {
      return request(VER1, '/policies/options_for_select', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    policyGetIOS: function policyGetIOS() {
      return request(VER1, `/policies/ios/initial`, { method: 'GET' });
    },

    // localizations

    listLocalizations: function listLocalizations(meta: ListQueryParameters) {
      return request(VER1, '/localizations', {
        data: meta,
        method: 'GET',
      });
    },

    createLocalizationsRealTimeId: function createLocalizationsRealTimeId(
      data
    ) {
      return request(VER1, `/localizations/real_time`, {
        data: data,
        method: 'POST',
      });
    },

    listLocalizationsRealTime: function listLocalizationsRealTime(id) {
      return request(VER1, `/localizations/real_time/${id}`, {
        method: 'GET',
      });
    },
    // Invoice

    invoiceList: function invoiceList() {
      return request(VER1, '/zero_touch_reseller/invoices', {
        method: 'GET',
      });
    },

    invoiceGet: function invoiceGet(id: string) {
      return request(VER1, `/zero_touch_reseller/invoices/${id}`, {
        method: 'GET',
      });
    },

    invoiceGetImeiInfo: function invoiceGetImeiInfo(imei: string) {
      return request(VER1, `/zero_touch_reseller/invoices/imei_info/${imei}`, {
        method: 'GET',
      });
    },

    invoiceUpdate: function invoiceUpdate(id, data) {
      return request(VER1, `/zero_touch_reseller/invoices/${id}/devices/imei`, {
        data: data,
        method: 'PUT',
      });
    },

    invoiceRegisterZeroTouch: function (id: ID, data: RegisterZeroTouchData) {
      return request(
        VER1,
        `/zero_touch_reseller/invoices/${id}/devices/release`,
        {
          data,
          method: 'PUT',
        }
      );
    },

    invoiceRegisterItemsImei: function (id: ID, data: RegisterDevicesImeiData) {
      return request(VER1, `/zero_touch_reseller/invoices/${id}/items`, {
        data,
        method: 'PUT',
      });
    },

    invoiceRegisterCustomerId: function invoiceRegisterCustomerId(id, data) {
      return request(VER1, `/zero_touch_reseller/invoices/${id}/register`, {
        data,
        method: 'POST',
      });
    },

    resellerRegisterCustomerAccount: function resellerRegisterCustomerAccount(
      data: RegisterCustomerData
    ) {
      return request(VER1, `/zero_touch_reseller/account`, {
        data,
        method: 'POST',
      });
    },

    resellerCreateCustomerAccount: function resellerCreateCustomerAccount(
      data: RegisterCustomerData
    ) {
      return request(VER1, `/companies/confirmation_email_zero_touch`, {
        data,
        method: 'POST',
      });
    },

    resellerVerifyCustomerAccount: function resellerVerifyCustomerAccount() {
      return request(VER1, `/zero_touch_reseller/account/has_account`, {
        method: 'GET',
      });
    },
    resellerVerifyAcceptTerms: function () {
      return request(VER1, `/zero_touch_reseller/account/accept_terms`, {
        method: 'GET',
      });
    },
    resellerAcceptTermsDescription: function () {
      return request(
        VER1,
        `/zero_touch_reseller/account/accept_terms/description`,
        {
          method: 'GET',
        }
      );
    },
    resellerReplyAcceptTerms: function (data: boolean) {
      return request(VER1, `/zero_touch_reseller/account/accept_terms`, {
        method: 'POST',
        data,
      });
    },
    backofficeInvoiceList: function (meta: QuerysWithFilters) {
      return request(VER1, '/zero_touch_reseller/management/invoices', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    backofficeInvoice: function (id: string) {
      return request(VER1, `/zero_touch_reseller/management/invoices/${id}`, {
        method: 'GET',
      });
    },

    backofficeInvoiceApprove: function (id: ID, data: RegisterZeroTouchData) {
      return request(
        VER1,
        `/zero_touch_reseller/management/invoices/${id}/device/approve`,
        {
          data,
          method: 'PUT',
        }
      );
    },

    backofficeInvoiceReject: function (id: ID, data: RegisterZeroTouchData) {
      return request(
        VER1,
        `/zero_touch_reseller/management/invoices/${id}/device/reject`,
        {
          data,
          method: 'PUT',
        }
      );
    },

    invoiceImport: function invoiceImport(data) {
      return request(VER1, `/zero_touch_reseller/invoices`, {
        data,
        method: 'POST',
      });
    },
    invoiceImportDevicesImei: function invoiceImportDevicesImei(id, data) {
      return request(VER1, `/zero_touch_reseller/invoices/${id}/imei_batch`, {
        data: data,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },

    // Messages
    messageList: function messageList(meta: QuerysWithFilters) {
      return request(VER1, '/messages', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    messageListDetails: function messageListDetails(
      id: ID,
      meta: QuerysWithFilters
    ) {
      return request(VER1, `/messages/${id}/recipients`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    messageCreate: function messageCreate(data) {
      return request(VER1, '/messages', { data, method: 'POST' });
    },

    messageGet: function messageGet(id: string) {
      return request(VER1, `/messages/${id}`, { method: 'GET' });
    },

    // network
    networkSettingsList: function networkSettingsList(meta: QuerysWithFilters) {
      return request(VER1, '/networks', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    networkListSelect: function networkList(meta: QuerysWithFilters) {
      return request(VER1, '/networks/options_for_select', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    networkSettingsGet: function networkSettingsGet(id: ID) {
      return request(VER1, `/networks/${id}`, { method: 'GET' });
    },

    networkSettingsCreate: function networkSettingsCreate(
      data: NetworkSettingsType
    ) {
      return request(VER1, `/networks`, {
        data,
        method: 'POST',
      });
    },

    networkSettingsUpdate: function networkSettingsUpdate(
      id: ID,
      data: NetworkSettingsData
    ) {
      return request(VER1, `/networks/${id}`, {
        data,
        method: 'PUT',
      });
    },

    networkSettingsDelete: function networkSettingsDelete(id: ID) {
      return request(VER1, `/networks/${id}`, {
        method: 'DELETE',
      });
    },

    // Documents
    documentList: function documentList(meta: QuerysWithFilters) {
      return request(VER1, '/documents', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    documentListDetails: function documentListDetails(
      id: ID,
      meta: QuerysWithFilters
    ) {
      return request(VER1, `/documents/${id}/recipients`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    documentCreate: function documentCreate(data) {
      return request(VER1, '/documents', { data, method: 'POST' });
    },

    documentGet: function documentGet(id: string) {
      return request(VER1, `/documents/${id}`, { method: 'GET' });
    },

    // AdminUsers
    adminUserList: function adminUserList(meta: QuerysWithFilters) {
      return request(VER1, `/admin/users`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    adminUserPermissions: function adminUserPermissions(data) {
      return request(VER1, '/users', { data, method: 'GET' });
    },

    adminUserGet: function adminUserGet(id: number) {
      return request(VER1, `/admin/users/${id}`, { method: 'GET' });
    },

    adminUserCreation: function adminUserCreation(data) {
      return request(VER1, '/admin/users', { data, method: 'POST' });
    },

    adminUserEdit: function adminUserEdit(id: number, data) {
      return request(VER1, `/admin/users/${id}`, { data, method: 'PUT' });
    },

    adminUserChangePassword: function adminUserChangePassword(data: string) {
      return request(VER1, '/admin/users/change_password', {
        data,
        method: 'PUT',
      });
    },

    adminUserRecoverPassword: function adminUserChangePassword(data: string) {
      return request(VER1, '/admin/users/recover_password', {
        data,
        method: 'PUT',
      });
    },

    adminUserDelete: function adminUserDelete(id: ID) {
      return request(VER1, `/admin/users/${id}`, {
        method: 'DELETE',
      });
    },

    // Device Users
    deviceUserList: function deviceUserList(meta: QuerysWithFilters) {
      return request(VER1, `/device/users`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    deviceUserGet: function deviceUserGet(id: ID) {
      return request(VER1, `/device/users/${id}`, { method: 'GET' });
    },
    deviceUserEdit: function deviceUserEdit(id: ID, data) {
      return request(VER1, `/device/users/${id}`, {
        data: data,
        method: 'PUT',
      });
    },
    deviceUserSelect: function deviceUserSelect(meta: QuerysWithFilters) {
      return request(VER1, `/device/users/options_for_select`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    deviceSyncPolicy: function deviceSyncPolicy() {
      return request(VER1, `/devices/refresh`, { method: 'GET' });
    },

    // Devices
    deviceList: function deviceList(meta: QuerysWithFilters) {
      return request(VER1, `/devices`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    deviceGet: function deviceGet(id: number) {
      return request(VER1, `/devices/${id}`, { method: 'GET' });
    },
    deviceCreation: function deviceCreation(data) {
      return request(VER1, '/devices', { data: data, method: 'POST' });
    },
    deviceEdit: function deviceEdit(id: ID, data) {
      return request(VER1, `/devices/${id}`, { data: data, method: 'PUT' });
    },
    deviceEditPolicy: function deviceEditPolicy(id: ID, data) {
      return request(VER1, `/devices/${id}/change_policy`, {
        data: data,
        method: 'PUT',
      });
    },
    deviceChangePassword: function deviceChangePassword(id: ID, data) {
      return request(VER1, `/devices/${id}/change_password`, {
        data: data,
        method: 'PUT',
      });
    },
    deviceListManufacturer: function deviceListManufacturer(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/devices/manufacturers', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    deviceInventoryList: function deviceInventoryList() {
      return request(VER1, '/devices/inventory', { method: 'GET' });
    },
    deviceListModel: function deviceListModel(meta: QuerysWithFilters) {
      return request(VER1, '/devices/models', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    deviceActionRemove: function deviceActionRemove(id: ID) {
      return request(VER1, `/devices/${id}`, { method: 'DELETE' });
    },
    deviceActionDisable: function deviceActionDisable(id: ID) {
      return request(VER1, `/devices/${id}/disable`, { method: 'PUT' });
    },
    deviceActionEnable: function deviceActionEnable(id: ID) {
      return request(VER1, `/devices/${id}/enable`, { method: 'PUT' });
    },
    deviceActionNewPassword: function deviceActionNewPassword(id: ID) {
      return request(VER1, `/devices/${id}/reset_password`, { method: 'PUT' });
    },
    deviceActionRemoveScreenBlock: function deviceActionRemoveScreenBlock(
      id: ID
    ) {
      return request(VER1, `/devices/${id}/remove_lock_screen`, {
        method: 'PUT',
      });
    },
    deviceActionListPasswords: function deviceActionListPasswords(id: ID) {
      return request(VER1, `/devices/${id}/history/password`, {
        method: 'GET',
      });
    },
    deviceWithoutCommunicationList: function deviceWithoutCommunicationList(
      meta: QuerysWithFilters
    ) {
      return request(
        VER1,
        `/dashboards/devices/without_communication/details`,
        {
          data: { ...meta.queryParameters, ...meta.filters },
          method: 'GET',
        }
      );
    },
    deviceLockScreen: function deviceLockScreen(id: ID) {
      return request(VER1, `/devices/${id}/lock_screen`, {
        data: { id },
        method: 'PUT',
      });
    },
    deviceReboot: function deviceReboot(id: ID) {
      return request(VER1, `/devices/${id}/reboot`, {
        data: { id },
        method: 'PUT',
      });
    },
    deviceVerifyBatchActions: function deviceVerifyBatchActions() {
      return request(VER1, `/devices/has_command_in_processing`, {
        method: 'GET',
      });
    },
    deviceBatchActions: function deviceBatchActions(
      data: BatchDevicesActionsForm
    ) {
      return request(VER1, `/devices/send_commands`, {
        data,
        method: 'POST',
      });
    },
    deviceGetKioskAccessToken: function (id: ID) {
      return request(VER1, `/devices/${id}/kiosk_launcher_access_token`, {
        method: 'GET',
      });
    },
    // Applications
    applicationsList: function applicationsList(meta: QuerysWithFilters) {
      return request(VER1, '/applications', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    applicationsListForSelect: function applicationsListForSelect(
      meta: QuerysWithFilters
    ) {
      return request(VER1, '/applications/options_for_select', {
        data: { ...meta.filters },
        method: 'GET',
      });
    },
    // Device Infos
    deviceBatteryList: function deviceBatteryList(
      id: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/devices/${id}/history/battery`, {
        data: meta,
        method: 'GET',
      });
    },
    deviceStorageList: function deviceStorageList(
      id: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/devices/${id}/history/storage`, {
        data: meta,
        method: 'GET',
      });
    },
    deviceNonComplianceList: function deviceNonComplianceList(
      id: ID,
      meta: QuerysWithFilters
    ) {
      return request(VER1, `/devices/${id}/non_compliance`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },

    // States
    listStates: function listStates() {
      return request(VER1, '/states', { method: 'GET' });
    },

    // Reports Sites
    sitesList: function sitesList(meta: QuerysWithFilters) {
      return request(VER1, '/sites', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    sitesDateList: function sitesDateList(meta: ListQueryParameters) {
      return request(VER1, '/sites/history', { data: meta, method: 'GET' });
    },

    // Plans
    planList: function planList() {
      return request(VER1, '/license_devices/plans', { method: 'GET' });
    },

    // Application
    applicationDeviceUserList: function applicationDeviceUserList(
      meta: QuerysWithFilters
    ) {
      return request(VER1, `/applications/users`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    applicationConsumptionHistoryList:
      function applicationConsumptionHistoryList(meta: QuerysWithFilters) {
        return request(VER1, `/applications/daily_consumptions`, {
          data: { ...meta.queryParameters, ...meta.filters },
          method: 'GET',
        });
      },

    // Consumption Profile
    consumptionProfileGeneralGet: function consumptionProfileGeneralGet(
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/consumption_limits/company`, {
        data: meta,
        method: 'GET',
      });
    },
    consumptionProfileGeneralUpdate: function consumptionProfileGeneralUpdate(
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/consumption_limits/company`, {
        data: meta,
        method: 'PUT',
      });
    },
    // consumptionProfileGroup
    consumptionProfileGroupList: function consumptionProfileGroupList(
      meta: QuerysWithFilters
    ) {
      return request(VER1, `/rules/consumption_limits/groups`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    consumptionProfileGroupCreate: function consumptionProfileGroupCreate(
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/consumption_limits/groups`, {
        data: meta,
        method: 'POST',
      });
    },
    consumptionProfileGroupGet: function consumptionProfileGroupGet(
      ruleId: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/${ruleId}/consumption_limits/groups`, {
        data: meta,
        method: 'GET',
      });
    },
    consumptionProfileGroupUpdate: function consumptionProfileGroupUpdate(
      ruleId: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/${ruleId}/consumption_limits/groups`, {
        data: meta,
        method: 'PUT',
      });
    },
    consumptionProfileGroupDelete: function consumptionProfileGroupDelete(
      ruleId: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/${ruleId}/consumption_limits/groups/reset`, {
        data: meta,
        method: 'PUT',
      });
    },
    // consumptionProfileUser
    consumptionProfileUserList: function consumptionProfileUserList(
      meta: QuerysWithFilters
    ) {
      return request(VER1, `/rules/consumption_limits/users`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    consumptionProfileUserCreate: function consumptionProfileUserCreate(
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/consumption_limits/users`, {
        data: meta,
        method: 'POST',
      });
    },
    consumptionProfileUserGet: function consumptionProfileUserGet(
      ruleId: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/${ruleId}/consumption_limits/users`, {
        data: meta,
        method: 'GET',
      });
    },
    consumptionProfileUserUpdate: function consumptionProfileUserUpdate(
      ruleId: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/${ruleId}/consumption_limits/users`, {
        data: meta,
        method: 'PUT',
      });
    },
    consumptionProfileUserDelete: function consumptionProfileUserDelete(
      ruleId: ID,
      meta: ListQueryParameters
    ) {
      return request(VER1, `/rules/${ruleId}/consumption_limits/users/reset`, {
        data: meta,
        method: 'PUT',
      });
    },

    // Dashboard

    dashboardListDevicesActive: function dashboardListDevicesActive(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/devices/active/count', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListDevicesWithoutCompliance:
      function dashboardListDevicesWithoutCompliance(
        meta: ListQueryParameters
      ) {
        return request(VER1, '/dashboards/devices/without_compliance/count', {
          data: meta,
          method: 'GET',
        });
      },
    dashboardListDevicesModeOwner: function dashboardListDevicesModeOwner(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/devices/mode_owner/count', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListDevicesModeKiosk: function dashboardListDevicesModeKiosk(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/devices/mode_kiosk/count', {
        data: meta,
        method: 'GET',
      });
    },
    getKioskApplicationSettings: function getKioskApplicationSettings(
      policyId: number | string
    ) {
      return request(VER1, `/policies/${policyId}/kiosk_application_settings`, {
        method: 'GET',
      });
    },
    setKioskApplicationWallpaper: function setKioskApplicationWallpaper(data) {
      return request(VER1, `/upload/wallpaper`, {
        data: data,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    dashboardListDevicesOutdated: function dashboardListDevicesOutdated(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/devices/outdated/count', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListDevicesWithoutCommunication:
      function dashboardListDevicesWithoutCommunication(
        meta: ListQueryParameters
      ) {
        return request(
          VER1,
          '/dashboards/devices/without_communication/count',
          {
            data: meta,
            method: 'GET',
          }
        );
      },
    dashboardListUsers: function dashboardListUsers(meta: ListQueryParameters) {
      return request(VER1, '/dashboards/users/count', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListLicenses: function dashboardListLicenses(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/licenses_available/count', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListTotalLicenses: function (meta: ListQueryParameters) {
      return request(VER1, '/dashboards/total_licenses/count', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListUsageTimeByApplications:
      function dashboardListUsageTimeByApplications(meta: ListQueryParameters) {
        return request(VER1, '/dashboards/app_usage_time', {
          data: meta,
          method: 'GET',
        });
      },
    dashboardList5: function dashboardList5(meta: ListQueryParameters) {
      return request(VER1, '/dashboardList5', { data: meta, method: 'GET' });
    },
    dashboardListMostVisitedWebsites: function dashboardListMostVisitedWebsites(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/most_visited_sites', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListDevicesInventory: function dashboardListDevicesInventory(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/devices/manufacturers', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListDataConsumptionApp: function dashboardListDataConsumptionApp(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/data_consumption_by_app', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListDataConsumptionUser: function dashboardListDataConsumptionUser(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/data_consumption_by_user', {
        data: meta,
        method: 'GET',
      });
    },
    dashBoardListSmsConsumption: function dashBoardListSmsConsumption(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/total_sms_consumption', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListDataConsumpion: function dashboardListDataConsumpion(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/total_data_consumption', {
        data: meta,
        method: 'GET',
      });
    },
    dashboardListConsumptionSemester: function dashboardListConsumptionSemester(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/consumption_semester', {
        data: meta,
        method: 'GET',
      });
    },

    dashboardListNewUsersSemester: function dashboardListNewUsersSemester(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/new_users_semester', {
        data: meta,
        method: 'GET',
      });
    },

    dashboardListDevicesLocation: function dashboardListDevicesLocation(
      meta: ListQueryParameters
    ) {
      return request(VER1, '/dashboards/devices/locations', {
        data: meta,
        method: 'GET',
      });
    },

    // notification

    notificationList: function notificationList(meta: ListQueryParameters) {
      return request(VER1, '/notifications', { data: meta, method: 'GET' });
    },
    notificationListInProgress: function notificationListInProgress() {
      return request(VER1, '/notifications/inProgress', { method: 'GET' });
    },
    notificationReadUpdate: function notificationReadUpdate(id: ID) {
      return request(VER1, `/notifications/${id}/visualized`, {
        method: 'PUT',
      });
    },
    notificationGet: function notificationGet(id: ID) {
      return request(VER1, `/notifications/${id}`, {
        method: 'get',
      });
    },

    // terms

    termGetPrivacyPolicy: () => {
      return request(NO_VER, `/privacy_policy`, { method: 'GET' });
    },

    termTermsOfUsage: () => {
      return request(NO_VER, `/terms_of_usage`, { method: 'GET' });
    },

    // help

    faq: (locale: string) => {
      return request(NO_VER, `/faq/${locale}`, { method: 'GET' });
    },
    guides: () => {
      return request(NO_VER, `/guides`, { method: 'GET' });
    },

    // External Apps
    externalAppList: function (meta: QuerysWithFilters) {
      return request(VER1, '/external_applications', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    externalAppGet: function (id: number) {
      return request(VER1, `/external_applications/${id}`, {
        method: 'GET',
      });
    },
    externalAppCreate: function (data) {
      return request(VER1, '/external_applications', { data, method: 'POST' });
    },
    externalAppUpdate: function (id: ID, data) {
      return request(VER1, `/external_applications/${id}`, {
        data,
        method: 'PUT',
      });
    },

    // Remote Apps
    remoteAppList: function remoteAppList(meta: QuerysWithFilters) {
      return request(VER1, '/remote_applications', {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
    remoteAppCreate: function remoteAppCreate(data) {
      return request(VER1, '/remote_applications', { data, method: 'POST' });
    },
    remoteAppGet: function (id: number) {
      return request(VER1, `/remote_applications/${id}`, {
        method: 'GET',
      });
    },
    remoteAppRecipients: function (id: ID, meta: QuerysWithFilters) {
      return request(VER1, `/remote_applications/${id}/recipients`, {
        data: { ...meta.queryParameters, ...meta.filters },
        method: 'GET',
      });
    },
  };
}
