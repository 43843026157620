import { Flex, Box } from '@chakra-ui/react';
import React from 'react';

import FormControl from '../FormControl';
import Input, { InputProps } from '../Input';
import Spinner from '../Spinner';

export interface ButtonFileUploadProps extends InputProps {
  uploadTitle: string | React.ReactNode;
  buttonId?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
}

const ButtonFileUpload = ({
  uploadTitle,
  buttonId,
  isDisabled,
  isLoading,
  hasError,
  ...rest
}: ButtonFileUploadProps) => {
  return (
    <FormControl id="Xml" maxWidth="176px">
      <Input
        inputProps={{
          id: buttonId || 'labelUploadFile',
          required: true,
          type: 'file',
          style: { display: 'none' },
          isDisabled,
          ...rest.inputProps,
        }}
      />
      <Box maxWidth="176px" height="40px">
        <label htmlFor={buttonId || 'labelUploadFile'}>
          <Flex
            maxWidth="176px"
            height="40px"
            py="5"
            color={hasError ? 'warning.600' : 'primary.500'}
            border="1px solid"
            borderRadius="md"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            opacity={isDisabled && '0.4'}
            _hover={{
              opacity: '0.5',
            }}
            outline={hasError ? 'warning.600' : 'primary.500'}
            fontWeight="semibold"
          >
            {isLoading ? <Spinner /> : uploadTitle}
          </Flex>
        </label>
      </Box>
    </FormControl>
  );
};

export default ButtonFileUpload;
