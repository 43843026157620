import { formatBytesTo } from 'portal-lib';
import React from 'react';
import { useIntl } from 'react-intl';

import { useSorting } from '../../../../helper/sort';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { operationalSystemToIntl } from '../../../../pages/Policies/EditPolicies';
import { listCompanyConsumptionData, Types } from '../../../../store/company';
import { generateExcel } from '../../../../store/event';
import { selectorMetadata } from '../../../../store/ui';
import { ListMetadata } from '../../../../types/generic_list';
import OperationalSystemIcon from '../../../Icons/OperationalSystemIcon';
import Table from '../../../Table';

const DataTable = ({ data, allFilters, setSearch, keyProps, lastFetch }) => {
  const intl = useIntl();
  const { language } = useFormatDate();
  const dispatch = useAppDispatch();
  const consumptionsDataMetadata = useAppSelector(
    selectorMetadata(Types.CONSUMPTION_DATA)
  );

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(
      listCompanyConsumptionData(
        {
          ...consumptionsDataMetadata,
          ...newMetadata,
        },
        allFilters
      )
    );
  };

  const columns = useSorting(
    [
      {
        accessor: 'systemIcon',
        header: '',
        isExportHidden: true,
      },
      {
        accessor: 'operationalSystem',
        header: intl.formatMessage({
          id: 'global.operational_system',
        }),
        isExportField: true,
      },
      {
        accessor: 'consumptionDate',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.tabs_date',
        }),
      },
      {
        accessor: 'user',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.user',
        }),
      },
      {
        accessor: 'phone',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.phone',
        }),
      },
      {
        accessor: 'group',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.group',
        }),
      },
      {
        accessor: 'consumption',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.package_consumption',
        }),
      },
      {
        accessor: 'roaming',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.roaming',
        }),
      },
      {
        accessor: 'carrierNetwork',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.provider',
        }),
      },
    ],
    consumptionsDataMetadata
  );

  const rowData = data.map((consumption) => ({
    cells: [
      {
        field: 'systemIcon',
        value: (
          <OperationalSystemIcon
            operationalSystem={consumption?.operationalSystem}
          />
        ),
        isExportHidden: true,
      },
      {
        field: 'operationalSystem',
        value: intl.formatMessage({
          id: `${operationalSystemToIntl[consumption?.operationalSystem]}`,
        }),
        isExportField: true,
      },

      {
        field: 'dateConsumption',
        value: new Date(consumption.consumptionDate).toLocaleString(language, {
          timeZone: 'UTC',
          dateStyle: 'short',
        }),
      },
      {
        field: 'user',
        value: consumption?.user,
      },
      {
        field: 'phone',
        value: consumption?.phoneNumber,
      },
      {
        field: 'group',
        value: consumption?.group,
      },
      {
        field: 'consumption',
        value: formatBytesTo({ bytes: consumption?.consumption }),
      },
      {
        field: 'roaming',
        value: `${intl.formatMessage({
          id: consumption?.roaming ? 'global.yes' : 'global.no',
        })}`,
      },
      {
        field: 'carrierNetwork',
        value: consumption?.carrierNetwork,
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.CONSUMPTION_DATA,
        metadata: consumptionsDataMetadata,
        filters: lastFetch,
      })
    );
  };

  return (
    <Table
      headerColumns={columns}
      rows={rowData}
      handleSort={handleMetadata}
      metadata={consumptionsDataMetadata}
      pageActionsProps={{
        onSearch: setSearch,
        handleExportAllToExcel: handleExportAllToExcel,
        idDivTableForCopy: 'table-data',
      }}
      keyProp={keyProps}
      nameTable={`${intl.formatMessage({
        id: 'company_consumption.title',
      })}`}
    />
  );
};

export default DataTable;
