import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import {
  CompanyType,
  LicenseType,
  ConsumptionDataType,
  EnterpriseRegister,
  EnterpriseResponseType,
  SettingsUpdateType,
  HelpDeskCompanyType,
  CompanySendWelcomeEmailData,
  CarrierCompanyType,
  CompanyResendWelcomeEmailData,
  CompanyPlan,
} from '../../types/company';
import { GeneralConfigurationType } from '../../types/generalConfiguration';
import { ListQueryParameters } from '../../types/generic_list';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  GET_INFO: 'company/GET_INFO',
  EDIT: 'company/EDIT',
  LIST: 'company/LIST',
  CONFIRM_INFO: 'company/CONFIRM_INFO',
  CONSUMPTION_DATA: 'company/CONSUMPTION_DATA',
  CONSUMPTION_SMS: 'company/CONSUMPTION_SMS',
  LICENSE_LIST: 'company/LICENSE_LIST',
  LICENSE_STATES_OPTIONS: 'company/STATES_OPTIONS',
  DELETE: 'company/DELETE',
  SIGNUP_INITIAL: 'company/SIGNUP_INITIAL',
  COMPANY_REGISTER_ENTERPRISE: 'company/COMPANY_REGISTER_ENTERPRISE',
  GET_ENTERPRISE_WEB_TOKEN: 'company/GET_ENTERPRISE_WEB_TOKEN',
  VALIDATE_EMAIL_SEND: 'company/VALIDATE_EMAIL_SEND',
  VALIDATE_EMAIL: 'company/VALIDATE_EMAIL',
  SETTINGS_UPDATE: 'company/SETTINGS_UPDATE',
  GET_CONFIG: 'company/GET_CONFIG',
  VALIDATE_TOKEN: 'company/VALIDATE_TOKEN',
  NEW_EMAIL_TOKEN: 'company/NEW_EMAIL_TOKEN',
  SEND_WELCOME_EMAIL: 'company/SEND_WELCOME_EMAIL',
  CARRIER_RESEND_WELCOME_EMAIL: 'company/CARRIER_RESEND_WELCOME_EMAIL',
  CARRIER_LIST: 'company/CARRIER_LIST',
  CARRIER_GET: 'company/CARRIER_GET',
  CARRIER_REGISTER: 'company/CARRIER_REGISTER',
  CARRIER_EDIT: 'company/CARRIER_EDIT',
  CARRIER_CANCEL: 'company/CARRIER_CANCEL',
  LIST_PLAN_TO_FILTER: 'company/LIST_PLAN_TO_FILTER',
};

// Reducer
export interface CompanyState {
  company: CompanyType | CarrierCompanyType;
  companies: HelpDeskCompanyType[];
  manageableCompany: CarrierCompanyType;
  manageableCompanies: CarrierCompanyType[];
  consumptionsData: ConsumptionDataType[];
  consumptionsSms: ConsumptionDataType[];
  licenses: LicenseType[];
  licensesStatesOptions: string[];
  enterpriseRegister?: EnterpriseRegister;
  enterpriseWebToken: string;
  authenticatedEmail: boolean;
  generalConfiguration: GeneralConfigurationType;
  companyPlansToFilter: CompanyPlan[];
}

export const initialState: CompanyState = {
  company: null,
  companies: [],
  manageableCompany: null,
  manageableCompanies: [],
  consumptionsData: [],
  consumptionsSms: [],
  licenses: [],
  licensesStatesOptions: [],
  enterpriseRegister: {},
  enterpriseWebToken: null,
  authenticatedEmail: false,
  generalConfiguration: {},
  companyPlansToFilter: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyGetInfoSuccess: (state, action: PayloadAction<CompanyType>) => {
      state.company = action.payload;
    },
    companyEditInfoSuccess: (state, action: PayloadAction<CompanyType>) => {
      state.company = action.payload;
    },
    companyListHelpDeskCompaniesSuccess: (
      state,
      action: PayloadAction<HelpDeskCompanyType[]>
    ) => {
      state.companies = action.payload;
    },
    companyLicensesListSuccess: (
      state,
      action: PayloadAction<LicenseType[]>
    ) => {
      state.licenses = action.payload;
    },
    companyConsumptionsDataListSuccess: (
      state,
      action: PayloadAction<ConsumptionDataType[]>
    ) => {
      state.consumptionsData = action.payload;
    },
    companyConsumptionsSmsListSuccess: (
      state,
      action: PayloadAction<ConsumptionDataType[]>
    ) => {
      state.consumptionsSms = action.payload;
    },
    licensesStatesOptionsListSuccess: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.licensesStatesOptions = action.payload;
    },
    companyValidateEmailToken: (state, action: PayloadAction<boolean>) => {
      state.authenticatedEmail = action.payload;
    },
    setRegisterToken: (state, action: PayloadAction<string>) => {
      state.enterpriseRegister.enterpriseToken = action.payload;
    },
    urlCallbackSuccess: (
      state,
      action: PayloadAction<EnterpriseResponseType>
    ) => {
      state.enterpriseRegister.signupUrlName = action.payload.name;
    },
    enterpriseWebTokenGetSuccess: (
      state,
      action: PayloadAction<{ webToken: string }>
    ) => {
      state.enterpriseWebToken = action.payload.webToken;
    },
    enterpriseWebTokenClear: (state) => {
      state.enterpriseWebToken = initialState.enterpriseWebToken;
    },
    getGeneralConfigCompanySuccess: (
      state,
      action: PayloadAction<GeneralConfigurationType>
    ) => {
      state.generalConfiguration = action.payload;
    },
    carrierCompanyListSuccess: (
      state,
      action: PayloadAction<CarrierCompanyType[]>
    ) => {
      state.manageableCompanies = action.payload;
    },
    carrierCompanyClear: (state) => {
      state.manageableCompany = initialState.manageableCompany;
    },
    carrierCompanyGetSuccess: (
      state,
      action: PayloadAction<CarrierCompanyType>
    ) => {
      state.manageableCompany = {
        ...action.payload,
        referenceCompanyEmail: action?.payload?.email,
      };
    },
    carrierCompanyEditInfo: (
      state,
      action: PayloadAction<CarrierCompanyType>
    ) => {
      state.manageableCompany = action.payload;
    },
    carrierCompanyEditSuccess: (
      state,
      action: PayloadAction<CarrierCompanyType>
    ) => {
      state.manageableCompany = action.payload;
    },
    companyPlansToFilterSuccess: (
      state,
      action: PayloadAction<CompanyPlan[]>
    ) => {
      state.companyPlansToFilter = action.payload;
    },
  },
});

export default companySlice.reducer;

// Action Creators

export const {
  companyGetInfoSuccess,
  companyEditInfoSuccess,
  companyListHelpDeskCompaniesSuccess,
  companyLicensesListSuccess,
  companyConsumptionsDataListSuccess,
  companyConsumptionsSmsListSuccess,
  licensesStatesOptionsListSuccess,
  companyValidateEmailToken,
  urlCallbackSuccess,
  setRegisterToken,
  enterpriseWebTokenGetSuccess,
  enterpriseWebTokenClear,
  getGeneralConfigCompanySuccess,
  carrierCompanyListSuccess,
  carrierCompanyClear,
  carrierCompanyEditInfo,
  carrierCompanyEditSuccess,
  carrierCompanyGetSuccess,
  companyPlansToFilterSuccess,
} = companySlice.actions;

export function listCompanyInfo() {
  return {
    type: Types.GET_INFO,
  };
}

export function editCompany(data) {
  return {
    type: Types.EDIT,
    payload: data,
  };
}
export function newEmailToken(data) {
  return {
    type: Types.NEW_EMAIL_TOKEN,
    payload: data,
  };
}
export function confirmInfoCompany(data) {
  return {
    type: Types.CONFIRM_INFO,
    payload: data,
  };
}

export function listCompanies(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listCompanyLicenses(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LICENSE_LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listCompanyConsumptionData(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.CONSUMPTION_DATA,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listCompanyConsumptionSms(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.CONSUMPTION_SMS,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listLicensesStatesOptions() {
  return {
    type: Types.LICENSE_STATES_OPTIONS,
  };
}

export function deleteCompany() {
  return {
    type: Types.DELETE,
  };
}

export const validateEmailSendCompany = createAction<string>(
  Types.VALIDATE_EMAIL_SEND
);

export const validateEmailCompany = createAction<string>(Types.VALIDATE_EMAIL);

export function sendUrlCallback(data) {
  return {
    type: Types.SIGNUP_INITIAL,
    payload: data,
  };
}

export function sendRegisterToken(data: EnterpriseRegister) {
  return {
    type: Types.COMPANY_REGISTER_ENTERPRISE,
    payload: data,
  };
}

export function getEnterpriseWebToken(data: { parentFrameUrl: string }) {
  return {
    type: Types.GET_ENTERPRISE_WEB_TOKEN,
    payload: data,
  };
}

export function updateEnterpriseConfig(data: SettingsUpdateType) {
  return {
    type: Types.SETTINGS_UPDATE,
    payload: data,
  };
}

export function getGeneralConfiguration() {
  return {
    type: Types.GET_CONFIG,
  };
}

export function sendWelcomeEmail(data: CompanySendWelcomeEmailData) {
  return {
    type: Types.SEND_WELCOME_EMAIL,
    payload: data,
  };
}

export function resendWelcomeEmail(data: CompanyResendWelcomeEmailData) {
  return {
    type: Types.CARRIER_RESEND_WELCOME_EMAIL,
    payload: data,
  };
}

export function listManageableCompanies(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.CARRIER_LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function carrierGetCompany(id: string) {
  return {
    type: Types.CARRIER_GET,
    payload: id,
  };
}

export function carrierRegisterCompany(data: Partial<CarrierCompanyType>) {
  return {
    type: Types.CARRIER_REGISTER,
    payload: data,
  };
}

export function carrierEditCompany(data: Partial<CarrierCompanyType>) {
  return {
    type: Types.CARRIER_EDIT,
    payload: data,
  };
}

export function carrierCancelCompany(id: ID) {
  return {
    type: Types.CARRIER_CANCEL,
    payload: id,
  };
}

export function companyPlanToFilter(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_PLAN_TO_FILTER,
    payload: { filters: sanitizeFilter(filters) },
  };
}
