import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, ForkEffect, select } from 'redux-saga/effects';

import { QuerysWithFilters } from '../../types/generic_list';
import { GroupType } from '../../types/group';
import {
  Types,
  groupCreateSuccess,
  groupListSuccess,
  groupGetSuccess,
  groupUpdateSuccess,
  groupDevicesListSuccess,
  groupListFilterSuccess,
  listGroups,
} from '../group';
import { listNotifications } from '../notification';
import { selectorMetadata, uiAddSuccess } from '../ui';
import { api, safe, requestList, requestSimple } from './util';

function* handleCreation({ payload, type }: PayloadAction<GroupType>) {
  yield call(
    requestSimple,
    api,
    'groupCreation',
    type,
    groupCreateSuccess,
    payload
  );
}

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'groupList', type, groupListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleListFilter({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'groupListFilter',
    type,
    groupListFilterSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* getGroup({ payload, type }: PayloadAction<number>) {
  yield call(requestSimple, api, 'groupGet', type, groupGetSuccess, payload);
}

function* updateGroup({ payload, type }: PayloadAction<GroupType>) {
  yield call(
    requestSimple,
    api,
    'groupUpdate',
    type,
    groupUpdateSuccess,
    payload.id,
    payload
  );
}

function* handleDelete({ payload: id, type }: PayloadAction<GroupType>) {
  yield call(requestSimple, api, 'groupDelete', type, uiAddSuccess, id);
  const metadata = yield select(selectorMetadata(Types.LIST));
  yield put(listGroups(metadata));
}

function* listLinkedUser({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'groupLinkedDeviceList',
    type,
    groupDevicesListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleImportGroups({ payload, type }: PayloadAction<File>) {
  yield call(requestSimple, api, 'groupImportGroups', type, undefined, payload);
  yield put(listNotifications());
}

export default function* groupSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(Types.CREATE, safe(handleCreation));
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.LIST_FILTER, safe(handleListFilter));
  yield takeLatest(Types.GET, safe(getGroup));
  yield takeLatest(Types.UPDATE, safe(updateGroup));
  yield takeLatest(Types.DELETE, safe(handleDelete));
  yield takeLatest(Types.LIST_LINKED_USER, safe(listLinkedUser));
  yield takeLatest(Types.IMPORT_GROUP, safe(handleImportGroups));
}
