import { endOfDay, isAfter, isBefore, isDate, startOfDay } from 'date-fns';
import _ from 'lodash';
import { useMemo } from 'react';

import { ListMetadata } from '../types/generic_list';

type SingleField<T> = keyof T | string;

type Field<T> = keyof T | (keyof T)[] | string | string[];

interface Query<T> {
  data: Data<T>;
  textSearch: (field: Field<T>, value: string) => Query<T>;
  someEquals: (
    arrayField: SingleField<T>,
    fieldInsideArray: SingleField<T>,
    value: unknown
  ) => Query<T>;
  equals: (field: Field<T>, value: string) => Query<T>;
  startDate: (field: Field<T>, value: Date) => Query<T>;
  endDate: (field: Field<T>, value: Date) => Query<T>;
  includes: (
    arrayField: SingleField<T>,
    fieldInsideArray: SingleField<T>,
    arrayValue: unknown[]
  ) => Query<T>;
}

type Data<T = unknown[]> = Array<T>;

interface Pagination {
  metadata: ListMetadata;
}

interface Config<T> {
  filter?: (query: Query<T>) => Query<T>;
  pagination?: Pagination;
}

const filterFields = <T>(
  data: Data<T>,
  field: Field<T>,
  filterFunction: (value: unknown) => boolean
): Data<T> => {
  const fields = (typeof field === 'string' ? [field] : field) as (keyof T)[];

  return data.filter((obj) =>
    fields.some((fieldToCompare) => filterFunction(_.get(obj, fieldToCompare)))
  );
};

function queryFactory<T>(data: Data<T>): Query<T> {
  return {
    data,
    someEquals(arrayField, fieldInsideArray, value) {
      const newData =
        typeof value === 'undefined'
          ? this.data
          : filterFields(this.data, arrayField, (arr: unknown[]) =>
              arr.some((obj) => _.get(obj, fieldInsideArray) === value)
            );

      this.data = newData;

      return this;
    },
    textSearch(field, value) {
      const newData = filterFields(this.data, field, (dataValue: string) =>
        `${dataValue}`.toLowerCase().includes(`${value}`.toLowerCase())
      );

      this.data = newData;

      return this;
    },
    equals(field, value) {
      const newData =
        typeof value === 'undefined'
          ? this.data
          : filterFields(this.data, field, (dataValue) => dataValue === value);

      this.data = newData;

      return this;
    },
    startDate(field, value) {
      const newData = filterFields(this.data, field, (dataValue: string) =>
        isDate(new Date(dataValue)) &&
        isDate(new Date(value)) &&
        dataValue &&
        value
          ? isAfter(new Date(dataValue), startOfDay(value))
          : true
      );

      this.data = newData;

      return this;
    },
    endDate(field, value) {
      const newData = filterFields(this.data, field, (dataValue: string) => {
        return isDate(new Date(dataValue)) &&
          isDate(new Date(value)) &&
          dataValue &&
          value
          ? isBefore(new Date(dataValue), endOfDay(value))
          : true;
      });
      this.data = newData;

      return this;
    },
    includes(arrayField, fieldInsideArray, arrayValue) {
      const newData =
        typeof arrayValue === 'undefined'
          ? this.data
          : filterFields(this.data, arrayField, (arr: unknown[]) =>
              arr.some((obj) =>
                arrayValue.includes(_.get(obj, fieldInsideArray))
              )
            );

      this.data = newData;

      return this;
    },
  };
}

export const useFilter = <T = unknown[]>(data: Data<T>, config?: Config<T>) => {
  const newData = useMemo(() => {
    const filtered = config?.filter
      ? config.filter(queryFactory(data)).data
      : data;

    const newData = {
      data: filtered,
      totalPages: 0,
      totalItems: filtered.length,
    };

    if (!config?.pagination) return newData;

    const { metadata } = config.pagination;

    const firstPageIndex = (metadata.page - 1) * metadata.pageSize;
    const lastPageIndex = firstPageIndex + metadata.pageSize;

    const paginatedData = newData.data.slice(firstPageIndex, lastPageIndex);

    newData.totalPages = Math.ceil(
      newData.data.length / metadata.pageSize
    ) as number;
    newData.data = paginatedData;

    return newData;
  }, [config, data]);

  return newData;
};
