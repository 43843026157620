import { Flex } from '@chakra-ui/layout';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import Text from '../../../../../../../../Text';
import InputForDynamic, { CommonProps } from './InputForDynamic';

export default function ColorInput(props: CommonProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const isDisabled = props?.isDisabled;

  return (
    <Flex align="center" gridGap={2}>
      <Flex w="full" maxW={10}>
        <InputForDynamic
          {...props}
          ref={inputRef}
          type="color"
          containerStyles={{ w: 'full' }}
          styles={{ m: 0, px: 0.5 }}
          name="kioskColor"
        />
      </Flex>

      <Text
        m={0}
        textDecor="underline"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        color="primary.500"
        fontWeight="semibold"
        filter="auto"
        brightness={isDisabled && '120%'}
        onClick={() => {
          inputRef.current.click();
        }}
      >
        {intl.formatMessage({ id: 'edit_policy.kiosk.kiosk_icon_font_color' })}
      </Text>
    </Flex>
  );
}
