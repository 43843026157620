import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { PrivilegeEnum } from '../../helper';
import { resetStoreAction } from '../../helper/redux';
import routes from '../../routes';
import { LoginFormType, LoginResponse } from '../../types/loginAdminUser';
import { PortalSettingsType } from '../../types/portalSettings';
import { HistoryState } from '../../types/route';
import { ID } from '../../types/util';
import {
  authHelpDeskLoginSuccess,
  authHelpDeskSelectCompanySuccess,
  authLoginError,
  authLoginSuccess,
  authLogout,
  authLogoutError,
  authPersisterSuccess,
  authRefreshToken,
  AuthState,
  Types,
} from '../auth';
import { history } from '../history';
import { portalSettingsGetSuccess } from '../portalSettings';
import { api, apiRefreshToken, requestSimple, safe } from './util';

function* handleLogin(action: PayloadAction<LoginFormType>) {
  try {
    const response: LoginResponse = yield call(api, 'login', action.payload);
    yield handleFillStatesAndPushForward(response, action);
  } catch (e) {
    yield put(authLoginError());
    throw e;
  }
}

function* handleFillStatesAndPushForward(
  response: LoginResponse,
  action: PayloadAction<LoginFormType>
) {
  const newResponse = mapperResponse(response, action);

  yield put(authLoginSuccess(newResponse));
  yield put(portalSettingsGetSuccess(newResponse.user.settings || {}));

  const routeForwardByPrivilege = {
    default: routes.dashboard,
    [PrivilegeEnum.HELP_DESK]: routes.helpDesk.companiesList,
    [PrivilegeEnum.BACKOFFICE]: routes.zeroTouch.backoffice.manage,
  };

  let routeToForward =
    routeForwardByPrivilege[response.user.privilege] ||
    routeForwardByPrivilege.default;

  if (response.user.privilege === PrivilegeEnum.HELP_DESK) {
    yield put(authHelpDeskLoginSuccess(newResponse));
  } else if (!response.user.company.enterpriseId) {
    const { enterpriseRegister } = yield select((state) => state.company);

    if (
      enterpriseRegister?.signupUrlName &&
      enterpriseRegister?.enterpriseToken
    ) {
      routeToForward = routes.company.callback;
    } else {
      routeToForward = routes.company.register;
    }
  }

  yield put(authPersisterSuccess());
  yield call(forwardTo, routeToForward);
}

function mapperResponse(
  response: Partial<AuthState> & { user: { settings: PortalSettingsType } },
  action: { payload: LoginFormType; type: string }
) {
  const newUser = {
    ...response.user,
    language:
      response?.user?.language || response?.user?.settings?.defaultLanguage,
    company: {
      ...response?.user?.company,
      name: response?.user?.company?.corporateName,
    },
  };
  const newResponse = {
    ...response,
    user: newUser,
    keepLogged: action.payload.keepLogged,
  };
  return newResponse;
}

function* handleLogout({ payload: historyState }: PayloadAction<HistoryState>) {
  try {
    yield put(authLogout());
    yield call(api, 'logout');
  } catch (e) {
    yield put(authLogoutError());
    throw e;
  } finally {
    yield put(resetStoreAction());

    yield call(forwardTo, routes.login, historyState);
  }
}

function* handleRecover({
  payload,
  type,
}: PayloadAction<{ email: string; url: string }>) {
  yield requestSimple(
    api,
    'adminUserRecoverPassword',
    type,
    undefined,
    payload
  );
}

function* handleChangeSession({
  payload,
  type,
}: PayloadAction<{ companyId: ID }>) {
  yield requestSimple(
    api,
    'authChangeSession',
    type,
    authHelpDeskSelectCompanySuccess,
    payload
  );
  yield call(forwardTo, routes.dashboard);
}

function* handleLoginConfirmation({
  payload,
  type,
}: PayloadAction<LoginFormType>) {
  const response = yield requestSimple(
    api,
    'loginConfirmation',
    type,
    undefined,
    payload
  );
  yield handleFillStatesAndPushForward(response, { payload, type });
}

function* handleResendToken({
  payload,
  type,
}: PayloadAction<{ email: string }>) {
  yield requestSimple(api, 'loginResendToken', type, undefined, payload);
}

function forwardTo(location, historyState?: HistoryState) {
  history.push(location, historyState);
}

function* handleRefreshSession({ type }) {
  const newResponse = yield call(apiRefreshToken, 'getNewToken', type);

  yield put(
    authRefreshToken({
      expiresIn: newResponse?.expiresIn,
      accessToken: newResponse?.accessToken,
      refreshToken: newResponse?.refreshToken,
      accessTokenCreateAt: newResponse?.accessTokenCreateAt,
    })
  );
  yield put(portalSettingsGetSuccess(newResponse.user.settings || {}));
}

export default function* sessionSaga() {
  yield takeLatest(Types.LOGIN, safe(handleLogin));
  yield takeLatest(Types.RECOVER_PASSWORD, safe(handleRecover));
  yield takeLatest(Types.LOGOUT, safe(handleLogout));
  yield takeLatest(Types.CHANGE_SESSION, safe(handleChangeSession));
  yield takeLatest(Types.LOGIN_CONFIRMATION, safe(handleLoginConfirmation));
  yield takeLatest(Types.RESEND_TOKEN, safe(handleResendToken));
  yield takeLatest(Types.REFRESH_SESSION, safe(handleRefreshSession));
}
