import { Box, useRadio, useStyleConfig } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

// function RadioCard(props, ...rest) {
function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const style = useStyleConfig('RadioButton');

  return (
    <Box
      as="label"
      {...checkbox}
      cursor={props.disabled ? 'not-allowed' : 'pointer'}
      opacity={props.disabled && '0.5'}
      maxWidth="260px"
      pl="20px"
      pr="20px"
      h="45px"
      d="flex"
      alignItems="center"
      justifyContent="center"
      {...style}
    >
      <input {...input} disabled={props.disabled} />
      {props.children}
    </Box>
  );
}

RadioCard.propTypes = {
  children: PropTypes.any,
};

export default RadioCard;
