import { Button, Divider, Flex, Image, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { iconImgToRender } from '../../../../../../../../helper';
import { PolicyCompanyApplicationType } from '../../../../../../../../types/policy';
import Card from '../../../../../../../Card';
import LeftArrowCircleIcon from '../../../../../../../Icons/LeftArrowCircle';

interface AppActionHeaderProps {
  companyApplication: PolicyCompanyApplicationType;
  handleClose?: () => void;
  title: string;
}

const AppActionHeader = ({
  companyApplication,
  handleClose,
  title,
}: AppActionHeaderProps) => {
  return (
    <Card p="6" mb="8">
      <Flex align="center" w="100%" gridGap="6">
        <Flex align="center" w="60%" gridGap="6" flex="2">
          <Flex align="center">
            <Image
              w="45px"
              src={iconImgToRender(companyApplication?.iconBase64)}
              mr="6"
            />
            <Text fontWeight="bold" fontSize="2xl">
              {companyApplication?.name}
            </Text>
          </Flex>
          <Divider orientation="vertical" h="32px" />
          <Text fontSize="2xl">
            <FormattedMessage id={title} />
          </Text>
        </Flex>
        {handleClose && (
          <Button
            variant="link"
            leftIcon={<LeftArrowCircleIcon boxSize={8} color="primary.500" />}
            onClick={() => handleClose()}
            color="primary.500"
          >
            <FormattedMessage id="edit_policy.app_config.list_application" />
          </Button>
        )}
      </Flex>
    </Card>
  );
};

export default AppActionHeader;
