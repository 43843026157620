import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { hasEmpty } from '../../store/ui';
import PageActions from '../PageActions';
import PagePagination from '../PagePagination';
import PageShowToaster from '../PageShowToaster';
import TableComponent from './Table';
import { TableContainerProps } from './TableInterfaces';

const Table = ({
  headerColumns,
  rows,
  metadata,
  handleSort,
  keyProp,
  nameTable,
  disabledActions,
  pageActionsProps = {},
  pageSizeFixed,
  isLoadMore,
  messageError,
  isEmpty,
  paginationProps,
}: TableContainerProps) => {
  const isTypesEmpty = hasEmpty(keyProp);

  useEffect(() => {
    const page = +metadata?.page || 1;

    if (isTypesEmpty && page > 1) {
      handleSort({ page: page - 1 });
    }
  }, [isTypesEmpty]);

  return (
    <Box>
      <PageShowToaster
        toasterKeys={[keyProp]}
        messageError={messageError}
        disabledSuccess
      />
      {!disabledActions && (
        <PageActions
          initialSearch={pageActionsProps.initialSearch}
          onSearch={pageActionsProps.onSearch}
          idDivTableForCopy={
            pageActionsProps.idDivTableForCopy || nameTable || 'table'
          }
          actionButtons={pageActionsProps.actionButtons}
          linkNew={pageActionsProps.linkNew}
          labelButtonNew={pageActionsProps.labelButtonNew}
          disabledLinkNew={pageActionsProps.disabledLinkNew}
          labelPrimaryButton={pageActionsProps.labelPrimaryButton}
          onClickPrimaryButton={pageActionsProps.onClickPrimaryButton}
          isPrimaryButtonLoading={pageActionsProps.isPrimaryButtonLoading}
          disabledPrimaryButton={pageActionsProps.disabledPrimaryButton}
          excel={{
            name: nameTable,
            tableData: rows,
            tableColumns: headerColumns,
            tableMetadata: metadata,
            handleExportAllToExcel: pageActionsProps.handleExportAllToExcel,
          }}
          disabledAllExportsActions={pageActionsProps.disabledAllExportsActions}
          disabledExportAction={pageActionsProps.disabledExportAction}
          menuActionButton={pageActionsProps.menuActionButton}
          emptyExportActionsMessage="table.action.empty_export_message"
          isEmptyExportActions={isTypesEmpty}
        />
      )}
      <TableComponent
        id={pageActionsProps.idDivTableForCopy || nameTable || 'table'}
        headerColumns={headerColumns}
        rows={rows}
        handleSort={handleSort}
        keyProp={keyProp}
        isEmpty={isEmpty || isTypesEmpty}
      />
      <PagePagination
        pagination={!isTypesEmpty && metadata}
        onPageChange={handleSort}
        pageSizeFixed={pageSizeFixed}
        isLoadMore={isLoadMore}
        {...paginationProps}
      />
    </Box>
  );
};

export default Table;
