import React from 'react';
import { IntlShape } from 'react-intl';

import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../../store/policy';
import {
  ManagementModeEnum,
  PasswordPolicyScopePropertyEnum,
  PolicyPasswordType,
  QualityPasswordEnum,
} from '../../../../../../../types/policy';
import { CardItemType } from '../../../../../../CardContent';
import Select from '../../../../../../Select';
import SwitchButton from '../../../../../../SwitchButton';
import { GetterSettingWithCustomParamsFn } from '../../../PoliciesInterfaces';
import { getAlphanumericPasswordSettings } from './AlphanumericPassword';
import { getBiometricPasswordSettings } from './BiometricPassword';
import { getComplexPasswordSettings } from './ComplexPassword';
interface PasswordPolicySettingsProps {
  passwordScope: PasswordPolicyScopePropertyEnum;
}
export interface PasswordPolicyChildrenProps {
  passwordPolicies?: PolicyPasswordType;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  intl: IntlShape;
}

export const intlPolicyPassword = 'update_policies.passwordPolicies';

export const getPasswordPolicySettings: GetterSettingWithCustomParamsFn<
  PasswordPolicySettingsProps
> = ({ intl, policy, passwordScope }) => {
  const dispatch = useAppDispatch();

  const hasAppBlockSIM =
    policy?.managementMode === ManagementModeEnum.BLOCK_SIM_ANDROID;

  const isProfileScope =
    passwordScope === PasswordPolicyScopePropertyEnum.SCOPE_PROFILE;

  const devicePasswordPolicyInProfileScope =
    isProfileScope &&
    policy?.passwordPolicy?.appliedScopeDevicePasswordPolicyInScopeProfile;

  const isAlphanumeric = [
    QualityPasswordEnum.NUMERIC,
    QualityPasswordEnum.ALPHABETIC,
    QualityPasswordEnum.ALPHANUMERIC,
    QualityPasswordEnum.NUMERIC_COMPLEX,
  ].includes(policy?.passwordPolicy?.[passwordScope]?.passwordQuality);

  const isComplex =
    policy?.passwordPolicy?.[passwordScope]?.passwordQuality ===
    QualityPasswordEnum.COMPLEX;

  const isUnspecified =
    policy?.passwordPolicy?.[passwordScope]?.passwordQuality ===
      QualityPasswordEnum.UNSPECIFIED || hasAppBlockSIM;

  const qualityPasswordOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.unspecified`,
      }),
      value: QualityPasswordEnum.UNSPECIFIED,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.biometric_weak`,
      }),
      value: QualityPasswordEnum.BIOMETRIC_WEAK,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.something`,
      }),
      value: QualityPasswordEnum.SOMETHING,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.numeric`,
      }),
      value: QualityPasswordEnum.NUMERIC,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.numeric_complex`,
      }),
      value: QualityPasswordEnum.NUMERIC_COMPLEX,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.alphabetic`,
      }),
      value: QualityPasswordEnum.ALPHABETIC,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.alphanumeric`,
      }),
      value: QualityPasswordEnum.ALPHANUMERIC,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.complex`,
      }),
      value: QualityPasswordEnum.COMPLEX,
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event?.target;
    const IS_SELECT = type === 'select-one';
    const NUMBER_LIMIT = 15;

    dispatch(
      policySelected({
        passwordPolicy: {
          ...policy?.passwordPolicy,
          [passwordScope]: {
            ...policy?.passwordPolicy?.[passwordScope],
            [name]: IS_SELECT
              ? value
              : value.length > NUMBER_LIMIT
              ? policy?.passwordPolicy?.[passwordScope][name]
              : Number(value),
          },
        },
      })
    );
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    dispatch(
      policySelected({
        passwordPolicy: {
          ...policy.passwordPolicy,
          [name]: checked,
        },
      })
    );
  };

  const descriptionToIntl = {
    [QualityPasswordEnum.BIOMETRIC_WEAK]: `${intlPolicyPassword}.description.biometric_weak`,
    [QualityPasswordEnum.SOMETHING]: `${intlPolicyPassword}.description.something`,
    [QualityPasswordEnum.COMPLEX]: `${intlPolicyPassword}.description.complex`,
    [QualityPasswordEnum.NUMERIC]: `${intlPolicyPassword}.description.numeric`,
    [QualityPasswordEnum.NUMERIC_COMPLEX]: `${intlPolicyPassword}.description.numeric_complex`,
    [QualityPasswordEnum.ALPHABETIC]: `${intlPolicyPassword}.description.alphabetic`,
    [QualityPasswordEnum.ALPHANUMERIC]: `${intlPolicyPassword}.description.alphanumeric`,
    [QualityPasswordEnum.UNSPECIFIED]: hasAppBlockSIM
      ? `${intlPolicyPassword}.description.app_block_sim`
      : `${intlPolicyPassword}.description.unspecified`,
  };

  const initialSettings: CardItemType[] = [
    {
      title: `${intlPolicyPassword}.applied_device_settings.title`,
      isHidden: !isProfileScope,
      children: (
        <SwitchButton
          name="appliedScopeDevicePasswordPolicyInScopeProfile"
          isChecked={
            policy?.passwordPolicy
              ?.appliedScopeDevicePasswordPolicyInScopeProfile
          }
          onChange={handleCheckBoxChange}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.title`,
      description:
        descriptionToIntl[
          policy?.passwordPolicy?.[passwordScope]?.passwordQuality
        ],
      isHidden: devicePasswordPolicyInProfileScope,
      children: (
        <Select
          name="passwordQuality"
          isDisabled={hasAppBlockSIM}
          value={
            hasAppBlockSIM
              ? QualityPasswordEnum.UNSPECIFIED
              : policy?.passwordPolicy?.[passwordScope]?.passwordQuality
          }
          onChange={handleChange}
        >
          {qualityPasswordOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
  ];

  const listBiometricSettings = getBiometricPasswordSettings({
    passwordPolicies: policy?.passwordPolicy?.[passwordScope],
    handleChange,
    intl,
  });
  const listComplexSettings = getComplexPasswordSettings({
    passwordPolicies: policy?.passwordPolicy?.[passwordScope],
    handleChange,
    intl,
  });

  const listAlphanumericSettings = getAlphanumericPasswordSettings({
    passwordPolicies: policy?.passwordPolicy?.[passwordScope],
    handleChange,
    intl,
  });

  const defaultSettings = [...initialSettings, ...listBiometricSettings];

  if (isUnspecified || devicePasswordPolicyInProfileScope)
    return initialSettings;
  if (isAlphanumeric) return [...defaultSettings, ...listAlphanumericSettings];
  if (isComplex) return [...defaultSettings, ...listComplexSettings];

  return defaultSettings;
};
