import { Flex, FlexProps, useStyleConfig } from '@chakra-ui/react';

interface CardProps extends FlexProps {
  children: React.ReactNode;
}

const Card = ({ children, ...rest }: CardProps) => {
  const style = useStyleConfig('Card');
  return (
    <Flex flexDirection="column" __css={{ ...style }} {...rest} minWidth="0">
      {children}
    </Flex>
  );
};

export default Card;
