import { Box, Flex } from '@chakra-ui/layout';
import { useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import Button from '../Button';
import Upload from '../Icons/Upload';
import Spinner from '../Spinner';
import Text from '../Text';

interface Props {
  onDrop?: DropzoneOptions['onDrop'];
  /** Accepted files ref: https://react-dropzone.js.org/#section-accepting-specific-file-types */
  accept?: DropzoneOptions['accept'];
  maxFiles?: DropzoneOptions['maxFiles'];
  isLoading?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
}

enum Errors {
  default = 'default',
}

const errorToIntl: Record<Errors, string> = {
  default: 'dropzone.rejected_file',
};

export default function DropZone({
  onDrop: onDropParam,
  accept,
  maxFiles,
  isLoading,
  errorMessage: errorMessageProp,
  isDisabled,
}: Props) {
  const intl = useIntl();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onDrop: DropzoneOptions['onDrop'] = (acceptedFiles, ...rest) => {
    if (acceptedFiles?.length > 0) {
      onDropParam?.(acceptedFiles, ...rest);
      setErrorMessage('');
    } else {
      setErrorMessage(intl.formatMessage({ id: errorToIntl.default }));
    }
  };

  const { getInputProps, getRootProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept,
      maxFiles,
      disabled: isDisabled,
    });

  return (
    <Flex
      direction="column"
      w="full"
      filter="auto"
      brightness={isDisabled && '120%'}
    >
      <Flex
        {...getRootProps()}
        h="15rem"
        w="full"
        border="2px"
        borderStyle="dashed"
        borderRadius="lg"
        borderColor="gray.800"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        filter="auto"
        brightness={isDragActive && '50%'}
        transition="filter 0.2s ease-in-out"
        cursor={isDisabled && 'not-allowed'}
      >
        <input {...getInputProps()} />

        {isLoading && <Spinner size="xl" />}

        {!isLoading && (
          <>
            <Upload boxSize={8} color="gray.800" />

            <Flex flexDirection="column" gridGap={2} mt={6} alignItems="center">
              <Text fontSize="lg" m={0}>
                {isDragReject
                  ? intl.formatMessage({ id: 'dropzone.rejected_file' })
                  : intl.formatMessage({ id: 'dropzone.drop_text' })}
              </Text>

              <Text m={0} fontSize="sm">
                {intl.formatMessage({ id: 'dropzone.or' })}
              </Text>

              <Button variant="outline" isDisabled={isDisabled}>
                {intl.formatMessage({ id: 'dropzone.button' })}
              </Button>
            </Flex>
          </>
        )}
      </Flex>

      {(!!errorMessage || !!errorMessageProp) && (
        <Box
          color="warning.500"
          fontSize="sm"
          fontWeight="bold"
          h="21px"
          m="0"
          mt={1}
        >
          {errorMessageProp || errorMessage}
        </Box>
      )}
    </Flex>
  );
}
