import { Flex } from '@chakra-ui/react';
import { endOfDay, format, startOfDay } from 'date-fns';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { handleFormatStringToTime } from '../../../../../../helper/date';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import {
  GpsFrequencyEnum,
  LocationModeEnum,
} from '../../../../../../types/policy';
import { CardItemType } from '../../../../../CardContent';
import FormControl from '../../../../../FormControl';
import Select from '../../../../../Select';
import SwitchButton from '../../../../../SwitchButton';
import TimePicker from '../../../../../TimePicker/TimePicker';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getLocalizationSettings: GetterSettingFn = ({
  intl,
  policy,
  handleChange,
  handleChangeByFields,
}) => {
  const dispatch = useAppDispatch();

  const today = new Date();

  const handlePeriodFilterChange = (date: Date, name: string) => {
    dispatch(
      policySelected({
        [name]: date ? format(date, 'HH:mm:ss') : null,
      })
    );
  };

  const localizationModeOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.mode.select.user_choice`,
      }),
      value: LocationModeEnum.LOCATION_USER_CHOICE,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.mode.select.location_enforced`,
      }),
      value: LocationModeEnum.LOCATION_ENFORCED,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.mode.select.location_disabled`,
      }),
      value: LocationModeEnum.LOCATION_DISABLED,
    },
  ];

  const gpsFrequencyOptions = [
    {
      value: GpsFrequencyEnum['3min'],
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.monitoring_hours.gps_frequency.min3`,
      }),
    },
    {
      value: GpsFrequencyEnum['10min'],
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.monitoring_hours.gps_frequency.min10`,
      }),
    },
    {
      value: GpsFrequencyEnum['30min'],
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.monitoring_hours.gps_frequency.min30`,
      }),
    },
    {
      value: GpsFrequencyEnum['1h'],
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.monitoring_hours.gps_frequency.h1`,
      }),
    },
    {
      value: GpsFrequencyEnum['6h'],
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.monitoring_hours.gps_frequency.h6`,
      }),
    },
    {
      value: GpsFrequencyEnum['12h'],
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.monitoring_hours.gps_frequency.h12`,
      }),
    },
    {
      value: GpsFrequencyEnum['24h'],
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.localization.monitoring_hours.gps_frequency.h24`,
      }),
    },
  ];

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.localization.mode.title`,
      description: `${intlPolicySettingsKey}.localization.mode.description`,
      children: (
        <Select
          name="locationMode"
          value={policy?.locationMode}
          onChange={handleChange}
        >
          {localizationModeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.localization.location_sharing_disabled.title`,
      description: `${intlPolicySettingsKey}.localization.location_sharing_disabled.description`,
      children: (
        <SwitchButton
          name="shareLocationDisabled"
          isChecked={policy?.shareLocationDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.localization.monitoring_hours.title`,
      description: `${intlPolicySettingsKey}.localization.monitoring_hours.description`,
      children: (
        <Flex gridGap={3}>
          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.localization.monitoring_hours.initial_time`}
              />
            }
          >
            <TimePicker
              textLabel={intl.formatMessage({
                id: `${intlPolicySettingsKey}.localization.monitoring_hours.select`,
              })}
              onChange={(e) =>
                handlePeriodFilterChange(e, 'gpsCaptureStartTime')
              }
              selected={handleFormatStringToTime(policy?.gpsCaptureStartTime)}
              hasValue={!!policy?.gpsCaptureStartTime}
              minTime={startOfDay(today)}
              maxTime={
                handleFormatStringToTime(policy?.gpsCaptureEndTime) ||
                endOfDay(today)
              }
            />
          </FormControl>
          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.localization.monitoring_hours.final_time`}
              />
            }
          >
            <TimePicker
              textLabel={intl.formatMessage({
                id: `${intlPolicySettingsKey}.localization.monitoring_hours.select`,
              })}
              selected={handleFormatStringToTime(policy?.gpsCaptureEndTime)}
              onChange={(e) => handlePeriodFilterChange(e, 'gpsCaptureEndTime')}
              hasValue={!!policy?.gpsCaptureEndTime}
              maxTime={endOfDay(today)}
              minTime={
                handleFormatStringToTime(policy?.gpsCaptureStartTime) ||
                startOfDay(today)
              }
            />
          </FormControl>
        </Flex>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.localization.find_every.title`,
      description: `${intlPolicySettingsKey}.localization.find_every.description`,
      children: (
        <Select
          name="gpsFrequency"
          value={policy?.gpsFrequency}
          onChange={(e) => {
            handleChangeByFields(e.target.name, parseInt(e.target.value));
          }}
        >
          {gpsFrequencyOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
  ];

  return listSettings;
};
