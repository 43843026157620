import React from 'react';

import { CardItemType } from '../../../../../CardContent';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getSyncBackupSettings: GetterSettingFn = ({
  handleChange,
  policy,
}) => {
  const listSettings: CardItemType[] = [
    // {
    //   title: `${intlPolicySettingsKey}.sync_backup.backup.title`,
    //   description: `${intlPolicySettingsKey}.sync_backup.backup.description`,
    //   children: (
    //     <SwitchButton
    //       name="allowCloudBackup"
    //       isChecked={policy?.allowCloudBackup}
    //       onChange={handleChange}
    //     />
    //   ),
    // },
    // {
    //   title: `${intlPolicySettingsKey}.sync_backup.keychain.title`,
    //   description: `${intlPolicySettingsKey}.sync_backup.keychain.description`,
    //   children: (
    //     <SwitchButton
    //       name="allowCloudKeychainSync"
    //       isChecked={policy?.allowCloudKeychainSync}
    //       onChange={handleChange}
    //     />
    //   ),
    // },
    {
      title: `${intlPolicySettingsKey}.sync_backup.data.title`,
      description: `${intlPolicySettingsKey}.sync_backup.data.description`,
      children: (
        <SwitchButton
          name="allowManagedAppsCloudSync"
          isChecked={policy?.allowManagedAppsCloudSync}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.sync_backup.enterprise_books.title`,
      description: `${intlPolicySettingsKey}.sync_backup.enterprise_books.description`,
      children: (
        <SwitchButton
          name="allowEnterpriseBookBackup"
          isChecked={policy?.allowEnterpriseBookBackup}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.sync_backup.sync_books.title`,
      description: `${intlPolicySettingsKey}.sync_backup.sync_books.description`,
      children: (
        <SwitchButton
          name="allowEnterpriseBookMetadataSync"
          isChecked={policy?.allowEnterpriseBookMetadataSync}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.sync_backup.photo.title`,
      description: `${intlPolicySettingsKey}.sync_backup.photo.description`,
      children: (
        <SwitchButton
          name="allowCloudPhotoLibrary"
          isChecked={policy?.allowCloudPhotoLibrary}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.sync_backup.photo_menu.title`,
      description: `${intlPolicySettingsKey}.sync_backup.photo_menu.description`,
      children: (
        <SwitchButton
          name="allowSharedStream"
          isChecked={policy?.allowSharedStream}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.sync_backup.encrypted_backup.title`,
      description: `${intlPolicySettingsKey}.sync_backup.encrypted_backup.description`,
      children: (
        <SwitchButton
          name="forceEncryptedBackup"
          isChecked={policy?.forceEncryptedBackup}
          onChange={handleChange}
        />
      ),
    },
  ];

  return listSettings;
};
