import React from 'react';

import {
  MicrophoneAccessEnum,
  TetheringSettingsEnum,
  WifiDirectSettingsEnum,
} from '../../../../../../types/policy';
import { CardItemType } from '../../../../../CardContent';
import Select from '../../../../../Select';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getHardwareSettings: GetterSettingFn = ({
  handleChange,
  intl,
  policy,
}) => {
  const microphoneAccessOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.microphone_access.select.user_choice`,
      }),
      value: MicrophoneAccessEnum.MICROPHONE_ACCESS_USER_CHOICE,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.microphone_access.select.access_disabled`,
      }),
      value: MicrophoneAccessEnum.MICROPHONE_ACCESS_DISABLED,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.microphone_access.select.access_enforced`,
      }),
      value: MicrophoneAccessEnum.MICROPHONE_ACCESS_ENFORCED,
    },
  ];

  const tetheringSettingsOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.tethering_settings.select.allow_all_tethering`,
      }),
      value: TetheringSettingsEnum.ALLOW_ALL_TETHERING,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.tethering_settings.select.disallow_wifi_tethering`,
      }),
      value: TetheringSettingsEnum.DISALLOW_WIFI_TETHERING,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.tethering_settings.select.disallow_all_tethering`,
      }),
      value: TetheringSettingsEnum.DISALLOW_ALL_TETHERING,
    },
  ];

  const wifiDirectSettingsOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.wifi_direct_settings.select.allow_wifi_direct`,
      }),
      value: WifiDirectSettingsEnum.ALLOW_WIFI_DIRECT,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.hardware.wifi_direct_settings.select.disallow_wifi_direct`,
      }),
      value: WifiDirectSettingsEnum.DISALLOW_WIFI_DIRECT,
    },
  ];

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.hardware.disable_camera.title`,
      description: `${intlPolicySettingsKey}.hardware.disable_camera.description`,
      children: (
        <SwitchButton
          name="cameraDisabled"
          isChecked={policy?.cameraDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.lock_volume.title`,
      description: `${intlPolicySettingsKey}.hardware.lock_volume.description`,
      children: (
        <SwitchButton
          name="adjustVolumeDisabled"
          isChecked={policy?.adjustVolumeDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.disable_bluetooth.title`,
      description: `${intlPolicySettingsKey}.hardware.disable_bluetooth.description`,
      children: (
        <SwitchButton
          name="bluetoothDisabled"
          isChecked={policy?.bluetoothDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.bluetooth_settings_block.title`,
      description: `${intlPolicySettingsKey}.hardware.bluetooth_settings_block.description`,
      children: (
        <SwitchButton
          name="bluetoothConfigDisabled"
          isChecked={policy?.bluetoothConfigDisabled}
          onChange={handleChange}
        />
      ),
    },
    // {
    //   title: `${intlPolicySettingsKey}.hardware.bluetooth_contact_sharing_disabled.title`,
    //   description: `${intlPolicySettingsKey}.hardware.bluetooth_contact_sharing_disabled.description`,
    //   children: (
    //     <SwitchButton
    //       name="bluetoothContactSharingDisabled"
    //       isChecked={policy?.bluetoothContactSharingDisabled}
    //       onChange={handleChange}
    //     />
    //   ),
    // },
    {
      title: `${intlPolicySettingsKey}.hardware.outgoing_beam_disabled.title`,
      description: `${intlPolicySettingsKey}.hardware.outgoing_beam_disabled.description`,
      children: (
        <SwitchButton
          name="outgoingBeamDisabled"
          isChecked={policy?.outgoingBeamDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.tethering_settings.title`,
      description: `${intlPolicySettingsKey}.hardware.tethering_settings.description`,
      children: (
        <Select
          name="tetheringSettings"
          value={policy?.tetheringSettings}
          onChange={handleChange}
          minWidth="450px"
        >
          {tetheringSettingsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.wifi_direct_settings.title`,
      description: `${intlPolicySettingsKey}.hardware.wifi_direct_settings.description`,
      children: (
        <Select
          name="wifiDirectSettings"
          value={policy?.wifiDirectSettings}
          onChange={handleChange}
          minWidth="450px"
        >
          {wifiDirectSettingsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.disable_screenshots.title`,
      description: `${intlPolicySettingsKey}.hardware.disable_screenshots.description`,
      children: (
        <SwitchButton
          name="screenCaptureDisabled"
          isChecked={policy?.screenCaptureDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.microphone_access.title`,
      description: `${intlPolicySettingsKey}.hardware.microphone_access.description`,
      children: (
        <Select
          name="microphoneAccess"
          value={policy?.microphoneAccess}
          onChange={handleChange}
        >
          {microphoneAccessOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.hardware.outgoing_calls_disabled.title`,
      description: `${intlPolicySettingsKey}.hardware.outgoing_calls_disabled.description`,
      children: (
        <SwitchButton
          name="outgoingCallsDisabled"
          isChecked={policy?.outgoingCallsDisabled}
          onChange={handleChange}
        />
      ),
    },
  ];

  return listSettings;
};
