import { ExpandedIndex, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import Accordion from '../../../../Accordion';
import AccordionContainer from '../../../../AccordionContainer';
import { PolicyAccordionProps } from '../PoliciesInterfaces';

interface PolicyAccordionsProps {
  accordionList: PolicyAccordionProps[];
  searchWords?: string;
}

const PolicyAccordions = ({
  accordionList,
  searchWords,
}: PolicyAccordionsProps) => {
  const [openAccordions, setOpenAccordions] = useState<ExpandedIndex>([]);

  useEffect(() => {
    handleToggleAccordions();
  }, [searchWords]);

  function handleToggleAccordions() {
    if (!!searchWords) {
      const newValue = [...Array(accordionList.length)]?.map(
        (_, index) => index
      );
      setOpenAccordions(newValue);
    } else {
      setOpenAccordions([]);
    }
  }

  return (
    <Accordion index={openAccordions} onChange={setOpenAccordions}>
      <Flex direction="column" gridGap={6}>
        {accordionList.length > 0 &&
          accordionList.map((accordion) => (
            <AccordionContainer
              key={accordion.title}
              searchWord={searchWords}
              {...accordion}
            />
          ))}
      </Flex>
    </Accordion>
  );
};

export default PolicyAccordions;
