import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { AuditType, AuditUserType } from '../../types/audit';
import { ListQueryParameters } from '../../types/generic_list';

// Action Types

export const Types = {
  GET_AUDIT_LIST: 'audit/GET_AUDIT_LIST',
  USERS_TO_FILTER: 'audit/USERS_TO_FILTER',
};

// Reducer
export interface AuditState {
  audits: AuditType[];
  usersToFilter: AuditUserType[];
}

export const initialState: AuditState = {
  audits: [],
  usersToFilter: [],
};

const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    auditListSuccess: (state, action: PayloadAction<AuditType[]>) => {
      state.audits = action.payload;
    },
    auditUsersFilterSuccess: (
      state,
      action: PayloadAction<AuditUserType[]>
    ) => {
      state.usersToFilter = action.payload;
    },
  },
});

export default auditSlice.reducer;

// Action Creators

export const { auditListSuccess, auditUsersFilterSuccess } = auditSlice.actions;

export function listAudits(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.GET_AUDIT_LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listUsersToFilter(filters?: Record<string, unknown>) {
  return {
    type: Types.USERS_TO_FILTER,
    payload: { filters: sanitizeFilter(filters) },
  };
}
