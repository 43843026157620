import { formatDate } from '../helper/date';
import { useAppSelector } from './useRedux';

export interface FormatDateProps {
  handleFormatDateByUserLogged: (
    date: string | Date,
    dateFormat?: 'short' | 'full' | 'long' | 'medium' | undefined,
    timeFormat?: 'short' | 'full' | 'long' | 'medium' | undefined
  ) => string;
  language: string;
  timezone: string;
}

export function useFormatDate(): FormatDateProps {
  const { user } = useAppSelector((state) => state.auth);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const language = user.language || portalSettings?.defaultLanguage;
  const timezone = user.timezone || portalSettings?.defaultTimeZone;

  function handleFormatDateByUserLogged(
    date: string,
    dateFormat?: 'short' | 'full' | 'long' | 'medium' | undefined,
    timeFormat?: 'short' | 'full' | 'long' | 'medium' | undefined
  ) {
    const formatedDate = formatDate(
      language,
      timezone,
      date,
      dateFormat,
      timeFormat
    );
    return formatedDate;
  }

  return { handleFormatDateByUserLogged, language, timezone };
}
