import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { ListQueryParameters } from '../../types/generic_list';
import {
  RemoteAppListType,
  RemoteAppDetailsType,
  RemoteAppType,
} from '../../types/remoteApps';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  CREATE: 'remoteApp/CREATE',
  LIST: 'remoteApp/LIST',
  GET: 'remoteApp/GET',
  LIST_DETAILS: 'remoteApp/LIST_DETAILS',
  UPDATE: 'remoteApp/UPDATE',
};

// Reducer

interface RemoteAppsState {
  remoteApps: RemoteAppListType[];
  remoteApp: RemoteAppType;
  remoteAppsDetail: RemoteAppDetailsType[];
}

const initialState: RemoteAppsState = {
  remoteApp: {},
  remoteApps: [],
  remoteAppsDetail: [],
};

export const remoteAppsSlice = createSlice({
  name: 'remoteApps',
  initialState,
  reducers: {
    remoteAppSelected: (
      state,
      action: PayloadAction<Partial<RemoteAppListType>>
    ) => {
      state.remoteApp = { ...state.remoteApp, ...action.payload };
    },
    remoteAppSelectedClear: (state) => {
      state.remoteApp = initialState.remoteApp;
    },
    remoteAppListSuccess: (
      state,
      action: PayloadAction<RemoteAppListType[]>
    ) => {
      state.remoteApps = action.payload;
    },
    remoteAppListFilterSuccess: (
      state,
      action: PayloadAction<RemoteAppListType[]>
    ) => {
      state.remoteApps = action.payload;
    },
    remoteAppCreateSuccess: (state, action: PayloadAction<RemoteAppType>) => {
      state.remoteApp = action.payload;
    },
    remoteAppGetSuccess: (state, action: PayloadAction<RemoteAppType>) => {
      state.remoteApp = action.payload;
    },
    remoteAppDetailListSuccess: (
      state,
      action: PayloadAction<RemoteAppDetailsType[]>
    ) => {
      state.remoteAppsDetail = action.payload;
    },
  },
});
export default remoteAppsSlice.reducer;

// Action Creators

export const {
  remoteAppSelected,
  remoteAppSelectedClear,
  remoteAppListSuccess,
  remoteAppListFilterSuccess,
  remoteAppCreateSuccess,
  remoteAppGetSuccess,
  remoteAppDetailListSuccess,
} = remoteAppsSlice.actions;

export function listRemoteApps(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function getRemoteApp(id: number) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function createRemoteApp(data: RemoteAppType) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}

export function listRemoteAppDetails(
  id: ID,
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_DETAILS,
    payload: { queryParameters, filters: sanitizeFilter(filters), id },
  };
}
