import React, { useState } from 'react';

import Eye from '../Icons/Eye';
import EyeDisabled from '../Icons/EyeDisabled';
import Input from '../Input';

interface InputPasswordProps {
  inputProps: Record<string, unknown>;
}

const InputPassword = ({ inputProps }: InputPasswordProps) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Input
      inputProps={{
        type: toggle ? 'text' : 'password',
        autoComplete: 'off',
        ...inputProps,
      }}
      rightElement={
        <i onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <Eye boxSize={6} cursor="pointer" />
          ) : (
            <EyeDisabled boxSize={6} cursor="pointer" />
          )}
        </i>
      }
    />
  );
};

export default InputPassword;
