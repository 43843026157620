import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { ManufacturerType } from '../../types/device';
import {
  EnterpriseAppsType,
  EnterpriseDeviceType,
  SelectedAppData,
} from '../../types/enterpriseApps';
import { ListQueryParameters } from '../../types/generic_list';

// Action Types

export const Types = {
  LIST: 'enterprise_apps/LIST',
  DELETE: 'enterprise_apps/DELETE',
  SELECT_APP: 'enterprise_apps/SELECT_APP',
  LIST_MODAL: 'enterprise_apps/LIST_MODAL',
  LIST_ENTERPRISE_SYSTEM_APPS: 'enterprise_apps/LIST_ENTERPRISE_SYSTEM_APPS',
  LIST_ENTERPRISE_DEVICES: 'enterprise_apps/LIST_ENTERPRISE_DEVICES',
  LIST_ENTERPRISE_MANUFACTURERS:
    'enterprise_apps/LIST_ENTERPRISE_MANUFACTURERS',
};

// Reducer

interface EnterPriseAppsState {
  enterpriseApps: EnterpriseAppsType[];
  enterpriseApp: EnterpriseAppsType;
  enterpriseAppsModal: EnterpriseAppsType[];
  enterpriseSystemApps: EnterpriseAppsType[];
  enterpriseDevices: EnterpriseDeviceType[];
  enterpriseManufacturers: ManufacturerType[];
}

const initialState: EnterPriseAppsState = {
  enterpriseApps: [],
  enterpriseApp: {},
  enterpriseAppsModal: [],
  enterpriseSystemApps: [],
  enterpriseDevices: [],
  enterpriseManufacturers: [],
};

export const enterpriseAppsSlice = createSlice({
  name: 'enterpriseApps',
  initialState,
  reducers: {
    enterpriseAppsListClear: (state) => {
      state.enterpriseApps = initialState.enterpriseApps;
    },
    enterpriseAppsListSuccess: (
      state,
      action: PayloadAction<EnterpriseAppsType[]>
    ) => {
      state.enterpriseApps = action.payload;
    },
    enterpriseAppsSelectSuccess: (
      state,
      action: PayloadAction<EnterpriseAppsType>
    ) => {
      state.enterpriseApp = action.payload;
    },
    enterpriseAppsListModalSuccess: (
      state,
      action: PayloadAction<EnterpriseAppsType[]>
    ) => {
      state.enterpriseAppsModal = action.payload;
    },
    enterpriseAppsSystemAppsListSuccess: (
      state,
      action: PayloadAction<EnterpriseAppsType[]>
    ) => {
      state.enterpriseSystemApps = action.payload;
    },
    enterpriseDevicesListSuccess: (
      state,
      action: PayloadAction<EnterpriseDeviceType[]>
    ) => {
      state.enterpriseDevices = action.payload;
    },
    enterpriseManufacturersListSuccess: (
      state,
      action: PayloadAction<ManufacturerType[]>
    ) => {
      state.enterpriseManufacturers = action.payload;
    },
  },
});
export default enterpriseAppsSlice.reducer;

// Action Creators

export const {
  enterpriseAppsListClear,
  enterpriseAppsListSuccess,
  enterpriseAppsSelectSuccess,
  enterpriseAppsListModalSuccess,
  enterpriseAppsSystemAppsListSuccess,
  enterpriseDevicesListSuccess,
  enterpriseManufacturersListSuccess,
} = enterpriseAppsSlice.actions;

export function listEnterpriseApps(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}
export function deleteEnterpriseApps(id: EnterpriseAppsType['id']) {
  return {
    type: Types.DELETE,
    payload: id,
  };
}

export function selectEnterpriseApp(data: SelectedAppData) {
  return {
    type: Types.SELECT_APP,
    payload: data,
  };
}

export function listEnterpriseAppsToModal(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_MODAL,
    payload: { filters: sanitizeFilter(filters) },
  };
}

export function listEnterpriseSystemApps(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_ENTERPRISE_SYSTEM_APPS,
    payload: { filters: sanitizeFilter(filters) },
  };
}

export function listEnterpriseDevices(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_ENTERPRISE_DEVICES,
    payload: { filters: sanitizeFilter(filters) },
  };
}

export function listEnterpriseManufacturers(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_ENTERPRISE_MANUFACTURERS,
    payload: { filters: sanitizeFilter(filters) },
  };
}
