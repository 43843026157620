import { Box, Flex, FlexProps } from '@chakra-ui/layout';
import { StyleProps } from '@chakra-ui/styled-system';
import {
  ChangeEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../hooks/useRedux';
import { setDisableSave } from '../../../../../../../../../store/policy';
import {
  ManageConfigurationInputType,
  ManageConfigurationInputTypeEnum,
} from '../../../../../../../../../types/policy';
import Input from '../../../../../../../../Input';
import { InputErrorEnum, inputErrorToIntl } from './utils';

export type CustomOnChangeValue = { name: string; value: (string | number)[] };

export type OnChangeParam = ChangeEvent<HTMLInputElement> | CustomOnChangeValue;

export interface CommonProps {
  name: string;
  value: unknown;
  isInteger: boolean;
  isHidden: boolean;
  onChange: (
    event: OnChangeParam,
    fieldType?: ManageConfigurationInputType
  ) => void;
  type?: ManageConfigurationInputType | HTMLInputTypeAttribute;
  styles?: StyleProps;
  containerStyles?: FlexProps;
  isDisabled?: boolean;
}

const intLimits = {
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
};

const InputForDynamic = forwardRef<HTMLInputElement, CommonProps>(
  (
    {
      isHidden,
      isInteger,
      name,
      onChange,
      value,
      type,
      styles,
      containerStyles,
      isDisabled,
    },
    ref
  ) => {
    const selectedApp = useAppSelector((state) => state.policy.selectedApp);
    const dispatch = useAppDispatch();
    const [inputError, setInputError] = useState(
      isInteger ? validateIntValue(value as string) : false
    );

    const inputTypeStrategy = {
      [ManageConfigurationInputTypeEnum.HIDDEN]: 'text',
      color: 'color',
      default: 'text',
    };

    const inputType = isInteger
      ? 'text'
      : inputTypeStrategy[type] || inputTypeStrategy.default;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (isInteger) {
        const newValue = event.target.value.replace(/[^0-9]/g, '');

        event.target.value = newValue;
      }

      onChange(event);

      if (isInteger) {
        const newInvalid = validateIntValue(event.target.value);

        if (newInvalid != inputError) {
          setInputError(newInvalid);
        }
      }
    };

    function validateIntValue(value: string): InputErrorEnum | false {
      const numberValue = Number(value);

      if (numberValue > intLimits.max || numberValue < intLimits.min) {
        return InputErrorEnum.INTEGER_LIMIT;
      }

      return false;
    }

    useEffect(() => {
      dispatch(
        setDisableSave({
          prop: `${selectedApp?.companyApplication?.name}-${name}`,
          value: !!inputError,
        })
      );
    }, [inputError]);

    return (
      <Flex direction="column" gridGap={4} {...containerStyles}>
        <Input
          ref={ref}
          inputProps={{
            name,
            value,
            onChange: handleChange,
            type: inputType,
            readOnly: isHidden,
            isDisabled: isDisabled || isHidden,
            ...styles,
          }}
        />

        {!!inputError && (
          <Box
            color="warning.500"
            fontSize="sm"
            fontWeight="bold"
            h="21px"
            ml="1"
          >
            <FormattedMessage
              id={inputErrorToIntl[inputError]}
              values={{ min: intLimits.min, max: intLimits.max }}
            />
          </Box>
        )}
      </Flex>
    );
  }
);

export default InputForDynamic;
