import { call, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { ExternalAppData, ExternalAppType } from '../../types/externalApps';
import { QuerysWithFilters } from '../../types/generic_list';
import {
  externalAppListSuccess,
  externalAppGetSuccess,
  Types,
} from '../externalApps';
import { api, requestList, requestSimple, safe } from './util';

function* handleCreate({ payload, type }: PayloadAction<ExternalAppType>) {
  yield call(requestSimple, api, 'externalAppCreate', type, undefined, payload);
}

function* handleList({
  type,
  payload: { filters, queryParameters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'externalAppList',
    type,
    externalAppListSuccess,
    {
      filters,
      queryParameters,
    }
  );
}

function* handleGet({ payload, type }: PayloadAction<number>) {
  yield call(
    requestSimple,
    api,
    'externalAppGet',
    type,
    externalAppGetSuccess,
    payload
  );
}

function* handleUpdate({ payload, type }: PayloadAction<ExternalAppData>) {
  yield call(
    requestSimple,
    api,
    'externalAppUpdate',
    type,
    undefined,
    payload.id,
    payload
  );
}

export default function* externalAppSaga() {
  yield takeLatest(Types.CREATE, safe(handleCreate));
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.UPDATE, safe(handleUpdate));
}
