import { SimpleGrid } from '@chakra-ui/layout';
import { Box, Tooltip } from '@chakra-ui/react';
import _ from 'lodash';

import { ModeProps } from '../../../../pages/Policies/RegisterPolicy';
import { ManagementModeEnum } from '../../../../types/policy';
import PolicyMode, { PolicyModeProps } from './PolicyMode';

interface Props {
  managementModes: ModeProps[];
  onSelectMode: PolicyModeProps['onSelectMode'];
  selectedPolicyMode: ManagementModeEnum;
}

export default function PolicyOperationalSystem({
  managementModes,
  onSelectMode,
  selectedPolicyMode,
}: Props) {
  return (
    <SimpleGrid gridGap={4} columns={{ lg: 3, sm: 1 }}>
      {managementModes.map(
        (mode) =>
          mode.show && (
            <Tooltip
              label={mode.toolTipLabel}
              placement="top"
              isDisabled={_.isEmpty(mode.toolTipLabel) || !mode.isDisabled}
              hasArrow
            >
              <Box>
                <PolicyMode
                  key={mode.value}
                  mode={mode}
                  onSelectMode={onSelectMode}
                  isChecked={selectedPolicyMode === mode.value}
                  isDisabled={mode.isDisabled || false}
                />
              </Box>
            </Tooltip>
          )
      )}
    </SimpleGrid>
  );
}
