import React from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
interface HighlightTextProps {
  searchWord?: string;
  textToHighlight?: string;
}

const HighlightText = ({ textToHighlight, searchWord }: HighlightTextProps) => {
  const intl = useIntl();

  return (
    <Highlighter
      searchWords={[searchWord]}
      autoEscape={true}
      textToHighlight={intl.formatMessage({ id: textToHighlight })}
    />
  );
};

export default HighlightText;
