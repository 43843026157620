export enum ResellerExceptionEnum {
  INVALID_FEDERAL_CODE = 'INVALID_FEDERAL_CODE',
  FAIL_CREATE_COMPANY = 'FAIL_CREATE_COMPANY',
  FAIL_CREATE_INVOICE = 'FAIL_CREATE_INVOICE',
  FAIL_CREATE_INVOICE_DUPLICATE = 'FAIL_CREATE_INVOICE_DUPLICATE',
  FAIL_CREATE_INVOICE_NOT_FOUND = 'FAIL_CREATE_INVOICE_NOT_FOUND',
  FAIL_CREATE_INVOICE_CNPJ_INVALID = 'FAIL_CREATE_INVOICE_CNPJ_INVALID',
  FAIL_CREATE_INVOICE_CNPJ_RESELLER = 'FAIL_CREATE_INVOICE_CNPJ_RESELLER',
  FAIL_CREATE_INVOICE_CNPJ = 'FAIL_CREATE_INVOICE_CNPJ',
  FAIL_CREATE_INVOICE_CNPJ_UNAUTHORIZED = 'FAIL_CREATE_INVOICE_CNPJ_UNAUTHORIZED',
  FAIL_CREATE_ACCOUNT = 'FAIL_CREATE_ACCOUNT',
  FAIL_UPDATE_ITEM = 'FAIL_UPDATE_ITEM',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_CUSTUMER_ID = 'INVALID_CUSTUMER_ID',
  REQUESTED_EMAIL = 'REQUESTED_EMAIL',
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
  INVALID_TOKEN = 'INVALID_TOKEN',
  FORBIDDEN_RESOURCE = 'Forbidden resource',
  FAIL_CREATE_INVOICE_WITHOUT_DEVICE = 'FAIL_CREATE_INVOICE_WITHOUT_DEVICE',
  FAIL_APPROVE_DEVICE = 'FAIL_APPROVE_DEVICE',
  FAIL_DEVICE_INVALID_IMEI = 'FAIL_DEVICE_INVALID_IMEI',
  FAIL_DEVICE_INVALID_IMEI_MODEL = 'FAIL_DEVICE_INVALID_IMEI_MODEL',
  FAIL_DEVICE_INVALID_MODEL = 'FAIL_DEVICE_INVALID_MODEL',
  FAIL_DEVICE_DUPLICATE_IMEI = 'FAIL_DEVICE_DUPLICATE_IMEI',
}

export const apiErrorMessagesByResponse = {
  [ResellerExceptionEnum.INVALID_FEDERAL_CODE]:
    'manage_invoice.error_message.invalid_federal_code',
  [ResellerExceptionEnum.FAIL_CREATE_COMPANY]:
    'manage_invoice.error_message.fail_create_company',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE]:
    'manage_invoice.error_message.fail_create_invoice',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE_DUPLICATE]:
    'manage_invoice.error_message.fail_create_invoice_duplicate',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE_CNPJ]:
    'manage_invoice.error_message.fail_create_invoice_cnpj',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE_CNPJ_INVALID]:
    'manage_invoice.error_message.fail_create_invoice_cnpj',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE_CNPJ_RESELLER]:
    'manage_invoice.error_message.fail_invalid_cnpj_reseler',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE_CNPJ_UNAUTHORIZED]:
    'manage_invoice.error_message.fail_create_invoice_cnpj_unauthorized',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE_NOT_FOUND]:
    'manage_invoice.error_message.fail_create_invoice_not_found',
  [ResellerExceptionEnum.FORBIDDEN_RESOURCE]:
    'manage_invoice.error_message.unauthorized',
  [ResellerExceptionEnum.FAIL_UPDATE_ITEM]:
    'invoice.error_message.fail_update_item',
  [ResellerExceptionEnum.FAIL_CREATE_ACCOUNT]:
    'customer_id.error_message.fail_create_account',
  [ResellerExceptionEnum.INVALID_EMAIL]:
    'customer_id.error_message.invalid_email',
  [ResellerExceptionEnum.INVALID_CUSTUMER_ID]:
    'customer_id.error_message.invalid_customer_id',
  [ResellerExceptionEnum.REQUESTED_EMAIL]:
    'customer_id.error_message.requested_email',
  [ResellerExceptionEnum.COMPANY_NOT_FOUND]:
    'customer_id.error_message.company_not_found',
  [ResellerExceptionEnum.INVALID_TOKEN]:
    'customer_id.error_message.invalid_token',
  [ResellerExceptionEnum.FAIL_CREATE_INVOICE_WITHOUT_DEVICE]:
    'customer_id.error_message.fail_create_invoice_without_device',
  [ResellerExceptionEnum.FAIL_APPROVE_DEVICE]:
    'zero_touch_register_details.error_message.fail_approve_device',
  [ResellerExceptionEnum.FAIL_DEVICE_INVALID_IMEI]:
    'zero_touch_register_details.error_message.fail_device_invalid_imei',
  [ResellerExceptionEnum.FAIL_DEVICE_INVALID_IMEI_MODEL]:
    'zero_touch_register_details.error_message.fail_device_invalid_imei_model',
  [ResellerExceptionEnum.FAIL_DEVICE_INVALID_MODEL]:
    'zero_touch_register_details.error_message.fail_device_invalid_model',
  [ResellerExceptionEnum.FAIL_DEVICE_DUPLICATE_IMEI]:
    'zero_touch_register_details.error_message.fail_device_duplicate_imei',
  default: 'manage_invoice.error_message.default_error',
};
