import { Box, Flex } from '@chakra-ui/react';
import _ from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { EnterpriseOriginEnum } from '../../../../../../types/enterpriseApps';
import {
  PolicyApplicationType,
  PolicyCompanyApplicationType,
} from '../../../../../../types/policy';
import FormContainer from '../../../../../FormContainer';
import FormControl from '../../../../../FormControl';
import Heading from '../../../../../Heading';
import Input from '../../../../../Input';
import Modal from '../../../../../Modal';

interface ModalAddManualSystemAppsProps {
  isOpen?: boolean;
  onClose?: () => void;
  handleAddManualApplicationToList?: (
    manualApplication: PolicyCompanyApplicationType[]
  ) => void;
  applications?: PolicyApplicationType[];
}

interface FormType {
  name?: string;
  packageName?: string;
}

const ModalAddManualSystemApps = ({
  handleAddManualApplicationToList,
  applications,
  isOpen,
  onClose,
}: ModalAddManualSystemAppsProps) => {
  const intl = useIntl();
  const [form, setForm] = useState<FormType>({
    packageName: '',
    name: '',
  });

  const hasPackageNameInApplicationList = applications?.find(
    (application) =>
      application?.companyApplication?.packageName === form?.packageName?.trim()
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    setForm({ ...form, [name]: value });
  };

  const handlePrimary = () => {
    handleAddManualApplicationToList([
      {
        origin: EnterpriseOriginEnum.MANUAL,
        name: form?.name,
        packageName: form?.packageName,
        id: Number(_.uniqueId()),
        iconBase64: null,
      },
    ]);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      preserveScrollBarGap
      modalWidth="600px"
    >
      <Flex direction="column" width="full" p="6">
        <Heading fontSize="32px">
          <FormattedMessage id="update_policies.modal_add_manual_system_apps.title" />
        </Heading>
        <FormContainer
          labelPrimary={
            <FormattedMessage id="update_policies.modal_add_manual_system_apps.add_button" />
          }
          handlePrimary={handlePrimary}
          labelSecondary={<FormattedMessage id="global.cancel" />}
          handleSecondary={onClose}
          disabledPrimary={
            !!hasPackageNameInApplicationList || !form?.packageName
          }
          divider={false}
        >
          <FormControl
            textLabel={
              <FormattedMessage
                id={'update_policies.modal_add_manual_system_apps.input_name'}
              />
            }
            mb="6"
          >
            <Input
              inputProps={{
                name: 'name',
                value: form?.name,
                onChange: handleInputChange,
                placeholder: intl.formatMessage({
                  id: 'update_policies.modal_add_manual_system_apps.input_name.placeholder',
                }),
              }}
            />
          </FormControl>
          <FormControl
            textLabel={
              <FormattedMessage
                id={
                  'update_policies.modal_add_manual_system_apps.input_package_name'
                }
              />
            }
            mb="6"
          >
            <Flex flexDirection="column" w="full">
              <Input
                inputProps={{
                  name: 'packageName',
                  value: form?.packageName,
                  onChange: handleInputChange,
                  placeholder: intl.formatMessage({
                    id: 'update_policies.modal_add_manual_system_apps.input_package_name.placeholder',
                  }),
                }}
              />
              <Box
                color="warning.500"
                fontSize="sm"
                fontWeight="bold"
                h="21px"
                m="0"
              >
                {hasPackageNameInApplicationList && (
                  <FormattedMessage id="update_policies.modal_add_manual_system_apps.input_package_name.error" />
                )}
              </Box>
            </Flex>
          </FormControl>
        </FormContainer>
      </Flex>
    </Modal>
  );
};

export default ModalAddManualSystemApps;
