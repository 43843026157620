import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, select, put } from 'redux-saga/effects';

import { EnterpriseAppsType } from '../../types/enterpriseApps';
import { QuerysWithFilters } from '../../types/generic_list';
import {
  enterpriseAppsListSuccess,
  listEnterpriseApps,
  Types,
  enterpriseAppsSelectSuccess,
  enterpriseAppsListModalSuccess,
  enterpriseAppsSystemAppsListSuccess,
  enterpriseDevicesListSuccess,
  enterpriseManufacturersListSuccess,
} from '../enterpriseApps';
import { selectorMetadata, uiAddSuccess } from '../ui';
import { api, requestList, requestSimple, safe } from './util';

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'enterpriseApps',
    type,
    enterpriseAppsListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}
function* handleDelete({
  payload: id,
  type,
}: PayloadAction<EnterpriseAppsType>) {
  yield call(requestSimple, api, 'enterpriseAppDelete', type, uiAddSuccess, id);
  const metadata = yield select(selectorMetadata(Types.LIST));
  yield put(listEnterpriseApps(metadata));
}

function* handleSelectApp({
  payload,
  type,
}: PayloadAction<EnterpriseAppsType>) {
  yield call(
    requestSimple,
    api,
    'enterpriseAppSelect',
    type,
    enterpriseAppsSelectSuccess,
    payload
  );
}

function* handleListModal({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'enterpriseAppsListModal',
    type,
    enterpriseAppsListModalSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleListEnterpriseSystemApps({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'enterpriseSystemAppsList',
    type,
    enterpriseAppsSystemAppsListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleListEnterpriseDevices({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'enterpriseDevicesList',
    type,
    enterpriseDevicesListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleListEnterpriseManufacturers({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'deviceListManufacturer',
    type,
    enterpriseManufacturersListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

export default function* enterpriseAppsSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.DELETE, safe(handleDelete));
  yield takeLatest(Types.SELECT_APP, safe(handleSelectApp));
  yield takeLatest(Types.LIST_MODAL, safe(handleListModal));
  yield takeLatest(
    Types.LIST_ENTERPRISE_SYSTEM_APPS,
    safe(handleListEnterpriseSystemApps)
  );
  yield takeLatest(
    Types.LIST_ENTERPRISE_DEVICES,
    safe(handleListEnterpriseDevices)
  );
  yield takeLatest(
    Types.LIST_ENTERPRISE_MANUFACTURERS,
    safe(handleListEnterpriseManufacturers)
  );
}
