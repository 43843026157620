import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { QuerysWithFilters } from '../../types/generic_list';
import { Types, auditUsersFilterSuccess, auditListSuccess } from '../audit';
import { api, requestList, requestSimple, safe } from './util';

function* handleListAudit({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'auditsList', type, auditListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleListUsersFilter({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'auditGetUsersToFilter',
    type,
    auditUsersFilterSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

export default function* auditSaga() {
  yield takeLatest(Types.GET_AUDIT_LIST, safe(handleListAudit));
  yield takeLatest(Types.USERS_TO_FILTER, safe(handleListUsersFilter));
}
