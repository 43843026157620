import { AxiosRequestConfig } from 'axios';

import { RootState } from '..';

import queryString from 'query-string';

import { getToken } from '../auth';
import { axiosApi } from './axios';

export default function ApiUtil(state: RootState, apiUrl?: string) {
  return {
    request,
  };

  async function request(
    version: string,
    path: string,
    { method, headers, data, ...config }: AxiosRequestConfig
  ) {
    if (method === 'GET' && data) {
      path = [path, queryString.stringify(data)].join('?');
      data = undefined;
    }

    return axiosApi({
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ContentType: 'application/json',
        Authorization: `Bearer ${getToken(state)}`,
        ...headers,
      },
      ...config,
      baseURL: apiUrl || undefined,
      url: `${version}${path}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export const genericError = {
  status: 0,
  message: 'genericError',
  keyIntl: 'error.generic_error',
};
