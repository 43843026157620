import { Flex, TextProps, Tooltip } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { exportInClipboard } from '../../helper/exports';
import Copy from '../Icons/Copy';
import Text from '../Text';

interface TextCopyProps extends TextProps {
  text: string;
}

type TooltipState = 'hover' | 'copied';

export const TextCopy = ({ text, ...rest }: TextCopyProps) => {
  const [state, setState] = useState<TooltipState>('hover');
  const tooltipMessage = useMemo(
    () => ({
      hover: 'global.copy.click_to_copy',
      copied: 'global.copy.success',
    }),
    []
  );

  const handleCopy = () => {
    exportInClipboard(text);
    setState('copied');
  };

  return (
    <Flex justifyContent="start">
      <Tooltip
        hasArrow
        arrowSize={8}
        bg="success.500"
        padding="2"
        borderRadius="5"
        label={<FormattedMessage id={tooltipMessage[state]} />}
        closeOnClick={false}
        onClose={() => setState('hover')}
      >
        <Flex>
          <Text
            fontWeight="500"
            color="primary.500"
            margin="0"
            cursor="pointer"
            overflowWrap="anywhere"
            {...rest}
            onClick={handleCopy}
          >
            {text}
            <Copy boxSize={6} color="primary.500" marginLeft=".5rem" />
          </Text>
        </Flex>
      </Tooltip>
    </Flex>
  );
};
