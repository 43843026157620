import { Flex, Spinner } from '@chakra-ui/react';

interface ChartLoading {
  color: string;
  spinnerSize?: string;
  isSmall?: boolean;
}

const ChartLoading = ({ color, spinnerSize, isSmall }: ChartLoading) => {
  return (
    <Flex align="center" justify="center" h={isSmall ? '278px' : '400px'}>
      <Spinner color={color} size={spinnerSize || 'xl'} />
    </Flex>
  );
};

export default ChartLoading;
