import _ from 'lodash';
import { useMemo } from 'react';

import { planFeatureKeyByRoute } from '../helper/plan';
import { Plan } from '../types/loginAdminUser';
import { useAppSelector } from './useRedux';

interface UsePlanParams {
  featurePlanKey?: keyof Plan;
}

export const usePlan = ({ featurePlanKey }: UsePlanParams = {}) => {
  const { user } = useAppSelector((state) => state.auth);
  const hasFeaturePlanByParam: boolean = useMemo(
    () => featurePlanKey && handleCheckFeaturePlan(featurePlanKey),
    [featurePlanKey]
  );

  function handleCheckFeaturePlan(planKey: keyof Plan): boolean {
    const hasFeatureKey = user?.plan?.[planKey];

    if (_.isUndefined(hasFeatureKey)) {
      return true;
    }

    return !!hasFeatureKey;
  }

  function handleCheckFeaturePlanByRoute(route: string): boolean {
    const [domain] = route.split('?');
    const featureKey = planFeatureKeyByRoute[domain];

    if (featureKey) {
      let hasFeaturePlan = true;

      if (_.isArray(featureKey)) {
        hasFeaturePlan = featureKey.some((key) => handleCheckFeaturePlan(key));
      } else {
        hasFeaturePlan = handleCheckFeaturePlan(featureKey);
      }

      return hasFeaturePlan;
    }

    return true;
  }

  return {
    /** Variable that contains the entire plan of logged user. */
    plan: user?.plan,
    /** Variable is true if logged user has some plan. */
    hasPlan: !!user?.plan,
    /** Variable logged user plan name. */
    planName: user?.plan?.name,
    /** Variable if user have plan according by plan key param hook */
    hasFeaturePlan: hasFeaturePlanByParam,
    /** Function return if user have plan by route. If user not have plan, return true. */
    handleCheckFeaturePlanByRoute,
    /** Function return if user have plan in the param. If user not have plan, return true. */
    handleCheckFeaturePlan,
  };
};
