import { Flex } from '@chakra-ui/layout';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { featurePlanKeys } from '../../../../../../../../helper/plan';
import { usePlan } from '../../../../../../../../hooks/usePlan';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/useRedux';
import {
  getKioskAppConfigs,
  Types,
} from '../../../../../../../../store/policy';
import { hasLoading } from '../../../../../../../../store/ui';
import { PolicyType } from '../../../../../../../../types/policy';
import { AppConfigAccordionCard } from '../../../PolicyApps/TableActions/AppConfig/AppConfigAccordionCard';
import AppActionAccordionSkeleton from '../../../PolicyApps/TableActions/components/AppActionAccordionSkeleton';
import AppActionHeader from '../../../PolicyApps/TableActions/components/AppActionHeader';

interface Props {
  policy: PolicyType;
}

export default function KioskManageConfiguration({ policy }: Props) {
  const dispatch = useAppDispatch();
  const isLoading = hasLoading(Types.GET_KIOSK_APP_CONFIGS);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const intl = useIntl();
  const planAlert = useMemo(
    () =>
      intl.formatMessage({
        id: 'edit_policy.kiosk.manage_configuration.plan_alert',
      }),
    []
  );
  const { hasFeaturePlan } = usePlan({
    featurePlanKey: featurePlanKeys.allowUnlimitedKiosk,
  });

  useEffect(() => {
    if (!policy?.kioskApplication?.managedConfigurations) {
      dispatch(getKioskAppConfigs(policy.id));
    }
  }, []);

  return (
    <>
      {policy?.kioskApplication && (
        <AppActionHeader
          companyApplication={policy?.kioskApplication.companyApplication}
          title="edit_policy.kiosk.manage_configuration"
        />
      )}

      <Flex direction="column" gridGap={3}>
        {isLoading ? (
          <AppActionAccordionSkeleton />
        ) : (
          policy?.kioskApplication?.managedConfigurations?.map((config) => (
            <AppConfigAccordionCard
              selectedAppConfig={config}
              key={config.key}
              value={config.value as string}
              isKiosk
              isDisabled={!hasFeaturePlan}
              planAlert={!hasFeaturePlan && planAlert}
              inputContainerWidth={
                config.key === portalSettings.appKioskWallpaperKey && 'full'
              }
            />
          ))
        )}
      </Flex>
    </>
  );
}
