import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex } from '@chakra-ui/layout';
import _ from 'lodash';
import React, { ChangeEvent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DynamicInputApplications } from '.';
import { useAppDispatch } from '../../../../../../../../../hooks/useRedux';
import {
  addAppBundleProp,
  removeBundleProp,
} from '../../../../../../../../../store/policy';
import {
  ManageConfigurationInputType,
  ManageConfigurationInputTypeEnum,
  ManagedConfigurationsBundleValueType,
  ManagedConfigurationsType,
} from '../../../../../../../../../types/policy';
import Button from '../../../../../../../../Button';
import Text from '../../../../../../../../Text';

interface Props {
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  nestedProperties?: ManagedConfigurationsType[];
  inputContainerWidth?: string;
  value?: ManagedConfigurationsBundleValueType[];
  type: ManageConfigurationInputType;
  bundlePath?: string;
  firstLevel?: boolean;
}

export const BundleArrayForDynamic = ({
  name,
  onChange,
  nestedProperties,
  value,
  bundlePath,
  firstLevel,
}: Props) => {
  const dispatch = useAppDispatch();
  const properties = useMemo(() => nestedProperties[0], [nestedProperties]);

  const handleAddBundle = () => {
    dispatch(
      addAppBundleProp({
        bundlePath,
        prop: properties,
        firstLevel: value?.length > 0 ? false : firstLevel,
      })
    );
  };

  const handleRemoveBundle = (index: number) => () => {
    const path = `${bundlePath}.value[${index}]`;

    dispatch(removeBundleProp({ path, bundlePath, fieldIndex: index }));
  };

  return (
    <Flex direction="row">
      <Divider mx={3} orientation="vertical" height="auto" />

      <Box w="full">
        {(_.isArray(value) ? value : [null])?.map((prop, index) => {
          const isRadio =
            prop?.type === ManageConfigurationInputTypeEnum.BOOL ||
            prop?.type === ManageConfigurationInputTypeEnum.MULTISELECT;

          return (
            <React.Fragment key={`${prop?.key}-${index}`}>
              <Flex
                w="full"
                gridGap={4}
                alignItems="center"
                justify="space-between"
              >
                <Text fontSize="md" textAlign="left" m={0}>
                  {properties?.title}
                </Text>

                {prop?.type && (
                  <Button variant="outline" onClick={handleRemoveBundle(index)}>
                    <DeleteIcon mr={4} />

                    <FormattedMessage id="app_managed_configurations.remove_bundle_array_button" />
                  </Button>
                )}
              </Flex>

              <Flex
                flexDirection={isRadio ? 'row' : 'column'}
                justifyContent={isRadio ? 'space-between' : 'flex-start'}
                py="6"
              >
                <Box mb={!isRadio && '6'} color="gray.500">
                  {properties?.description}
                </Box>

                {prop?.type && (
                  <Box ml={isRadio && '6'}>
                    <DynamicInputApplications
                      type={prop.type}
                      handleChange={onChange}
                      name={
                        name.includes('value')
                          ? `${name}.value[${index}]`
                          : `value[${index}]`
                      }
                      entries={properties?.entries}
                      nestedProperties={properties.nestedProperties}
                      value={prop?.value}
                      bundlePath={`${bundlePath}.value[${index}]`}
                    />
                  </Box>
                )}
              </Flex>
            </React.Fragment>
          );
        })}

        <Button variant="outline" onClick={handleAddBundle}>
          <AddIcon mr={4} />

          <FormattedMessage id="app_managed_configurations.add_bundle_array_button" />
        </Button>
      </Box>
    </Flex>
  );
};
