import React from 'react';

import Alert, { AlertHtml } from '../../../../../Alert';
import { intlPolicyAppsKey } from './index';

const alertAppBlockSIM = (
  intl,
  hasAppBlockSIM,
  onConfirm?: () => void,
  appName?: string
) => {
  Alert({
    onConfirm: () => onConfirm(),
    html: (
      <AlertHtml
        text={intl.formatMessage(
          {
            id: hasAppBlockSIM
              ? `${intlPolicyAppsKey}.alert.text.remove`
              : `${intlPolicyAppsKey}.alert.text.add`,
          },
          {
            appName: appName,
          }
        )}
      />
    ),
    confirmButtonText: intl.formatMessage({
      id: hasAppBlockSIM ? 'global.ok' : 'global.add',
    }),
    allowOutsideClick: true,
    allowEscapeKey: true,
    disabledCancel: hasAppBlockSIM,
    cancelButtonText: intl.formatMessage({ id: 'global.cancel' }),
  });
};

export default alertAppBlockSIM;
