import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { PrivilegeEnum } from '../../helper';
import { AdminUserType } from '../../types/adminUser';
import { QuerysWithFilters } from '../../types/generic_list';
import {
  Types,
  adminUserListSuccess,
  adminUserGetSuccess,
  adminUserCreateSuccess,
  adminUserEditSuccess,
  listAdminUsers,
} from '../adminUser';
import { authLogout, authUpdateUserLanguage } from '../auth';
import { RootState } from '../index';
import { selectorMetadata, uiAddSuccess } from '../ui';
import { api, requestList, requestSimple, safe } from './util';

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'adminUserList', type, adminUserListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleGet({ payload, type }: PayloadAction<AdminUserType['id']>) {
  yield call(
    requestSimple,
    api,
    'adminUserGet',
    type,
    adminUserGetSuccess,
    payload
  );
}

function* handleCreation({ payload, type }: PayloadAction<AdminUserType>) {
  yield call(
    requestSimple,
    api,
    'adminUserCreation',
    type,
    adminUserCreateSuccess,
    payload
  );
}

function* handleEdit({ payload, type }: PayloadAction<AdminUserType>) {
  const response = yield call(
    requestSimple,
    api,
    'adminUserEdit',
    type,
    adminUserEditSuccess,
    payload.id,
    payload
  );

  const { user } = yield select((state: RootState) => state.auth);
  if (payload.id === user.id) {
    if (
      response?.language !== user?.language ||
      response?.timezone !== user?.timezone
    ) {
      yield put(
        authUpdateUserLanguage({
          language: payload.language,
          timezone: payload.timezone,
        })
      );
      yield put(uiAddSuccess(type));
    }
    if (response?.privilege !== user?.privilege) {
      yield put(authLogout());
      yield call(api, 'logout');
    }
  }
}

function* handleChangePassword({
  payload,
  type,
}: PayloadAction<{ password: string; token: string }>) {
  yield call(
    requestSimple,
    api,
    'adminUserChangePassword',
    type,
    undefined,
    payload
  );
}

function* handleDelete({
  payload,
  type,
}: PayloadAction<{ id: AdminUserType['id']; privilege: PrivilegeEnum }>) {
  yield call(
    requestSimple,
    api,
    'adminUserDelete',
    type,
    uiAddSuccess,
    payload.id
  );
  const metadata = yield select(selectorMetadata(Types.LIST));
  yield put(listAdminUsers(metadata, { privilege: payload.privilege }));
}

export default function* adminUserSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.CREATE, safe(handleCreation));
  yield takeLatest(Types.EDIT, safe(handleEdit));
  yield takeLatest(Types.EDIT_WITH_EMAIL, safe(handleEdit));
  yield takeLatest(Types.CHANGE_PASSWORD, safe(handleChangePassword));
  yield takeLatest(Types.DELETE, safe(handleDelete));
}
