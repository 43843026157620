import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  BatchDevicesActionsForm,
  DeviceType,
  ResetPasswordFlagEnum,
} from '../../types/device';
import { QuerysWithFilters } from '../../types/generic_list';
import { ID } from '../../types/util';
import {
  Types,
  deviceCreateSuccess,
  deviceListSuccess,
  deviceEditSuccess,
  deviceGetSuccess,
  deviceManufacturersListSuccess,
  deviceInventoryListSuccess,
  deviceModelsListSuccess,
  deviceActionListPasswordsSuccess,
  deviceListWithoutCommunicationSuccess,
  listDevices,
  deviceVerifyCommandInProcess,
  deviceGetKioskAccessTokenSuccess,
} from '../device';
import { listNotifications } from '../notification';
import { selectorMetadata, uiAddLoading } from '../ui';
import { api, safe, requestList, requestSimple } from './util';

function* handleList({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'deviceList', type, deviceListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleListManufacturer({
  type,
  payload: { filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'deviceListManufacturer',
    type,
    deviceManufacturersListSuccess,
    {
      filters,
    }
  );
}

function* handleListInventory({ type }) {
  yield call(
    requestSimple,
    api,
    'deviceInventoryList',
    type,
    deviceInventoryListSuccess
  );
}

function* handleListModel({
  type,
  payload: { filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'deviceListModel',
    type,
    deviceModelsListSuccess,
    {
      filters,
    }
  );
}

function* handleGet({ type, payload }: PayloadAction<DeviceType['id']>) {
  yield call(requestSimple, api, 'deviceGet', type, deviceGetSuccess, payload);
}

function* handleCreation({ type, payload }: PayloadAction<DeviceType>) {
  yield call(
    requestSimple,
    api,
    'deviceCreation',
    type,
    deviceCreateSuccess,
    payload
  );
}

function* handleEdit({ type, payload }: PayloadAction<DeviceType>) {
  yield call(
    requestSimple,
    api,
    'deviceEdit',
    type,
    deviceEditSuccess,
    payload.id,
    payload
  );
}

function* handleEditPolicy({
  type,
  payload,
}: PayloadAction<{ deviceId: ID; policy: { policyId: ID } }>) {
  yield call(
    requestSimple,
    api,
    'deviceEditPolicy',
    type,
    deviceEditSuccess,
    payload.deviceId,
    payload.policy
  );

  const metadata = yield select(selectorMetadata(Types.LIST));
  yield put(listDevices(metadata));
}

function* handleChangeDevicePassword({
  type,
  payload,
}: PayloadAction<{
  deviceId: ID;
  newPassword?: string;
  resetPasswordFlags?: ResetPasswordFlagEnum[];
}>) {
  yield call(
    requestSimple,
    api,
    'deviceChangePassword',
    type,
    undefined,
    payload.deviceId,
    payload
  );
}

function* handleRemove({ type, payload: id }: PayloadAction<ID>) {
  yield call(requestSimple, api, 'deviceActionRemove', type, undefined, id);
  const metadata = yield select(selectorMetadata(type));
  yield put(listDevices(metadata));
}

function* handleDisable({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'deviceActionDisable',
    type,
    undefined,
    payload
  );
  const metadata = yield select(selectorMetadata(Types.LIST));
  yield put(listDevices(metadata));
}

function* handleEnable({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'deviceActionEnable',
    type,
    undefined,
    payload
  );
  const metadata = yield select(selectorMetadata(Types.LIST));
  yield put(listDevices(metadata));
}

function* handleNewPassword({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'deviceActionNewPassword',
    type,
    undefined,
    payload
  );
  const metadata = yield select(selectorMetadata(type));
  yield put(listDevices(metadata));
}

function* handleRemoveScreenBlock({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'deviceActionRemoveScreenBlock',
    type,
    undefined,
    payload
  );
  const metadata = yield select(selectorMetadata(type));
  yield put(listDevices(metadata));
}

function* handleListPasswords({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'deviceActionListPasswords',
    type,
    deviceActionListPasswordsSuccess,
    payload
  );
}

function* handleListDevicesWithoutCommunication({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'deviceWithoutCommunicationList',
    type,
    deviceListWithoutCommunicationSuccess,
    {
      queryParameters,
      filters,
    }
  );
  const metadata = yield select(selectorMetadata(type));
  yield put(listDevices(metadata));
}

function* handleSyncDeviceEnterprise({ type }: PayloadAction) {
  yield put(uiAddLoading(Types.LIST));
  yield call(requestSimple, api, 'deviceSyncPolicy', type, undefined);
  const metadata = yield select(selectorMetadata(type));
  yield put(listDevices(metadata));
}

function* handleLockScreen({ type, payload }: PayloadAction<ID>) {
  yield call(requestSimple, api, 'deviceLockScreen', type, undefined, payload);
}

function* handleRebootDevice({ type, payload }: PayloadAction<ID>) {
  yield call(requestSimple, api, 'deviceReboot', type, undefined, payload);
}

function* handleVerifyBatchDevicesActions({ type }) {
  yield call(
    requestSimple,
    api,
    'deviceVerifyBatchActions',
    type,
    deviceVerifyCommandInProcess
  );
}

function* handleGetKioskAccessToken({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'deviceGetKioskAccessToken',
    type,
    deviceGetKioskAccessTokenSuccess,
    payload
  );
}

function* handleBatchDevicesActions({
  type,
  payload,
}: PayloadAction<BatchDevicesActionsForm>) {
  yield call(
    requestSimple,
    api,
    'deviceBatchActions',
    type,
    undefined,
    payload
  );

  yield put(listNotifications());
}

export default function* deviceSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.LIST_MANUFACTURER, safe(handleListManufacturer));
  yield takeLatest(Types.LIST_INVENTORY, safe(handleListInventory));
  yield takeLatest(Types.LIST_MODEL, safe(handleListModel));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.CREATE, safe(handleCreation));
  yield takeLatest(Types.EDIT, safe(handleEdit));
  yield takeLatest(Types.EDIT_POLICY, safe(handleEditPolicy));
  yield takeLatest(Types.ACTION_REMOVE, safe(handleRemove));
  yield takeLatest(Types.ACTION_DISABLE, safe(handleDisable));
  yield takeLatest(Types.ACTION_ENABLE, safe(handleEnable));
  yield takeLatest(Types.ACTION_NEWPASSWORD, safe(handleNewPassword));
  yield takeLatest(Types.ACTION_LISTPASSWORDS, safe(handleListPasswords));
  yield takeLatest(
    Types.LIST_NO_COMMUNICATION,
    safe(handleListDevicesWithoutCommunication)
  );
  yield takeLatest(Types.SYNC_DEVICE, safe(handleSyncDeviceEnterprise));
  yield takeLatest(Types.ACTION_LOCK, safe(handleLockScreen));
  yield takeLatest(
    Types.ACTION_REMOVE_SCREEN_BLOCK,
    safe(handleRemoveScreenBlock)
  );
  yield takeLatest(Types.ACTION_REBOOT, safe(handleRebootDevice));
  yield takeLatest(
    Types.ACTION_CHANGE_DEVICE_PASSWORD,
    safe(handleChangeDevicePassword)
  );
  yield takeLatest(
    Types.VERIFY_BATCH_DEVICES_ACTIONS,
    safe(handleVerifyBatchDevicesActions)
  );
  yield takeLatest(
    Types.BATCH_DEVICES_ACTIONS,
    safe(handleBatchDevicesActions)
  );
  yield takeLatest(
    Types.GET_KIOSK_ACCESS_TOKEN,
    safe(handleGetKioskAccessToken)
  );
}
