import { Box, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

interface ChartTooltipProps {
  color: string;
  children?: React.ReactNode;
}

const ChartTooltip = ({ color, children }: ChartTooltipProps) => {
  const style = useStyleConfig('ChartTooltip');
  return (
    <Box
      __css={{
        ...style,
      }}
      borderColor={color}
    >
      {children}
    </Box>
  );
};

export default ChartTooltip;
