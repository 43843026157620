import { NotAllowedIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert, { AlertHtml } from '../../../components/Alert';
import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import SendIcon from '../../../components/Icons/Send';
import PageFilter from '../../../components/PageFilter';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import RadioButtonForm from '../../../components/RadioButton/RadioButtonForm';
import Table from '../../../components/Table';
import TableActions from '../../../components/TableActions';
import MenuItem from '../../../components/TableActions/MenuItem';
import { DATE_OF_TODAY, routeWithParameters } from '../../../helper';
import { handleExportAllToExcel } from '../../../helper/exports';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  carrierCancelCompany,
  listManageableCompanies,
  resendWelcomeEmail,
  Types,
} from '../../../store/company';
import { selectorMetadata } from '../../../store/ui';
import { CarrierCompanyType } from '../../../types/company';
import { ListMetadata } from '../../../types/generic_list';

interface CompaniesFilterType {
  status?: string;
  startAt?: Date;
  endAt?: Date;
}

const CompaniesList = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const { manageableCompanies } = useAppSelector((state) => state.company);
  const metadata = useAppSelector(selectorMetadata(Types.CARRIER_LIST));

  const [filterSearch, setFilterSearch] = useState('');
  const [filters, setFilters] = useState<CompaniesFilterType>({ status: '' });

  const allFilters = {
    search: filterSearch,
    status: filters?.status,
    startAt: filters?.startAt,
    endAt: filters?.endAt,
  };

  useEffect(() => {
    dispatch(listManageableCompanies(inFirstPage(metadata), allFilters));
  }, [filterSearch, filters]);

  const statusOptions = [
    {
      label: 'global.all',
      value: '',
    },
    {
      label: 'manage_companies.status.active',
      value: 'true',
    },
    {
      label: 'manage_companies.status.canceled',
      value: 'false',
    },
  ];

  const headersColumn = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'manage_companies.column.social_reason',
        }),
        accessor: 'corporateName',
      },
      {
        header: intl.formatMessage({
          id: 'manage_companies.column.cnpj',
        }),
        accessor: 'federalCode',
      },
      {
        header: intl.formatMessage({
          id: 'manage_companies.column.email',
        }),
        accessor: 'email',
      },
      {
        header: intl.formatMessage({
          id: 'manage_companies.column.licenses',
        }),
        accessor: 'numberOfLicenses',
      },
      {
        header: intl.formatMessage({
          id: 'manage_companies.column.created_at',
        }),
        accessor: 'createdAt',
      },
      {
        header: intl.formatMessage({
          id: 'manage_companies.column.updated_at',
        }),
        accessor: 'updatedAt',
      },
      {
        header: intl.formatMessage({
          id: 'manage_companies.column.status',
        }),
        accessor: 'status',
      },
    ],
    metadata
  );

  const data = manageableCompanies?.map((company) => ({
    key: String(company?.id),
    cells: [
      {
        field: 'corporateName',
        value: company?.corporateName,
      },
      {
        field: 'federalCode',
        value: company?.federalCode,
      },
      {
        field: 'email',
        value: company?.email,
      },
      {
        field: 'numberOfLicenses',
        value: company?.numberOfLicenses,
      },
      {
        field: 'createdAt',
        value: handleFormatDateByUserLogged(company?.createdAt),
      },
      {
        field: 'updatedAt',
        value: handleFormatDateByUserLogged(company?.updatedAt),
      },
      {
        field: 'status',
        value: intl.formatMessage({
          id: `manage_companies.status.${[
            company?.status ? 'active' : 'canceled',
          ]}`,
        }),
      },
      {
        value: '',
        field: 'actions',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={
                <FormattedMessage id="manage_companies.company" />
              }
              linkEdit={routeWithParameters(routes.carrier.edit, {
                id: company?.id,
              })}
              moreItems={
                <>
                  <MenuItem
                    icon={<SendIcon boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage id="manage_companies.action_button.resend_email" />
                    }
                    onClick={() => handleResendWelcomeEmail(company)}
                    isDisabled={!company?.status}
                  />
                  <MenuItem
                    icon={
                      <NotAllowedIcon
                        boxSize={6}
                        color="warning.600"
                        mr="5px"
                      />
                    }
                    text={
                      <FormattedMessage id="manage_companies.action_button.cancel_company" />
                    }
                    isDisabled={!company?.status}
                    onClick={() =>
                      Alert({
                        onConfirm: () =>
                          dispatch(carrierCancelCompany(company.id)),
                        onCancel: () => null,
                        html: (
                          <AlertHtml
                            text={intl.formatMessage(
                              {
                                id: 'manage_companies.alert.cancel_company_text',
                              },
                              { name: company?.corporateName }
                            )}
                          />
                        ),
                        confirmButtonText: intl.formatMessage({
                          id: 'global.confirm',
                        }),
                        cancelButtonText: intl.formatMessage({
                          id: 'global.cancel',
                        }),
                      })
                    }
                  />
                </>
              }
            />
          );
        },
      },
    ],
  }));

  function handleResendWelcomeEmail(company: CarrierCompanyType) {
    Alert({
      onConfirm: () => dispatch(resendWelcomeEmail({ companyId: company.id })),
      onCancel: () => null,
      html: (
        <AlertHtml
          text={intl.formatMessage(
            {
              id: 'manage_companies.alert.resend_company_email_text',
            },
            { name: company?.corporateName, email: company?.email }
          )}
        />
      ),
      confirmButtonText: intl.formatMessage({
        id: 'global.confirm',
      }),
      cancelButtonText: intl.formatMessage({
        id: 'global.cancel',
      }),
    });
  }

  function handleMetadata(newMetadata: Partial<ListMetadata>) {
    dispatch(
      listManageableCompanies({ ...metadata, ...newMetadata }, allFilters)
    );
  }

  function handlePeriodFilterChange(date: Date, field: string) {
    setFilters({ ...filters, [field]: date });
  }

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="manage_companies.title" />}
        description={<FormattedMessage id="manage_companies.description" />}
      />
      <PageShowToaster
        toasterKeys={[Types.CARRIER_CANCEL, Types.CARRIER_REGISTER]}
      />

      <PageShowToaster
        toasterKeys={[Types.CARRIER_RESEND_WELCOME_EMAIL]}
        messageSuccess={intl.formatMessage({
          id: 'manage_companies.alert.resend_company_email.success_message',
        })}
      />

      <FormContainer>
        <PageFilter gridGap={4}>
          <FormControl
            as={Flex}
            flexDirection="column"
            justifyContent="space-between"
            w="350px"
            textLabel={
              <FormattedMessage id="manage_companies.filter.created_at" />
            }
          >
            <Flex w="350px" gridGap={4}>
              <Box w="165px">
                <DatePicker
                  maxDate={filters.endAt || DATE_OF_TODAY}
                  hasValue={filters.startAt}
                  selected={filters.startAt}
                  placeholder={intl.formatMessage({
                    id: 'manage_companies.filter.start_at',
                  })}
                  onChange={(e) => {
                    handlePeriodFilterChange(e, 'startAt');
                  }}
                />
              </Box>
              <Box w="165px">
                <DatePicker
                  minDate={filters.startAt}
                  maxDate={DATE_OF_TODAY}
                  hasValue={filters.endAt}
                  placeholder={intl.formatMessage({
                    id: 'manage_companies.filter.end_at',
                  })}
                  selected={filters.endAt}
                  onChange={(e) => {
                    handlePeriodFilterChange(e, 'endAt');
                  }}
                />
              </Box>
            </Flex>
          </FormControl>

          <RadioButtonForm
            name="status"
            label="manage_companies.filter.status"
            options={statusOptions}
            value={filters?.status}
            defaultValue={filters?.status}
            onChange={(value) =>
              setFilters({ ...filters, status: String(value) })
            }
          />
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={headersColumn}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.CARRIER_LIST}
        pageActionsProps={{
          onSearch: setFilterSearch,
          labelButtonNew: intl.formatMessage({
            id: 'manage_companies.button.new',
          }),
          linkNew: routes.carrier.register,
          handleExportAllToExcel: () =>
            handleExportAllToExcel(
              dispatch,
              Types.CARRIER_LIST,
              metadata,
              allFilters
            ),
        }}
        nameTable={`${intl.formatMessage({ id: 'manage_companies.title' })}`}
      />
    </>
  );
};

export default CompaniesList;
