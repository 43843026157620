import { SimpleGrid } from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Alert, { AlertHtml } from '../../../components/Alert';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import PageErrors from '../../../components/PageErrors';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  carrierCompanyClear,
  carrierCompanyEditInfo,
  carrierEditCompany,
  carrierGetCompany,
  carrierRegisterCompany,
  companyPlanToFilter,
  Types,
} from '../../../store/company';
import { hasLoading, hasSomeLoading, hasSuccess } from '../../../store/ui';
import { CompanyPlan } from '../../../types/company';

const EditCompany = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { manageableCompany, companyPlansToFilter } = useAppSelector(
    (state) => state.company
  );
  const isRegisterSuccess = hasSuccess(Types.CARRIER_REGISTER);
  const isLoading = hasSomeLoading([
    Types.CARRIER_REGISTER,
    Types.CARRIER_EDIT,
  ]);

  const isGetLoading = hasLoading(Types.CARRIER_GET);

  const isEditCompany = !!id;
  const isDisabledPrimary =
    !manageableCompany?.corporateName ||
    !manageableCompany?.federalCode ||
    !manageableCompany?.email ||
    !manageableCompany?.numberOfLicenses ||
    !manageableCompany?.planId;

  const planSelected = useMemo(() => {
    return companyPlansToFilter?.find(
      (plan) => plan?.id === manageableCompany?.planId
    );
  }, [manageableCompany?.planId]);

  const configByPage = {
    register: {
      title: 'edit_company.register.title',
      description: 'edit_company.register.description',
      action: carrierRegisterCompany,
      submitLabel: 'global.register',
      alertText: 'edit_company.register.alert.text',
    },
    edit: {
      title: 'edit_company.edit.title',
      description: 'edit_company.edit.description',
      action: carrierEditCompany,
      submitLabel: 'global.update',
      alertText:
        manageableCompany?.referenceCompanyEmail !== manageableCompany?.email
          ? 'edit_company.edit.alert_email_update.text'
          : 'edit_company.edit.alert_update.text',
    },
  }[isEditCompany ? 'edit' : 'register'];

  useEffect(() => {
    if (isRegisterSuccess) {
      history.push(routes.carrier.manage);
    }
  }, [isRegisterSuccess]);

  useEffect(() => {
    dispatch(companyPlanToFilter({}));
    if (id) {
      dispatch(carrierGetCompany(id));
    }
    return () => {
      dispatch(carrierCompanyClear());
    };
  }, [id]);

  const handleSubmit = () => {
    Alert({
      onCancel: () => null,
      onConfirm: () => dispatch(configByPage.action(manageableCompany)),
      html: (
        <AlertHtml
          text={intl.formatMessage(
            {
              id: configByPage.alertText,
            },
            {
              name: manageableCompany?.name,
              email: manageableCompany?.email,
            }
          )}
        />
      ),
      confirmButtonText: intl.formatMessage({
        id: configByPage.submitLabel,
      }),
      cancelButtonText: intl.formatMessage({ id: 'global.cancel' }),
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'numberOfLicenses') {
      const numberValue = Number(value);
      if (value === '' || (!isNaN(numberValue) && numberValue > 0)) {
        dispatch(
          carrierCompanyEditInfo({
            ...manageableCompany,
            numberOfLicenses: numberValue,
          })
        );
      }
      return;
    }

    dispatch(
      carrierCompanyEditInfo({
        ...manageableCompany,
        [name]: value,
      })
    );
  };

  const handleSelectPlan = (plan: CompanyPlan) => {
    dispatch(
      carrierCompanyEditInfo({
        ...manageableCompany,
        planId: plan?.id,
      })
    );
  };

  const handlePlanInputChange = (value: string) => {
    dispatch(companyPlanToFilter({ search: value }));
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id={configByPage.title} />}
        description={<FormattedMessage id={configByPage.description} />}
      />

      <PageErrors
        toasterKeys={[
          Types.CARRIER_EDIT,
          Types.CARRIER_REGISTER,
          Types.CARRIER_GET,
        ]}
        translateKey="edit_company"
      />
      <PageShowToaster toasterKeys={[Types.CARRIER_EDIT]} disabledError />

      <FormContainer
        labelPrimary={<FormattedMessage id={configByPage.submitLabel} />}
        handlePrimary={handleSubmit}
        disabledPrimary={isDisabledPrimary}
        loadingPrimary={isLoading}
        labelSecondary={<FormattedMessage id="global.back" />}
        handleSecondary={() => history.push(routes.carrier.manage)}
      >
        <SimpleGrid columns={{ md: 1, lg: 3 }} gap="6" p="2">
          <FormControl
            textLabel={<FormattedMessage id="edit_company.corporate_name" />}
            required
          >
            <Input
              inputProps={{
                name: 'corporateName',
                value: manageableCompany?.corporateName || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_company.federal_code" />}
            required
          >
            <Input
              inputProps={{
                name: 'federalCode',
                value: manageableCompany?.federalCode || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_company.email" />}
            required
          >
            <Input
              inputProps={{
                name: 'email',
                value: manageableCompany?.email || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl
            textLabel={
              <FormattedMessage id="edit_company.number_of_licenses" />
            }
            required
          >
            <Input
              inputProps={{
                name: 'numberOfLicenses',
                value: manageableCompany?.numberOfLicenses || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_company.plan" />}
            required
          >
            <SelectAutocomplete
              options={companyPlansToFilter}
              value={planSelected}
              onChange={handleSelectPlan}
              isLoading={isGetLoading}
              onInputChange={handlePlanInputChange}
              getOptionLabel={(option) => `${option.name || ''}`}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_company.contact" />}
          >
            <Input
              inputProps={{
                name: 'contact',
                value: manageableCompany?.contact || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_company.phone_number" />}
          >
            <Input
              inputProps={{
                name: 'phoneNumber',
                value: manageableCompany?.phoneNumber || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_company.zip_code" />}
          >
            <Input
              inputProps={{
                name: 'zipCode',
                value: manageableCompany?.zipCode || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_company.address" />}
          >
            <Input
              inputProps={{
                name: 'address',
                value: manageableCompany?.address || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="edit_company.city" />}>
            <Input
              inputProps={{
                name: 'city',
                value: manageableCompany?.city || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="edit_company.state" />}>
            <Input
              inputProps={{
                name: 'state',
                value: manageableCompany?.state || '',
                onChange: handleInputChange,
              }}
              isLoading={isGetLoading}
            />
          </FormControl>
        </SimpleGrid>
      </FormContainer>
    </>
  );
};

export default EditCompany;
