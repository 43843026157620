import { DeviceActionEnum } from './device';
import { DayOfWeekCodeEnum } from './generalConfiguration';

export enum ActivityEnum {
  INSERT = 'insert',
  UPDATE = 'update',
  DELETE = 'delete',
  ACCEPT = 'accept',
  SEND = 'send',
}

export enum CategoryEnum {
  COMPANY = 'company',
  DEVICE = 'device',
  POLICY = 'policy',
  ADMIN_USER = 'adminUser',
}

export const disableCategoryByActivity: Record<CategoryEnum, ActivityEnum[]> = {
  [CategoryEnum.ADMIN_USER]: [ActivityEnum.INSERT, ActivityEnum.UPDATE],
  [CategoryEnum.COMPANY]: [ActivityEnum.DELETE],
  [CategoryEnum.DEVICE]: [ActivityEnum.UPDATE],
  [CategoryEnum.POLICY]: [ActivityEnum.UPDATE, ActivityEnum.ACCEPT],
};

export const disableActivityByCategory: Record<ActivityEnum, CategoryEnum[]> = {
  [ActivityEnum.DELETE]: [CategoryEnum.COMPANY],
  [ActivityEnum.INSERT]: [CategoryEnum.ADMIN_USER],
  [ActivityEnum.UPDATE]: [
    CategoryEnum.POLICY,
    CategoryEnum.DEVICE,
    CategoryEnum.ADMIN_USER,
  ],
  [ActivityEnum.ACCEPT]: [CategoryEnum.POLICY],
  [ActivityEnum.SEND]: [
    CategoryEnum.POLICY,
    CategoryEnum.ADMIN_USER,
    CategoryEnum.COMPANY,
  ],
};

export interface FieldsResponseType {
  email: string;
  authenticatedEmail: boolean;
  privacyPolicy: boolean;
  userTerms: boolean;
  corporateName: string;
  dataProtectionOfficerName: string;
  dataProtectionOfficerEmail: string;
  dataProtectionOfficerPhone: string;
  euRepresentativeName: string;
  euRepresentativeEmail: string;
  euRepresentativePhone: string;
  federalCode: string;
  phoneNumber: string;
  contact: string;
  address: string;
  zipCode: string;
  city: string;
  state: string;
  timezone: string;
  createdAt: Date;
  startDayOfWeek: DayOfWeekCodeEnum;
  endDayOfWeek: DayOfWeekCodeEnum;
  startCycleDay: number;
  syncTime: number;
  notifyByEmail: boolean;
  emailToken: string;
  company?: string;
  groups?: string;
  users?: string;
  commandType?: DeviceActionEnum;
  action?: string;
}

export interface AuditType {
  id?: string;
  activity?: string;
  category?: CategoryEnum;
  adminUserName?: string;
  value?: string;
  createdAt?: string;
  entityFieldsUpdated?: Partial<FieldsResponseType>;
}

export interface AuditUserType {
  name?: string;
}
