import { Center, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '../../hooks/useRedux';
import { uiRemoveEmpty } from '../../store/ui';
import AlertIcon from '../Icons/Alert';

interface TableAlertType {
  text?: string;
  isEmpty?: boolean;
  alertKey?: string;
}

type stateTimed = {
  type: 'isEmpty' | 'isNotEmpty';
  show: boolean;
  message?: React.ReactNode;
};

const TableAlert = ({ text, isEmpty, alertKey }: TableAlertType) => {
  const intl = useIntl();

  const dispatch = useAppDispatch();

  const [stateTimed, setStateTimed] = useState<stateTimed>({
    type: 'isEmpty',
    show: false,
  });

  useEffect(() => {
    return () => {
      dispatch(uiRemoveEmpty(alertKey));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty) {
      setStateTimed({
        type: 'isNotEmpty',
        show: false,
      });
    }
    if (!!isEmpty) {
      setStateTimed({
        type: 'isEmpty',
        show: true,
        message: intl.formatMessage({
          id: text ? text : 'global.empty_results',
        }),
      });
    }
  }, [isEmpty]);

  return (
    <>
      {stateTimed.show === true && (
        <Flex bg="white" pt="13px" pb="13px" pl="20px" borderRadius="10px">
          <Center color="gray">
            <AlertIcon w="35px" h="35px" />
            <Text as="em" ml="15px">
              {stateTimed.message}
            </Text>
          </Center>
        </Flex>
      )}
    </>
  );
};

export default TableAlert;
