import { Box, useStyleConfig } from '@chakra-ui/react';
import { IntlShape } from 'react-intl';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Text from '../Text';

interface AlertProps extends SweetAlertOptions {
  onConfirm?: () => void;
  onCancel?: () => void;
  html?: React.ReactNode;
  disabledCancel?: boolean;
  cancelReason?: Swal.DismissReason;
  cancelSolid?: boolean;
  confirmSolid?: boolean;
  confirmMessage?: string;
}

const Alert = ({
  onConfirm,
  onCancel,
  disabledCancel,
  cancelReason,
  html,
  customClass,
  ...rest
}: AlertProps) => {
  withReactContent(Swal)
    .fire({
      icon: 'warning',
      showCancelButton: !disabledCancel,
      customClass: {
        confirmButton: 'button solid',
        cancelButton: 'button outline',
        ...customClass,
      },
      iconColor: 'gray.600',
      html: html,
      allowEscapeKey: true,
      allowOutsideClick: true,
      backdrop: true,
      ...rest,
    })
    .then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      } else if (
        result.isDismissed &&
        (!cancelReason || cancelReason === result.dismiss)
      ) {
        onCancel && onCancel();
      }
    });
};

export const AlertDelete = (
  intl: IntlShape,
  { onConfirm, confirmMessage, ...rest }: AlertProps
) => {
  Alert({
    onConfirm: onConfirm,
    html: intl.formatMessage({
      id: confirmMessage ? confirmMessage : 'alert.delete.text',
    }),
    confirmButtonText: intl.formatMessage({ id: 'alert.button.remove' }),
    cancelButtonText: intl.formatMessage({ id: 'alert.button.cancel' }),
    ...rest,
  });
};

interface AlertHtmlProps {
  text: string | React.ReactElement;
  irreversible?: React.ReactNode;
}

export const AlertHtml = (props: AlertHtmlProps) => {
  const style = useStyleConfig('Alert');

  return (
    <Box d="flex" flexDirection="column" alignItems="center">
      <Text>{props.text}</Text>
      {props?.irreversible && (
        <Text mt="10px" __css={{ ...style }}>
          {props?.irreversible}
        </Text>
      )}
    </Box>
  );
};

export default Alert;
