import { AnyAction, combineReducers } from 'redux';

import { RootTypes } from '../helper/redux';
import adminUser from './adminUser';
import application from './application';
import audit from './audit';
import auth from './auth';
import company from './company';
import consumptionProfile from './consumptionProfile';
import dashboard from './dashboard';
import device from './device';
import deviceInfo from './deviceInfo';
import deviceUser from './deviceUser';
import document from './document';
import enterpriseApps from './enterpriseApps';
import event from './event';
import externalApps from './externalApps';
import group from './group';
import help from './help';
import invoice from './invoice';
import location from './location';
import message from './message';
import network from './network';
import notification from './notification';
import plan from './plan';
import policy from './policy';
import portalSettings from './portalSettings';
import remoteApps from './remoteApps';
import remoteView from './remoteView';
import reports from './reports';
import term from './term';
import ui from './ui';

const appReducers = combineReducers({
  adminUser,
  audit,
  application,
  auth,
  company,
  consumptionProfile,
  dashboard,
  device,
  deviceInfo,
  deviceUser,
  document,
  event,
  enterpriseApps,
  group,
  help,
  invoice,
  location,
  message,
  network,
  notification,
  plan,
  policy,
  portalSettings,
  reports,
  remoteView,
  term,
  ui,
  externalApps,
  remoteApps,
});

const reducers = (state: ReturnType<typeof appReducers>, action: AnyAction) => {
  if (action.type === RootTypes.RESET_STORE_TYPE) {
    state = undefined;
  }

  return appReducers(state, action);
};

export default reducers;
