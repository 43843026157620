import { Box, BoxProps, Divider, Heading } from '@chakra-ui/react';
import React from 'react';

import Text from '../Text';

interface FormSubtitleProps extends BoxProps {
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  size?: 'xl' | 'lg' | 'md' | 'sm';
}

const FormSubtitle = ({
  subtitle,
  description,
  children,
  size = 'xl',
  ...rest
}: FormSubtitleProps) => {
  return (
    <Box {...rest}>
      {subtitle && (
        <Box>
          <Heading as={'h2'} fontSize={size} m="0% 0% 1% 0%">
            {subtitle}
          </Heading>
        </Box>
      )}
      {description && (
        <Text as="i" m="0" fontSize="xs" color="gray.300">
          {description}
        </Text>
      )}
      <Box mt="1%">
        <Divider mb="1.5%" />
      </Box>
      {children}
    </Box>
  );
};

export default FormSubtitle;
