import { useMemo } from 'react';

import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import {
  BatteryPluggedModeEnum,
  KeyguardDisabledFeatureEnum,
  MaximumTimeToLockEnum,
  PolicyAndroidType,
} from '../../../../../../types/policy';
import { CardItemType } from '../../../../../CardContent';
import Select from '../../../../../Select';
import SwitchButton from '../../../../../SwitchButton';
import SwitchList from '../../components/SwitchList';
import { intlPolicySettingsKey } from '../../IOS/PolicySettings';
import { GetterSettingFn } from '../../PoliciesInterfaces';

export const getLockScreenSettings: GetterSettingFn = ({
  intl,
  policy,
  handleChangeByFields,
}) => {
  const dispatch = useAppDispatch();

  const checkConfigChecked = (
    property: keyof PolicyAndroidType,
    value: KeyguardDisabledFeatureEnum
  ) => {
    if (Array.isArray(policy?.[property])) {
      return (policy[property] as Array<unknown>).includes(value);
    }
  };

  const disableAll = useMemo(
    () =>
      checkConfigChecked(
        'keyguardDisabledFeatures',
        KeyguardDisabledFeatureEnum.ALL_FEATURES
      ),
    [policy.keyguardDisabledFeatures]
  );

  const handleMultiValueChange = (name: string, newValues: string[]) => {
    dispatch(policySelected({ [name]: newValues }));
  };

  const handleSingleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    let newValues = policy?.[name];

    if (checked) {
      newValues = [...policy?.[name], value];
    } else {
      newValues = policy?.[name]?.filter((item) => item !== value);
    }

    dispatch(policySelected({ [name]: newValues }));
  };

  const maximumTimeToLockOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.fifteen_seconds`,
      }),
      value: MaximumTimeToLockEnum['15s'],
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.thirty_seconds`,
      }),
      value: MaximumTimeToLockEnum['30s'],
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.one_minute`,
      }),
      value: MaximumTimeToLockEnum['1min'],
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.two_minutes`,
      }),
      value: MaximumTimeToLockEnum['2min'],
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.five_minutes`,
      }),
      value: MaximumTimeToLockEnum['5min'],
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.ten_minutes`,
      }),
      value: MaximumTimeToLockEnum['10min'],
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.thirty_minutes`,
      }),
      value: MaximumTimeToLockEnum['30min'],
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.select.nothing`,
      }),
      value: MaximumTimeToLockEnum.NOTHING,
    },
  ];

  const stayOnPluggedModesOptions = [
    {
      label: `${intlPolicySettingsKey}.lockScreen.stay_on_plugged_modes.select.ac`,
      value: BatteryPluggedModeEnum.AC,
    },
    {
      label: `${intlPolicySettingsKey}.lockScreen.stay_on_plugged_modes.select.usb`,
      value: BatteryPluggedModeEnum.USB,
    },
    {
      label: `${intlPolicySettingsKey}.lockScreen.stay_on_plugged_modes.select.wireless`,
      value: BatteryPluggedModeEnum.WIRELESS,
    },
  ];

  const keyguardDisabledFeaturesOptions = [
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_camera.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_camera.description`,
      value: KeyguardDisabledFeatureEnum.CAMERA,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_notifications.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_notifications.description`,
      value: KeyguardDisabledFeatureEnum.NOTIFICATIONS,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_unredacted_notifications.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_unredacted_notifications.description`,
      value: KeyguardDisabledFeatureEnum.UNREDACTED_NOTIFICATIONS,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_trust_agents.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_trust_agents.description`,
      value: KeyguardDisabledFeatureEnum.TRUST_AGENTS,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_finger_print.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_finger_print.description`,
      value: KeyguardDisabledFeatureEnum.DISABLE_FINGERPRINT,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_remote_input.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_remote_input.description`,
      value: KeyguardDisabledFeatureEnum.DISABLE_REMOTE_INPUT,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_face_auth.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_face_auth.description`,
      value: KeyguardDisabledFeatureEnum.FACE,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_iris_auth.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_iris_auth.description`,
      value: KeyguardDisabledFeatureEnum.IRIS,
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_biometric_auth.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_lock_screen_biometric_auth.description`,
      value: KeyguardDisabledFeatureEnum.BIOMETRICS,
    },
  ];

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.title`,
      description: `${intlPolicySettingsKey}.lockScreen.maximum_time_to_lock.description`,
      children: (
        <Select
          name="maximumTimeToLock"
          value={policy?.maximumTimeToLock}
          onChange={(e) => {
            handleChangeByFields(e.target.name, Number(e.target.value));
          }}
        >
          {maximumTimeToLockOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.stay_on_plugged_modes.title`,
      description: `${intlPolicySettingsKey}.lockScreen.stay_on_plugged_modes.description`,
      customStyles: {
        alignItems: 'inherit',
        direction: 'column',
        gridGap: 6,
      },
      children: (
        <SwitchList
          simpleGridStyles={{ width: 'full', columns: [4] }}
          onChange={handleMultiValueChange}
          switchListOptions={stayOnPluggedModesOptions}
          name="stayOnPluggedModes"
          valuesToCompare={policy?.stayOnPluggedModes}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.lockScreen.disable_all_features.title`,
      description: `${intlPolicySettingsKey}.lockScreen.disable_all_features.description`,
      children: (
        <SwitchButton
          name="keyguardDisabledFeatures"
          isChecked={checkConfigChecked(
            'keyguardDisabledFeatures',
            KeyguardDisabledFeatureEnum.ALL_FEATURES
          )}
          onChange={handleSingleValueChange}
          value={KeyguardDisabledFeatureEnum.ALL_FEATURES}
        />
      ),
    },
    ...keyguardDisabledFeaturesOptions.map((option) => ({
      title: option.title,
      description: option.description,
      children: (
        <SwitchButton
          name="keyguardDisabledFeatures"
          isChecked={
            disableAll
              ? false
              : checkConfigChecked('keyguardDisabledFeatures', option.value)
          }
          onChange={handleSingleValueChange}
          isDisabled={disableAll}
          value={option.value}
        />
      ),
    })),
  ];

  return listSettings;
};
