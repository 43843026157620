import React, { ChangeEvent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import { InstallTypeEnum } from '../../../../../../types/policy';
import { CardItemType } from '../../../../../CardContent';
import SelectAutocomplete from '../../../../../SelectAutocomplete';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getVpnSettings: GetterSettingFn = ({ handleChange, policy }) => {
  const dispatch = useAppDispatch();

  const allEnableInstallTypes = [
    InstallTypeEnum.FORCE_INSTALLED,
    InstallTypeEnum.AVAILABLE,
    InstallTypeEnum.PREINSTALLED,
  ];

  const applicationsForSelect = useMemo(
    () =>
      policy?.applications
        ?.filter((application) =>
          allEnableInstallTypes.includes(application?.installType)
        )
        .map((application) => {
          const { companyApplication } = application;
          return {
            id: companyApplication?.id,
            packageName: companyApplication?.packageName,
            name: `${companyApplication?.name} - ${companyApplication?.packageName}`,
          };
        }),
    []
  );

  const vpnApplicationSelected = applicationsForSelect?.find(
    (application) =>
      application?.packageName === policy?.alwaysOnVpnPackage?.packageName
  );

  const handleAlwaysVpnChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      policySelected({
        alwaysOnVpnPackage: {
          ...policy.alwaysOnVpnPackage,
          lockdownEnabled: event?.target?.checked,
        },
      })
    );
  };

  const handleAutoCompleteChange = (newApplication) => {
    const alwaysOnVpnPackageToFill = newApplication
      ? {
          lockdownEnabled: !!policy?.alwaysOnVpnPackage?.lockdownEnabled,
          packageName: newApplication?.packageName,
        }
      : null;

    dispatch(
      policySelected({
        alwaysOnVpnPackage: alwaysOnVpnPackageToFill,
      })
    );
  };

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.vpn.vpn.title`,
      description: `${intlPolicySettingsKey}.vpn.vpn.description`,
      children: (
        <SwitchButton
          name="vpnConfigDisabled"
          isChecked={policy?.vpnConfigDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.vpn.package_name.title`,
      description: `${intlPolicySettingsKey}.vpn.package_name.description`,
      children: (
        <SelectAutocomplete
          options={applicationsForSelect}
          customStyles={{
            width: '450px',
          }}
          getOptionLabel={(option) => option?.name}
          value={vpnApplicationSelected}
          onChange={handleAutoCompleteChange}
          placeholder={
            <FormattedMessage
              id={`${intlPolicySettingsKey}.vpn.lockdown_enabled.placeholder`}
            />
          }
        />
      ),
      childrenItem: {
        title: `${intlPolicySettingsKey}.vpn.lockdown_enabled.title`,
        description: `${intlPolicySettingsKey}.vpn.lockdown_enabled.description`,
        children: (
          <SwitchButton
            name="lockdownEnabled"
            isChecked={
              !vpnApplicationSelected
                ? false
                : policy?.alwaysOnVpnPackage?.lockdownEnabled
            }
            onChange={handleAlwaysVpnChange}
            isDisabled={!vpnApplicationSelected}
          />
        ),
      },
    },
  ];

  return listSettings;
};
