import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  IconProps,
  Tooltip as TooltipChakra,
  TooltipProps,
} from '@chakra-ui/react';

interface TooltipType extends TooltipProps {
  iconChildren?: IconProps;
}

const Tooltip = ({ iconChildren, ...rest }: TooltipType) => {
  return (
    <TooltipChakra {...rest}>
      {!iconChildren ? <InfoOutlineIcon ml="5px" /> : iconChildren}
    </TooltipChakra>
  );
};

export default Tooltip;
