import { Flex } from '@chakra-ui/layout';

import { PolicyType } from '../../../../../../../../types/policy';
import CustomLauncherConfig from '../CustomLauncherConfig';
import KioskManageConfiguration from '../KioskManageConfiguration';

interface Props {
  policy: PolicyType;
}

export default function KioskConfigs({ policy }: Props) {
  return (
    <Flex direction="column" gridGap={6}>
      <CustomLauncherConfig policy={policy} />

      <KioskManageConfiguration policy={policy} />
    </Flex>
  );
}
