import { Flex } from '@chakra-ui/layout';
import { ChangeEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import { intlPolicySettingsKey } from '../..';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../../../store/policy';
import FormControl from '../../../../../../../FormControl';
import Input from '../../../../../../../Input';
import InputKeywords from '../../../../../../../InputKeywords';

export default function Manual() {
  const { policy } = useAppSelector((state) => state.policy);
  const dispatch = useAppDispatch();

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, name } = e?.target;

    const isPortInput = name === 'port';

    dispatch(
      policySelected({
        recommendedGlobalProxy: {
          ...(policy?.recommendedGlobalProxy || {}),
          [name]: isPortInput ? Number(value) : value,
        },
      })
    );
  };

  const handleAddExcludedHosts = (
    propertyToFill: string,
    newKeyword: string
  ) => {
    const newKeywords = [
      newKeyword,
      ...(policy?.recommendedGlobalProxy?.[propertyToFill] || []),
    ];

    dispatch(
      policySelected({
        recommendedGlobalProxy: {
          ...(policy?.recommendedGlobalProxy || {}),
          [propertyToFill]: newKeywords,
        },
      })
    );
  };

  const handleRemoveExcludedHosts = (
    propertyToFill: string,
    deleteKeyword: string
  ) => {
    const newKeywords = (
      policy?.recommendedGlobalProxy?.[propertyToFill] || []
    ).filter((keyword) => keyword !== deleteKeyword);

    dispatch(
      policySelected({
        recommendedGlobalProxy: {
          ...(policy?.recommendedGlobalProxy || {}),
          [propertyToFill]: newKeywords,
        },
      })
    );
  };

  return (
    <Flex w="full" gridGap={4} direction="column">
      <Flex w="full" gridGap={4}>
        <FormControl
          w="full"
          textLabel={
            <FormattedMessage
              id={`${intlPolicySettingsKey}.network.recommended_global_proxy.host`}
            />
          }
        >
          <Input
            inputProps={{
              value: policy?.recommendedGlobalProxy?.host || '',
              name: 'host',
              onChange: handleInputChange,
            }}
          />
        </FormControl>

        <FormControl
          w="full"
          maxW="10rem"
          textLabel={
            <FormattedMessage
              id={`${intlPolicySettingsKey}.network.recommended_global_proxy.port`}
            />
          }
        >
          <Input
            inputProps={{
              value: policy?.recommendedGlobalProxy?.port || '',
              name: 'port',
              onChange: handleInputChange,
            }}
          />
        </FormControl>
      </Flex>

      <Flex w="full">
        <FormControl
          w="full"
          textLabel={
            <FormattedMessage
              id={`${intlPolicySettingsKey}.network.recommended_global_proxy.ignored_hosts`}
            />
          }
        >
          <InputKeywords
            name="excludedHosts"
            handleSubmit={handleAddExcludedHosts}
            onConfirmDelete={handleRemoveExcludedHosts}
            keywords={policy?.recommendedGlobalProxy?.excludedHosts || []}
            emptyMessage={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.network.recommended_global_proxy.empty_list`}
              />
            }
          />
        </FormControl>
      </Flex>
    </Flex>
  );
}
