import { VisuallyHidden } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { policyToggleBlockAllSiteUrl } from '../../../../../../store/policy';
import {
  PolicyType,
  SitesBlockKeywordsType,
} from '../../../../../../types/policy';
import SwitchButton from '../../../../../SwitchButton';
import CategoryAccordionCard from '../../Android/PolicyApps/TableActions/AppConfig/CategoryAccordionCard';
import UrlListAccordionCard from '../../Android/PolicyApps/TableActions/AppConfig/UrlListAccordionCard';
import AppActionAccordionCard from '../../Android/PolicyApps/TableActions/components/AppActionAccordionCard';

interface SitesType {
  policy: PolicyType;
}

const Sites = ({ policy }: SitesType) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const sitesBlockKeywords: SitesBlockKeywordsType = {
    labelBlockKeywords: {
      title: intl.formatMessage({
        id: 'update_policies.sites.block_keyword_title',
      }),
      description: intl.formatMessage({
        id: 'update_policies.sites.block_keyword_subtitle',
      }),
    },
    blockKeywords: policy?.blockKeywords,
  };

  const categoryData = {
    labelBlockCategories: {
      title: intl.formatMessage({
        id: 'update_policies.sites.block_category_title',
      }),
      description: intl.formatMessage({
        id: 'update_policies.sites.block_category_subtitle',
      }),
    },
    blockWebsiteCategories: policy?.blockWebsiteCategories,
  };

  return (
    <>
      <AppActionAccordionCard
        title={intl.formatMessage({
          id: 'update_policies.sites.block_all_title',
        })}
        description={intl.formatMessage({
          id: 'update_policies.sites.block_all_subtitle',
        })}
        isRadio
      >
        <SwitchButton
          name="isBlocked"
          isChecked={policy?.blockAllWebsites}
          onChange={(event) =>
            dispatch(policyToggleBlockAllSiteUrl(event.target.checked))
          }
        />
      </AppActionAccordionCard>
      <UrlListAccordionCard blockKeywordsContent={sitesBlockKeywords} />

      <VisuallyHidden>
        <CategoryAccordionCard selectedAppConfig={categoryData} />
      </VisuallyHidden>
    </>
  );
};

export default Sites;
