import { PayloadAction, AnyAction, createAction } from '@reduxjs/toolkit';

type SelectedAction = PayloadAction<Record<string, unknown>>;
type SelectedClearAction = PayloadAction<undefined>;
type ListSuccessAction = PayloadAction<unknown[]>;
type GetSuccessAction = PayloadAction<unknown>;
type CreateSuccessAction = PayloadAction<unknown>;
type UpdateSuccessAction = PayloadAction<unknown>;
type DeleteSuccessAction = PayloadAction<unknown>;

export function isSelected(action: AnyAction): action is SelectedAction {
  return action.type.endsWith('selected');
}

export function isSelectedClear(
  action: AnyAction
): action is SelectedClearAction {
  return action.type.endsWith('selectedClear');
}

export function isListSuccess(action: AnyAction): action is ListSuccessAction {
  return action.type.endsWith('listSuccess');
}

export function isGetSuccess(action: AnyAction): action is GetSuccessAction {
  return action.type.endsWith('getSuccess');
}

export function isCreateSuccess(
  action: AnyAction
): action is CreateSuccessAction {
  return action.type.endsWith('createSuccess');
}

export function isUpdateSuccess(
  action: AnyAction
): action is UpdateSuccessAction {
  return action.type.endsWith('updateSuccess');
}

export function isDeleteSuccess(
  action: AnyAction
): action is DeleteSuccessAction {
  return action.type.endsWith('deleteSuccess');
}

export const getNameEntity = (type: string) => type.split('/')[1];

export const RESET_STORE_TYPE = 'store/RESET_STORE_TYPE';

export const RootTypes = {
  RESET_STORE_TYPE: 'store/RESET_STORE_TYPE',
};

export const resetStoreAction = createAction(RootTypes.RESET_STORE_TYPE);
