import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { handleCreateApplicationObject } from '../../components/pages/Policies/EditPolicies/Android/PolicyApps/utils';
import { QuerysWithFilters } from '../../types/generic_list';
import {
  InstallTypeEnum,
  PolicyCompanyApplicationType,
  PolicyIOSSupervisedActionPayload,
  PolicyIOSSupervisedFormDataType,
  PolicyType,
} from '../../types/policy';
import { ID } from '../../types/util';
import {
  policyCreateSuccess,
  policyListSuccess,
  enrollmentTokenGetSuccess,
  Types,
  listPolicies,
  policyUpdateSuccess,
  policyGetSuccess,
  policyAppConfigurationSuccess,
  policyListFilterSuccess,
  successGetKioskAppConfigs,
  successSetKioskWallpaper,
  policyApplicationsAdd,
  PoliciesState,
} from '../policy';
import { PortalSettingsState } from '../portalSettings';
import {
  selectorMetadata,
  uiAddEmpty,
  uiAddSuccess,
  uiRemoveEmpty,
} from '../ui';
import { api, requestList, requestSimple, safe } from './util';

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'policyList', type, policyListSuccess, {
    queryParameters,
    filters,
  });
}
function* handleCreate({ payload, type }: PayloadAction<PolicyType>) {
  yield call(
    requestSimple,
    api,
    'policyCreate',
    type,
    policyCreateSuccess,
    payload
  );
}

function* handleCreateIOSSupervised({
  payload,
  type,
}: PayloadAction<PolicyIOSSupervisedFormDataType>) {
  yield call(
    requestSimple,
    api,
    'policyCreateIOSSupervised',
    type,
    policyCreateSuccess,
    payload
  );
}

function* handleGetEnrollmentToken({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'getEnrollmentToken',
    type,
    enrollmentTokenGetSuccess,
    payload
  );
}
function* handleDelete({ payload: id, type }: PayloadAction<PolicyType>) {
  yield call(requestSimple, api, 'policyDelete', type, uiAddSuccess, id);
}

function* handleCopy({ type, payload }: PayloadAction<ID>) {
  yield call(requestSimple, api, 'policyCopy', type, undefined, payload);
  const metadata = yield select(selectorMetadata(type));
  yield put(listPolicies(metadata));
}

function* handleUpdate({ payload, type }: PayloadAction<PolicyType>) {
  yield call(
    requestSimple,
    api,
    'policyUpdate',
    type,
    policyUpdateSuccess,
    payload.id,
    payload
  );
}

function* handleUpdateIOSSupervised({
  payload,
  type,
}: PayloadAction<PolicyIOSSupervisedActionPayload>) {
  yield call(
    requestSimple,
    api,
    'policyIOSSupervisedUpdate',
    type,
    policyUpdateSuccess,
    payload.id,
    payload.formData
  );
}

function* handleGetAppSettings({ payload, type }: PayloadAction<PolicyType>) {
  const response = yield call(api, 'policyAppSettings', payload);
  if (
    response?.managedConfigurations.length === 0 ||
    response?.permissionGrants.length === 0 ||
    (!response?.blockKeyWords && response?.managedConfigurations.length === 0)
  ) {
    yield put(uiAddEmpty(type));
  } else {
    yield put(uiRemoveEmpty(type));
  }
  yield put(policyAppConfigurationSuccess(response));
}

function* handleGet({ payload, type }: PayloadAction<number>) {
  yield call(requestSimple, api, 'policyGet', type, policyGetSuccess, payload);
}

function* handleListFilter({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'policyListFilter',
    type,
    policyListFilterSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleGetIOSPolicy({ type }: PayloadAction) {
  yield call(requestSimple, api, 'policyGetIOS', type, policyGetSuccess);
}

function* handleGetKioskAppConfigs({
  type,
  payload,
}: PayloadAction<{ id: PolicyType['id'] }>) {
  const data = yield call(
    requestSimple,
    api,
    'getKioskApplicationSettings',
    type,
    undefined,
    payload.id
  );

  const { portalSettings }: { portalSettings: PortalSettingsState } =
    yield select();

  yield put(
    successGetKioskAppConfigs({
      kioskAppConfigs: data,
      appKioskIconFontColorKey:
        portalSettings?.portalSettings?.appKioskIconFontColorKey,
    })
  );
}

function* handleSetKioskAppWallpaper({
  payload,
}: PayloadAction<{ file: File }>) {
  const wallpaperUrl = yield call(
    api,
    'setKioskApplicationWallpaper',
    payload.file
  );

  const { portalSettings }: { portalSettings: PortalSettingsState } =
    yield select();

  yield put(
    successSetKioskWallpaper({
      wallpaperConfigKey: portalSettings.portalSettings.appKioskWallpaperKey,
      wallpaperUrl,
    })
  );
}

function* handleGetRemoteViewApplication({ type }: PayloadAction) {
  const {
    policy,
    portalSettings,
  }: { portalSettings: PortalSettingsState; policy: PoliciesState } =
    yield select();

  const response: PolicyCompanyApplicationType = yield call(
    requestSimple,
    api,
    'enterpriseAppSelect',
    type,
    undefined,
    {
      packageName: portalSettings.portalSettings.appRemoteViewPackageName,
    }
  );

  yield put(
    policyApplicationsAdd([
      handleCreateApplicationObject(
        {
          ...response,
          iconBase64: response.iconBase64,
        },
        InstallTypeEnum.FORCE_INSTALLED,
        policy.policy.kioskCustomLauncherEnabled
      ),
    ])
  );
}

export default function* messageSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET_ENROLLMENT_TOKEN, safe(handleGetEnrollmentToken));
  yield takeLatest(Types.CREATE, safe(handleCreate));
  yield takeLatest(
    Types.CREATE_IOS_SUPERVISED,
    safe(handleCreateIOSSupervised)
  );
  yield takeLatest(Types.COPY, safe(handleCopy));
  yield takeLatest(Types.DELETE, safe(handleDelete));
  yield takeLatest(Types.UPDATE, safe(handleUpdate));
  yield takeLatest(
    Types.UPDATE_IOS_SUPERVISED,
    safe(handleUpdateIOSSupervised)
  );
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.GET_APP_SETTINGS, safe(handleGetAppSettings));
  yield takeLatest(Types.LIST_POLICIES_TO_SELECT, safe(handleListFilter));
  yield takeLatest(Types.GET_IOS, safe(handleGetIOSPolicy));
  yield takeLatest(Types.GET_KIOSK_APP_CONFIGS, safe(handleGetKioskAppConfigs));
  yield takeLatest(
    Types.SET_KIOSK_APP_WALLPAPER,
    safe(handleSetKioskAppWallpaper)
  );
  yield takeLatest(
    Types.GET_REMOTE_VIEW_APPLICATION,
    safe(handleGetRemoteViewApplication)
  );
}
