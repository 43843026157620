import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../../../../hooks/useRedux';
import {
  policySetKioskEnable,
  setPolicyKioskInfos,
} from '../../../../../../store/policy';
import {
  KioskBarStatusEnum,
  KioskConfigAccessEnum,
  KioskErrorMessagesEnum,
  KioskNavigateButtonEnum,
  KioskPowerButtonEnum,
  KioskTelephoneServicesButtonEnum,
  PolicyType,
} from '../../../../../../types/policy';
import Alert, { AlertHtml } from '../../../../../Alert';
import Card from '../../../../../Card';
import SwitchButton from '../../../../../SwitchButton';
import Text from '../../../../../Text';
import KioskConfigs from './components/KioskConfigs';

interface KioskType {
  policy: PolicyType;
}

const Kiosk = ({ policy }: KioskType) => {
  const intl = useIntl();

  const dispatch = useAppDispatch();

  const isNavigationDisable =
    policy?.kioskCustomization?.systemNavigation ===
    KioskNavigateButtonEnum.NAVIGATION_DISABLED;

  useEffect(() => {
    if (!policy?.kioskCustomization && policy?.kioskCustomLauncherEnabled) {
      dispatch(
        setPolicyKioskInfos({
          name: 'kioskCustomization',
          value: {
            powerButtonActions: KioskPowerButtonEnum.POWER_BUTTON_AVAILABLE,
            systemErrorWarnings:
              KioskErrorMessagesEnum.ERROR_AND_WARNINGS_ENABLED,
            systemNavigation: KioskNavigateButtonEnum.HOME_BUTTON_ONLY,
            statusBar: isNavigationDisable
              ? KioskBarStatusEnum.SYSTEM_INFO_ONLY
              : KioskBarStatusEnum.NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED,
            deviceSettings: KioskConfigAccessEnum.SETTINGS_ACCESS_ALLOWED,
            telephoneServices:
              KioskTelephoneServicesButtonEnum.NAVIGATION_ENABLED,
          },
        })
      );
    }
  }, [policy]);

  function handleKioskChange(kioskValue: boolean) {
    return dispatch(policySetKioskEnable(kioskValue));
  }

  function handleShowAlert(isKioskEnabled: boolean) {
    return Alert({
      onCancel: () => {
        return;
      },
      onConfirm: () => handleKioskChange(isKioskEnabled),
      html: (
        <AlertHtml
          text={intl.formatMessage({
            id: isKioskEnabled
              ? 'edit_policy.kiosk.modal.title'
              : 'edit_policy.kiosk.modal.disable.title',
          })}
        />
      ),
      confirmButtonText: intl.formatMessage({
        id: isKioskEnabled
          ? 'edit_policy.kiosk.modal.active'
          : 'edit_policy.kiosk.modal.disable',
      }),
      allowOutsideClick: true,
      allowEscapeKey: true,
      cancelButtonText: intl.formatMessage({
        id: 'global.cancel',
      }),
    });
  }

  return (
    <>
      <Card p="6" borderRadius="10px">
        <Flex align="center" justify="space-between" w="100%" mr="1%">
          <Flex direction="column">
            <Text m="0" fontWeight="medium">
              <FormattedMessage id="edit_policy.kiosk.kiosk_mode.title" />
            </Text>
            <Text m="0">
              <FormattedMessage id="edit_policy.kiosk.kiosk_mode.subtitle" />
            </Text>
          </Flex>
          <Flex align="center">
            <SwitchButton
              name="kioskCustomLauncherEnabled"
              isChecked={policy?.kioskCustomLauncherEnabled}
              onChange={(event) => handleShowAlert(event.target.checked)}
            />
          </Flex>
        </Flex>
      </Card>
      {policy?.kioskCustomLauncherEnabled ? (
        <KioskConfigs policy={policy} />
      ) : null}
    </>
  );
};

export default Kiosk;
