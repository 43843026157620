import { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from '../Datepicker';
interface TimePickerProps extends ReactDatePickerProps {
  onChange: (value: Date) => void;
  textLabel?: string;
  disableClose?: boolean;
  hasValue?: boolean;
}
const TimePicker = ({
  onChange,
  textLabel,
  hasValue,
  disableClose,
  ...rest
}: TimePickerProps) => {
  return (
    <DatePicker
      onChange={onChange}
      showTimeSelect
      hasValue={hasValue}
      disableClose={disableClose}
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption={textLabel}
      dateFormat="HH:mm"
      timeFormat="HH:mm"
      {...rest}
    />
  );
};

export default TimePicker;
