import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { ErrorToaster } from '../../helper/error';
import {
  CompanyResendWelcomeEmailData,
  CarrierCompanyType,
  CompanySendWelcomeEmailData,
  CompanyType,
  EnterpriseResponseType,
} from '../../types/company';
import { QuerysWithFilters } from '../../types/generic_list';
import { ID } from '../../types/util';
import { authChangeExpiresIn, authSetEnterpriseId } from '../auth';
import {
  Types,
  companyLicensesListSuccess,
  companyConsumptionsSmsListSuccess,
  companyConsumptionsDataListSuccess,
  companyGetInfoSuccess,
  companyEditInfoSuccess,
  licensesStatesOptionsListSuccess,
  urlCallbackSuccess,
  enterpriseWebTokenGetSuccess,
  getGeneralConfigCompanySuccess,
  companyValidateEmailToken,
  companyListHelpDeskCompaniesSuccess,
  carrierCompanyListSuccess,
  carrierCompanyEditSuccess,
  carrierCompanyGetSuccess,
  companyPlansToFilterSuccess,
  listManageableCompanies,
} from '../company';
import { RootState } from '../index';
import { selectorMetadata, uiAddError } from '../ui';
import { api, requestList, requestSimple, safe } from './util';

function* handleGetInfo({ payload, type }: PayloadAction<CompanyType>) {
  yield call(
    requestSimple,
    api,
    'companyGetInfo',
    type,
    companyGetInfoSuccess,
    payload
  );
}

function* handleGetLicensesStates({
  payload,
  type,
}: PayloadAction<CompanyType>) {
  yield call(
    requestSimple,
    api,
    'companyGetLicensesStates',
    type,
    licensesStatesOptionsListSuccess,
    payload
  );
}

function* handleEditInfo({ payload, type }: PayloadAction<CompanyType>) {
  yield call(
    requestSimple,
    api,
    'editCompany',
    type,
    companyEditInfoSuccess,
    payload
  );
}

function* handleNewEmailToken({ payload, type }: PayloadAction<CompanyType>) {
  yield call(requestSimple, api, 'newEmailToken', type, undefined, payload);
}

function* handleConfirmInfo({ payload, type }: PayloadAction<CompanyType>) {
  yield call(
    requestSimple,
    api,
    'confirmInfoCompany',
    type,
    undefined,
    payload
  );
}

function* handleListConsumptionData({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'companyConsumptionData',
    type,
    companyConsumptionsDataListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleListConsumptionSms({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'companyConsumptionSms',
    type,
    companyConsumptionsSmsListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleListLicense({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'companyLicenseList',
    type,
    companyLicensesListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleDeleteCompany({ type }: PayloadAction<string>) {
  yield call(requestSimple, api, 'deleteCompany', type, undefined);
}

function* handleSendValidateEmail({ type }: PayloadAction<string>) {
  yield call(requestSimple, api, 'companyValidateEmailSend', type);
}

function* handleValidateEmailToken({
  payload: token,
  type,
}: PayloadAction<string>) {
  yield put(companyValidateEmailToken(null));
  const isValid = yield call(
    requestSimple,
    api,
    'companyValidateEmailToken',
    type,
    companyValidateEmailToken,
    { token }
  );
  if (!isValid) {
    yield put(
      uiAddError({
        [Types.VALIDATE_TOKEN]: new ErrorToaster(
          'register_validate.token.error'
        ),
      })
    );
  }
}

function* handleSendUrlCallback({ payload, type }) {
  const response: EnterpriseResponseType = yield call(
    requestSimple,
    api,
    'sendUrlCallback',
    type,
    urlCallbackSuccess,
    payload
  );
  yield call(redirectTo, response.url);
}

function* handleSendRegisterToken({ payload, type }) {
  yield call(
    requestSimple,
    api,
    'sendRegisterToken',
    type,
    authSetEnterpriseId,
    payload
  );
}

function* handleGetEnterpriseWebToken({
  payload,
  type,
}: PayloadAction<CompanyType>) {
  yield call(
    requestSimple,
    api,
    'companyGetEnterpriseWebToken',
    type,
    enterpriseWebTokenGetSuccess,
    payload
  );
}

function* handleUpdateSettings({ payload, type }: PayloadAction<CompanyType>) {
  const { expiresIn } = yield select((state: RootState) => state.auth);
  const response = yield call(
    requestSimple,
    api,
    'companyUpdateSettings',
    type,
    undefined,
    payload
  );

  if (response?.loginExpirationInSeconds !== expiresIn) {
    yield put(authChangeExpiresIn(response.loginExpirationInSeconds));
  }
}

function* handleGetGeneralConfig({ type }: PayloadAction<CompanyType>) {
  yield call(
    requestSimple,
    api,
    'companyGetInfo',
    type,
    getGeneralConfigCompanySuccess
  );
}

function* handleListCompanies({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'helpDeskListCompanies',
    type,
    companyListHelpDeskCompaniesSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleSendWelcomeEmail({
  payload,
  type,
}: PayloadAction<CompanySendWelcomeEmailData>) {
  yield call(
    requestSimple,
    api,
    'companySendWelcomeEmail',
    type,
    undefined,
    payload
  );
}

function* handleResendWelcomeEmail({
  payload,
  type,
}: PayloadAction<CompanyResendWelcomeEmailData>) {
  yield call(
    requestSimple,
    api,
    'carrierResendWelcomeEmail',
    type,
    undefined,
    payload.companyId
  );
}

function* handleListManageableCompanies({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'carrierListCompanies',
    type,
    carrierCompanyListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleRegisterManageableCompany({
  type,
  payload,
}: PayloadAction<CarrierCompanyType>) {
  yield call(
    requestSimple,
    api,
    'carrierRegisterCompany',
    type,
    undefined,
    payload
  );
}

function* handleGetManageableCompany({ type, payload }: PayloadAction<string>) {
  yield call(
    requestSimple,
    api,
    'carrierGetCompany',
    type,
    carrierCompanyGetSuccess,
    payload
  );
}
function* handleEditManageableCompany({
  type,
  payload,
}: PayloadAction<CarrierCompanyType>) {
  yield call(
    requestSimple,
    api,
    'carrierEditCompany',
    type,
    carrierCompanyEditSuccess,
    payload
  );
}

function* handleCancelManageableCompany({ type, payload }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'carrierCancelCompany',
    type,
    undefined,
    payload
  );

  const metadata = yield select(selectorMetadata(Types.CARRIER_LIST));
  yield put(listManageableCompanies(metadata));
}

function* handleCompanyPlansToFilter({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'companyPlansFilter',
    type,
    companyPlansToFilterSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function redirectTo(location) {
  window.location.href = location;
}

export default function* companySaga() {
  yield takeLatest(Types.GET_INFO, safe(handleGetInfo));
  yield takeLatest(Types.EDIT, safe(handleEditInfo));
  yield takeLatest(Types.NEW_EMAIL_TOKEN, safe(handleNewEmailToken));
  yield takeLatest(Types.CONSUMPTION_DATA, safe(handleListConsumptionData));
  yield takeLatest(Types.CONSUMPTION_SMS, safe(handleListConsumptionSms));
  yield takeLatest(Types.LICENSE_LIST, safe(handleListLicense));
  yield takeLatest(Types.LICENSE_STATES_OPTIONS, safe(handleGetLicensesStates));
  yield takeLatest(Types.DELETE, safe(handleDeleteCompany));
  yield takeLatest(Types.VALIDATE_EMAIL_SEND, safe(handleSendValidateEmail));
  yield takeLatest(Types.VALIDATE_EMAIL, safe(handleValidateEmailToken));
  yield takeLatest(Types.SIGNUP_INITIAL, safe(handleSendUrlCallback));
  yield takeLatest(
    Types.COMPANY_REGISTER_ENTERPRISE,
    safe(handleSendRegisterToken)
  );
  yield takeLatest(
    Types.GET_ENTERPRISE_WEB_TOKEN,
    safe(handleGetEnterpriseWebToken)
  );
  yield takeLatest(Types.SETTINGS_UPDATE, safe(handleUpdateSettings));
  yield takeLatest(Types.CONFIRM_INFO, safe(handleConfirmInfo));
  yield takeLatest(Types.GET_CONFIG, safe(handleGetGeneralConfig));
  yield takeLatest(Types.LIST, safe(handleListCompanies));
  yield takeLatest(Types.SEND_WELCOME_EMAIL, safe(handleSendWelcomeEmail));
  yield takeLatest(Types.CARRIER_LIST, safe(handleListManageableCompanies));
  yield takeLatest(Types.CARRIER_GET, safe(handleGetManageableCompany));
  yield takeLatest(
    Types.CARRIER_REGISTER,
    safe(handleRegisterManageableCompany)
  );
  yield takeLatest(
    Types.CARRIER_RESEND_WELCOME_EMAIL,
    safe(handleResendWelcomeEmail)
  );
  yield takeLatest(Types.CARRIER_EDIT, safe(handleEditManageableCompany));
  yield takeLatest(Types.CARRIER_CANCEL, safe(handleCancelManageableCompany));
  yield takeLatest(Types.LIST_PLAN_TO_FILTER, safe(handleCompanyPlansToFilter));
}
