import { Flex, Link } from '@chakra-ui/layout';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../hooks/useRedux';
import { genericError } from '../../../../../../../../../store/api/ApiUtil';
import {
  setKioskAppWallpaper,
  Types,
} from '../../../../../../../../../store/policy';
import { hasError, hasLoading } from '../../../../../../../../../store/ui';
import DropZone from '../../../../../../../../DropZone';
import Text from '../../../../../../../../Text';
import { CommonProps } from './InputForDynamic';

const errorToIntl = {
  default: 'edit_policy.kiosk.kiosk_wallpaper.errors.default',
  INVALID_SIZE_IMG: 'edit_policy.kiosk.kiosk_wallpaper.errors.invalid_size',
  [genericError.message]:
    'edit_policy.kiosk.kiosk_wallpaper.errors.server_limit',
};

export default function UploadKioskWallpaper({ isDisabled }: CommonProps) {
  const isLoading = hasLoading(Types.SET_KIOSK_APP_WALLPAPER);
  const error = hasError(Types.SET_KIOSK_APP_WALLPAPER);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { policy } = useAppSelector((state) => state.policy);
  const wallpaperUrl = useMemo(() => {
    return policy?.kioskApplication?.managedConfigurations.find(
      (managedConfiguration) =>
        managedConfiguration.key === portalSettings.appKioskWallpaperKey
    )?.value;
  }, [policy?.kioskApplication?.managedConfigurations]);

  const handleDrop = (files: File[]) => {
    const file = files[0];

    const formData = new FormData();

    formData.append('file', file);

    dispatch(setKioskAppWallpaper(formData));
  };

  return (
    <Flex w="full" gridGap={6}>
      <Flex w="50%">
        <DropZone
          onDrop={handleDrop}
          maxFiles={1}
          isLoading={isLoading}
          isDisabled={isDisabled}
          errorMessage={
            error &&
            intl.formatMessage({
              id:
                errorToIntl[error.message || 'default'] || errorToIntl.default,
            })
          }
          accept={{
            'image/png': [],
            'image/jpg': [],
            'image/jpeg': [],
            'image/gif': [],
          }}
        />
      </Flex>

      {wallpaperUrl && (
        <Flex direction="column" w="50%">
          <Text m="0">
            <FormattedMessage id="edit_policy.kiosk.kiosk_wallpaper" />
          </Text>

          <Link href={wallpaperUrl as string} isExternal>
            {wallpaperUrl}
          </Link>
        </Flex>
      )}
    </Flex>
  );
}
