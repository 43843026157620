import { Box, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { customMatch, handleOpenExternalWindow } from '../../helper/router';
import { useAppSelector } from '../../hooks/useRedux';
import { ManualSetting } from '../../types/portalSettings';
import HelpIcon from '../Icons/Help';

export default function ManualLink() {
  const intl = useIntl();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const location = useLocation();

  const manualSetting = useMemo<ManualSetting>(() => {
    const setting = portalSettings?.manuals?.find((manualConfig) =>
      customMatch(location.pathname, manualConfig.route)
    );

    return setting;
  }, [location.pathname]);

  if (!manualSetting || !manualSetting?.link) return null;

  return (
    <Tooltip
      closeOnClick={false}
      hasArrow
      arrowSize={8}
      borderRadius="5"
      label={intl.formatMessage({ id: 'manual.open_manual' })}
      placement="left"
    >
      <Box
        display="flex"
        alignItems="center"
        h="full"
        w="min-content"
        cursor="pointer"
        onClick={() => handleOpenExternalWindow(manualSetting?.link)}
      >
        <HelpIcon color="gray.500" boxSize={6} />
      </Box>
    </Tooltip>
  );
}
