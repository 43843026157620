import { call, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { QuerysWithFilters } from '../../types/generic_list';
import { RemoteAppType } from '../../types/remoteApps';
import {
  remoteAppDetailListSuccess,
  remoteAppGetSuccess,
  remoteAppListSuccess,
  Types,
} from '../remoteApps';
import { api, requestList, requestSimple, safe } from './util';

function* handleCreate({ payload, type }: PayloadAction<RemoteAppType>) {
  yield call(requestSimple, api, 'remoteAppCreate', type, undefined, payload);
}

function* handleList({
  type,
  payload: { filters, queryParameters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'remoteAppList', type, remoteAppListSuccess, {
    filters,
    queryParameters,
  });
}

function* handleGet({ payload, type }: PayloadAction<number>) {
  yield call(
    requestSimple,
    api,
    'remoteAppGet',
    type,
    remoteAppGetSuccess,
    payload
  );
}

function* handleGetDetails({
  type,
  payload: { filters, queryParameters, id },
}: PayloadAction<QuerysWithFilters & { id: number }>) {
  yield call(
    requestList,
    api,
    'remoteAppRecipients',
    type,
    remoteAppDetailListSuccess,
    id,
    {
      filters,
      queryParameters,
    }
  );
}

export default function* remoteAppSaga() {
  yield takeLatest(Types.CREATE, safe(handleCreate));
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.LIST_DETAILS, safe(handleGetDetails));
}
