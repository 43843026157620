import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PaginationPayload } from '../../types/generic_list';
import PageLoadMore from '../PageLoadMore';
import Select from '../Select';
import Pagination from './Pagination';

export interface PagePaginationProps {
  pagination: PaginationPayload;
  onPageChange: (payload: Partial<PaginationPayload>) => void;
  pageSizeFixed?: boolean;
  isLoadMore?: boolean;
  isColumn?: boolean;
}

const PagePagination = ({
  pagination,
  onPageChange,
  pageSizeFixed,
  isLoadMore,
  isColumn,
}: PagePaginationProps) => {
  const currentTotalItems =
    Math.min(pagination?.page * pagination?.pageSize, pagination?.totalItems) ||
    0;

  return (
    <Flex
      flexDir={{ lg: isColumn ? 'column' : 'row', md: 'column', sm: 'column' }}
      alignItems="center"
      justifyContent="space-between"
      mt="40px"
      gridRowGap={4}
    >
      {pagination && (
        <>
          <Flex ml="1%" alignItems="center">
            <Box minW="200px">
              <Text color="gray.500" alignSelf="center">
                <FormattedMessage
                  id="global.showing_x_of"
                  values={{
                    rowInit:
                      (pagination.page - 1) * pagination.pageSize + 1 || 0,
                    rowEnd: currentTotalItems,
                    totalRowa: pagination.totalItems || 0,
                  }}
                />
              </Text>
            </Box>
            {!pageSizeFixed && (
              <Box width="127px">
                <Select
                  color="gray.300"
                  backgroundColor="white"
                  ml="10%"
                  value={pagination.pageSize}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    onPageChange({
                      pageSize: parseInt(e.target.value),
                      page: 1,
                    })
                  }
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="80">80</option>
                </Select>
              </Box>
            )}
          </Flex>
          {!isLoadMore ? (
            <Pagination
              onPageChange={(page: number) => onPageChange({ page })}
              totalCount={pagination.totalItems || 0}
              currentPage={Number(pagination.page) || 0}
              pageSize={pagination.pageSize}
            />
          ) : (
            currentTotalItems !== pagination.totalItems && (
              <Flex w="full" align="center" justifyContent="center">
                <PageLoadMore
                  handleLoadMore={() =>
                    onPageChange({ pageSize: pagination.pageSize * 2 })
                  }
                  mt="0"
                />
              </Flex>
            )
          )}
        </>
      )}
    </Flex>
  );
};

export default PagePagination;
