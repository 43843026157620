import { DayOfWeekEnum } from '../components/pages/Policies/EditPolicies/Android/PolicySettings/utils';
import { EnterpriseOriginEnum } from './enterpriseApps';
import { NetworkSettingsType } from './network';
import { ID } from './util';

export enum KioskNavigateButtonEnum {
  NAVIGATION_ENABLED = 'NAVIGATION_ENABLED',
  NAVIGATION_DISABLED = 'NAVIGATION_DISABLED',
  HOME_BUTTON_ONLY = 'HOME_BUTTON_ONLY',
}

export enum KioskPowerButtonEnum {
  POWER_BUTTON_BLOCKED = 'POWER_BUTTON_BLOCKED',
  POWER_BUTTON_AVAILABLE = 'POWER_BUTTON_AVAILABLE',
}

export enum KioskErrorMessagesEnum {
  ERROR_AND_WARNINGS_ENABLED = 'ERROR_AND_WARNINGS_ENABLED',
  ERROR_AND_WARNINGS_MUTED = 'ERROR_AND_WARNINGS_MUTED',
}

export enum KioskBarStatusEnum {
  NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED = 'NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED',
  SYSTEM_INFO_ONLY = 'SYSTEM_INFO_ONLY',
  NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED = 'NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED',
}

export enum KioskConfigAccessEnum {
  SETTINGS_ACCESS_ALLOWED = 'SETTINGS_ACCESS_ALLOWED',
  SETTINGS_ACCESS_BLOCKED = 'SETTINGS_ACCESS_BLOCKED',
}

export enum KioskTelephoneServicesButtonEnum {
  NAVIGATION_ENABLED = 'TELEPHONE_SERVICES_ENABLED',
  NAVIGATION_DISABLED = 'TELEPHONE_SERVICES_DISABLED',
}

export enum PasswordPolicyScopeEnum {
  'SCOPE_UNSPECIFIED' = 'SCOPE_UNSPECIFIED',
  'SCOPE_DEVICE' = 'SCOPE_DEVICE',
  'SCOPE_PROFILE' = 'SCOPE_PROFILE',
}

export enum PasswordPolicyScopePropertyEnum {
  'SCOPE_DEVICE' = 'deviceScope',
  'SCOPE_PROFILE' = 'profileScope',
}

export enum RequirePasswordUnlockEnum {
  'USE_DEFAULT_DEVICE_TIMEOUT' = 'USE_DEFAULT_DEVICE_TIMEOUT',
  'REQUIRE_EVERY_DAY' = 'REQUIRE_EVERY_DAY',
}

export enum SystemUpdateEnum {
  SYSTEM_UPDATE_TYPE_UNSPECIFIED = 'SYSTEM_UPDATE_TYPE_UNSPECIFIED',
  AUTOMATIC = 'AUTOMATIC',
  WINDOWED = 'WINDOWED',
  POSTPONE = 'POSTPONE',
}

export enum AutoUpdateModeEnum {
  AUTO_UPDATE_DEFAULT = 'AUTO_UPDATE_DEFAULT',
  AUTO_UPDATE_POSTPONED = 'AUTO_UPDATE_POSTPONED',
  AUTO_UPDATE_HIGH_PRIORITY = 'AUTO_UPDATE_HIGH_PRIORITY',
}

export enum AutoDateTimeZoneEnum {
  AUTO_DATE_AND_TIME_ZONE_USER_CHOICE = 'AUTO_DATE_AND_TIME_ZONE_USER_CHOICE',
  AUTO_DATE_AND_TIME_ZONE_ENFORCED = 'AUTO_DATE_AND_TIME_ZONE_ENFORCED',
}

export enum UsbDataAccessEnum {
  ALLOW_USB_DATA_TRANSFER = 'ALLOW_USB_DATA_TRANSFER',
  DISALLOW_USB_FILE_TRANSFER = 'DISALLOW_USB_FILE_TRANSFER',
  DISALLOW_USB_DATA_TRANSFER = 'DISALLOW_USB_DATA_TRANSFER',
}

export enum ConfigureWifiEnum {
  ALLOW_CONFIGURING_WIFI = 'ALLOW_CONFIGURING_WIFI',
  DISALLOW_ADD_WIFI_CONFIG = 'DISALLOW_ADD_WIFI_CONFIG',
  DISALLOW_CONFIGURING_WIFI = 'DISALLOW_CONFIGURING_WIFI',
}

export enum WifiDirectSettingsEnum {
  ALLOW_WIFI_DIRECT = 'ALLOW_WIFI_DIRECT',
  DISALLOW_WIFI_DIRECT = 'DISALLOW_WIFI_DIRECT',
}

export enum TetheringSettingsEnum {
  ALLOW_ALL_TETHERING = 'ALLOW_ALL_TETHERING',
  DISALLOW_WIFI_TETHERING = 'DISALLOW_WIFI_TETHERING',
  DISALLOW_ALL_TETHERING = 'DISALLOW_ALL_TETHERING',
}

export enum GpsFrequencyEnum {
  '3min' = 180,
  '10min' = 600,
  '30min' = 1800,
  '1h' = 3600,
  '6h' = 21600,
  '12h' = 43200,
  '24h' = 86400,
}

export enum MicrophoneAccessEnum {
  MICROPHONE_ACCESS_USER_CHOICE = 'MICROPHONE_ACCESS_USER_CHOICE',
  MICROPHONE_ACCESS_DISABLED = 'MICROPHONE_ACCESS_DISABLED',
  MICROPHONE_ACCESS_ENFORCED = 'MICROPHONE_ACCESS_ENFORCED',
}

export enum MaximumTimeToLockEnum {
  '15s' = 15000,
  '30s' = 30000,
  '1min' = 60000,
  '2min' = 120000,
  '5min' = 300000,
  '10min' = 600000,
  '30min' = 1800000,
  NOTHING = 0,
}

export enum QualityPasswordEnum {
  SOMETHING = 'SOMETHING',
  NUMERIC = 'NUMERIC',
  NUMERIC_COMPLEX = 'NUMERIC_COMPLEX',
  ALPHABETIC = 'ALPHABETIC',
  ALPHANUMERIC = 'ALPHANUMERIC',
  COMPLEX = 'COMPLEX',
  BIOMETRIC_WEAK = 'BIOMETRIC_WEAK',
  UNSPECIFIED = 'PASSWORD_QUALITY_UNSPECIFIED',
}

export enum LocationModeEnum {
  HIGH_ACCURACY = 'HIGH_ACCURACY',
  SENSORS_ONLY = 'SENSORS_ONLY',
  BATTERY_SAVING = 'BATTERY_SAVING',
  OFF = 'OFF',
  LOCATION_USER_CHOICE = 'LOCATION_USER_CHOICE',
  LOCATION_ENFORCED = 'LOCATION_ENFORCED',
  LOCATION_DISABLED = 'LOCATION_DISABLED',
}

export enum UntrustedAppsPolicyEnum {
  DISALLOW_INSTALL = 'DISALLOW_INSTALL',
  ALLOW_INSTALL_DEVICE_WIDE = 'ALLOW_INSTALL_DEVICE_WIDE',
}

export enum DeveloperSettingEnum {
  DEVELOPER_SETTINGS_DISABLED = 'DEVELOPER_SETTINGS_DISABLED',
  DEVELOPER_SETTINGS_ALLOWED = 'DEVELOPER_SETTINGS_ALLOWED',
}

export enum EncryptionPolicyEnum {
  ENCRYPTION_POLICY_UNSPECIFIED = 'ENCRYPTION_POLICY_UNSPECIFIED',
  ENABLED_WITHOUT_PASSWORD = 'ENABLED_WITHOUT_PASSWORD',
  ENABLED_WITH_PASSWORD = 'ENABLED_WITH_PASSWORD',
}

export enum PermissionPolicyEnum {
  PERMISSION_POLICY_UNSPECIFIED = 'PERMISSION_POLICY_UNSPECIFIED',
  PROMPT = 'PROMPT',
  GRANT = 'GRANT',
  DENY = 'DENY',
}

export enum PolicyTypeEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export enum ManagementModeEnum {
  MANAGED_ANDROID = 'MANAGED_ANDROID',
  MANAGED_IOS = 'MANAGED_IOS',
  BLOCK_SIM_ANDROID = 'BLOCK_SIM_ANDROID',
  IOS_SUPERVISED = 'SUPERVISED_IOS',
  WORK_PROFILE_ANDROID = 'WORK_PROFILE_ANDROID',
}

export enum ManagementModeEnumToIntl {
  MANAGED_ANDROID = 'devices.column.accordion.management_mode.managed_android',
  MANAGED_IOS = 'devices.column.accordion.management_mode.managed_ios',
  BLOCK_SIM_ANDROID = 'devices.column.accordion.management_mode.block_sim_android',
  IOS_SUPERVISED = 'devices.column.accordion.management_mode.ios_supervised',
  WORK_PROFILE_ANDROID = 'devices.column.accordion.management_mode.work_profile_android',
}

export enum PlayStoreModeEnum {
  WHITELIST = 'WHITELIST',
  BLACKLIST = 'BLACKLIST',
  KIOSK = 'KIOSK',
}

export enum KioskCustomLauncherEnabledEnum {
  YES = 'YES',
  NO = 'NO',
}
export interface kioskCustomization {
  powerButtonActions: string;
  systemErrorWarnings: string;
  systemNavigation: string;
  statusBar: string;
  deviceSettings: string;
  telephoneServices?: string;
}

export enum PolicyAppPermissionSelectEnum {
  PROMPT = 'PROMPT',
  GRANT = 'GRANT',
  DENY = 'DENY',
}

export enum BatteryPluggedModeEnum {
  AC = 'AC',
  USB = 'USB',
  WIRELESS = 'WIRELESS',
}

export enum InstallTypeEnum {
  PREINSTALLED = 'PREINSTALLED',
  FORCE_INSTALLED = 'FORCE_INSTALLED',
  BLOCKED = 'BLOCKED',
  AVAILABLE = 'AVAILABLE',
  REQUIRED_FOR_SETUP = 'REQUIRED_FOR_SETUP',
  KIOSK_MODE = 'KIOSK_MODE',
}

export enum RecommendedGlobalProxyType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
  NONE = 'NONE',
}

export enum KeyguardDisabledFeatureEnum {
  KEYGUARD_DISABLED_FEATURE_UNSPECIFIED = 'KEYGUARD_DISABLED_FEATURE_UNSPECIFIED',
  CAMERA = 'CAMERA',
  NOTIFICATIONS = 'NOTIFICATIONS',
  UNREDACTED_NOTIFICATIONS = 'UNREDACTED_NOTIFICATIONS',
  TRUST_AGENTS = 'TRUST_AGENTS',
  DISABLE_FINGERPRINT = 'DISABLE_FINGERPRINT',
  DISABLE_REMOTE_INPUT = 'DISABLE_REMOTE_INPUT',
  FACE = 'FACE',
  IRIS = 'IRIS',
  BIOMETRICS = 'BIOMETRICS',
  ALL_FEATURES = 'ALL_FEATURES',
}

export interface PolicyCompanyApplicationType {
  id?: ID;
  name?: string;
  origin?: EnterpriseOriginEnum;
  iconBase64?: string;
  packageName?: string;
}

export interface PermissionGrantsType {
  description?: string;
  name?: string;
  permission?: string;
  permissionPolicy?: string;
}

export type ManageConfigurationInputType =
  | 'URLLIST'
  | 'STRING'
  | 'BOOL'
  | 'INTEGER'
  | 'CHOICE'
  | 'MULTISELECT'
  | 'HIDDEN'
  | 'BLOCKTOTAL'
  | 'BUNDLE'
  | 'BUNDLE_ARRAY';

export enum ManageConfigurationInputTypeEnum {
  URLLIST = 'URLLIST',
  STRING = 'STRING',
  BOOL = 'BOOL',
  INTEGER = 'INTEGER',
  CHOICE = 'CHOICE',
  MULTISELECT = 'MULTISELECT',
  HIDDEN = 'HIDDEN',
  BLOCKTOTAL = 'BLOCKTOTAL',
  BUNDLE = 'BUNDLE',
  BUNDLE_ARRAY = 'BUNDLE_ARRAY',
}

export interface ManagedConfigurationsType {
  description?: string;
  key?: string;
  title?: string;
  type?: ManageConfigurationInputType;
  value?: string | boolean | (string | number)[] | ManagedConfigurationsType[];
  defaultValue?:
    | string
    | boolean
    | (string | number)[]
    | ManagedConfigurationsType[];
  entries?: EntriesOptionsType[];
  subcategories?: string[];
  nestedProperties?: ManagedConfigurationsType[];
}

export interface ManagedConfigurationsBundleValueType {
  key?: string;
  type?: ManageConfigurationInputType;
  value?: string | boolean | ManagedConfigurationsBundleValueType[];
}

export interface BlockWebsiteCategoriesType {
  websiteCategory: string;
}

export interface LabelBlockCategoriesType {
  title: string;
  description: string;
}

export interface PolicySystemApplicationType {
  enterpriseApplicationId?: ID;
  name?: string;
  origin?: EnterpriseOriginEnum;
  packageName?: string;
  iconBase64?: string;
  visible?: boolean;
  workTimes?: AppWorkTime[];
}

export interface AppWorkTime {
  dayOfWeek: DayOfWeekEnum;
  startTime: string | null;
  endTime: string | null;
  blocked: boolean;
}

export interface PolicyApplicationType {
  id?: ID;
  companyApplicationId?: ID;
  installType?: InstallTypeEnum;
  companyApplication?: PolicyCompanyApplicationType;
  autoUpdateMode?: AutoUpdateModeEnum;
  minimumVersionCode?: string;
  permissionGrants?: PermissionGrantsType[];
  managedConfigurations?: ManagedConfigurationsType[];
  blockKeywords?: BlockKeywordsType[];
  labelBlockKeywords?: LabelBlockKeywordsType;
  blockWebsiteCategories?: BlockWebsiteCategoriesType[];
  labelBlockCategories?: LabelBlockCategoriesType;
  visible?: boolean;
  workTimes?: AppWorkTime[];
}

export interface LabelBlockKeywordsType {
  title: string;
  description: string;
}

export enum OperationalSystemEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export interface PolicyIOSType {
  id?: ID;
  blockTotal?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  blockAllWebsites?: boolean;
  blockKeywords?: BlockKeywordsType[];
  blockWebsiteCategories?: BlockWebsiteCategoriesType[];
  allowAssistant?: boolean;
  allowAssistantWhileLocked?: boolean;
  allowSpotlightInternetResults?: boolean;
  allowRemoteScreenObservation?: boolean;
  allowScreenShot?: boolean;
  allowCamera?: boolean;
  forceITunesStorePasswordEntry?: boolean;
  forceWatchWristDetection?: boolean;
  forceLimitAdTracking?: boolean;
  allowApplePersonalizedAdvertising?: boolean;
  allowInAppPurchases?: boolean;
  allowOpenFromUnmanagedToManaged?: boolean;
  allowEnterpriseAppTrust?: boolean;
  allowDiagnosticSubmission?: boolean;
  forceAirDropUnmanaged?: boolean;
  forceEncryptedBackup?: boolean;
  allowEnterpriseBookBackup?: boolean;
  allowCloudBackup?: boolean;
  allowCloudKeychainSync?: boolean;
  allowCloudPhotoLibrary?: boolean;
  allowSharedStream?: boolean;
  allowManagedAppsCloudSync?: boolean;
  allowEnterpriseBookMetadataSync?: boolean;
  allowLockScreenNotificationsView?: boolean;
  allowLockScreenControlCenter?: boolean;
  allowLockScreenTodayView?: boolean;
  allowVoiceDialing?: boolean;
  allowFingerprintForUnlock?: boolean;
}

export interface PolicyIOSSupervisedType {
  profileFile?: File;
  file?: boolean;
  name?: string;
  displayName?: string;
}

export type PolicyIOSSupervisedFormDataType = FormData;

export interface PolicyIOSSupervisedActionPayload {
  id?: ID;
  formData: PolicyIOSSupervisedFormDataType;
}

export interface PolicyAndroidType extends PolicySettingsType {
  id?: ID;
  playStore?: string;
  kioskMode?: string;
  policyType?: PolicyTypeEnum;
  kioskCustomization?: kioskCustomization;
  playStoreMode?: PlayStoreModeEnum;
  applications?: PolicyApplicationType[];
  systemApplications?: PolicySystemApplicationType[];
  manualApplications?: PolicySystemApplicationType[];
  kioskCustomLauncherEnabled?: boolean;
  passwordPolicy?: {
    deviceScope?: PolicyPasswordType;
    profileScope?: PolicyPasswordType;
    appliedScopeDevicePasswordPolicyInScopeProfile?: boolean;
  };
  systemUpdate?: SystemUpdateType;
  operationalSystem?: OperationalSystemEnum;
  kioskApplication?: PolicyApplicationType & {
    managedConfigurations?: ManagedConfigurationsType[];
  };
}

export interface PolicyResponseType {
  id?: ID;
  name?: string;
  operationalSystem?: OperationalSystemEnum;
  androidPolicy?: PolicyAndroidType;
  iosPolicy?: PolicyIOSType;
}

export interface PolicyType
  extends PolicyAndroidType,
    PolicyIOSType,
    PolicyIOSSupervisedType {
  name?: string;
  managementMode?: ManagementModeEnum;
  devicesCount?: number;
}

export interface PolicyToSelectType {
  id?: ID;
  name?: string;
}

export interface EnrollmentTokenType {
  value?: string;
  policyName?: string;
  policy?: PolicyType;
  additionalData?: null;
  qrCode?: string;
  expirationTimestamp?: string;
}

export interface SystemUpdateType {
  type?: SystemUpdateEnum;
  startMinutes?: string;
  endMinutes?: string;
  freezePeriods?: FreezePeriodType[];
}

export interface FreezePeriodType {
  id?: string;
  startDate?: string;
  endDate?: string;
}

export interface RecommendedGlobalProxy {
  host?: string;
  port?: number;
  excludedHosts?: string[];
  pacUri?: string;
  type?: RecommendedGlobalProxyType;
}

export interface PolicyPasswordType {
  passwordMinimumLength?: number;
  passwordMinimumLetters?: number;
  passwordMinimumLowerCase?: number;
  passwordMinimumNonLetter?: number;
  passwordMinimumNumeric?: number;
  passwordMinimumSymbols?: number;
  passwordMinimumUpperCase?: number;
  passwordHistoryLength?: number;
  maximumFailedPasswordsForWipe?: number;
  passwordExpirationTimeout?: number;
  passwordQuality?: QualityPasswordEnum;
  requirePasswordUnlock?: RequirePasswordUnlockEnum;
  passwordScope?: PasswordPolicyScopeEnum;
}

export interface AdvancedSecurityOverridesType {
  untrustedAppsPolicy: UntrustedAppsPolicyEnum;
  developerSettings: DeveloperSettingEnum;
}

export interface ChoosePrivateKeyRuleType {
  urlPattern: string;
  packageNames: string[];
  privateKeyAlias: string;
}

export interface PolicySettingsType {
  vpnConfigDisabled?: boolean;
  configureWifi?: ConfigureWifiEnum;
  networkResetDisabled?: boolean;
  mobileNetworksConfigDisabled?: boolean;
  bluetoothDisabled?: boolean;
  bluetoothConfigDisabled?: boolean;
  outgoingBeamDisabled?: boolean;
  tetheringSettings?: TetheringSettingsEnum;
  screenCaptureDisabled?: boolean;
  microphoneAccess?: MicrophoneAccessEnum;
  addUserDisabled?: boolean;
  removeUserDisabled?: boolean;
  factoryResetDisabled?: boolean;
  mountPhysicalMediaDisabled?: boolean;
  usbDataAccess?: UsbDataAccessEnum;
  locationMode?: LocationModeEnum;
  untrustedApp?: UntrustedAppsPolicyEnum;
  developerSettings?: DeveloperSettingEnum;
  shareLocationDisabled?: boolean;
  gpsCaptureStartTime?: string;
  gpsCaptureEndTime?: string;
  dataRoamingDisabled?: boolean;
  gpsFrequency?: GpsFrequencyEnum;
  cameraDisabled?: boolean;
  adjustVolumeDisabled?: boolean;
  modifyAccountsDisabled?: boolean;
  minimumApiLevel?: number;
  advancedSecurityOverrides?: AdvancedSecurityOverridesType;
  frpAdminEmails?: string;
  outgoingCallsDisabled?: boolean;
  bluetoothContactSharingDisabled?: boolean;
  smsDisabled?: boolean;
  skipFirstUseHintsEnabled?: boolean;
  setWallpaperDisabled?: boolean;
  setUserIconDisabled?: boolean;
  credentialsConfigDisabled?: boolean;
  keyguardDisabledFeatures?: KeyguardDisabledFeatureEnum[];
  cellBroadcastsConfigDisabled?: boolean;
  alwaysOnVpnPackage?: AlwaysOnVpnPackageType;
  autoDateAndTimeZone?: AutoDateTimeZoneEnum;
  encryptionPolicy?: EncryptionPolicyEnum;
  permittedAccessibilityServices?: string[];
  maximumTimeToLock?: MaximumTimeToLockEnum;
  permittedInputMethods?: string[];
  recommendedGlobalProxy?: RecommendedGlobalProxy;
  stayOnPluggedModes?: BatteryPluggedModeEnum[];
  accountTypesWithManagementDisabled?: string[];
  choosePrivateKeyRules?: ChoosePrivateKeyRuleType[];
  privateKeySelectionEnabled?: boolean;
  policyNetworks?: NetworkSettingsType[];
  networkIds?: number[];
  defaultPermissionPolicy?: PermissionPolicyEnum;
  remoteViewEnabled?: boolean;
  lockAppsOutsideWorkingHours?: boolean;
  startDayOfWork?: DayOfWeekEnum;
  endDayOfWork?: DayOfWeekEnum;
  startWorkingTime?: string;
  endWorkingTime?: string;
  wifiDirectSettings?: WifiDirectSettingsEnum;
}

export type AlwaysOnVpnPackageType = {
  packageName?: string;
  lockdownEnabled?: boolean;
};

export type EntriesOptionsType = {
  name?: string;
  value?: string | number;
};

export interface BlockKeywordsType {
  keyword?: string;
  blocked?: boolean;
}

export enum WebsiteCategoryEnum {
  ADULT_CONTENT = 'ADULT_CONTENT',
  ALCOHOLIC = 'ALCOHOLIC',
  BUSINESS = 'BUSINESS',
  CHAT = 'CHAT',
  CURSE_WORDS = 'CURSE_WORDS',
  DRUGS = 'DRUGS',
  EMAIL = 'EMAIL',
  EDUCATIONAL = 'EDUCATIONAL',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FILE_SHARES = 'FILE_SHARES',
  FORUM = 'FORUM',
  GAMBLING = 'GAMBLING',
  GAMES = 'GAMES',
  GOVERN = 'GOVERN',
  HEALTH = 'HEALTH',
  JOBS = 'JOBS',
  NEWS = 'NEWS',
  PROXIES = 'PROXIES',
  SEARCH_ENGINE = 'SEARCH_ENGINE',
  SHOPPING = 'SHOPPING',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  SPORTS = 'SPORTS',
  TABACO = 'TABACO',
  TECHNOLOGY = 'TECHNOLOGY',
  TRAVEL = 'TRAVEL',
  VIOLENCE = 'VIOLENCE',
}

export interface SitesBlockKeywordsType {
  labelBlockKeywords: LabelBlockKeywordsType;
  blockKeywords: BlockKeywordsType[];
}
