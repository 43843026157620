// Action Types

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import {
  ApplicationConsumptionHistoryType,
  ApplicationDeviceUserType,
  ApplicationsFilter,
  ApplicationType,
} from '../../types/application';
import { ListQueryParameters } from '../../types/generic_list';
import { ID } from '../../types/util';

export const Types = {
  LIST: 'application/LIST',
  LIST_REPORT: 'application/LIST_REPORT',
  LIST_FOR_SELECT: 'application/LIST_FOR_SELECT',
  LIST_APPLICATION_DEVICE_USERS: 'application/LIST_APPLICATION_DEVICE_USERS',
  LIST_APPLICATION_CONSUMPTION_HISTORY:
    'application/LIST_APPLICATION_CONSUMPTION_HISTORY',
  CHECK_INSTALL_APPLICATION: 'application/android/CHECK_INSTALL_APPLICATION',
  CHECK_UNINSTALL_APPLICATION:
    'application/android/CHECK_UNINSTALL_APPLICATION',
};

// Reducer
interface ApplicationsState {
  application: ApplicationType;
  applications: ApplicationType[];
  consumptionHistorical: ApplicationConsumptionHistoryType[];
  deviceUser: ApplicationDeviceUserType;
  deviceUsers: ApplicationDeviceUserType[];
  filter: ApplicationsFilter;
  applicationsForSelect: ApplicationType[];
}

const initialState: ApplicationsState = {
  application: {},
  applications: [],
  consumptionHistorical: [],
  deviceUser: {},
  deviceUsers: [],
  filter: {},
  applicationsForSelect: [],
};

export const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    applicationConsumptionHistoryListSuccess: (
      state,
      action: PayloadAction<ApplicationConsumptionHistoryType[]>
    ) => {
      state.consumptionHistorical = action.payload;
    },
    applicationDeviceUsersListSuccess: (
      state,
      action: PayloadAction<ApplicationDeviceUserType[]>
    ) => {
      state.deviceUsers = action.payload;
    },
    applicationDeviceUserSet: (state, action: PayloadAction<ID>) => {
      const deviceUserId = action.payload;
      state.deviceUser = state.deviceUsers.find(
        (deviceUser) => deviceUser.userId === deviceUserId
      );
    },
    applicationDeviceUserClear: (state) => {
      state.deviceUser = null;
    },
    applicationListSuccess: (
      state,
      action: PayloadAction<ApplicationType[]>
    ) => {
      state.applications = action.payload;
    },
    applicationSelected: (
      state,
      action: PayloadAction<Partial<ApplicationType>>
    ) => {
      state.application = { ...state.application, ...action.payload };
    },
    applicationSelectedClear: (state) => {
      state.application = initialState.application;
    },
    applicationSet: (state, action: PayloadAction<ApplicationType['name']>) => {
      const applicationName = action.payload;
      state.application = state.applications.find(
        (application) => application.name === applicationName
      );
    },
    applicationSetFilter: (
      state,
      action: PayloadAction<Partial<ApplicationsFilter>>
    ) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    applicationListForSelectSuccess: (
      state,
      action: PayloadAction<ApplicationType[]>
    ) => {
      state.applicationsForSelect = action.payload;
    },
  },
});

export default applicationSlice.reducer;

// Action Creators

export const {
  applicationConsumptionHistoryListSuccess,
  applicationDeviceUserSet,
  applicationDeviceUserClear,
  applicationDeviceUsersListSuccess,
  applicationListSuccess,
  applicationSet,
  applicationSetFilter,
  applicationListForSelectSuccess,
} = applicationSlice.actions;

export function listApplications(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listApplicationConsumptionHistory(
  queryParameters: ListQueryParameters & {
    applicationName: string;
    userId?: string | number;
  },
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_APPLICATION_CONSUMPTION_HISTORY,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listApplicationDeviceUsers(
  queryParameters: ListQueryParameters & { applicationName: string },
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_APPLICATION_DEVICE_USERS,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function checkInstallApplication(data) {
  return {
    type: Types.CHECK_INSTALL_APPLICATION,
    payload: data,
  };
}

export function checkUninstallApplication(data) {
  return {
    type: Types.CHECK_UNINSTALL_APPLICATION,
    payload: data,
  };
}

export const listApplicationsOptionsForSelect = createAction(
  Types.LIST_FOR_SELECT,
  (filters?: Record<string, unknown>) => ({
    payload: { filters: sanitizeFilter(filters) },
  })
);
