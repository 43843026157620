import { IntlShape } from 'react-intl';

import { CardContainerProps } from '../../../AccordionContainer';

export function handleAlphabeticalSort(
  accordionItemA: CardContainerProps,
  accordionItemB: CardContainerProps,
  intl: IntlShape
) {
  const a = intl.formatMessage({ id: accordionItemA.title });
  const b = intl.formatMessage({ id: accordionItemB.title });
  return a.localeCompare(b);
}

export const defaultAccountTypesOptions = [
  'com.samsung.android.email',
  'com.samsung.android.exchange',
  'com.samsung.android.ldap',
  'com.osp.app.signin',
  'com.google',
  'com.google.android.gm.legacyimap',
  'com.google.android.gm.pop3',
  'com.google.android.gm.exchange',
  'com.google.work',
  'com.facebook.auth.login',
].map((accountType) => {
  return {
    label: accountType,
    value: accountType,
  };
});
