import { Checkbox } from '@chakra-ui/checkbox';
import { Flex, Grid, GridItem, VStack } from '@chakra-ui/layout';
import { ChangeEventHandler, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { featurePlanKeys } from '../../../../../../../helper/plan';
import { usePlan } from '../../../../../../../hooks/usePlan';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../hooks/useRedux';
import {
  initializeAppWorkTime,
  setSelectedAppWorkTimes,
} from '../../../../../../../store/policy';
import { AppWorkTime } from '../../../../../../../types/policy';
import Button from '../../../../../../Button';
import Modal from '../../../../../../Modal';
import ModalTemplate from '../../../../../../Modal/ModalTemplate';
import { PlanWarning } from '../../../../../../PlanWarning';
import {
  daysOfWeekList,
  initialWorkTimeHours,
} from '../../PolicySettings/utils';
import { WorkTimeDay } from './BlockTimeDay';
import { Header } from './Header';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const blockTimeModalIntlKey = `update_policies.applications.blockTimeModal`;

export const ModalBlockTime = ({ onClose, isOpen }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { selectedApp } = useAppSelector((state) => state.policy);
  const defaultWorkTimes = useMemo<AppWorkTime[]>(() => {
    return daysOfWeekList.map<AppWorkTime>((dayOfWeek) => ({
      blocked: false,
      dayOfWeek,
      endTime: initialWorkTimeHours.end,
      startTime: initialWorkTimeHours.start,
    }));
  }, []);
  const [enableWorkTime, setEnableWorkTime] = useState(
    !!selectedApp?.workTimes?.length || false
  );
  const [workTimes, setWorkTimes] = useState(
    selectedApp?.workTimes?.length ? selectedApp?.workTimes : defaultWorkTimes
  );
  const { hasFeaturePlan } = usePlan({
    featurePlanKey: featurePlanKeys.allowLocalization,
  });

  const disabled = !enableWorkTime || !hasFeaturePlan;

  const handleChangeEnableWorkTime: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEnableWorkTime(event.target.checked);
    dispatch(initializeAppWorkTime({ initialize: event.target.checked }));
  };

  const handleChangeWorkTime = (newWorkTime: AppWorkTime) => {
    setWorkTimes((prev) =>
      prev.map((mapWorkTime) =>
        mapWorkTime.dayOfWeek === newWorkTime.dayOfWeek
          ? newWorkTime
          : mapWorkTime
      )
    );
  };

  const handleConfirmModal = () => {
    dispatch(setSelectedAppWorkTimes(enableWorkTime ? workTimes : null));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} modalWidth="900px">
      <ModalTemplate
        title={intl.formatMessage({ id: `${blockTimeModalIntlKey}.title` })}
        description={
          <>
            {intl.formatMessage({
              id: `${blockTimeModalIntlKey}.description`,
            })}

            {!hasFeaturePlan && <PlanWarning mt={2} />}
          </>
        }
      >
        <VStack width="full" spacing={6}>
          <Grid templateColumns="4fr 2fr 10fr .3fr" gap={4} width="full">
            <GridItem colSpan={4} mb={2}>
              <Checkbox
                isChecked={enableWorkTime}
                isDisabled={!hasFeaturePlan}
                onChange={handleChangeEnableWorkTime}
              >
                {intl.formatMessage({
                  id: `${blockTimeModalIntlKey}.enableBlockTime`,
                })}
              </Checkbox>
            </GridItem>

            <GridItem>
              <Header>
                {intl.formatMessage({
                  id: `${blockTimeModalIntlKey}.table.header.dayOfWeek`,
                })}
              </Header>
            </GridItem>

            <GridItem>
              <Header>
                {intl.formatMessage({
                  id: `${blockTimeModalIntlKey}.table.header.block`,
                })}
              </Header>
            </GridItem>

            <GridItem>
              <Header>
                {intl.formatMessage({
                  id: `${blockTimeModalIntlKey}.table.header.blockTime`,
                })}
              </Header>
            </GridItem>

            <GridItem> </GridItem>

            {workTimes.map((workTime) => (
              <WorkTimeDay
                workTime={workTime}
                key={workTime.dayOfWeek}
                disabled={disabled}
                onChange={handleChangeWorkTime}
              />
            ))}
          </Grid>

          <Flex w="full" gridGap={4} justifyContent="flex-end">
            <Button width="180px" variant="outline" h="45px" onClick={onClose}>
              {intl.formatMessage({
                id: `${blockTimeModalIntlKey}.cancel`,
              })}
            </Button>

            <Button width="180px" h="45px" onClick={handleConfirmModal}>
              {intl.formatMessage({
                id: `${blockTimeModalIntlKey}.confirm`,
              })}
            </Button>
          </Flex>
        </VStack>
      </ModalTemplate>
    </Modal>
  );
};
