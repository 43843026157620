import React, { useEffect, useState } from 'react';

import { toggleCheckbox } from '../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { adminUserSelected } from '../../../../store/adminUser';
import { listGroupsToFilter } from '../../../../store/group';
import { ID } from '../../../../types/util';
import TabManager from './TabManager';

const GroupManager = () => {
  const dispatch = useAppDispatch();
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const { adminUser } = useAppSelector((state) => state.adminUser);

  const [input, setInput] = useState('');

  useEffect(() => {
    dispatch(listGroupsToFilter({ search: input }));
  }, [input]);

  const handleChance = (groupId: ID) => {
    dispatch(
      adminUserSelected({
        groupIds: toggleCheckbox(adminUser?.groupIds, groupId),
      })
    );
  };

  return (
    <TabManager
      values={groupsToFilter}
      ids={adminUser?.groupIds}
      handleChance={handleChance}
      setInput={setInput}
    />
  );
};

export default GroupManager;
