import { Body, BodyCell, Header } from '../Table/TableInterfaces';

export function tableToExportObject(data: Body[], columns: Header[]) {
  return data.map((row) => {
    const excludeColumns = ({ isExportHidden }: BodyCell) => isExportHidden;
    return row.cells
      .map((cell, columnIndex) => {
        const columnTitle = (columns: Header[], index: number) =>
          columns[index]?.header || '';
        return {
          title: columnTitle(columns, columnIndex),
          value: cell.value,
          hidden: excludeColumns(cell),
        };
      })
      .filter(({ hidden }) => !hidden);
  });
}

export function exportObjectToExcel(
  data: {
    title: string;
    value: unknown;
  }[][]
) {
  return data.map((row) => {
    const rowToExcel = row.map(({ title, value }) => {
      return value
        ? {
            [title]: value,
          }
        : { [title]: '' };
    });
    return rowToExcel.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  });
}

export const exportObjectToText = (
  exportObject: {
    title: string;
    value: unknown;
  }[][]
) => {
  const titlesText = exportObject[0].map((column) => ({
    ...column,
    value: column.title,
  }));
  return [titlesText, ...exportObject]
    .map((row) => row.map(({ value }) => value).join('\t'))
    .join('\n');
};
