import { PayloadAction } from '@reduxjs/toolkit';
import {
  takeLatest,
  call,
  put,
  takeEvery,
  delay,
  select,
} from 'redux-saga/effects';

import { RootState } from '..';

import { POLLING_TIME_REQUEST_MS } from '../../helper';
import { ErrorToaster } from '../../helper/error';
import { sanitizeFilter } from '../../helper/filter';
import { ListPayload, QuerysWithFilters } from '../../types/generic_list';
import { GeolocationReverseType, LocationType } from '../../types/locations';
import { ID } from '../../types/util';
import Api from '../api/Api';
import { startLocalizationPolling, stopLocalizationPolling } from '../event';
import {
  locationListSuccess,
  locationGeolocationListSuccess,
  locationGeolocationReverseSuccess,
  Types,
  locationCreateIdSuccess,
  stopForTimeoutLocalizationPolling,
  listGeolocationReverse,
  clearStoreLocalization,
  locationListHistoriesSuccess,
  locationGetAddressSuccess,
  getUsersOptionsSuccess,
} from '../location';
import {
  uiAddSuccess,
  uiAddError,
  uiAddMetadata,
  uiAddEmpty,
  uiRemoveEmpty,
  uiRemoveError,
} from '../ui';
import { api, apiMaps, safe, requestList, requestSimple } from './util';

function* handleListLast({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'locationLastList', type, locationListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleListGeolocation({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  const list: LocationType[] = yield call(api, 'locationGeolocationList', {
    ...queryParameters,
    ...filters,
  });
  if (list.length === 0) {
    yield put(
      uiAddError({ [type]: new ErrorToaster('global.generic_not_found') })
    );
  }
  yield put(locationGeolocationListSuccess(list));
}

function* handleListHistoric({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield put(uiAddMetadata({ [type]: queryParameters }));
  const { items, ...pagination }: ListPayload<LocationType> = yield call(
    api,
    'listLocationHistoric',
    { queryParameters, filters }
  );
  yield put(locationListHistoriesSuccess(items));
  const reqGeolocationReverse = items
    .filter((item) => !item.address)
    .map((item) =>
      listGeolocationReverse(item.latitude, item.longitude, item.id, item.date)
    );

  for (const index in reqGeolocationReverse) {
    yield put(reqGeolocationReverse[index]);
  }

  if (items.length === 0) {
    yield put(uiAddEmpty(type));
  } else {
    yield put(uiRemoveEmpty(type));
  }
  yield put(uiAddMetadata({ [type]: pagination }));
}

function* handleListLocalizations({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  const locations: LocationType[] = yield call(api, 'listLocalizations', {
    ...queryParameters,
    ...filters,
  });
  if (locations.length === 0) {
    yield put(uiAddEmpty(type));
  } else {
    yield put(uiRemoveEmpty(type));
  }
  yield put(locationListSuccess(locations));
}

export function* handlePollingLocalizationRealtime() {
  while (true) {
    try {
      const id = yield select((state: RootState) => state.location.poolingId);
      const locations: LocationType = yield call(
        api,
        'listLocalizationsRealTime',
        id
      );
      yield put(locationGeolocationListSuccess([locations]));
      if (locations) {
        yield put(uiAddSuccess(Types.CREATE_LOCALIZATION_REALTIME_ID));
        yield put(uiRemoveError(Types.CREATE_LOCALIZATION_REALTIME_ID));
        yield put(uiRemoveEmpty(Types.CREATE_LOCALIZATION_REALTIME_ID));
      } else {
        yield put(
          uiAddError({
            [Types.CREATE_LOCALIZATION_REALTIME_ID]: new ErrorToaster(
              'geolocalization.realtime.not_found'
            ),
          })
        );
      }
      yield put(stopLocalizationPolling());
    } catch (e) {
      yield delay(POLLING_TIME_REQUEST_MS);
    }
  }
}

export function* timeoutLocalization() {
  const dateToStop = yield select(
    (state: RootState) => state.location.dateToStop
  );
  while (new Date() < dateToStop) {
    yield delay(POLLING_TIME_REQUEST_MS);
  }
  yield put(stopForTimeoutLocalizationPolling());
}

function* handleTimeout() {
  yield put(
    uiAddError({
      [Types.CREATE_LOCALIZATION_REALTIME_ID]: new ErrorToaster(
        'geolocalization.realtime.time_exceeded'
      ),
    })
  );
  yield put(uiAddEmpty(Types.CREATE_LOCALIZATION_REALTIME_ID));
  yield put(clearStoreLocalization());
}

function* handleCreateLocalizationsRealTimeId({
  payload: id,
}: PayloadAction<ID>) {
  const data = yield call(api, 'createLocalizationsRealTimeId', {
    userId: id,
  });
  yield put(locationCreateIdSuccess(data));
  yield put(startLocalizationPolling());
}

function* handleListGeolocationReverse({ payload: { lat, lon, id, date } }) {
  const geolocationReverse: GeolocationReverseType =
    yield yield handleGetGeolocationReverse(lat, lon);

  const addressToUpdate: Partial<LocationType> = {
    id: id,
    address: formatGeolocationReverseToAddress(geolocationReverse),
    date,
  };

  yield put(locationGeolocationReverseSuccess(addressToUpdate));

  yield updateAddress(addressToUpdate, 'localizationUpdateAddress');
}

function* handleGetAddress({ payload }: PayloadAction<LocationType>) {
  const locationReverse: GeolocationReverseType =
    yield handleGetGeolocationReverse(payload.latitude, payload.longitude);
  const locationToUpdate: LocationType = {
    ...payload,
    address: formatGeolocationReverseToAddress(locationReverse),
  };

  yield put(locationGetAddressSuccess(locationToUpdate));
  yield updateAddress(locationToUpdate, 'localizationDeviceUpdateAddress');
}

function* updateAddress(
  partialLocation: Partial<LocationType>,
  endpoint?: keyof ReturnType<typeof Api>
) {
  yield call(api, endpoint, partialLocation);
}

const formatGeolocationReverseToAddress = (geolocation) => {
  const { road, suburb, city, country, state, postcode } = sanitizeFilter(
    geolocation.address
  );

  const addressObject = sanitizeFilter({
    road,
    suburb,
    city,
    state,
    postcode,
    country,
  });
  const arrAddress = Object.values(addressObject);
  return arrAddress.join(', ');
};

function* handleGetGeolocationReverse(lat: string, lon: string) {
  return yield call(apiMaps, 'listLocationGeolocationReverse', {
    lat,
    lon,
  });
}

function* handleGetUsersOptions({ type }: PayloadAction) {
  return yield call(
    requestSimple,
    api,
    'deviceUserSelect',
    type,
    getUsersOptionsSuccess,
    {}
  );
}

export default function* locationSaga() {
  yield takeLatest(Types.LIST_LAST, safe(handleListLast));
  yield takeLatest(Types.LIST_LOCALIZATION, safe(handleListLocalizations));
  yield takeLatest(
    Types.CREATE_LOCALIZATION_REALTIME_ID,
    safe(handleCreateLocalizationsRealTimeId)
  );
  yield takeLatest(Types.LIST_HISTORIC, safe(handleListHistoric));
  yield takeLatest(Types.LIST_GEOLOCALIZATION, safe(handleListGeolocation));
  yield takeEvery(
    Types.LIST_GEOLOCATION_REVERSE,
    safe(handleListGeolocationReverse)
  );
  yield takeLatest(Types.UPDATE, safe(updateAddress));
  yield takeLatest(Types.POLL_STOP_FOR_TIMEOUT, handleTimeout);
  yield takeLatest(Types.GET_ADDRESS, safe(handleGetAddress));
  yield takeLatest(Types.GET_USERS_OPTIONS, safe(handleGetUsersOptions));
}
