import React from 'react';

import { CardItemType } from '../../../../../CardContent';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getSharingSettings: GetterSettingFn = ({
  handleChange,
  policy,
}) => {
  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.sharing.allow_camera.title`,
      description: `${intlPolicySettingsKey}.sharing.allow_camera.description`,
      children: (
        <SwitchButton
          name="allowCamera"
          isChecked={policy?.allowCamera}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.sharing.allow_screen_shot.title`,
      description: `${intlPolicySettingsKey}.sharing.allow_screen_shot.description`,
      children: (
        <SwitchButton
          name="allowScreenShot"
          isChecked={policy?.allowScreenShot}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.sharing.allow_remote_screen_observation.title`,
      description: `${intlPolicySettingsKey}.sharing.allow_remote_screen_observation.description`,
      children: (
        <SwitchButton
          name="allowRemoteScreenObservation"
          isChecked={policy?.allowRemoteScreenObservation}
          onChange={handleChange}
        />
      ),
    },
  ];

  return listSettings;
};
