import { Box, useStyleConfig } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Skeleton from '../Skeleton';

interface CreatableSelectAutoCompleteProps
  extends React.ComponentProps<typeof CreatableSelect> {
  customStyles?: { [key: string]: string };
  isLoading?: boolean;
  customCreateLabel?: string;
}

const CreatableSelectAutoComplete = ({
  customStyles,
  isLoading,
  ...rest
}: CreatableSelectAutoCompleteProps) => {
  const style = useStyleConfig('ChakraReactSelect');

  return isLoading ? (
    <Skeleton h="45px" />
  ) : (
    <CreatableSelect
      chakraStyles={{
        ...style,
        container: (provided) => ({
          ...provided,
          ...customStyles,
        }),
      }}
      size={{ base: 'lg', height: '100%' }}
      isClearable
      components={{
        DropdownIndicator: null,
        NoOptionsMessage: () => (
          <Box m="0 8px">
            <FormattedMessage id="global.not_option_found" />
          </Box>
        ),
      }}
      placeholder={<FormattedMessage id="global.select_placeholder" />}
      formatCreateLabel={(input) => (
        <FormattedMessage
          id="global.creatable.add_new_option"
          values={{
            inputValue: input,
          }}
        />
      )}
      {...rest}
    />
  );
};

export default CreatableSelectAutoComplete;
