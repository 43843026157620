import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put } from 'redux-saga/effects';

import {
  DeviceInfoBatteryType,
  DeviceInfoStorageType,
} from '../../types/deviceInfo';
import { QuerysWithFilters } from '../../types/generic_list';
import { ID } from '../../types/util';
import {
  Types,
  deviceInfoBatteryListSuccess,
  deviceInfoStorageListSuccess,
  deviceInfoNonComplianceListSuccess,
} from '../deviceInfo';
import { api, requestList, safe } from './util';

function* handleListBattery({
  payload: { idDeviceInfo, filters },
}: PayloadAction<QuerysWithFilters & { idDeviceInfo: number }>) {
  const infos: DeviceInfoBatteryType[] = yield call(
    api,
    'deviceBatteryList',
    idDeviceInfo,
    { ...filters }
  );
  yield put(deviceInfoBatteryListSuccess(infos));
}

function* handleListStorage({
  payload: { idDeviceInfo, filters },
}: PayloadAction<QuerysWithFilters & { idDeviceInfo: number }>) {
  const infos: DeviceInfoStorageType[] = yield call(
    api,
    'deviceStorageList',
    idDeviceInfo,
    { ...filters }
  );
  yield put(deviceInfoStorageListSuccess(infos));
}

function* handleListNonCompliance({
  payload: { id, queryParameters, filters },
  type,
}: PayloadAction<{ id: ID } & QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'deviceNonComplianceList',
    type,
    deviceInfoNonComplianceListSuccess,
    id,
    {
      queryParameters,
      filters,
    }
  );
}

export default function* locationSaga() {
  yield takeLatest(Types.LIST_BATTERY, safe(handleListBattery));
  yield takeLatest(Types.LIST_STORAGE, safe(handleListStorage));
  yield takeLatest(Types.LIST_NON_COMPLIANCE, safe(handleListNonCompliance));
}
