import { Box, useRadioGroup } from '@chakra-ui/react';
import { timezoneFind, timezoneList } from 'portal-lib';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import InputPassword from '../../../components/InputPassword';
import PageErrors from '../../../components/PageErrors';
import GroupManager from '../../../components/pages/AdminUser/EditAdminUser/GroupManager';
import PageTitle from '../../../components/PageTitle';
import RadioButton from '../../../components/RadioButton';
import Select from '../../../components/Select';
import { idioms } from '../../../data/idioms';
import { PrivilegeEnum } from '../../../helper';
import { getModePermissions } from '../../../helper/mode';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  editAdminUser,
  getAdminUsers,
  adminUserSelected,
  createAdminUser,
  adminUserSelectedClear,
  Types,
  editAdminUserWithEmail,
} from '../../../store/adminUser';
import { hasSomeLoading, hasSomeSuccess } from '../../../store/ui';
import { AdminUserType } from '../../../types/adminUser';

type radioTabs = 'company' | 'group_manager';

const EditAdminUser = () => {
  const { accessFilterCompany, accessFilterGroup } = usePrivilege();
  const [selectedTab, setSelectedTab] = useState<radioTabs>('company');
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    user: { company },
  } = useAppSelector((state) => state.auth);
  const { adminUser, adminUserCopy } = useAppSelector(
    (state) => state.adminUser
  );
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const success = hasSomeSuccess([
    Types.CREATE,
    Types.EDIT,
    Types.EDIT_WITH_EMAIL,
  ]);
  const loadingPrimary = hasSomeLoading([
    Types.CREATE,
    Types.EDIT,
    Types.EDIT_WITH_EMAIL,
  ]);

  const { isCreate, isUpdate } = getModePermissions(id);

  const initialValues = {
    timezone: portalSettings.defaultTimeZone,
    language: 'pt-br',
  };

  const intl = useIntl();

  useEffect(() => {
    if (!adminUser.timezone) {
      dispatch(adminUserSelected({ timezone: initialValues.timezone }));
    }
    if (!adminUser.language) {
      dispatch(adminUserSelected({ language: initialValues.language }));
    }
  }, [adminUser.language, adminUser.timezone]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(getAdminUsers(parseInt(id)));
    }
    return () => {
      dispatch(adminUserSelectedClear());
    };
  }, [id, isUpdate]);

  useEffect(() => {
    if (success) {
      history.push(routes.adminUsers.manage);
    }
  }, [success]);

  useEffect(() => {
    setSelectedTab(
      ruleOptions.find(({ privilege }) => privilege === adminUser.privilege)?.id
    );
  }, [adminUser.privilege]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(adminUserSelected({ [e.target.name]: e.target.value }));
  };

  const handleInputChangeCustom = (name: string, value: unknown) => {
    dispatch(adminUserSelected({ [name]: value }));
  };

  const ruleOptions: {
    id: radioTabs;
    label: unknown;
    authorized: boolean;
    privilege: PrivilegeEnum;
  }[] = [
    {
      id: 'company',
      label: intl.formatMessage({ id: 'edit_admin.privilege_company' }),
      authorized: accessFilterCompany,
      privilege: PrivilegeEnum.COMPANY,
    },
    {
      id: 'group_manager',
      label: intl.formatMessage({ id: 'edit_admin.privilege_group' }),
      authorized: accessFilterGroup,
      privilege: PrivilegeEnum.GROUP,
    },
  ];

  useEffect(() => {
    if (isCreate) {
      const radioDefaultValueNewRegister = accessFilterCompany
        ? 'company'
        : 'group_manager';
      dispatch(
        adminUserSelected({
          privilege: ruleOptions.find(
            ({ id }) => id === radioDefaultValueNewRegister
          )?.privilege,
        })
      );
    }
  }, [accessFilterCompany, accessFilterGroup, isCreate]);

  const changeRadio = (value: radioTabs) => {
    dispatch(
      adminUserSelected({
        privilege: ruleOptions.find(({ id }) => id === value)?.privilege,
      })
    );
  };

  const { getRadioProps } = useRadioGroup({
    name: 'ruleOptions',
    onChange: changeRadio,
    value: selectedTab,
  });

  const renderTab = () => {
    switch (selectedTab) {
      case 'company':
        return <></>;
      case 'group_manager':
        return <GroupManager />;
    }
  };

  const handlePrimary = () => {
    const filledUser: AdminUserType = {
      ...adminUser,
      url: `${window.location.origin}${routes.passwordChange}`,
    };

    if (isCreate) {
      dispatch(createAdminUser(filledUser));
    } else {
      if (filledUser.email === adminUserCopy.email) {
        dispatch(editAdminUser(filledUser));
      } else {
        dispatch(editAdminUserWithEmail(filledUser));
      }
    }
  };

  const handleSecondary = () => {
    history.push(routes.adminUsers.manage);
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={
          isCreate ? (
            <FormattedMessage id="edit_admin.register" />
          ) : (
            <FormattedMessage id="edit_admin.user" />
          )
        }
        description={<FormattedMessage id="edit_admin.description_new" />}
      />
      <PageErrors
        toasterKeys={[Types.CREATE, Types.EDIT]}
        translateKey="edit_admin"
      />
      <FormContainer
        labelPrimary={
          isCreate ? (
            <FormattedMessage id="global.register" />
          ) : (
            <FormattedMessage id="global.update" />
          )
        }
        handlePrimary={handlePrimary}
        loadingPrimary={loadingPrimary}
        labelSecondary={<FormattedMessage id="global.cancel" />}
        handleSecondary={handleSecondary}
      >
        <Box d="flex" flexDirection="row">
          <FormControl textLabel={<FormattedMessage id="edit_admin.name" />}>
            <Input
              inputProps={{
                name: 'name',
                value: adminUser?.name || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_admin.federalCode" />}
          >
            <Input
              inputProps={{
                name: 'federalCode',
                value: adminUser?.federalCode || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="edit_admin.email" />}>
            <Input
              inputProps={{
                id: 'email-datamob',
                type: 'email',
                name: 'email-datamob',
                value: adminUser?.email || '',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChangeCustom('email', e.target.value),
              }}
            />
          </FormControl>
        </Box>
        <Box d="flex" flexDirection="row" mt="30px">
          <FormControl textLabel={<FormattedMessage id="edit_admin.company" />}>
            <Input
              inputProps={{
                backgroundColor: 'white',
                disabled: true,
                placeholder: 'Empresa',
                name: 'company',
                value: company?.name || '',
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_admin.timezone" />}
          >
            <Select
              name="timezone"
              value={
                timezoneFind(adminUser?.timezone)?.value ||
                initialValues.timezone
              }
              onChange={handleInputChange}
            >
              {timezoneList.map((timestamp) => (
                <option key={timestamp.value} value={timestamp.value}>
                  {timestamp.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="edit_admin.language" />}
          >
            <Select
              name="language"
              value={adminUser?.language || initialValues.language}
              onChange={handleInputChange}
            >
              {idioms.map((idiom) => (
                <option key={idiom.code} value={idiom.code}>
                  {idiom.text}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        {isUpdate && (
          <Box d="flex" flexDirection="row" mt="30px">
            <FormControl
              textLabel={<FormattedMessage id="edit_admin.password" />}
            >
              <InputPassword
                inputProps={{
                  autoComplete: 'new-password',
                  name: 'password-datamob',
                  value: adminUser?.password || '',
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChangeCustom('password', e.target.value),
                }}
              />
            </FormControl>
          </Box>
        )}
        <Box mt="3%">
          <Box d="flex" flexDirection="row" w="100%">
            {ruleOptions
              .filter((rule) => rule.authorized)
              .map((option) => {
                const radio = getRadioProps({
                  value: option.id,
                });
                return (
                  <RadioButton key={option.id} {...radio}>
                    {option.label}
                  </RadioButton>
                );
              })}
          </Box>
        </Box>
        {renderTab()}
      </FormContainer>
    </>
  );
};

export default EditAdminUser;
