import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { QuerysWithFilters } from '../../types/generic_list';
import { MessageType } from '../../types/message';
import { ID } from '../../types/util';
import {
  messageListSuccess,
  Types,
  messageCreateSuccess,
  messageDetailListSuccess,
  messageGetSuccess,
} from '../message';
import { api, requestList, requestSimple, safe } from './util';

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'messageList', type, messageListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleListDetails({
  payload: { id, queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters & { id: ID }>) {
  yield call(
    requestList,
    api,
    'messageListDetails',
    type,
    messageDetailListSuccess,
    id,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleCreation({ payload, type }: PayloadAction<MessageType>) {
  yield call(
    requestSimple,
    api,
    'messageCreate',
    type,
    messageCreateSuccess,
    payload
  );
}

function* handleGet({ payload, type }: PayloadAction<MessageType['id']>) {
  yield call(
    requestSimple,
    api,
    'messageGet',
    type,
    messageGetSuccess,
    payload
  );
}

export default function* messageSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.LIST_DETAILS, safe(handleListDetails));
  yield takeLatest(Types.CREATE, safe(handleCreation));
}
