import { Box, Checkbox } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Autocomplete, ID } from '../../../../types/util';
import FormSubtitle from '../../../FormSubtitle';
import PageActions from '../../../PageActions';

interface TabManagerProps {
  values: Autocomplete[];
  ids: ID[];
  handleChance: (id: ID) => void;
  setInput: (value: string) => void;
}

const TabManager = ({
  values,
  ids,
  handleChance,
  setInput,
}: TabManagerProps) => {
  const handleInputChange = (search: string) => {
    setInput(search);
  };

  return (
    <Box mt="2%">
      <FormSubtitle
        subtitle={<FormattedMessage id="edit_admin.group" />}
        description={<FormattedMessage id="edit_admin.group_description" />}
      >
        <PageActions onSearch={handleInputChange} disabledAllExportsActions />
      </FormSubtitle>
      <Box
        mt="2%"
        maxHeight="300px"
        overflowY="auto"
        d="flex"
        flexDirection="row"
        flexWrap="wrap"
        gridRowGap={15}
        gridColumnGap={15}
      >
        {values.map((value) => {
          return (
            <Checkbox
              key={value.id}
              color="gray.500"
              minWidth="360px"
              isChecked={ids?.includes(value.id)}
              onChange={() => handleChance(value.id)}
            >
              {value.name}
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export default TabManager;
