import { getRefreshToken } from '../auth';
import { RootState } from '../index';
import { VER1 } from './Api';
import ApiUtil from './ApiUtil';
import { axiosApi } from './axios';
import { endpoint } from './endpoint';

export default function ApiRefreshToken(state: RootState) {
  const { request } = ApiUtil(state);

  return {
    getNewToken,
  };

  function getNewToken() {
    return request(VER1, endpoint.refresh, {
      headers: {
        Authorization: `Bearer ${getRefreshToken(state)}`,
      },
      method: 'POST',
    });
  }
}

export async function postRefreshToken(refreshToken: string) {
  const { post } = axiosApi;
  return post(`${VER1}${endpoint.refresh}`, undefined, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ContentType: 'application/json',
      Authorization: `Bearer ${refreshToken}`,
    },
  });
}
