import {
  Text as TextChakra,
  TextProps as TextPropsChakra,
  useStyleConfig,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface TextProps extends TextPropsChakra {
  children: ReactNode;
}

const Text = ({ children, ...rest }: TextProps) => {
  const style = useStyleConfig('Text');

  return (
    <TextChakra
      m="2% 0% 3% 0%"
      __css={{
        ...style,
      }}
      {...rest}
    >
      {children}
    </TextChakra>
  );
};

export default Text;
