import { Flex, Text } from '@chakra-ui/layout';
import { Checkbox, Tooltip } from '@chakra-ui/react';
import _ from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';

import Alert, { AlertHtml } from '../../../components/Alert';
import Button from '../../../components/Button';
import EditableField from '../../../components/EditableField';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import AlertIcon from '../../../components/Icons/Alert';
import Search from '../../../components/Icons/Search';
import Input from '../../../components/Input';
import { backofficeInvoiceDeviceStatusToIntl } from '../../../components/pages/ZeroTouch/utils';
import { apiErrorMessagesByResponse } from '../../../components/pages/ZeroTouch/zeroTouchErros';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import { Body, Header } from '../../../components/Table/TableInterfaces';
import { TextCopy } from '../../../components/TextCopy';
import { DEBOUNCE_TIME } from '../../../helper';
import { useFilter } from '../../../hooks/useFilter';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import { history } from '../../../store/history';
import {
  approvedDevices,
  getBackofficeInvoice,
  rejectedDevices,
  setBackofficeDeviceObservation,
  Types,
} from '../../../store/invoice';
import { hasError, hasLoading, hasSomeSuccess } from '../../../store/ui';
import {
  InvoiceDeviceStatusEnum,
  InvoiceDeviceType,
} from '../../../types/invoice';

interface Filters {
  search: string;
  status?: { id: InvoiceDeviceStatusEnum; name: string } | null;
}

enum AlertActionEnum {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}
const ZeroTouchRegisterDetails = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { backofficeInvoice } = useAppSelector((state) => state.invoice);

  const [filters, setFilters] = useState<Filters>({ search: '' });
  const [selectedDevices, setSelectedDevices] = useState<InvoiceDeviceType[]>(
    []
  );

  const OBSERVATION_MAX_LENGTH = 140;

  const listApiError =
    hasError(Types.GET_BACKOFFICE_INVOICE) ||
    hasError(Types.REJECT_DEVICES) ||
    hasError(Types.APPROVE_DEVICES);
  const actionReleaseSuccess = hasSomeSuccess([
    Types.APPROVE_DEVICES,
    Types.REJECT_DEVICES,
  ]);

  const isRejectLoading = hasLoading(Types.REJECT_DEVICES);
  const isApproveLoading = hasLoading(Types.APPROVE_DEVICES);

  const checkboxInvoiceDeviceStatusActive = [
    InvoiceDeviceStatusEnum.WAITING,
    InvoiceDeviceStatusEnum.DISAPPROVE,
  ];

  const { data: filteredDevices } = useFilter(
    backofficeInvoice?.devices || [],
    {
      filter: (query) =>
        query
          .textSearch(['model', 'manufacturer', 'imei'], filters.search)
          .equals('status', filters?.status?.id || undefined),
    }
  );
  const totalFilteredWaiting = useMemo(
    () =>
      filteredDevices?.filter(
        (device) => device.status === InvoiceDeviceStatusEnum.WAITING
      )?.length || 0,
    [filteredDevices]
  );

  const columns: Header[] = [
    {
      accessor: 'checkbox',
      header: '',
      transform: () => (
        <Flex alignItems="center">
          <Checkbox
            isDisabled={totalFilteredWaiting === 0}
            isChecked={
              selectedDevices.length
                ? selectedDevices.length === totalFilteredWaiting
                : false
            }
            onChange={handleChangeAllDevices}
          />
        </Flex>
      ),
    },
    {
      accessor: 'model',
      header: intl.formatMessage({
        id: 'zero_touch_register_details.column.model',
      }),
    },
    {
      accessor: 'manufacturer',
      header: intl.formatMessage({
        id: 'zero_touch_register_details.column.manufacturer',
      }),
    },
    {
      accessor: 'imei',
      header: intl.formatMessage({
        id: 'zero_touch_register_details.column.imei',
      }),
    },
    {
      accessor: 'status',
      header: intl.formatMessage({
        id: 'zero_touch_register_details.column.status',
      }),
    },
    {
      accessor: 'observation',
      header: intl.formatMessage({
        id: 'zero_touch_register_details.column.observation',
      }),
    },
  ];
  const rows: Body[] =
    filteredDevices?.map((device, deviceIndex) => ({
      key: String(device?.deviceId),
      cells: [
        {
          field: 'checkbox',
          value: '',
          transform: () => (
            <Flex alignItems="center">
              <Checkbox
                isDisabled={
                  !checkboxInvoiceDeviceStatusActive.includes(device.status)
                }
                isChecked={
                  !!selectedDevices.find(
                    (mapDevice) => mapDevice.productId === device.productId
                  )
                }
                onChange={handleCheckboxChange(device)}
              />
            </Flex>
          ),
        },
        {
          field: 'model',
          value: device.model || '-',
        },
        {
          field: 'manufacturer',
          value: device.manufacturer || '-',
        },
        {
          field: 'imei',
          value: device.imei || '-',
        },
        {
          field: 'status',
          value: '',
          transform: () =>
            backofficeInvoiceDeviceStatusToIntl[device.status] ? (
              <Flex gridGap={2}>
                {intl.formatMessage({
                  id: `${backofficeInvoiceDeviceStatusToIntl[device.status]}`,
                })}
                {device.status === InvoiceDeviceStatusEnum.DISAPPROVE &&
                  device?.statusError && (
                    <Tooltip
                      hasArrow
                      arrowSize={8}
                      bg="warning.500"
                      label={device?.statusError}
                      cursor="pointer"
                    >
                      <Flex>
                        <AlertIcon boxSize={5} />
                      </Flex>
                    </Tooltip>
                  )}
              </Flex>
            ) : (
              ''
            ),
        },
        {
          field: 'observation',
          value: '',
          transform: () => (
            <Flex width="22rem">
              <EditableField
                width="100%"
                disableEdit={
                  !checkboxInvoiceDeviceStatusActive.includes(device.status)
                }
                onSubmit={(newObservation) => {
                  handleSetObservation(deviceIndex, newObservation);
                  handleUpdateSelectedDevices({
                    ...device,
                    observation: newObservation,
                  });
                }}
                defaultValue={device?.observation}
                maxCharacterLength={OBSERVATION_MAX_LENGTH}
              />
            </Flex>
          ),
        },
      ],
    })) || [];

  const handleUpdateSelectedDevices = (newDevice: InvoiceDeviceType) => {
    setSelectedDevices((prev) =>
      prev.map((mapDevice) =>
        mapDevice.productId === newDevice.productId ? newDevice : mapDevice
      )
    );
  };

  const handleSetFilter = (field: keyof Filters) => (value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleCheckboxChange =
    (device: InvoiceDeviceType) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedDevices((prev) => [...prev, device]);
      } else {
        setSelectedDevices((prev) =>
          prev.filter((mapDevice) => mapDevice.productId !== device.productId)
        );
      }
    };

  const handleClearDevices = () => setSelectedDevices([]);

  const handleChangeAllDevices = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedDevices(
        filteredDevices.filter(
          (device) => device.status === InvoiceDeviceStatusEnum.WAITING
        )
      );
    } else {
      handleClearDevices();
    }
  };

  const handleSetObservation = (
    deviceIndex: number,
    newObservation?: string
  ) => {
    dispatch(
      setBackofficeDeviceObservation({ deviceIndex, value: newObservation })
    );
  };

  function handleApprove() {
    dispatch(approvedDevices({ invoiceId: id, devices: selectedDevices }));
  }

  function handleReject() {
    dispatch(rejectedDevices({ invoiceId: id, devices: selectedDevices }));
  }

  const alertActionByMode = {
    [AlertActionEnum.APPROVE]: {
      textConfirmButton:
        'zero_touch_register_details.alert.confirm_button.approve',
      messageAlert: 'zero_touch_register_details.alert.message_alert.approve',
      handleConfirmButton: handleApprove,
    },
    [AlertActionEnum.REJECT]: {
      textConfirmButton: 'zero_touch_register_details.alert.reject_button',
      messageAlert: 'zero_touch_register_details.alert.message_alert.reject',
      handleConfirmButton: handleReject,
    },
  };
  function handleShowAlert(alertMode: AlertActionEnum) {
    return Alert({
      onCancel: () => {
        return;
      },
      onConfirm: () => alertActionByMode[alertMode].handleConfirmButton(),
      html: (
        <AlertHtml
          text={intl.formatMessage({
            id: alertActionByMode[alertMode].messageAlert,
          })}
        />
      ),
      confirmButtonText: intl.formatMessage({
        id: alertActionByMode[alertMode].textConfirmButton,
      }),
      allowOutsideClick: true,
      allowEscapeKey: true,
      cancelButtonText: intl.formatMessage({
        id: 'global.cancel',
      }),
    });
  }

  useEffect(() => {
    dispatch(getBackofficeInvoice(id));
  }, []);

  useEffect(() => {
    if (actionReleaseSuccess) {
      handleClearDevices();
    }
  }, [actionReleaseSuccess]);

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="zero_touch_register_details.title" />}
        description={
          <FormattedMessage id="zero_touch_register_details.description" />
        }
      />

      <PageShowToaster
        toasterKeys={[Types.REJECT_DEVICES]}
        disabledError
        messageSuccess={
          <FormattedMessage id="zero_touch_register_details.success.refused" />
        }
      />
      <PageShowToaster
        toasterKeys={[Types.APPROVE_DEVICES]}
        disabledError
        messageSuccess={
          <FormattedMessage id="zero_touch_register_details.success.approved" />
        }
      />

      <PageShowToaster
        toasterKeys={[
          Types.GET_BACKOFFICE_INVOICE,
          Types.REJECT_DEVICES,
          Types.APPROVE_DEVICES,
        ]}
        disabledSuccess
        messageError={
          <FormattedMessage
            id={
              apiErrorMessagesByResponse[listApiError?.message] ||
              apiErrorMessagesByResponse.default
            }
          />
        }
      />

      <FormContainer>
        <Flex direction="column">
          <Flex marginBottom=".5rem" justifyContent="space-between">
            <Flex>
              <Flex>
                <Text fontWeight="semibold">
                  {intl.formatMessage({
                    id: 'zero_touch_register_details.company_name',
                  })}
                  : &nbsp;
                </Text>
                <Text>{backofficeInvoice?.company?.corporateName || '-'}</Text>
              </Flex>
            </Flex>
            <Flex>
              <Button
                variant="ghost"
                onClick={() => history.push(routes.zeroTouch.backoffice.manage)}
                h="24px"
              >
                <FormattedMessage id="global.back" />
              </Button>
            </Flex>
          </Flex>

          <Flex marginBottom=".5rem">
            <Text fontWeight="semibold">
              {intl.formatMessage({
                id: 'zero_touch_register_details.federal_code',
              })}
              : &nbsp;
            </Text>
            <Text>{backofficeInvoice?.company?.federalCode || '-'}</Text>
          </Flex>

          <Flex>
            <Text fontWeight="semibold">
              {intl.formatMessage({
                id: 'zero_touch_register_details.access_key',
              })}
              : &nbsp;
            </Text>
            <TextCopy text={backofficeInvoice?.accessKey} />
          </Flex>
        </Flex>

        <Flex margin="2rem 0 2rem 1.5%" alignItems="flex-end">
          <FormControl w="23rem" marginLeft="1.5%">
            <Input
              defaultValue={filters.search}
              inputProps={{
                onChange: _.debounce(
                  (e) => handleSetFilter('search')(e.target.value),
                  DEBOUNCE_TIME
                ),
              }}
              leftElement={<Search boxSize={6} color="gray.600" />}
            />
          </FormControl>

          <FormControl w="15rem">
            <SelectAutocomplete
              value={filters?.status}
              onChange={handleSetFilter('status')}
              customStyles={{
                minH: '0',
              }}
              options={Object.entries(backofficeInvoiceDeviceStatusToIntl).map(
                ([key, value]) => ({
                  id: key,
                  name: intl.formatMessage({ id: value }),
                })
              )}
            />
          </FormControl>

          <Flex ml="auto" gridGap="1rem">
            <Button
              fontWeight="400"
              variant="outline"
              minW="176px"
              isLoading={isRejectLoading}
              disabled={!selectedDevices.length}
              onClick={() => handleShowAlert(AlertActionEnum.REJECT)}
            >
              {intl.formatMessage({
                id: 'zero_touch_register_details.actions.reject',
              })}
            </Button>

            <Button
              fontWeight="400"
              minW="176px"
              isLoading={isApproveLoading}
              disabled={!selectedDevices.length}
              onClick={() => handleShowAlert(AlertActionEnum.APPROVE)}
            >
              {intl.formatMessage({
                id: 'zero_touch_register_details.actions.approve',
              })}
            </Button>
          </Flex>
        </Flex>

        <Table
          headerColumns={columns}
          rows={rows}
          keyProp={Types.GET_BACKOFFICE_INVOICE}
          disabledActions
        />
      </FormContainer>
    </>
  );
};

export default ZeroTouchRegisterDetails;
