export const idioms = [
  {
    text: 'Português',
    code: 'pt-br',
  },
  {
    text: 'English',
    code: 'en',
  },
  {
    text: 'Español',
    code: 'es',
  },
];
