import { EntriesOptionsType } from '../../../../../../../../../types/policy';
import Select from '../../../../../../../../Select';
import { CommonProps } from './InputForDynamic';

interface Props extends CommonProps {
  entries?: EntriesOptionsType[];
}

export default function SelectForDynamic({
  name,
  onChange,
  entries,
  value,
  isDisabled,
}: Props) {
  return (
    <Select
      name={name}
      value={value}
      isDisabled={isDisabled}
      onChange={onChange}
    >
      {entries?.map((option) => (
        <option key={option?.value} value={option?.value}>
          {option?.name}
        </option>
      ))}
    </Select>
  );
}
