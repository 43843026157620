import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { DocumentType } from '../../types/document';
import { QuerysWithFilters } from '../../types/generic_list';
import { ID } from '../../types/util';
import {
  documentListSuccess,
  Types,
  documentCreateSuccess,
  documentDetailListSuccess,
  documentGetSuccess,
} from '../document';
import { api, requestList, requestSimple, safe } from './util';

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'documentList', type, documentListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleListDetails({
  payload: { id, queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters & { id: ID }>) {
  yield call(
    requestList,
    api,
    'documentListDetails',
    type,
    documentDetailListSuccess,
    id,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleCreation({ payload, type }: PayloadAction<DocumentType>) {
  yield call(
    requestSimple,
    api,
    'documentCreate',
    type,
    documentCreateSuccess,
    payload
  );
}

function* handleGet({ payload, type }: PayloadAction<DocumentType['id']>) {
  yield call(
    requestSimple,
    api,
    'documentGet',
    type,
    documentGetSuccess,
    payload
  );
}

export default function* documentSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.LIST_DETAILS, safe(handleListDetails));
  yield takeLatest(Types.CREATE, safe(handleCreation));
}
