import {
  Spinner as ChakraSpinner,
  SpinnerProps as chakraSpinnerProps,
} from '@chakra-ui/react';

const Spinner = ({ ...rest }: chakraSpinnerProps) => {
  return <ChakraSpinner color="primary.500" {...rest} />;
};

export default Spinner;
