import { FlexProps, Flex, AvatarBadgeProps, Box } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface Props {
  flexProps?: FlexProps;
  badgeProps?: AvatarBadgeProps;
  children: ReactElement;
  showBadge?: boolean;
}

export const IconBadge = ({
  flexProps,
  badgeProps,
  children,
  showBadge,
}: Props) => {
  return (
    <Flex w="min-content" position="relative" {...flexProps}>
      {children}

      {showBadge && (
        <Box
          position="absolute"
          w="6px"
          h="6px"
          right="-2px"
          top="-2px"
          borderRadius="full"
          bg="red.500"
          {...badgeProps}
        />
      )}
    </Flex>
  );
};
