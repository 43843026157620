import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import {
  ExternalAppsListType,
  ExternalAppData,
  ExternalAppType,
} from '../../types/externalApps';
import { ListQueryParameters } from '../../types/generic_list';

// Action Types

export const Types = {
  CREATE: 'externalApps/CREATE',
  LIST: 'externalApps/LIST',
  GET: 'externalApps/GET',
  UPDATE: 'externalApps/UPDATE',
};

// Reducer

interface ExternalAppsState {
  externalApps: ExternalAppsListType[];
  externalApp: ExternalAppType;
}

const initialState: ExternalAppsState = {
  externalApps: [],
  externalApp: {},
};

export const externalAppsSlice = createSlice({
  name: 'externalApps',
  initialState,
  reducers: {
    externalAppListSuccess: (
      state,
      action: PayloadAction<ExternalAppsListType[]>
    ) => {
      state.externalApps = action.payload;
    },
    externalAppListFilterSuccess: (
      state,
      action: PayloadAction<ExternalAppsListType[]>
    ) => {
      state.externalApps = action.payload;
    },
    externalAppGetSuccess: (state, action: PayloadAction<ExternalAppType>) => {
      state.externalApp = action.payload;
    },
  },
});
export default externalAppsSlice.reducer;

// Action Creators

export const {
  externalAppListSuccess,
  externalAppListFilterSuccess,
  externalAppGetSuccess,
} = externalAppsSlice.actions;

export function listExternalApps(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function getExternalApp(id: number) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function updateExternalApp(data: ExternalAppData) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function createExternalApp(data: ExternalAppData) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}
