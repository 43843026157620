import { Flex } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import {
  AutoUpdateModeEnum,
  PolicyApplicationType,
} from '../../../../../../../types/policy';
import CardContent, { CardItemType } from '../../../../../../CardContent';
import Input from '../../../../../../Input';
import Select from '../../../../../../Select';
import { intlPolicyAppsKey } from '../index';
import AppActionHeader from './components/AppActionHeader';

interface AppConfigProps {
  selectedApp: PolicyApplicationType;
  handleClose: () => void;
  handleChange?: (
    event: ChangeEvent<HTMLInputElement>,
    application?: PolicyApplicationType
  ) => void;
  handleChangeByFields?: (
    application: PolicyApplicationType,
    name: string,
    value: unknown
  ) => void;
}

const AppAdvancedConfig = ({
  handleClose,
  handleChange,
  handleChangeByFields,
  selectedApp,
}: AppConfigProps) => {
  const intl = useIntl();

  const options = [
    {
      label: intl.formatMessage({
        id: `${intlPolicyAppsKey}.app_advanced_config.auto_update_mode.default`,
      }),
      value: AutoUpdateModeEnum.AUTO_UPDATE_DEFAULT,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyAppsKey}.app_advanced_config.auto_update_mode.postponed`,
      }),
      value: AutoUpdateModeEnum.AUTO_UPDATE_POSTPONED,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyAppsKey}.app_advanced_config.auto_update_mode.high_priority`,
      }),
      value: AutoUpdateModeEnum.AUTO_UPDATE_HIGH_PRIORITY,
    },
  ];

  const listAdvancedSettings: CardItemType[] = [
    {
      title: `${intlPolicyAppsKey}.app_advanced_config.auto_update_mode.title`,
      description: `${intlPolicyAppsKey}.app_advanced_config.auto_update_mode.description`,
      children: (
        <Select
          name="autoUpdateMode"
          value={selectedApp?.autoUpdateMode}
          onChange={(event) => handleChange(event, selectedApp)}
        >
          {options.map((modeOption) => (
            <option key={modeOption.value} value={modeOption.value}>
              {modeOption.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicyAppsKey}.app_advanced_config.minimum_version_code.title`,
      description: `${intlPolicyAppsKey}.app_advanced_config.minimum_version_code.description`,
      children: (
        <Input
          flex="1"
          inputProps={{
            name: 'minimumVersionCode',
            value: selectedApp?.minimumVersionCode || '',
            onChange: (event) => {
              const { value, name } = event?.target;
              const number = value.replace(/[^0-9]/g, '');
              handleChangeByFields(
                selectedApp,
                name,
                value && !number
                  ? null
                  : number.length > 10
                  ? selectedApp?.minimumVersionCode
                  : number
              );
            },
          }}
        />
      ),
    },
  ];

  return (
    <Flex direction="column">
      <AppActionHeader
        companyApplication={selectedApp.companyApplication}
        handleClose={handleClose}
        title={'update_policies.applications.app_advanced_config.title'}
      />
      <CardContent cardItems={listAdvancedSettings} />
    </Flex>
  );
};

export default AppAdvancedConfig;
