import React from 'react';

import { RequirePasswordUnlockEnum } from '../../../../../../../types/policy';
import { CardItemType } from '../../../../../../CardContent';
import Input from '../../../../../../Input';
import Select from '../../../../../../Select';
import { intlPolicyPassword, PasswordPolicyChildrenProps } from './index';

export const getBiometricPasswordSettings = ({
  passwordPolicies,
  handleChange,
  intl,
}: PasswordPolicyChildrenProps) => {
  const requirePasswordUnlockOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.password_require.default`,
      }),
      value: RequirePasswordUnlockEnum.USE_DEFAULT_DEVICE_TIMEOUT,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyPassword}.select.password_require.every_day`,
      }),
      value: RequirePasswordUnlockEnum.REQUIRE_EVERY_DAY,
    },
  ];

  const biometricPasswordList: CardItemType[] = [
    {
      title: `${intlPolicyPassword}.passwordHistoryLength`,
      children: (
        <Input
          inputProps={{
            name: 'passwordHistoryLength',
            value: passwordPolicies?.passwordHistoryLength || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.maximumFailedPasswordsForWipe`,
      children: (
        <Input
          inputProps={{
            name: 'maximumFailedPasswordsForWipe',
            value: passwordPolicies?.maximumFailedPasswordsForWipe || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.passwordExpirationTimeout`,
      children: (
        <Input
          inputProps={{
            name: 'passwordExpirationTimeout',
            value: passwordPolicies?.passwordExpirationTimeout || '',
            onChange: handleChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyPassword}.requirePasswordUnlock`,
      children: (
        <Select
          name="requirePasswordUnlock"
          value={passwordPolicies?.requirePasswordUnlock}
          onChange={handleChange}
        >
          {requirePasswordUnlockOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
  ];

  return biometricPasswordList;
};
