import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import { CardContainerProps } from '../../../../../AccordionContainer';
import CardContent from '../../../../../CardContent';
import PolicyAccordions from '../../components/PolicyAccordions';
import PolicyFilterSettings from '../../components/PolicyFilterSettings';
import { handleAlphabeticalSort } from '../../policiesHelpers';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { handleFilterAccordionSettingsIntlKeys } from '../../utils';
import { getAssistantSettings } from './AssistantSettings';
import { getBlockScreenSettings } from './BlockScreenSettings';
import { getRestrictionSettings } from './RestrictionSettings';
import { getSharingSettings } from './SharingSettings';
import { getSyncBackupSettings } from './SyncBackupSettings';

export interface AccordionSettingsProps {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeByFields?: (
    name: string,
    value: unknown,
    checked?: boolean
  ) => void;
}

export const intlPolicySettingsKey = 'update_policies.settings.accordion';

const PolicySettings = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { policy } = useAppSelector((state) => state.policy);

  const [filter, setFilter] = useState<string>('');

  const handleSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = event?.target;
    handleSettingsByFields(name, value, checked);
  };

  const handleSettingsByFields = (
    name: string,
    value: unknown,
    checked?: boolean
  ) => {
    dispatch(
      policySelected({
        [name]: !!value ? value : checked,
      })
    );
  };

  function handlePropagatePropsToFunction(fn: GetterSettingFn) {
    return fn({
      handleChange: handleSettingsChange,
      handleChangeByFields: handleSettingsByFields,
      policy,
      intl,
    });
  }

  const accordionItems: CardContainerProps[] = [
    {
      title: `${intlPolicySettingsKey}.sharing`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getSharingSettings),
    },
    {
      title: `${intlPolicySettingsKey}.assistant`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getAssistantSettings),
    },
    {
      title: `${intlPolicySettingsKey}.sync_backup`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getSyncBackupSettings),
    },
    {
      title: `${intlPolicySettingsKey}.block_screen`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getBlockScreenSettings),
    },
    {
      title: `${intlPolicySettingsKey}.restriction`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getRestrictionSettings),
    },
  ]
    .filter((accordion) =>
      filter
        ? accordion?.options.some(
            (option) =>
              !option.isHidden &&
              handleFilterAccordionSettingsIntlKeys(
                [
                  option?.title,
                  option?.description,
                  option?.childrenItem?.title,
                  option?.childrenItem?.description,
                ],
                filter,
                intl
              )
          )
        : accordion
    )
    .sort((accordionItemA, accordionItemB) =>
      handleAlphabeticalSort(accordionItemA, accordionItemB, intl)
    );

  return (
    <Flex gridGap="6" flexDirection="column">
      <PolicyFilterSettings setFilter={setFilter} />
      <PolicyAccordions accordionList={accordionItems} searchWords={filter} />
    </Flex>
  );
};

export default PolicySettings;
