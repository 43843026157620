import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { DEBOUNCE_TIME } from '../../helper';
import { exportInClipboard, exportInExcel } from '../../helper/exports';
import { useAppSelector } from '../../hooks/useRedux';
import Button from '../Button';
import GenerateReportAlert from '../GenerateReportAlert';
import Copy from '../Icons/Copy';
import Download from '../Icons/Download';
import Search from '../Icons/Search';
import Input from '../Input';
import MenuActionButton from '../MenuActionButton';
import { convertToExcel } from './convertToExcel';
import {
  tableToExportObject,
  exportObjectToText,
} from './exportObjectConverts';
import { PageActionsProps } from './interfaces';
import WrapperExportAction from './WrapperExportAction';

const defaultProps = {
  labelButtonNew: <FormattedMessage id="global.register_new" />,
  initialSearch: '',
};

const PageActions: React.FC<PageActionsProps> = ({
  initialSearch,
  onSearch,
  excel,
  idDivTableForCopy,
  actionButtons,
  linkNew,
  labelButtonNew,
  disabledLinkNew,
  disabledAllExportsActions,
  disabledExportAction,
  labelPrimaryButton,
  onClickPrimaryButton,
  disabledPrimaryButton,
  isPrimaryButtonLoading,
  menuActionButton,
  isEmptyExportActions,
  emptyExportActionsMessage,
}: PageActionsProps) => {
  const intl = useIntl();
  const TOOLTIP_TIMEOUT = 2000;

  const [searchFilterUndebounced, setSearchFilterUndebounced] =
    useState(initialSearch);
  const setSearchFilterDebounced = useCallback(
    _.debounce((value) => onSearch(value), DEBOUNCE_TIME),
    []
  );
  const [isOpen, setIsOpen] = useState(false);

  const portalName = useAppSelector(
    (state) => state.portalSettings.portalSettings.name
  );

  const hasActionButtons = actionButtons?.length > 0;

  const handleExportPageToExcel = () => {
    return exportInExcel({
      dataInfo: convertToExcel(
        intl,
        excel.name,
        excel.tableData,
        excel.tableColumns,
        excel.tableMetadata
      ),
      name: `${intl.formatMessage({
        id: 'global.report',
      })} ${excel.name} - ${portalName}`,
    });
  };

  const handleTooltip = () => {
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), TOOLTIP_TIMEOUT);
  };

  const handleExport = () => {
    GenerateReportAlert({
      intl,
      handleFirstPageReport: handleExportPageToExcel,
      handleCompleteReport: excel.handleExportAllToExcel,
    });
  };

  const handleCopy = async () => {
    const textTable = exportObjectToText(
      tableToExportObject(excel.tableData, excel.tableColumns)
    );
    await exportInClipboard(textTable);
    handleTooltip();
  };

  return (
    <Box
      d="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      m="3% 0 3% 0"
    >
      <Box w="376px" ml="1.5%">
        {onSearch && (
          <Input
            inputProps={{
              'data-testid': 'search-input',
              backgroundColor: 'white',
              value: searchFilterUndebounced,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchFilterUndebounced(e.target.value);
                setSearchFilterDebounced(e.target.value);
              },
            }}
            leftElement={<Search boxSize={6} color="gray.600" />}
          />
        )}
      </Box>

      <Box alignSelf="center" mr="1.5%" d="flex" gridColumnGap="40px">
        {!disabledAllExportsActions && !disabledExportAction && (
          <WrapperExportAction
            hasTooltip={isEmptyExportActions}
            tooltipMessage={
              <FormattedMessage
                id={
                  isEmptyExportActions &&
                  !!emptyExportActionsMessage &&
                  emptyExportActionsMessage
                }
              />
            }
            disabled={!excel || isEmptyExportActions}
            onClick={handleExport}
          >
            <Download boxSize={6} />
            <FormattedMessage id="global.excel" />
          </WrapperExportAction>
        )}
        {!!idDivTableForCopy && !disabledAllExportsActions && (
          <WrapperExportAction
            hasTooltip={isOpen || isEmptyExportActions}
            isTooltipOpen={!isEmptyExportActions && isOpen}
            disabled={isEmptyExportActions}
            bg={isOpen && 'success.500'}
            tooltipMessage={
              <FormattedMessage
                id={
                  isEmptyExportActions && !!emptyExportActionsMessage
                    ? emptyExportActionsMessage
                    : 'global.copy.success'
                }
              />
            }
            onClick={handleCopy}
          >
            <Copy boxSize={6} color="primary.500" />
            <FormattedMessage id="global.copy" />
          </WrapperExportAction>
        )}
        {menuActionButton?.title && <MenuActionButton {...menuActionButton} />}
        {hasActionButtons &&
          actionButtons?.map((actionButton) => (
            <Button
              key={actionButton.id}
              variant="outline"
              h="45px"
              minW="176px"
              isLoading={actionButton?.isLoading}
              onClick={actionButton?.onClick}
              disabled={actionButton?.disabled}
            >
              {actionButton?.label}
            </Button>
          ))}
        {linkNew && (
          <Link to={linkNew}>
            <Button
              h="45px"
              w="176px"
              fontWeight="400"
              disabled={disabledLinkNew}
            >
              {labelButtonNew}
            </Button>
          </Link>
        )}
        {!!labelPrimaryButton && (
          <Button
            minW="176px"
            h="45px"
            fontWeight="400"
            isLoading={isPrimaryButtonLoading}
            disabled={disabledPrimaryButton}
            onClick={onClickPrimaryButton}
          >
            {labelPrimaryButton}
          </Button>
        )}
      </Box>
    </Box>
  );
};

PageActions.defaultProps = defaultProps;

export default PageActions;
