import React from 'react';

import { CardItemType } from '../../../../../CardContent';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getRestrictionSettings: GetterSettingFn = ({
  handleChange,
  policy,
}) => {
  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.restriction.force_limit_ad_tracking.title`,
      description: `${intlPolicySettingsKey}.restriction.force_limit_ad_tracking.description`,
      children: (
        <SwitchButton
          name="forceLimitAdTracking"
          isChecked={policy?.forceLimitAdTracking}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.allow_apple_personalized_advertising.title`,
      description: `${intlPolicySettingsKey}.restriction.allow_apple_personalized_advertising.description`,
      children: (
        <SwitchButton
          name="allowApplePersonalizedAdvertising"
          isChecked={policy?.allowApplePersonalizedAdvertising}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.allow_enterprise_app_trust.title`,
      description: `${intlPolicySettingsKey}.restriction.allow_enterprise_app_trust.description`,
      children: (
        <SwitchButton
          name="allowEnterpriseAppTrust"
          isChecked={policy?.allowEnterpriseAppTrust}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.allow_open_from_unmanaged_to_managed.title`,
      description: `${intlPolicySettingsKey}.restriction.allow_open_from_unmanaged_to_managed.description`,
      children: (
        <SwitchButton
          name="allowOpenFromUnmanagedToManaged"
          isChecked={policy?.allowOpenFromUnmanagedToManaged}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.force_air_drop_unmanaged.title`,
      description: `${intlPolicySettingsKey}.restriction.force_air_drop_unmanaged.description`,
      children: (
        <SwitchButton
          name="forceAirDropUnmanaged"
          isChecked={policy?.forceAirDropUnmanaged}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.allow_diagnostic_submission.title`,
      description: `${intlPolicySettingsKey}.restriction.allow_diagnostic_submission.description`,
      children: (
        <SwitchButton
          name="allowDiagnosticSubmission"
          isChecked={policy?.allowDiagnosticSubmission}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.force_watch_wrist_detection.title`,
      description: `${intlPolicySettingsKey}.restriction.force_watch_wrist_detection.description`,
      children: (
        <SwitchButton
          name="forceWatchWristDetection"
          isChecked={policy?.forceWatchWristDetection}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.allow_in_app_purchases.title`,
      description: `${intlPolicySettingsKey}.restriction.allow_in_app_purchases.description`,
      children: (
        <SwitchButton
          name="allowInAppPurchases"
          isChecked={policy?.allowInAppPurchases}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.restriction.force_itunes_store_password_entry.title`,
      description: `${intlPolicySettingsKey}.restriction.force_itunes_store_password_entry.description`,
      children: (
        <SwitchButton
          name="forceITunesStorePasswordEntry"
          isChecked={policy?.forceITunesStorePasswordEntry}
          onChange={handleChange}
        />
      ),
    },
  ];

  return listSettings;
};
