import { EnterpriseOriginEnum } from '../../../../../../types/enterpriseApps';
import {
  InstallTypeEnum,
  PolicyApplicationType,
  PolicyCompanyApplicationType,
} from '../../../../../../types/policy';

export const handleCreateApplicationObject = (
  companyApplication: PolicyCompanyApplicationType,
  installType: InstallTypeEnum,
  visible: boolean
): PolicyApplicationType => {
  return {
    companyApplicationId: companyApplication?.id,
    installType: installType,
    visible: visible,
    companyApplication,
  };
};

export const originsApplicationToOverwrite = [
  EnterpriseOriginEnum.SYSTEM,
  EnterpriseOriginEnum.MANUAL,
];
