import { Divider, Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import _ from 'lodash';
import React, { Fragment, ReactNode } from 'react';

import Card from '../Card';
import HighlightText from '../HighlightText';
import Text from '../Text';

export interface ChildrenItemType {
  title?: string;
  description?: string;
  isHidden?: boolean;
  isDisabled?: boolean;
  tooltipLabel?: string;
  children?: ReactNode;
  customStyles?: FlexProps;
}

export interface CardItemType {
  title: string;
  description?: string;
  children?: ReactNode;
  isHidden?: boolean;
  childrenItem?: ChildrenItemType;
  customStyles?: FlexProps;
  isDisabled?: boolean;
  tooltipLabel?: string;
}

export interface CardContentProps extends FlexProps {
  cardItems: CardItemType[];
  searchWord?: string;
}

const CardContent = ({ cardItems, searchWord, ...rest }: CardContentProps) => {
  const items = cardItems?.filter((cardItem) => !cardItem.isHidden);
  const TOTAL_ITEMS = items.length;

  return (
    <Card p="6" {...rest}>
      {items &&
        items.map(
          (
            {
              title,
              description,
              children,
              childrenItem,
              customStyles,
              isDisabled,
              tooltipLabel,
            },
            index
          ) => (
            <Fragment key={index}>
              <Tooltip
                label={tooltipLabel}
                isDisabled={_.isEmpty(tooltipLabel) || !isDisabled}
                hasArrow
              >
                <Flex flexDirection="column">
                  <Flex
                    align="center"
                    opacity={isDisabled && '50%'}
                    pointerEvents={isDisabled ? 'none' : 'auto'}
                    gridGap={10}
                    {...customStyles}
                  >
                    <Flex flexDirection="column" flex="2">
                      <Text m={0} fontWeight="medium">
                        <HighlightText
                          textToHighlight={title}
                          searchWord={searchWord}
                        />
                      </Text>
                      {description && (
                        <Text m={0} color="gray.50">
                          <HighlightText
                            textToHighlight={description}
                            searchWord={searchWord}
                          />
                        </Text>
                      )}
                    </Flex>
                    <Flex
                      flex="1"
                      align="center"
                      justify="flex-end"
                      pointerEvents={isDisabled ? 'none' : 'auto'}
                    >
                      {children}
                    </Flex>
                  </Flex>
                </Flex>
              </Tooltip>
              {!!childrenItem && (
                <Tooltip
                  label={childrenItem?.tooltipLabel}
                  isDisabled={
                    _.isEmpty(childrenItem?.tooltipLabel) ||
                    !childrenItem?.isDisabled
                  }
                  hasArrow
                >
                  <Flex flexDirection="column">
                    <Flex
                      align="center"
                      display={childrenItem?.isHidden ? 'none' : 'flex'}
                      mt={6}
                      opacity={childrenItem?.isDisabled && '50%'}
                      pointerEvents={childrenItem?.isDisabled ? 'none' : 'auto'}
                      gridGap={10}
                      {...childrenItem?.customStyles}
                    >
                      {!!childrenItem?.title && (
                        <Flex flexDirection="column" flex="2">
                          <Text m={0} fontWeight="medium">
                            <HighlightText
                              textToHighlight={childrenItem?.title}
                              searchWord={searchWord}
                            />
                          </Text>
                          {childrenItem?.description && (
                            <Text m={0} color="gray.50">
                              <HighlightText
                                textToHighlight={childrenItem?.description}
                                searchWord={searchWord}
                              />
                            </Text>
                          )}
                        </Flex>
                      )}

                      <Flex
                        flex="1"
                        align="center"
                        justify="flex-end"
                        opacity={isDisabled && '50%'}
                        pointerEvents={isDisabled ? 'none' : 'auto'}
                      >
                        {childrenItem?.children}
                      </Flex>
                    </Flex>
                  </Flex>
                </Tooltip>
              )}

              {index + 1 < TOTAL_ITEMS && <Divider my="8" />}
            </Fragment>
          )
        )}
    </Card>
  );
};

export default CardContent;
