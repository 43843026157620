import {
  SkeletonText as ChakraSkeletonText,
  Skeleton as ChakraSkeleton,
  SkeletonCircle as ChakraSkeletonCircle,
  SkeletonTextProps,
  SkeletonProps,
} from '@chakra-ui/react';

type SkeletonType = 'NORMAL' | 'TEXT' | 'CIRCLE';
interface SkeletonComponentProps extends SkeletonProps, SkeletonTextProps {
  type?: SkeletonType;
}

const Skeleton = ({ type = 'NORMAL', ...rest }: SkeletonComponentProps) => {
  const SkeletonTypes = {
    TEXT: ChakraSkeletonText,
    CIRCLE: ChakraSkeletonCircle,
    NORMAL: ChakraSkeleton,
  };

  const SkeletonComponent = SkeletonTypes[type];

  return <SkeletonComponent {...rest} />;
};

export default Skeleton;
