import { createAction, createSlice } from '@reduxjs/toolkit';

// Action Types

export const Types = {
  REPORT_START: 'event/REPORT_START',
  REPORT_GENERATE_EXCEL: 'event/REPORT_GENERATE_EXCEL',
  LOCALIZATION_START: 'event/LOCALIZATION_START',
  REPORT_STOP: 'event/REPORT_STOP',
  LOCALIZATION_STOP: 'event/LOCALIZATION_STOP',
};

// Reducer

const initialState = {};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {},
});

export default eventSlice.reducer;

// Action Creators

export const {} = eventSlice.actions;

export const startReportPolling = createAction(Types.REPORT_START);
export const startLocalizationPolling = createAction(Types.LOCALIZATION_START);

export const stopReportPolling = createAction(Types.REPORT_STOP);
export const stopLocalizationPolling = createAction(Types.LOCALIZATION_STOP);

export const generateExcel = createAction<Record<string, unknown>>(
  Types.REPORT_GENERATE_EXCEL
);
