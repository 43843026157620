import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, ForkEffect, select, put } from 'redux-saga/effects';

import { QuerysWithFilters } from '../../types/generic_list';
import { NetworkSettingsData, NetworkSettingsType } from '../../types/network';
import { ID } from '../../types/util';
import {
  networkSettingsGetSuccess,
  networkSettingsUpdateSuccess,
  networkSettingsListSuccess,
  Types,
  listNetworkSettings,
  networkOptionsForSelectSuccess,
} from '../network';
import { selectorMetadata, uiAddSuccess } from '../ui';
import { api, safe, requestList, requestSimple } from './util';

function* handleList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestList,
    api,
    'networkSettingsList',
    type,
    networkSettingsListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleGetNetworkSettings({ payload, type }: PayloadAction<ID>) {
  yield call(
    requestSimple,
    api,
    'networkSettingsGet',
    type,
    networkSettingsGetSuccess,
    payload
  );
}

function* handleCreateNetworkSettings({
  payload,
  type,
}: PayloadAction<NetworkSettingsType>) {
  yield call(
    requestSimple,
    api,
    'networkSettingsCreate',
    type,
    networkSettingsUpdateSuccess,
    payload
  );
}

function* handleUpdateNetworkSettings({
  payload,
  type,
}: PayloadAction<NetworkSettingsData>) {
  yield call(
    requestSimple,
    api,
    'networkSettingsUpdate',
    type,
    networkSettingsUpdateSuccess,
    payload.id,
    payload.networkSetting
  );
}

function* handleDeleteNetworkSettings({
  payload,
  type,
}: PayloadAction<NetworkSettingsData>) {
  yield call(
    requestSimple,
    api,
    'networkSettingsDelete',
    type,
    uiAddSuccess,
    payload
  );

  const metadata = yield select(selectorMetadata(Types.LIST));
  yield put(listNetworkSettings(metadata));
}

function* handleListOptionsForSelect({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'networkListSelect',
    type,
    networkOptionsForSelectSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

export default function* networkSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(
    Types.LIST_OPTIONS_FOR_SELECT,
    safe(handleListOptionsForSelect)
  );
  yield takeLatest(Types.GET, safe(handleGetNetworkSettings));
  yield takeLatest(Types.CREATE, safe(handleCreateNetworkSettings));
  yield takeLatest(Types.UPDATE, safe(handleUpdateNetworkSettings));
  yield takeLatest(Types.DELETE, safe(handleDeleteNetworkSettings));
}
