/* eslint-disable prefer-const */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { originsApplicationToOverwrite } from '../../components/pages/Policies/EditPolicies/Android/PolicyApps/utils';
import {
  DayOfWeekEnum,
  daysOfWeekList,
  initialWorkTimeHours,
} from '../../components/pages/Policies/EditPolicies/Android/PolicySettings/utils';
import { FIRST_METADATA } from '../../helper';
import { sanitizeFilter } from '../../helper/filter';
import { defaultAppKioskIconFontColor } from '../../helper/policy';
import { EnterpriseOriginEnum } from '../../types/enterpriseApps';
import { ListMetadata, ListQueryParameters } from '../../types/generic_list';
import { Plan } from '../../types/loginAdminUser';
import {
  AppWorkTime,
  BlockKeywordsType,
  EnrollmentTokenType,
  FreezePeriodType,
  ManageConfigurationInputType,
  ManageConfigurationInputTypeEnum,
  ManagedConfigurationsType,
  ManagementModeEnum,
  OperationalSystemEnum,
  PermissionGrantsType,
  PolicyAndroidType,
  PolicyApplicationType,
  PolicyIOSSupervisedActionPayload,
  PolicyIOSSupervisedFormDataType,
  PolicyIOSSupervisedType,
  PolicyIOSType,
  PolicyResponseType,
  PolicySystemApplicationType,
  PolicyToSelectType,
  PolicyType,
  RecommendedGlobalProxyType,
} from '../../types/policy';
import { PortalSettingsType } from '../../types/portalSettings';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  LIST: 'policy/LIST',
  CREATE: 'policy/CREATE',
  CREATE_IOS_SUPERVISED: 'policy/CREATE_IOS_SUPERVISED',
  GET_ENROLLMENT_TOKEN: 'policy/GET_ENROLLMENT_TOKEN',
  COPY: 'policy/COPY',
  DELETE: 'policy/DELETE',
  UPDATE: 'policy/UPDATE',
  UPDATE_IOS_SUPERVISED: 'policy/UPDATE_IOS_SUPERVISED',
  GET: 'policy/GET',
  GET_APP_SETTINGS: 'GET_APP_SETTINGS',
  LIST_POLICIES_TO_SELECT: 'policy/LIST_POLICIES_TO_SELECT',
  GET_IOS: 'policy/GET_IOS',
  GET_KIOSK_APP_CONFIGS: 'policy/GET_KIOSK_APP_CONFIGS',
  SET_KIOSK_APP_WALLPAPER: 'policy/SET_KIOSK_APP_WALLPAPER',
  GET_REMOTE_VIEW_APPLICATION: 'policy/GET_REMOTE_VIEW_APPLICATION',
};

// Reducer

export interface PoliciesState {
  policies: PolicyType[];
  enrollmentToken: EnrollmentTokenType;
  policy: PolicyType;
  copyPolicy: PolicyType;
  selectedApp: PolicyApplicationType;
  applicationsMetadata: ListMetadata;
  policiesToFilter: PolicyToSelectType[];
  disablePolicySave: Record<string, boolean>;
}

const initialState: PoliciesState = {
  policies: [],
  policy: null,
  enrollmentToken: {},
  copyPolicy: {},
  selectedApp: null,
  applicationsMetadata: FIRST_METADATA,
  policiesToFilter: [],
  disablePolicySave: {},
};

function findApplicationIndex(
  applications: PolicyApplicationType[],
  idToCompare: number
) {
  return applications.findIndex(
    (application) => application.companyApplication.id === idToCompare
  );
}

function sincronizePolicyAndApp(
  policy: PolicyType,
  selectedApp: PolicyApplicationType
) {
  const applicationIndex = findApplicationIndex(
    policy.applications,
    selectedApp.companyApplicationId
  );
  policy.applications[applicationIndex] = selectedApp;
}

export function systemApplicationToApplication(
  systemApplication: PolicySystemApplicationType
): PolicyApplicationType {
  const uniqueId = Number(_.uniqueId());
  return {
    ...systemApplication,
    companyApplicationId: systemApplication.enterpriseApplicationId || uniqueId,
    companyApplication: {
      id: systemApplication.enterpriseApplicationId || uniqueId,
      ...systemApplication,
    },
  };
}

export function applicationToSystemApplication(
  application: PolicyApplicationType
): PolicySystemApplicationType {
  return {
    enterpriseApplicationId: application.companyApplication.id,
    name: application.companyApplication.name,
    origin: application.companyApplication.origin,
    packageName: application.companyApplication.packageName,
    visible: application.visible,
    workTimes: application?.workTimes,
  };
}

function fillAndroidManualAndSystemApps(
  applications?: PolicySystemApplicationType[]
) {
  return (
    applications?.map((application) =>
      systemApplicationToApplication(application)
    ) || []
  );
}

function fillManagedConfiguration(
  configs: ManagedConfigurationsType[],
  setBundleArrayDefaultValue?: boolean
): ManagedConfigurationsType[] {
  return configs.map((config) => {
    if (_.isUndefined(config?.value) && !_.isUndefined(config.defaultValue)) {
      config.value = _.isArray(config.defaultValue)
        ? config.defaultValue
        : `${config.defaultValue}`;
    }

    if (config.type === ManageConfigurationInputTypeEnum.BUNDLE_ARRAY) {
      if (!setBundleArrayDefaultValue) return config;

      const newValue = config?.value || _.cloneDeep(config.nestedProperties);

      return {
        ...config,
        value: fillManagedConfiguration(
          newValue as ManagedConfigurationsType[]
        ),
      };
    }

    if (config.type === ManageConfigurationInputTypeEnum.BUNDLE) {
      const newValue = config?.value || _.cloneDeep(config.nestedProperties);

      return {
        ...config,
        value: fillManagedConfiguration(
          newValue as ManagedConfigurationsType[]
        ),
      };
    }

    return config;
  });
}

function fillPolicyAppConfigurationSuccess(
  app?: PolicyApplicationType
): PolicyApplicationType {
  return {
    ...app,
    managedConfigurations:
      app?.managedConfigurations &&
      fillManagedConfiguration(app.managedConfigurations),
  };
}

function fillAndroidPolicy(policy: PolicyType): PolicyType {
  const applications = [
    ...fillAndroidManualAndSystemApps(policy?.systemApplications),
    ...fillAndroidManualAndSystemApps(policy?.manualApplications),
    ...policy.applications,
  ];

  return {
    ...policy,
    applications,
    permittedInputMethods: policy?.permittedInputMethods?.sort() || [],
    permittedAccessibilityServices:
      policy?.permittedAccessibilityServices?.sort() || [],
    recommendedGlobalProxy: {
      ...(policy?.recommendedGlobalProxy || {}),
      type: policy?.recommendedGlobalProxy
        ? policy?.recommendedGlobalProxy?.pacUri
          ? RecommendedGlobalProxyType.AUTOMATIC
          : RecommendedGlobalProxyType.MANUAL
        : RecommendedGlobalProxyType.NONE,
    },
    startDayOfWork: policy?.startDayOfWork ?? DayOfWeekEnum.MONDAY,
    endDayOfWork: policy?.endDayOfWork ?? DayOfWeekEnum.SUNDAY,
  };
}

function fillPolicyByOperationalSystem(policy: PolicyResponseType): PolicyType {
  const { androidPolicy, iosPolicy, operationalSystem, ...rest } = policy;
  let policyFill: PolicyType;

  if (operationalSystem === OperationalSystemEnum.ANDROID) {
    policyFill = fillAndroidPolicy(androidPolicy);
  } else {
    policyFill = iosPolicy;
  }

  return { ...policyFill, operationalSystem, ...rest };
}

export function isPolicyIOSSupervised(
  policy: PolicyType
): policy is PolicyIOSType {
  return policy.managementMode === ManagementModeEnum.IOS_SUPERVISED;
}

function isPolicyIOS(policy: PolicyType): policy is PolicyIOSType {
  return policy.operationalSystem === OperationalSystemEnum.IOS;
}

export function isPolicyAndroid(
  policy: PolicyType
): policy is PolicyAndroidType {
  return policy.operationalSystem === OperationalSystemEnum.ANDROID;
}

export const fillBundleField = (
  nestedProperties: ManagedConfigurationsType[],
  value: ManagedConfigurationsType[],
  type: ManageConfigurationInputType
): ManagedConfigurationsType[] => {
  let newNestedProperties = _.cloneDeep(nestedProperties);

  if (type === ManageConfigurationInputTypeEnum.BUNDLE_ARRAY) {
    newNestedProperties = new Array(value?.length || 1).fill(
      nestedProperties[0]
    );
  }

  let newValue = _.merge(value || [], newNestedProperties);

  newValue = newValue.map((config) => {
    if (
      config.type === ManageConfigurationInputTypeEnum.BUNDLE ||
      config.type === ManageConfigurationInputTypeEnum.BUNDLE_ARRAY
    ) {
      return {
        ...config,
        value: !config.value
          ? config.value
          : fillBundleField(
              config.nestedProperties,
              config.value as ManagedConfigurationsType[],
              config.type
            ),
      };
    }

    return config;
  });

  return newValue;
};

function fillUpdateManagedConfigurations(
  managedConfigurations: ManagedConfigurationsType[]
) {
  return managedConfigurations.map((config) => {
    let data = { ...config };

    if (config.type === ManageConfigurationInputTypeEnum.HIDDEN)
      delete data.value;

    if (
      config.type === ManageConfigurationInputTypeEnum.MULTISELECT &&
      !(config?.value as (string | number)[])?.length
    )
      delete data.value;

    data.title && delete data.title;
    data.description && delete data.description;
    data.entries && delete data.entries;
    (data.defaultValue || _.isBoolean(data.defaultValue)) &&
      delete data.defaultValue;

    if (
      config.type === ManageConfigurationInputTypeEnum.BUNDLE ||
      config.type === ManageConfigurationInputTypeEnum.BUNDLE_ARRAY
    ) {
      delete data.nestedProperties;

      if (data.value) {
        data.value = fillUpdateManagedConfigurations(
          data.value as ManagedConfigurationsType[]
        );
      } else {
        delete data.value;
      }
    }

    return data;
  });
}

function fillUpdateAndroidApplication(
  application?: PolicyApplicationType
): PolicyApplicationType {
  let data = {
    ...application,
    companyApplication: {
      ...application.companyApplication,
      iconBase64: undefined,
    },
  };

  if (data.managedConfigurations) {
    data.managedConfigurations = fillUpdateManagedConfigurations(
      application.managedConfigurations
    );
  }

  return data;
}

function fillUpdateApplicationsAndroidPolicy(
  applicationsWithSystemApplications: PolicyApplicationType[]
): Partial<PolicyAndroidType> {
  const applications = applicationsWithSystemApplications.reduce(
    (accumulator, item) => {
      if (item.companyApplication.origin === EnterpriseOriginEnum.SYSTEM) {
        return {
          ...accumulator,
          systemApplications: [
            ...accumulator.systemApplications,
            applicationToSystemApplication(item),
          ],
        };
      }
      if (item.companyApplication.origin === EnterpriseOriginEnum.MANUAL) {
        return {
          ...accumulator,
          manualApplications: [
            ...accumulator.manualApplications,
            applicationToSystemApplication(item),
          ],
        };
      }

      return {
        ...accumulator,
        applications: [
          ...accumulator.applications,
          fillUpdateAndroidApplication(item),
        ],
      };
    },
    { applications: [], systemApplications: [], manualApplications: [] }
  );

  return applications;
}

export const fillUpdateAndroidPolicy = (
  policy: PolicyType,
  portalSettings: PortalSettingsType,
  plan: Plan
): PolicyType => {
  let newPolicy = policy;

  const applications = fillUpdateApplicationsAndroidPolicy(
    newPolicy?.applications
  );

  newPolicy = {
    ...newPolicy,
    ...applications,
  };

  newPolicy = _.cloneDeep(newPolicy);

  // Start fill to recommendedGlobalProxy
  const { type, pacUri, ...manualProxyConfig } = policy?.recommendedGlobalProxy;

  if (type === RecommendedGlobalProxyType.NONE) {
    newPolicy = {
      ...newPolicy,
      recommendedGlobalProxy: null,
    };
  } else if (type === RecommendedGlobalProxyType.AUTOMATIC) {
    newPolicy = {
      ...newPolicy,
      recommendedGlobalProxy: { pacUri: pacUri || '' },
    };
  } else {
    newPolicy = {
      ...newPolicy,
      recommendedGlobalProxy: {
        excludedHosts: manualProxyConfig?.excludedHosts || null,
        host: manualProxyConfig?.host || null,
        port: manualProxyConfig?.port || null,
      },
    };
  }
  // End fill to recommendedGlobalProxy

  //Start fill Android or Block SIM Policy
  if (
    policy.managementMode === ManagementModeEnum.BLOCK_SIM_ANDROID ||
    policy.managementMode === ManagementModeEnum.MANAGED_ANDROID
  ) {
    // Disable remote view config and remove remote view application if flag allowRemoteView is disabled in portal settings
    if (!portalSettings.allowRemoteView) {
      newPolicy = {
        ...newPolicy,
        remoteViewEnabled: false,
        applications: newPolicy.applications.filter(
          (app) =>
            app.companyApplication.packageName !==
            portalSettings.appRemoteViewPackageName
        ),
      };
    }
  }
  //End fill Android or Block SIM Policy

  // Start fill Android Kiosk App
  if (!!newPolicy?.kioskApplication) {
    if (plan && !plan?.allowUnlimitedKiosk) {
      delete newPolicy?.kioskApplication?.managedConfigurations;
    }
  }
  // End fill Android Kiosk App

  // Fill to all newPolicy object
  newPolicy = {
    ...newPolicy,
    networkIds: (policy?.policyNetworks || []).map((network) =>
      Number(network.id)
    ),
  };

  return newPolicy;
};

export const fillIOSSupervisedPolicy = (policy: PolicyIOSSupervisedType) => {
  const formData = new FormData();

  formData.append('file', policy?.profileFile || '');
  formData.append('name', policy.name);

  return formData;
};

export const fillKioskAppConfigurations = ({
  appKioskIconFontColorKey,
  kioskAppConfigs,
}: {
  kioskAppConfigs: PolicyApplicationType;
  appKioskIconFontColorKey: string;
}): PolicyApplicationType => {
  let filledManagedConfigs = kioskAppConfigs?.managedConfigurations.map(
    (config) =>
      config.key === appKioskIconFontColorKey
        ? { ...config, value: config.value || defaultAppKioskIconFontColor }
        : config
  );

  filledManagedConfigs = fillManagedConfiguration(filledManagedConfigs);

  return {
    ...kioskAppConfigs,
    managedConfigurations: filledManagedConfigs,
  };
};

export const policiesSlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    policyListClear: (state) => {
      state.policies = initialState.policies;
    },
    policyListSuccess: (state, action: PayloadAction<PolicyType[]>) => {
      state.policies = action.payload;
    },
    policySelectedClear: (state) => {
      state.policy = initialState.policy;
    },
    policyGetSuccess: (state, action: PayloadAction<PolicyResponseType>) => {
      const policyToFill = fillPolicyByOperationalSystem(action.payload);

      state.policy = policyToFill;
      state.copyPolicy = policyToFill;
      state.applicationsMetadata = {
        ...state.applicationsMetadata,
        totalItems: action.payload?.androidPolicy?.applications.length,
      };
    },
    policyUpdateSuccess: (state, action: PayloadAction<PolicyResponseType>) => {
      const policyToFill = fillPolicyByOperationalSystem(action.payload);

      const newPolicy: PolicyType = {
        ...policyToFill,
        ...(state?.policy?.kioskApplication
          ? { kioskApplication: state?.policy?.kioskApplication }
          : {}),
      };

      state.policy = newPolicy;
      state.copyPolicy = newPolicy;
    },
    policyCreateSuccess: (state, action: PayloadAction<PolicyType>) => {
      const policyToFill = fillPolicyByOperationalSystem(action.payload);

      state.policy = policyToFill;
      state.copyPolicy = policyToFill;
    },
    enrollmentTokenGetSuccess: (
      state,
      action: PayloadAction<EnrollmentTokenType>
    ) => {
      state.enrollmentToken = action.payload;
    },
    setEnrollmentToken: (state, action: PayloadAction<EnrollmentTokenType>) => {
      state.enrollmentToken = action.payload;
    },
    enrollmentTokenClear: (state) => {
      state.enrollmentToken = initialState.enrollmentToken;
    },
    setPolicyKioskInfos: (
      state,
      action: PayloadAction<{ name: string; value: unknown }>
    ) => {
      state.policy[action.payload.name] = action.payload.value;
    },
    policySelected: (state, action: PayloadAction<Partial<PolicyType>>) => {
      state.policy = { ...state.policy, ...action.payload };
    },
    policySetApplication: (
      state,
      action: PayloadAction<Partial<PolicyApplicationType>>
    ) => {
      state.selectedApp = action.payload;
    },
    policyApplicationSelected: (
      state,
      action: PayloadAction<Partial<PolicyApplicationType>>
    ) => {
      state.selectedApp = !!state.selectedApp && action.payload;
      sincronizePolicyAndApp(state.policy, action.payload);
    },
    policySetAppConfiguration: (
      state,
      action: PayloadAction<ManagedConfigurationsType>
    ) => {
      const index = state.selectedApp.managedConfigurations.findIndex(
        (config) => config.key === action.payload.key
      );
      state.selectedApp.managedConfigurations[index] = action.payload;
      sincronizePolicyAndApp(state.policy, state.selectedApp);
    },
    policySetKioskManagedConfiguration: (
      state,
      action: PayloadAction<ManagedConfigurationsType>
    ) => {
      const index =
        state.policy.kioskApplication.managedConfigurations.findIndex(
          (config) => config.key === action.payload.key
        );
      state.policy.kioskApplication.managedConfigurations[index] =
        action.payload;
    },
    policyAddSiteUrl: (
      state,
      action: PayloadAction<Partial<BlockKeywordsType[]>>
    ) => {
      const blockKeywordWrapper = isPolicyIOS(state.policy)
        ? state.policy
        : state.selectedApp;
      blockKeywordWrapper.blockKeywords = [
        ...blockKeywordWrapper?.blockKeywords,
        ...action.payload,
      ];

      if (isPolicyAndroid(state.policy)) {
        sincronizePolicyAndApp(state.policy, state.selectedApp);
      }
    },
    policyToggleBlockSiteUrl: (
      state,
      action: PayloadAction<BlockKeywordsType>
    ) => {
      const blockKeywordWrapper = isPolicyIOS(state.policy)
        ? state.policy
        : state.selectedApp;

      const index = blockKeywordWrapper.blockKeywords.findIndex(
        (config) => config.keyword === action.payload.keyword
      );
      blockKeywordWrapper.blockKeywords[index] = action.payload;

      if (isPolicyAndroid(state.policy)) {
        sincronizePolicyAndApp(state.policy, state.selectedApp);
      }
    },
    policyToggleBlockAllSiteUrl: (state, action: PayloadAction<boolean>) => {
      state.policy.blockAllWebsites = action.payload;
    },
    policyToggleBlockCategory: (
      state,
      action: PayloadAction<{ isChecked: boolean; websiteCategory: string }>
    ) => {
      const { isChecked, websiteCategory } = action.payload;

      const blockKeywordWrapper = isPolicyIOS(state.policy)
        ? state.policy
        : state.selectedApp;

      if (isChecked) {
        blockKeywordWrapper.blockWebsiteCategories =
          blockKeywordWrapper.blockWebsiteCategories.filter(
            (word) => word.websiteCategory !== websiteCategory
          );
      } else {
        blockKeywordWrapper.blockWebsiteCategories = [
          ...(blockKeywordWrapper?.blockWebsiteCategories || []),
          { websiteCategory },
        ];
      }

      if (isPolicyAndroid(state.policy)) {
        sincronizePolicyAndApp(state.policy, state.selectedApp);
      }
    },
    policyDeleteSiteUrl: (state, action: PayloadAction<BlockKeywordsType>) => {
      const blockKeywordWrapper = isPolicyIOS(state.policy)
        ? state.policy
        : state.selectedApp;

      blockKeywordWrapper.blockKeywords =
        blockKeywordWrapper.blockKeywords.filter(
          (word) => word.keyword !== action.payload
        );
      if (isPolicyAndroid(state.policy)) {
        sincronizePolicyAndApp(state.policy, state.selectedApp);
      }
    },
    policySetAppPermission: (
      state,
      action: PayloadAction<PermissionGrantsType>
    ) => {
      const indexPermission = state.selectedApp.permissionGrants.findIndex(
        (config) => config.permission === action.payload.permission
      );
      state.selectedApp.permissionGrants[indexPermission] = action.payload;
      sincronizePolicyAndApp(state.policy, state.selectedApp);
    },
    policyApplicationsAdd: (
      state,
      action: PayloadAction<Partial<PolicyApplicationType[]>>
    ) => {
      // filter to subscribe the old item with origin SYSTEM and packageName equal to new item
      const applicationsToFill = state.policy.applications.filter(
        (oldApplication) =>
          !action.payload.some(
            (newApplication) =>
              newApplication.companyApplication.packageName ===
                oldApplication.companyApplication.packageName &&
              originsApplicationToOverwrite.includes(
                oldApplication.companyApplication.origin
              )
          )
      );

      state.policy.applications = [...applicationsToFill, ...action.payload];
      state.applicationsMetadata = {
        ...state.applicationsMetadata,
        totalItems: state.policy.applications.length,
      };
    },
    policyAppConfigurationSuccess: (
      state,
      action: PayloadAction<PolicyApplicationType>
    ) => {
      const applicationIndex = findApplicationIndex(
        state.policy.applications,
        state.selectedApp.companyApplication.id
      );
      state.selectedApp = {
        ...fillPolicyAppConfigurationSuccess(action.payload),
        ...state.policy.applications[applicationIndex],
      };
    },
    policyApplicationRemove: (
      state,
      action: PayloadAction<Partial<PolicyApplicationType>>
    ) => {
      state.policy.applications = state.policy.applications.filter(
        (application) =>
          application.companyApplication.id !==
          action.payload.companyApplication.id
      );
      state.applicationsMetadata = {
        ...state.applicationsMetadata,
        totalItems: state.policy.applications.length,
      };
    },
    policyApplicationsMetadata: (
      state,
      action: PayloadAction<ListMetadata>
    ) => {
      state.applicationsMetadata = action.payload;
    },
    policyListFilterSuccess: (
      state,
      action: PayloadAction<PolicyToSelectType[]>
    ) => {
      state.policiesToFilter = action.payload;
    },
    policyFreezePeriodSelected: (
      state,
      action: PayloadAction<
        Partial<{
          freezePeriod: string;
          field?: string;
          indexOfFreeze?: number;
        }>
      >
    ) => {
      const { freezePeriod, indexOfFreeze, field } = action.payload;

      state.policy.systemUpdate.freezePeriods[indexOfFreeze][field] =
        freezePeriod;
    },
    policySetFreezePeriod: (
      state,
      action: PayloadAction<Partial<FreezePeriodType[]>>
    ) => {
      state.policy.systemUpdate.freezePeriods = action.payload;
    },
    successGetKioskAppConfigs: (
      state,
      action: PayloadAction<{
        kioskAppConfigs: PolicyApplicationType;
        appKioskIconFontColorKey: string;
      }>
    ) => {
      state.policy.kioskApplication = fillKioskAppConfigurations(
        action.payload
      );
    },
    successSetKioskWallpaper: (
      state,
      action: PayloadAction<{
        wallpaperUrl: string;
        wallpaperConfigKey: string;
      }>
    ) => {
      state.policy.kioskApplication.managedConfigurations =
        state.policy?.kioskApplication?.managedConfigurations.map((config) => {
          if (config.key === action.payload.wallpaperConfigKey) {
            return {
              ...config,
              value: action.payload.wallpaperUrl,
            };
          }

          return config;
        });
    },
    policySetKioskEnable: (state, action: PayloadAction<boolean>) => {
      const isEnable = action.payload;

      if (!isEnable) {
        state.policy.applications = state.policy.applications.filter(
          (application) =>
            !originsApplicationToOverwrite.includes(
              application.companyApplication.origin
            )
        );
      }

      state.policy.kioskCustomLauncherEnabled = isEnable;
    },
    addAppBundleProp: (
      state,
      {
        payload,
      }: PayloadAction<{
        bundlePath: string;
        prop: ManagedConfigurationsType;
        firstLevel?: boolean;
      }>
    ) => {
      const firstIndex = payload.bundlePath.split('.')[0];

      const mainConfig = _.get(
        state.selectedApp.managedConfigurations,
        firstIndex
      );

      let mainConfigMergedValue = _.merge(
        mainConfig.value || [],
        mainConfig.nestedProperties
      );

      mainConfigMergedValue = fillManagedConfiguration(
        mainConfigMergedValue,
        true
      );

      _.set(state.selectedApp.managedConfigurations, firstIndex, {
        ...mainConfig,
        value: mainConfigMergedValue,
      });

      if (payload.firstLevel) {
        sincronizePolicyAndApp(state.policy, state.selectedApp);

        return;
      }

      const newConfig = _.get(
        state.selectedApp.managedConfigurations,
        payload.bundlePath
      );

      let newValue = [...(newConfig?.value || []), payload.prop];

      newValue = fillManagedConfiguration(newValue, true);

      _.set(state.selectedApp.managedConfigurations, payload.bundlePath, {
        ...newConfig,
        value: newValue,
      });

      sincronizePolicyAndApp(state.policy, state.selectedApp);
    },
    removeBundleProp: (
      state,
      {
        payload,
      }: PayloadAction<{ path: string; bundlePath: string; fieldIndex: number }>
    ) => {
      const bundleArray = _.get(
        state.selectedApp.managedConfigurations,
        payload.bundlePath
      );

      if (bundleArray?.value?.length === 1) {
        _.set(
          state.selectedApp.managedConfigurations,
          `${payload.bundlePath}.value`,
          ''
        );

        sincronizePolicyAndApp(state.policy, state.selectedApp);

        return;
      }

      _.remove(
        _.get(
          state.selectedApp.managedConfigurations,
          `${payload.bundlePath}.value`
        ),
        (_, index) => index === payload.fieldIndex
      );

      sincronizePolicyAndApp(state.policy, state.selectedApp);
    },
    initializeAppWorkTime: (
      state,
      { payload: { initialize } }: PayloadAction<{ initialize: boolean }>
    ) => {
      if (initialize && !state?.selectedApp?.workTimes?.length) {
        state.selectedApp.workTimes = daysOfWeekList.map<AppWorkTime>(
          (dayOfWeek) => ({
            blocked: true,
            dayOfWeek,
            endTime: initialWorkTimeHours.end,
            startTime: initialWorkTimeHours.start,
          })
        );
      }
    },
    setSelectedAppWorkTimes: (
      state,
      { payload }: PayloadAction<AppWorkTime[]>
    ) => {
      state.selectedApp = {
        ...state.selectedApp,
        workTimes: payload,
      };

      sincronizePolicyAndApp(state.policy, state.selectedApp);
    },
    setDisableSave: (
      state,
      { payload }: PayloadAction<{ prop: string; value: boolean }>
    ) => {
      state.disablePolicySave[payload.prop] = payload.value;
    },
  },
});
export default policiesSlice.reducer;

// Action Creators

export const {
  policyListClear,
  enrollmentTokenClear,
  policyListSuccess,
  enrollmentTokenGetSuccess,
  setEnrollmentToken,
  policyCreateSuccess,
  policyUpdateSuccess,
  setPolicyKioskInfos,
  policySelected,
  policySelectedClear,
  policyGetSuccess,
  policyApplicationSelected,
  policyApplicationRemove,
  policyApplicationsAdd,
  policyApplicationsMetadata,
  policySetApplication,
  policyAppConfigurationSuccess,
  policySetAppConfiguration,
  policySetAppPermission,
  policyAddSiteUrl,
  policyToggleBlockSiteUrl,
  policyToggleBlockAllSiteUrl,
  policyDeleteSiteUrl,
  policyListFilterSuccess,
  policyToggleBlockCategory,
  policyFreezePeriodSelected,
  policySetFreezePeriod,
  successGetKioskAppConfigs,
  successSetKioskWallpaper,
  policySetKioskManagedConfiguration,
  policySetKioskEnable,
  addAppBundleProp,
  removeBundleProp,
  setSelectedAppWorkTimes,
  initializeAppWorkTime,
  setDisableSave,
} = policiesSlice.actions;

export function listPolicies(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}
export function getEnrollmentToken(id: ID) {
  return {
    type: Types.GET_ENROLLMENT_TOKEN,
    payload: id,
  };
}

export function createPolicy(data: PolicyType) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}

export function createIOSSupervisedPolicy(
  data: PolicyIOSSupervisedFormDataType
) {
  return {
    type: Types.CREATE_IOS_SUPERVISED,
    payload: data,
  };
}

export function copyPolicy(id: ID, data) {
  return {
    type: Types.COPY,
    payload: { id, data },
  };
}

export function deletePolicy(id: ID) {
  return {
    type: Types.DELETE,
    payload: id,
  };
}

export function updatePolicy(data: PolicyResponseType) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function updateIOSSupervisedPolicy(
  data: PolicyIOSSupervisedActionPayload
) {
  return {
    type: Types.UPDATE_IOS_SUPERVISED,
    payload: data,
  };
}

export function getPolicy(id: ID) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function getPolicyAppSettings(data) {
  return {
    type: Types.GET_APP_SETTINGS,
    payload: data,
  };
}

export function listPoliciesToSelect(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_POLICIES_TO_SELECT,
    payload: { filters: sanitizeFilter(filters) },
  };
}

export function getPolicyIos() {
  return {
    type: Types.GET_IOS,
  };
}

export function getKioskAppConfigs(id: PolicyType['id']) {
  return {
    type: Types.GET_KIOSK_APP_CONFIGS,
    payload: { id },
  };
}

export function setKioskAppWallpaper(file: FormData) {
  return {
    type: Types.SET_KIOSK_APP_WALLPAPER,
    payload: { file },
  };
}

export function getRemoteViewApplication() {
  return {
    type: Types.GET_REMOTE_VIEW_APPLICATION,
  };
}
