import { Flex, Tooltip, TooltipProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Button from '../Button';

interface Props extends TooltipProps {
  hasTooltip?: boolean;
  isTooltipOpen?: boolean;
  tooltipMessage?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const WrapperExportAction = ({
  hasTooltip = false,
  tooltipMessage,
  isTooltipOpen,
  children,
  disabled,
  onClick,
  ...rest
}: Props) => {
  if (hasTooltip) {
    return (
      <Tooltip
        hasArrow
        arrowSize={8}
        label={tooltipMessage}
        placement="bottom"
        padding="2"
        borderRadius="5"
        isOpen={isTooltipOpen || undefined}
        {...rest}
      >
        <Flex justify="center">
          <Button
            variant="link"
            fontWeight="normal"
            disabled={disabled}
            onClick={onClick}
          >
            {children}
          </Button>
        </Flex>
      </Tooltip>
    );
  }

  return (
    <Button
      variant="link"
      fontWeight="normal"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default WrapperExportAction;
