import { ButtonProps as ChakraButtonProps } from '@chakra-ui/button/dist/declarations/src/button';
import { Box, Flex, Heading, Icon } from '@chakra-ui/react';
import React from 'react';

import { ModeProps } from '../../../../pages/Policies/RegisterPolicy';
import { ManagementModeEnum } from '../../../../types/policy';
import Text from '../../../Text';

export interface PolicyModeProps extends ChakraButtonProps {
  mode?: ModeProps;
  onSelectMode?: (type: ManagementModeEnum) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
}

const PolicyMode = ({
  onSelectMode,
  mode,
  isDisabled,
  isChecked,
}: PolicyModeProps) => {
  const Circle = () => (
    <Flex
      h={5}
      w={5}
      justify="center"
      align="center"
      borderRadius="50%"
      border="2px"
      borderColor={isChecked ? 'primary.500' : 'gray.200'}
    >
      {isChecked && (
        <Box h={2.5} w={2.5} borderRadius="50%" bgColor="primary.500" />
      )}
    </Flex>
  );

  return (
    <Flex
      h="100%"
      w="100%"
      bgColor={isChecked ? 'gray.400' : 'white'}
      opacity={isDisabled && '70%'}
      _hover={{ bgColor: !isDisabled && 'gray.400' }}
      px={4}
      py={6}
      borderRadius={6}
      borderWidth="2px"
      borderColor={isChecked ? 'primary.500' : 'gray.200'}
      flexDirection="column"
      justify="flex-start"
      cursor={!isDisabled && 'pointer'}
      onClick={() => !isDisabled && onSelectMode(mode.value)}
    >
      <Flex align="center" justify="flex-start" mb={1} gridGap={2}>
        <Circle />
        <Icon w="30px" h="26px">
          {mode?.icon}
        </Icon>
        <Heading as="h4" size="md" fontWeight="normal">
          {mode.name}
        </Heading>
      </Flex>
      <Text>{mode.text}</Text>
    </Flex>
  );
};

export default PolicyMode;
