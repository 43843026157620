import { Divider, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePlan } from '../../../../../../../../hooks/usePlan';
import { useAppDispatch } from '../../../../../../../../hooks/useRedux';
import { policyAddSiteUrl } from '../../../../../../../../store/policy';
import { PolicyApplicationType } from '../../../../../../../../types/policy';
import Button from '../../../../../../../Button';
import Input from '../../../../../../../Input';
import Text from '../../../../../../../Text';
import AppActionAccordionCard from '../components/AppActionAccordionCard';
import ListUrl from './ListUrl';

interface UrlListAccordionCardType {
  blockKeywordsContent?: PolicyApplicationType;
  isBrowserApp?: boolean;
}

type ErrorKeyToIntl = 'alreadyExists' | 'maxCharacters' | 'planMaxItems';

const errorKeyDisableInput: ErrorKeyToIntl[] = ['planMaxItems'];

const maxURLCharacters = 255;

const UrlListAccordionCard = ({
  blockKeywordsContent,
  isBrowserApp,
}: UrlListAccordionCardType) => {
  const [addInputValue, setAddInputValue] = useState('');
  const [errorKeyword, setErrorKeyword] = useState<ErrorKeyToIntl>();
  const { plan } = usePlan();

  const dispatch = useAppDispatch();

  const errorToIntl: Record<ErrorKeyToIntl, string> = {
    alreadyExists: 'edit_policy.app_config.key_existing_alert',
    maxCharacters: 'edit_policy.app_config.max_characters',
    planMaxItems: 'edit_policy.app_config.plan_max_keywords',
  };

  const blockKeyWordsPolicy = blockKeywordsContent?.blockKeywords;

  useEffect(() => {
    const hasKeyword = blockKeyWordsPolicy?.some(
      (blockKeyword) =>
        blockKeyword?.keyword?.toLowerCase() === addInputValue.toLowerCase()
    );

    let errorKey: ErrorKeyToIntl;

    if (addInputValue?.length > maxURLCharacters) {
      errorKey = 'maxCharacters';
    } else {
      errorKey = hasKeyword ? 'alreadyExists' : undefined;
    }

    setErrorKeyword(errorKey);
  }, [addInputValue]);

  useEffect(() => {
    if (isBrowserApp && plan?.maxBlockKeywords) {
      let errorKey: ErrorKeyToIntl;

      if (blockKeyWordsPolicy?.length >= plan?.maxBlockKeywords) {
        errorKey = 'planMaxItems';
      } else {
        errorKey = undefined;
      }

      setErrorKeyword(errorKey);
    }
  }, [blockKeyWordsPolicy]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      policyAddSiteUrl([
        {
          keyword: addInputValue,
          blocked: false,
        },
      ])
    );
    setAddInputValue('');
  };

  return (
    <AppActionAccordionCard
      title={blockKeywordsContent?.labelBlockKeywords?.title}
      description={blockKeywordsContent?.labelBlockKeywords?.description}
      inputContainerWidth="100%"
    >
      <Flex flexDirection="column">
        <form onSubmit={handleSubmit}>
          <Flex>
            <Flex w="460px" flexDirection="column">
              <Input
                inputProps={{
                  value: addInputValue || '',
                  onChange: (e) => setAddInputValue(e.target.value),
                  isDisabled: !!errorKeyDisableInput.some(
                    (errorKey) => errorKey === errorKeyword
                  ),
                }}
              />
              {!!errorKeyword && (
                <Text color="warning.500" fontWeight="bold">
                  <FormattedMessage
                    id={errorToIntl[errorKeyword]}
                    values={{ maxCharacters: maxURLCharacters }}
                  />
                </Text>
              )}
            </Flex>
            <Button
              variant="solid"
              fontWeight="normal"
              disabled={!!errorKeyword || !addInputValue}
              type="submit"
              ml="3%"
            >
              <FormattedMessage id="global.add" />
            </Button>
          </Flex>
        </form>

        <Divider mt="4%" mb="4%" />
        {blockKeywordsContent?.blockKeywords?.map((blockKeyword) => (
          <Flex key={blockKeyword?.keyword} flexDirection="column" mb="1%">
            <ListUrl blockKeywords={blockKeyword} />
          </Flex>
        ))}
      </Flex>
    </AppActionAccordionCard>
  );
};

export default UrlListAccordionCard;
