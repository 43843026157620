import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import AndroidIcon from '../../../components/Icons/Android';
import IOS from '../../../components/Icons/IOS';
import Input from '../../../components/Input';
import PageErrors from '../../../components/PageErrors';
import ProfileFileInput from '../../../components/pages/Policies/EditPolicies/IOS/components/ProfileFileInput';
import { profileFileErroToIntl } from '../../../components/pages/Policies/EditPolicies/IOS/components/ProfileFileInput/profileFileErrors';
import PolicyOperationalSystem from '../../../components/pages/Policies/RegisterPolicy/PolicyOperationalSystem';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { routeWithParameters } from '../../../helper';
import { featurePlanKeys } from '../../../helper/plan';
import { usePlan } from '../../../hooks/usePlan';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  createIOSSupervisedPolicy,
  createPolicy,
  fillIOSSupervisedPolicy,
  Types,
} from '../../../store/policy';
import { hasError, hasSomeLoading, hasSomeSuccess } from '../../../store/ui';
import {
  ManagementModeEnum,
  PolicyIOSSupervisedType,
  PolicyType,
} from '../../../types/policy';

export type ModeProps = {
  name: string;
  value: ManagementModeEnum;
  show: boolean;
  text: string;
  icon?: ReactJSXElement;
  isDisabled?: boolean;
  toolTipLabel?: string;
};

interface ManagementModeToMap {
  ios: ModeProps[];
  android: ModeProps[];
}

export const managementModeToIntl = {
  [ManagementModeEnum.MANAGED_IOS]: 'register_policies.ios',
  [ManagementModeEnum.BLOCK_SIM_ANDROID]: 'register_policies.android_block_sim',
  [ManagementModeEnum.MANAGED_ANDROID]: 'register_policies.android',
  [ManagementModeEnum.IOS_SUPERVISED]: 'register_policies.ios_supervised',
  [ManagementModeEnum.WORK_PROFILE_ANDROID]:
    'register_policies.android_work_profile',
};

const RegisterPolicies = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const history = useHistory();
  const [selectedPolicyMode, setSelectedPolicyMode] =
    useState<ManagementModeEnum>(ManagementModeEnum.MANAGED_ANDROID);
  const [policyName, setPolicyName] = useState('');
  const [profileFile, setProfileFile] = useState<File>();
  const [profileFileName, setProfileFileName] = useState<string>();
  const { policy } = useAppSelector((state) => state.policy);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const isSubmitLoading = hasSomeLoading([
    Types.CREATE,
    Types.CREATE_IOS_SUPERVISED,
  ]);
  const isSuccess = hasSomeSuccess([Types.CREATE, Types.CREATE_IOS_SUPERVISED]);
  const isIOSSupervisedError = hasError(Types.CREATE_IOS_SUPERVISED);
  const { hasFeaturePlan: allowBlockSimPolicy } = usePlan({
    featurePlanKey: featurePlanKeys.allowBlockSimPolicy,
  });

  const { hasFeaturePlan: allowIOSSupervisedPolicy } = usePlan({
    featurePlanKey: featurePlanKeys.allowIOSSupervisedPolicy,
  });

  const managementModes: ManagementModeToMap = {
    android: [
      {
        name: intl.formatMessage({ id: 'register_policies.android' }),
        value: ManagementModeEnum.MANAGED_ANDROID,
        show: true,
        text: intl.formatMessage({
          id: 'register_policies.android.description',
        }),
        icon: <AndroidIcon />,
      },
      {
        name: intl.formatMessage({ id: 'register_policies.android_block_sim' }),
        value: ManagementModeEnum.BLOCK_SIM_ANDROID,
        show: portalSettings.allowAppBlockSim,
        text: intl.formatMessage({
          id: 'register_policies.android_block_sim.description',
        }),
        icon: <AndroidIcon />,
        isDisabled: !allowBlockSimPolicy,
        toolTipLabel: intl.formatMessage({
          id: 'register_policies.android_block_sim.tooltip',
        }),
      },
      {
        name: intl.formatMessage({
          id: 'register_policies.android_work_profile',
        }),
        value: ManagementModeEnum.WORK_PROFILE_ANDROID,
        show: portalSettings.allowWorkProfile,
        text: intl.formatMessage({
          id: 'register_policies.android_work_profile.description',
        }),
        icon: <AndroidIcon />,
      },
    ],
    ios: [
      {
        name: intl.formatMessage({ id: 'register_policies.ios' }),
        value: ManagementModeEnum.MANAGED_IOS,
        show: portalSettings.allowDeviceIOS,
        text: intl.formatMessage({
          id: 'register_policies.ios.description',
        }),
        icon: <IOS />,
      },
      {
        name: intl.formatMessage({ id: 'register_policies.ios_supervised' }),
        value: ManagementModeEnum.IOS_SUPERVISED,
        show: portalSettings.allowDeviceIOS,
        text: intl.formatMessage({
          id: 'register_policies.ios_supervised.description',
        }),
        icon: <IOS />,
        isDisabled: !allowIOSSupervisedPolicy,
        toolTipLabel: intl.formatMessage({
          id: 'register_policies.ios_supervised.tooltip',
        }),
      },
    ],
  };

  useEffect(() => {
    if (isSuccess && !!policy) {
      history.push(
        routeWithParameters(routes.policies.edit, {
          id: policy.id,
        })
      );
    }
  }, [isSuccess]);

  const handlePolicyNameChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setPolicyName(e.target.value);

  const handlePrimary = () => {
    if (selectedPolicyMode === ManagementModeEnum.IOS_SUPERVISED) {
      const policy: PolicyIOSSupervisedType = {
        name: policyName,
        profileFile,
      };

      const filledPolicyToDispatch = fillIOSSupervisedPolicy(policy);

      dispatch(createIOSSupervisedPolicy(filledPolicyToDispatch));

      return;
    }

    const policyToDispatch: PolicyType = {
      name: policyName,
      managementMode: selectedPolicyMode,
    };
    dispatch(createPolicy(policyToDispatch));
  };

  const handleSecondary = () => history.push(routes.policies.manage);

  const handleChangeTab = (newIndex: number) => {
    setSelectedPolicyMode(
      managementModes[!!newIndex ? 'ios' : 'android'][0].value
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="register_policies.title" />}
        description={<FormattedMessage id="register_policies.description" />}
      />
      <PageErrors
        toasterKeys={[Types.CREATE, Types.CREATE_IOS_SUPERVISED]}
        translateKey="register_policies"
        messageError={
          isIOSSupervisedError?.message &&
          intl.formatMessage({
            id: profileFileErroToIntl[isIOSSupervisedError?.message],
          })
        }
      />
      <PageShowToaster
        toasterKeys={[Types.CREATE, Types.CREATE_IOS_SUPERVISED]}
        disabledError
      />
      <FormContainer
        labelPrimary={<FormattedMessage id={'global.register'} />}
        disabledPrimary={
          !policyName ||
          !selectedPolicyMode ||
          (selectedPolicyMode === ManagementModeEnum.IOS_SUPERVISED &&
            !profileFile)
        }
        labelSecondary={<FormattedMessage id="global.cancel" />}
        handlePrimary={handlePrimary}
        handleSecondary={handleSecondary}
        loadingPrimary={isSubmitLoading}
      >
        <Flex flexDirection="column" p="2">
          <Flex gridGap={6}>
            <FormControl
              textLabel={<FormattedMessage id="register_policies.name" />}
              w="376px"
            >
              <Input
                inputProps={{
                  value: policyName,
                  width: '376px',
                  mb: '29px',
                  onChange: handlePolicyNameChange,
                }}
                placeholder={`${(<FormattedMessage id="global.name" />)}`}
              />
            </FormControl>

            {selectedPolicyMode === ManagementModeEnum.IOS_SUPERVISED && (
              <>
                <FormControl
                  textLabel={
                    <FormattedMessage id="register_policies.ios_supervised.profile" />
                  }
                >
                  <ProfileFileInput
                    profileName={profileFileName}
                    onChange={setProfileFile}
                    onChangeName={setProfileFileName}
                  />
                </FormControl>
              </>
            )}
          </Flex>

          <Tabs size="sm" mt={3} onChange={handleChangeTab}>
            <TabList>
              <Tab>
                <FormattedMessage id="register_policies.operational_system.android" />
              </Tab>

              <Tab hidden={!portalSettings.allowDeviceIOS}>
                <FormattedMessage id="register_policies.operational_system.ios" />
              </Tab>
            </TabList>

            <Text m={0} mt={6} mb={1} fontWeight="bold">
              <FormattedMessage id="register_policies.operationalSystem" />
            </Text>

            <TabPanels>
              <TabPanel>
                <PolicyOperationalSystem
                  managementModes={managementModes.android}
                  onSelectMode={setSelectedPolicyMode}
                  selectedPolicyMode={selectedPolicyMode}
                />
              </TabPanel>

              <TabPanel>
                <PolicyOperationalSystem
                  managementModes={managementModes.ios}
                  onSelectMode={setSelectedPolicyMode}
                  selectedPolicyMode={selectedPolicyMode}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </FormContainer>
    </>
  );
};

export default RegisterPolicies;
