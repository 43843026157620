import {
  MenuItem as MenuItemChakra,
  MenuItemProps as MenuItemPropsChakra,
} from '@chakra-ui/react';
import React from 'react';

export interface MenuItemProps extends MenuItemPropsChakra {
  text: React.ReactNode;
  onClick: () => void;
}

const MenuItem = ({ text, onClick, ...rest }: MenuItemProps) => {
  return (
    <MenuItemChakra color="gray.900" fontSize="sm" {...rest} onClick={onClick}>
      {text}
    </MenuItemChakra>
  );
};

export default MenuItem;
