import React from 'react';

import { CardItemType } from '../../../../../CardContent';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { intlPolicySettingsKey } from './index';

export const getAssistantSettings: GetterSettingFn = ({
  handleChange,
  policy,
}) => {
  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.assistant.allow_assistant.title`,
      description: `${intlPolicySettingsKey}.assistant.allow_assistant.description`,
      children: (
        <SwitchButton
          name="allowAssistant"
          isChecked={policy?.allowAssistant}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.assistant.allow_assistant_while_locked.title`,
      description: `${intlPolicySettingsKey}.assistant.allow_assistant_while_locked.description`,
      children: (
        <SwitchButton
          name="allowAssistantWhileLocked"
          isChecked={policy?.allowAssistantWhileLocked}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.assistant.allow_spotlight_internet_results.title`,
      description: `${intlPolicySettingsKey}.assistant.allow_spotlight_internet_results.description`,
      children: (
        <SwitchButton
          name="allowSpotlightInternetResults"
          isChecked={policy?.allowSpotlightInternetResults}
          onChange={handleChange}
        />
      ),
    },
  ];

  return listSettings;
};
