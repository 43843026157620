import React, { useState } from 'react';

import { featurePlanKeys } from '../../../../../../../helper/plan';
import { usePlan } from '../../../../../../../hooks/usePlan';
import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../../store/policy';
import {
  ConfigureWifiEnum,
  RecommendedGlobalProxy as RecommendedGlobalProxyT,
  RecommendedGlobalProxyType,
} from '../../../../../../../types/policy';
import { CardItemType } from '../../../../../../CardContent';
import Select from '../../../../../../Select';
import SelectAutocomplete from '../../../../../../SelectAutocomplete';
import SwitchButton from '../../../../../../SwitchButton';
import { GetterSettingFn } from '../../../PoliciesInterfaces';
import { intlPolicySettingsKey } from '../index';
import { AddWifiSettings } from '../WifiSettings/AddWifiSettings';
import RecommendedGlobalProxy from './RecommendedGlobalProxy';

export const getNetworkSettings: GetterSettingFn = ({
  handleChange,
  intl,
  policy,
}) => {
  const dispatch = useAppDispatch();
  const { hasFeaturePlan: allowNetworkSettingsByPlan } = usePlan({
    featurePlanKey: featurePlanKeys.allowNetworkSettings,
  });

  const recommendedGlobalProxyTypeIntl = {
    [RecommendedGlobalProxyType.MANUAL]: intl.formatMessage({
      id: `${intlPolicySettingsKey}.network.recommended_global_proxy.manual`,
    }),
    [RecommendedGlobalProxyType.AUTOMATIC]: intl.formatMessage({
      id: `${intlPolicySettingsKey}.network.recommended_global_proxy.automatic`,
    }),
    [RecommendedGlobalProxyType.NONE]: intl.formatMessage({
      id: `${intlPolicySettingsKey}.network.recommended_global_proxy.none`,
    }),
  };

  const [recommendedGlobalProxyType, setRecommendedGlobalProxyType] = useState({
    id: policy?.recommendedGlobalProxy?.type || RecommendedGlobalProxyType.NONE,
    name: recommendedGlobalProxyTypeIntl[
      policy?.recommendedGlobalProxy?.type || RecommendedGlobalProxyType.NONE
    ],
  });

  const recommendedGlobalProxyTypeOptions = [
    {
      id: RecommendedGlobalProxyType.NONE,
      name: recommendedGlobalProxyTypeIntl[RecommendedGlobalProxyType.NONE],
    },
    {
      id: RecommendedGlobalProxyType.MANUAL,
      name: recommendedGlobalProxyTypeIntl[RecommendedGlobalProxyType.MANUAL],
    },
    {
      id: RecommendedGlobalProxyType.AUTOMATIC,
      name: recommendedGlobalProxyTypeIntl[
        RecommendedGlobalProxyType.AUTOMATIC
      ],
    },
  ];

  const configureWifiOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.network.configure_wifi.select.allow_configuring_wifi`,
      }),
      value: ConfigureWifiEnum.ALLOW_CONFIGURING_WIFI,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.network.configure_wifi.select.disallow_add_wifi_config`,
      }),
      value: ConfigureWifiEnum.DISALLOW_ADD_WIFI_CONFIG,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.network.configure_wifi.select.disallow_configuring_wifi`,
      }),
      value: ConfigureWifiEnum.DISALLOW_CONFIGURING_WIFI,
    },
  ];

  const handleRecommendedProxyChange = (
    field: keyof RecommendedGlobalProxyT,
    value: unknown
  ) => {
    dispatch(
      policySelected({
        recommendedGlobalProxy: {
          ...policy.recommendedGlobalProxy,
          [field]: value,
        },
      })
    );
  };

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.network.configure_wifi.title`,
      description: `${intlPolicySettingsKey}.network.configure_wifi.description`,
      children: (
        <Select
          name="configureWifi"
          value={policy?.configureWifi}
          onChange={handleChange}
          minWidth="450px"
        >
          {configureWifiOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
      childrenItem: {
        customStyles: {
          alignItems: 'flex-start',
        },
        title: `${intlPolicySettingsKey}.wifi`,
        description: `${intlPolicySettingsKey}.wifi.description`,
        children: <AddWifiSettings isDisabled={!allowNetworkSettingsByPlan} />,
        isDisabled: !allowNetworkSettingsByPlan,
        tooltipLabel: intl.formatMessage({ id: 'global.plan_warning' }),
      },
    },
    {
      title: `${intlPolicySettingsKey}.network.network_reset.title`,
      description: `${intlPolicySettingsKey}.network.network_reset.description`,
      children: (
        <SwitchButton
          name="networkResetDisabled"
          isChecked={policy?.networkResetDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.network.roaming_block.title`,
      description: `${intlPolicySettingsKey}.network.roaming_block.description`,
      children: (
        <SwitchButton
          name="dataRoamingDisabled"
          isChecked={policy?.dataRoamingDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.network.mobile_network_block.title`,
      description: `${intlPolicySettingsKey}.network.mobile_network_block.description`,
      children: (
        <SwitchButton
          name="mobileNetworksConfigDisabled"
          isChecked={policy?.mobileNetworksConfigDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.network.cell_broadcasts_config_disabled.title`,
      description: `${intlPolicySettingsKey}.network.cell_broadcasts_config_disabled.description`,
      children: (
        <SwitchButton
          name="cellBroadcastsConfigDisabled"
          isChecked={policy?.cellBroadcastsConfigDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.network.sms_disabled.title`,
      description: `${intlPolicySettingsKey}.network.sms_disabled.description`,
      children: (
        <SwitchButton
          name="smsDisabled"
          isChecked={policy?.smsDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.network.recommended_global_proxy.title`,
      description: `${intlPolicySettingsKey}.network.recommended_global_proxy.description`,
      children: (
        <SelectAutocomplete
          options={recommendedGlobalProxyTypeOptions}
          value={recommendedGlobalProxyType}
          customStyles={{ width: '100%' }}
          isClearable={false}
          onChange={(newValue: typeof recommendedGlobalProxyType) => {
            setRecommendedGlobalProxyType(newValue);
            handleRecommendedProxyChange('type', newValue?.id);
          }}
        />
      ),
      childrenItem: {
        children: (
          <RecommendedGlobalProxy type={recommendedGlobalProxyType.id} />
        ),
      },
    },
  ];

  return listSettings;
};
