import DefaultIcon from '../assets/Images/app_icons/android.png';
import { ListMetadata } from '../types/generic_list';
import { StateType } from '../types/state';
import { decodeBase64ToImg } from './decode';

export const routeWithParameters = (
  route: string,
  parameters?: Record<string, string | number>
) => {
  return route.replace(/:([^\/]*)/g, (match, p1) => `${parameters?.[p1]}`);
};

export const getCallbackRegisterUrl = (routes) =>
  `${window.location.origin}${routes.company.callback}`;

export const iconImgToRender = (baseStr64?: string) => {
  return decodeBase64ToImg(baseStr64) || DefaultIcon;
};

export enum PrivilegeEnum {
  DEVICE_USER = 'device_user',
  GROUP = 'group',
  COMPANY = 'company',
  HELP_DESK = 'help_desk',
  BACKOFFICE = 'backoffice',
  CARRIER = 'carrier',
}

export enum EnableToEnum {
  APP = 'app',
  WEBSITE = 'website',
  CONSUMPTION = 'consumption',
}

export enum StateEnum {
  'PRE-ACTIVATED' = 'PRE-ACTIVATED',
  DEACTIVATE = 'DEACTIVATE',
  ACTIVATE = 'ACTIVATE',
  CANCELLED = 'CANCELLED',
  CANCELLING = 'CANCELLING',
  SUSPEND = 'SUSPEND',
  SUSPENSION = 'SUSPENSION',
}

export const listStates: StateType[] = [
  {
    key: StateEnum['PRE-ACTIVATED'],
    value: 'company_license.values.state.PRE-ACTIVATED',
  },
  {
    key: StateEnum.ACTIVATE,
    value: 'company_license.values.state.ACTIVATE',
  },
  {
    key: StateEnum.CANCELLED,
    value: 'company_license.values.state.CANCELLED',
  },
  {
    key: StateEnum.CANCELLING,
    value: 'company_license.values.state.CANCELLING',
  },
  {
    key: StateEnum.SUSPEND,
    value: 'company_license.values.state.SUSPEND',
  },
  {
    key: StateEnum.SUSPENSION,
    value: 'company_license.values.state.SUSPENSION',
  },
  {
    key: StateEnum.DEACTIVATE,
    value: 'company_license.values.state.DEACTIVATE',
  },
];

export const listStatesObject: { [key: string]: StateType } = {
  [StateEnum['PRE-ACTIVATED']]: listStates[0],
  [StateEnum.ACTIVATE]: listStates[1],
  [StateEnum.CANCELLED]: listStates[2],
  [StateEnum.CANCELLING]: listStates[3],
  [StateEnum.SUSPEND]: listStates[4],
  [StateEnum.SUSPENSION]: listStates[5],
  [StateEnum.DEACTIVATE]: listStates[6],
};

export const DEBOUNCE_TIME = 500;

export const toggleCheckbox: <T extends string | number>(
  list: T[],
  toggleId: T
) => T[] = (list, toggleId) => {
  const listOld = list || [];

  return list?.includes(toggleId)
    ? listOld.filter((id) => id !== toggleId)
    : [...listOld, toggleId];
};

export const GROUP_PAGE_SIZE_FIXED = 10;
export const ZOOM_MAP = 4;
export const POLLING_TIME_REQUEST_MS = 5000;
export const REALTIME_GEOLOCATION_LIMIT_MINUTES = 10;
export const DEFAULT_LANGUAGE = 'pt-br';

export const FIRST_METADATA: ListMetadata = {
  page: 1,
  pageSize: 20,
  totalItems: 0,
};

export const DATE_OF_TODAY = new Date();
