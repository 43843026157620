import {
  BoxProps,
  Box,
  Divider,
  useStyleConfig,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useAuthorization } from '../../hooks/useAuthorization';
import Button from '../Button';
import Card from '../Card';

type FormContainerVariantType = 'column' | 'row';

interface FormContainerProps extends BoxProps {
  children: React.ReactNode;
  labelPrimary?: string | React.ReactNode;
  handlePrimary?: () => void;
  disabledPrimary?: boolean;
  disabledSecondary?: boolean;
  labelSecondary?: string | React.ReactNode;
  handleSecondary?: () => void;
  labelFilter?: string | React.ReactNode;
  handleFilter?: () => void;
  disabledFilter?: boolean;
  loadingPrimary?: boolean;
  loadingFilter?: boolean;
  divider?: boolean;
  primaryWidth?: string;
  variant?: FormContainerVariantType;
}

const FormContainer: React.FC<FormContainerProps> = ({
  labelPrimary,
  handlePrimary,
  disabledPrimary,
  disabledSecondary,
  labelSecondary,
  handleSecondary,
  labelFilter,
  handleFilter,
  disabledFilter,
  loadingPrimary,
  loadingFilter,
  children,
  divider = true,
  primaryWidth,
  variant = 'column',
  ...rest
}: FormContainerProps) => {
  const style = useStyleConfig('FormContainer');
  const { path } = useRouteMatch();
  const { hasAuthorization } = useAuthorization({
    currentPage: path,
  });

  const styleByVariant: Record<FormContainerVariantType, FlexProps> = {
    row: {
      flexDirection: 'row',
      alignItems: 'end',
      justify: 'space-between',
    },
    column: {
      direction: 'column',
    },
  };

  return (
    <Card {...rest}>
      <Flex {...styleByVariant[variant]} py={2}>
        <Box d="flex" flexDirection="column">
          {children}
        </Box>
        {(handlePrimary || handleSecondary || handleFilter) && divider && (
          <Box>
            <Divider orientation="horizontal" mb="1.5%" mt="1.5%" />
          </Box>
        )}
        <Box
          d="flex"
          flexDirection={variant === 'column' ? 'row' : 'row-reverse'}
          alignItems="center"
        >
          {handlePrimary && (
            <Button
              h="45px"
              w={primaryWidth || '176px'}
              disabled={
                disabledPrimary ||
                !hasAuthorization.create ||
                !hasAuthorization.update
              }
              onClick={handlePrimary}
              isLoading={loadingPrimary}
            >
              {labelPrimary}
            </Button>
          )}
          {handlePrimary && handleSecondary && (
            <Divider
              orientation="vertical"
              h="22px"
              ml="1%"
              __css={{ ...style['divider'] }}
            />
          )}
          {handleSecondary && (
            <Button
              variant="ghost"
              onClick={handleSecondary}
              disabled={disabledSecondary}
              h="45px"
            >
              {labelSecondary}
            </Button>
          )}
          {handleFilter && (
            <Button
              variant="outline"
              h="45px"
              w="176px"
              disabled={
                disabledFilter ||
                !hasAuthorization.create ||
                !hasAuthorization.update
              }
              onClick={handleFilter}
              isLoading={loadingFilter}
            >
              {labelFilter}
            </Button>
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default FormContainer;
