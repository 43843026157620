import { endOfDay, isAfter, isBefore, isValid, startOfDay } from 'date-fns';
import { timezoneFind } from 'portal-lib';

import { LoginAdminUserType } from '../types/loginAdminUser';
import { PortalSettingsType } from '../types/portalSettings';

export const formatDate = (
  language: string,
  timezone: string,
  date: string | number | Date,
  dateFormat?: 'short' | 'full' | 'long' | 'medium',
  timeFormat?: 'short' | 'full' | 'long' | 'medium'
): string => {
  return (
    date &&
    new Date(date).toLocaleString(language, {
      timeZone: timezoneFind(timezone)?.value,
      dateStyle: dateFormat,
      timeStyle: timeFormat,
    })
  );
};

export const formatDateByUserLogged = (
  date: string | number | Date,
  user: LoginAdminUserType,
  portalSettings?: PortalSettingsType,
  dateFormat?: 'short' | 'full' | 'long' | 'medium',
  timeFormat?: 'short' | 'full' | 'long' | 'medium'
): string => {
  return formatDate(
    user.language || portalSettings?.defaultLanguage,
    user.timezone || portalSettings?.defaultTimeZone,
    date,
    dateFormat,
    timeFormat
  );
};

export function isDateInRange(
  dateToCompare: string | Date,
  startDateToCompare?: Date,
  endDateToCompare?: Date
) {
  const date = new Date(dateToCompare);

  const startDate = startDateToCompare && startOfDay(startDateToCompare);
  const endDate = endDateToCompare && endOfDay(endDateToCompare);

  const isBetween = isAfter(date, startDate) && isBefore(date, endDate);

  return startDate && endDate
    ? isBetween
    : isAfter(date, startDate) || isBefore(date, endDate);
}

export function getBiggerDate<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any> = Record<string, any>
>(arr: T[], fieldToCompare: keyof T, filter?: (obj: T) => boolean) {
  let biggerDate: Date;

  arr.forEach((obj) => {
    const value = obj[fieldToCompare] as string;

    if (!isValid(new Date(value))) {
      return;
    }

    if (filter) {
      if (filter?.(obj)) {
        if (biggerDate) {
          if (isAfter(new Date(value), biggerDate)) {
            biggerDate = new Date(value);
          }
        } else {
          biggerDate = new Date(value);
        }
      }
    } else {
      if (biggerDate) {
        if (isAfter(new Date(value), biggerDate)) {
          biggerDate = new Date(value);
        }
      } else {
        biggerDate = new Date(value);
      }
    }
  });

  return biggerDate;
}

export const handleFormatStringToTime = (time: string | null) => {
  if (time) {
    const dateToString = new Date().toISOString().split('T')[0];
    return new Date(`${dateToString} ${time}`);
  }
  return null;
};
