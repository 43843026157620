import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';

import { useAppSelector } from '../../../../../../../../hooks/useRedux';
import { Types } from '../../../../../../../../store/policy';
import { hasLoading } from '../../../../../../../../store/ui';
import { PolicyApplicationType } from '../../../../../../../../types/policy';
import AlertEmptyData from '../../../../../../../AlertEmptyData';
import AppActionAccordionSkeleton from '../components/AppActionAccordionSkeleton';
import AppActionHeader from '../components/AppActionHeader';
import { AppConfigAccordionCard } from './AppConfigAccordionCard';
import CategoryAccordionCard from './CategoryAccordionCard';
import UrlListAccordionCard from './UrlListAccordionCard';

interface AppConfigProps {
  selectedApp: PolicyApplicationType;
  handleCloseSettings: () => void;
}

const AppConfig = ({ selectedApp, handleCloseSettings }: AppConfigProps) => {
  const isLoading = hasLoading(Types.GET_APP_SETTINGS);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const isBrowserApp = useMemo(
    () =>
      selectedApp?.companyApplication?.packageName ===
      portalSettings?.appBrowserPackageName,
    [portalSettings]
  );

  return (
    <Flex flexDirection="column" gridGap={3}>
      <AppActionHeader
        companyApplication={selectedApp?.companyApplication}
        handleClose={handleCloseSettings}
        title={'edit_policy.app_config.title'}
      />
      {isLoading ? (
        <AppActionAccordionSkeleton />
      ) : (
        <>
          {selectedApp?.managedConfigurations?.map((config, index) => (
            <AppConfigAccordionCard
              selectedAppConfig={config}
              key={config.key}
              index={index}
            />
          ))}
          {selectedApp?.labelBlockKeywords?.title && (
            <UrlListAccordionCard
              blockKeywordsContent={selectedApp}
              isBrowserApp={isBrowserApp}
            />
          )}
          {selectedApp?.labelBlockCategories?.title && (
            <CategoryAccordionCard
              selectedAppConfig={selectedApp}
              isBrowserApp={isBrowserApp}
            />
          )}
        </>
      )}
      <AlertEmptyData
        alertKey={Types.GET_APP_SETTINGS}
        text="edit_policy.applications.managed_config.empty_config"
      />
    </Flex>
  );
};

export default AppConfig;
