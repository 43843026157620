export enum ProfileFileErrorEnum {
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_MOBILECONFIG = 'INVALID_MOBILECONFIG',
}

export const profileFileErroToIntl = {
  [ProfileFileErrorEnum.INVALID_FORMAT]:
    'register_policies.ios_supervised.profile_input.error_message.invalid_format',
  [ProfileFileErrorEnum.INVALID_MOBILECONFIG]:
    'register_policies.ios_supervised.profile_input.error_message.invalid_mobileconfig',
  default:
    'register_policies.ios_supervised.profile_input.error_message.default',
};
