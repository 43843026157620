import { Flex, useRadioGroup } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormControl from '../FormControl';
import RadioButton from './index';

export interface OptionsRadiosType {
  value: string | number;
  label: string;
}

interface RadioButtonFormProps {
  options?: OptionsRadiosType[];
  name: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (value: string | number) => void;
  label: string;
  textInfo?: string;
}

const RadioButtonForm = ({
  name,
  value,
  defaultValue,
  onChange,
  textInfo,
  options,
  label,
}: RadioButtonFormProps) => {
  const { getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  return (
    <FormControl
      textLabel={label && <FormattedMessage id={label} />}
      textInfo={textInfo && <FormattedMessage id={textInfo} />}
    >
      <Flex>
        {options?.map((option, index) => {
          const radio = getRadioProps({ value: option.value });
          return (
            <RadioButton key={index} {...radio}>
              <FormattedMessage id={option.label} />
            </RadioButton>
          );
        })}
      </Flex>
    </FormControl>
  );
};

export default RadioButtonForm;
