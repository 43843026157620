import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isAfter, isEqual, isValid } from 'date-fns';

import { FIRST_METADATA } from '../../helper';
import { getBiggerDate } from '../../helper/date';
import { sanitizeFilter } from '../../helper/filter';
import { ListMetadata, ListQueryParameters } from '../../types/generic_list';
import {
  InvoiceDeviceType,
  InvoiceType,
  BackofficeInvoiceType,
  BackofficeInvoiceResponseType,
  InvoiceDeviceStatusEnum,
  RegisterDevicesImeiData,
  RegisterZeroTouchData,
  InvoiceDeviceImeiInfoResponse,
  InvoiceItemType,
  RegisterCustomerData,
  ImportDevicesImeiData,
} from '../../types/invoice';

// Action Types

export const Types = {
  LIST: 'invoice/LIST',
  GET: 'invoice/GET',
  GET_IMEI_INFO: 'invoice/GET_IMEI_INFO',
  GET_BACKOFFICE_INVOICE: 'invoice/GET_BACKOFFICE_INVOICE',
  UPDATE: 'invoice/UPDATE',
  REGISTER_ZERO_TOUCH: 'invoice/REGISTER_ZERO_TOUCH',
  REGISTER_ITEMS_IMEI: 'invoice/REGISTER_ITEMS_IMEI',
  REGISTER_CUSTOMER_ID: 'invoice/REGISTER_CUSTOMER_ID',
  CREATE_CUSTOMER_ID: 'invoice/CREATE_CUSTOMER_ID',
  BACKOFFICE_LIST: 'invoice/BACKOFFICE_LIST',
  IMPORT_INVOICE: 'invoice/IMPORT_XML',
  APPROVE_DEVICES: 'invoice/APPROVE_DEVICES',
  REJECT_DEVICES: 'invoice/REJECT_DEVICES',
  VERIFY_CUSTOMER_ACCOUNT: 'invoice/VERIFY_CUSTOMER_ACCOUNT',
  VERIFY_CUSTOMER_ACCEPT_TERMS: 'invoice/VERIFY_CUSTOMER_ACCEPT_TERMS',
  GET_ACCEPT_TERMS_DESCRIPTION: 'invoice/GET_ACCEPT_TERMS_DESCRIPTION',
  REPLY_ACCEPT_TERMS: 'invoice/REPLY_ACCEPT_TERMS',
  IMPORT_DEVICES_IMEI: 'invoice/IMPORT_DEVICES_IMEI',
};

// Reducer

interface InvoiceState {
  invoice: InvoiceType;
  initialInvoice: InvoiceType;
  invoices: InvoiceType[];
  invoicesMetadata: ListMetadata;
  invoiceDevicesMetadata: ListMetadata;
  hasCustomerId: boolean;
  isAcceptedTerms: boolean;
  backofficeInvoices: BackofficeInvoiceType[];
  backofficeInvoicesMetadata: ListMetadata;
  backofficeInvoice: BackofficeInvoiceType;
  acceptTermsDescription: string;
}

const initialState: InvoiceState = {
  invoice: null,
  initialInvoice: null,
  invoices: [],
  invoicesMetadata: FIRST_METADATA,
  invoiceDevicesMetadata: FIRST_METADATA,
  hasCustomerId: null,
  backofficeInvoicesMetadata: FIRST_METADATA,
  backofficeInvoices: [],
  backofficeInvoice: null,
  isAcceptedTerms: null,
  acceptTermsDescription: '',
};

function findDeviceIndex(
  invoiceDevices: InvoiceDeviceType[],
  idToCompare: string
) {
  return invoiceDevices.findIndex((device) => device.productId === idToCompare);
}

function findItemIndex(invoiceItems: InvoiceItemType[], idToCompare: string) {
  return invoiceItems.findIndex((item) => item.id === idToCompare);
}

function findItemIndexByImei(
  invoiceItems: InvoiceItemType[],
  imeiToCompare: string
) {
  return invoiceItems.findIndex((item) => item?.imei === imeiToCompare);
}

function fillInvoice(invoice: InvoiceType) {
  return {
    ...invoice,
    items: invoice?.items?.map((item) => {
      return {
        ...item,
        manufacturer: item?.manufacturer || null,
        model: item?.model || null,
        imei: item?.imei || '',
      };
    }),
  };
}

function fillBackofficeInvoice(
  backofficeInvoice: BackofficeInvoiceType
): BackofficeInvoiceType {
  return {
    ...backofficeInvoice,
    devices: backofficeInvoice.devices.sort((a, b) => {
      if (
        a.status === InvoiceDeviceStatusEnum.WAITING &&
        b.status !== InvoiceDeviceStatusEnum.WAITING
      ) {
        return -1;
      } else if (
        a.status !== InvoiceDeviceStatusEnum.WAITING &&
        b.status === InvoiceDeviceStatusEnum.WAITING
      ) {
        return 1;
      } else {
        return 0;
      }
    }),
  };
}

function updateInvoiceState(
  state: InvoiceState,
  action: PayloadAction<InvoiceType>
) {
  const invoiceFilled = fillInvoice(action.payload);
  state.invoice = invoiceFilled;
  state.initialInvoice = invoiceFilled;
  state.invoiceDevicesMetadata = {
    ...state.invoiceDevicesMetadata,
    totalItems: action?.payload?.devices?.length,
  };
}

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    invoiceListSuccess: (state, action: PayloadAction<InvoiceType[]>) => {
      state.invoices = action.payload;
      state.invoicesMetadata = {
        ...state.invoicesMetadata,
        totalItems: action?.payload?.length,
      };
    },
    backofficeInvoiceListSuccess: (
      state,
      action: PayloadAction<BackofficeInvoiceResponseType>
    ) => {
      const sortInvoices = action.payload.sort((invoiceA, invoiceB) => {
        const biggerDateA = getBiggerDate(
          invoiceA.devices,
          'deviceTimestamp',
          (device) => device.status === InvoiceDeviceStatusEnum.WAITING
        );
        const biggerDateB = getBiggerDate(
          invoiceB.devices,
          'deviceTimestamp',
          (device) => device.status === InvoiceDeviceStatusEnum.WAITING
        );

        if (isValid(biggerDateA) && !isValid(biggerDateB)) {
          return -1;
        } else if (!isValid(biggerDateA) && isValid(biggerDateB)) {
          return 1;
        } else if (!isValid(biggerDateA) && !isValid(biggerDateB)) {
          return 0;
        } else if (isEqual(biggerDateA, biggerDateB)) {
          return 0;
        } else {
          return isAfter(biggerDateA, biggerDateB) ? -1 : 1;
        }
      });

      state.backofficeInvoices = sortInvoices;
      state.backofficeInvoicesMetadata = {
        ...state.backofficeInvoicesMetadata,
        totalItems: action.payload.length,
      };
    },
    invoiceGetSuccess: (state, action: PayloadAction<InvoiceType>) => {
      updateInvoiceState(state, action);
    },
    invoiceUpdateSuccess: (state, action: PayloadAction<InvoiceType>) => {
      updateInvoiceState(state, action);
    },
    invoiceImportSuccess: (state, action: PayloadAction<InvoiceType>) => {
      updateInvoiceState(state, action);
    },
    invoiceRegisterZeroTouchSuccess: (
      state,
      action: PayloadAction<InvoiceType>
    ) => {
      updateInvoiceState(state, action);
    },
    invoiceRegisterDevicesImeiSuccess: (
      state,
      action: PayloadAction<InvoiceType>
    ) => {
      updateInvoiceState(state, action);
    },
    invoiceGetImeiInfoSuccess: (
      state,
      action: PayloadAction<Partial<InvoiceDeviceImeiInfoResponse>>
    ) => {
      const itemIndex = findItemIndexByImei(
        state.invoice?.items,
        action.payload.imei
      );

      state.invoice.items[itemIndex] = {
        ...state.invoice.items[itemIndex],
        ...action.payload,
      };
    },
    setInvoiceItemInfo: (
      state,
      action: PayloadAction<Partial<InvoiceItemType>>
    ) => {
      const itemIndex = findItemIndex(state.invoice?.items, action.payload.id);
      state.invoice.items[itemIndex] = action.payload;
    },
    setInvoiceDeviceInfo: (
      state,
      action: PayloadAction<Partial<InvoiceDeviceType>>
    ) => {
      const deviceIndex = findDeviceIndex(
        state.invoice?.devices,
        action.payload.productId
      );
      state.invoice.devices[deviceIndex] = action.payload;
    },
    invoicesMetadata: (state, action: PayloadAction<ListMetadata>) => {
      state.invoicesMetadata = action.payload;
    },
    invoiceDevicesMetadata: (state, action: PayloadAction<ListMetadata>) => {
      state.invoiceDevicesMetadata = action.payload;
    },
    setBackofficeInvoicesMetadata: (
      state,
      action: PayloadAction<ListMetadata>
    ) => {
      state.backofficeInvoicesMetadata = action.payload;
    },
    clearInvoice: (state) => {
      state.invoice = initialState.invoice;
      state.initialInvoice = initialState.initialInvoice;
    },
    clearInvoicesList: (state) => {
      state.invoices = initialState.invoices;
    },
    clearHasCustomerId: (state) => {
      state.hasCustomerId = initialState.hasCustomerId;
    },
    backofficeInvoiceSuccess: (
      state,
      action: PayloadAction<BackofficeInvoiceType>
    ) => {
      state.backofficeInvoice = fillBackofficeInvoice(action.payload);
    },
    setBackofficeDeviceObservation: (
      state,
      action: PayloadAction<{ deviceIndex: number; value: string }>
    ) => {
      const {
        payload: { deviceIndex: devicePosition, value },
      } = action;

      state.backofficeInvoice.devices[devicePosition].observation = value;
    },
    invoiceVerifyCustomerAccountSuccess: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasCustomerId = action.payload;
    },
    invoiceApproveSuccess: (
      state,
      action: PayloadAction<BackofficeInvoiceType>
    ) => {
      state.backofficeInvoice = fillBackofficeInvoice(action.payload);
    },
    invoiceRejectSuccess: (
      state,
      action: PayloadAction<BackofficeInvoiceType>
    ) => {
      state.backofficeInvoice = fillBackofficeInvoice(action.payload);
    },
    invoiceVerifyCustomerAcceptTermsSuccess: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAcceptedTerms = action.payload;
    },
    invoiceGetAcceptTermsDescriptionSuccess: (
      state,
      action: PayloadAction<string>
    ) => {
      state.acceptTermsDescription = action.payload;
    },
    invoiceReplyTermsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isAcceptedTerms = action.payload;
    },
  },
});
export default invoiceSlice.reducer;

// Action Creators

export const {
  invoiceListSuccess,
  invoiceGetSuccess,
  invoiceUpdateSuccess,
  invoiceImportSuccess,
  setInvoiceDeviceInfo,
  setInvoiceItemInfo,
  invoiceRegisterZeroTouchSuccess,
  invoiceRegisterDevicesImeiSuccess,
  invoiceGetImeiInfoSuccess,
  invoiceDevicesMetadata,
  backofficeInvoiceListSuccess,
  setBackofficeInvoicesMetadata,
  invoicesMetadata,
  clearInvoice,
  clearInvoicesList,
  clearHasCustomerId,
  backofficeInvoiceSuccess,
  setBackofficeDeviceObservation,
  invoiceVerifyCustomerAccountSuccess,
  invoiceApproveSuccess,
  invoiceRejectSuccess,
  invoiceVerifyCustomerAcceptTermsSuccess,
  invoiceGetAcceptTermsDescriptionSuccess,
  invoiceReplyTermsSuccess,
} = invoiceSlice.actions;

export function listInvoices() {
  return {
    type: Types.LIST,
  };
}

export function listBackofficeInvoices(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.BACKOFFICE_LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function getInvoice(id: string) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function updateInvoice(data: RegisterZeroTouchData) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function registerZeroTouch(data: RegisterZeroTouchData) {
  return {
    type: Types.REGISTER_ZERO_TOUCH,
    payload: data,
  };
}

export function registerItemsImei(data: RegisterDevicesImeiData) {
  return {
    type: Types.REGISTER_ITEMS_IMEI,
    payload: data,
  };
}

export function getImeiInfo(imei: InvoiceItemType['imei']) {
  return {
    type: Types.GET_IMEI_INFO,
    payload: imei,
  };
}

export function importInvoice(data: { accessKey: InvoiceType['accessKey'] }) {
  return {
    type: Types.IMPORT_INVOICE,
    payload: data,
  };
}
export function getBackofficeInvoice(invoiceId: string) {
  return {
    type: Types.GET_BACKOFFICE_INVOICE,
    payload: invoiceId,
  };
}

export function registerCustomerId(data: RegisterCustomerData) {
  return {
    type: Types.REGISTER_CUSTOMER_ID,
    payload: data,
  };
}

export function resellerVerifyAccount() {
  return {
    type: Types.VERIFY_CUSTOMER_ACCOUNT,
  };
}

export function approvedDevices(data: RegisterZeroTouchData) {
  return {
    type: Types.APPROVE_DEVICES,
    payload: data,
  };
}

export function rejectedDevices(data: RegisterZeroTouchData) {
  return {
    type: Types.REJECT_DEVICES,
    payload: data,
  };
}
export function createCustomerId(data: RegisterCustomerData) {
  return {
    type: Types.CREATE_CUSTOMER_ID,
    payload: data,
  };
}
export function verifyCustomerAcceptTerms() {
  return {
    type: Types.VERIFY_CUSTOMER_ACCEPT_TERMS,
  };
}
export function getAcceptTermsDescription() {
  return {
    type: Types.GET_ACCEPT_TERMS_DESCRIPTION,
  };
}
export function replyAcceptTerms(response: boolean) {
  return {
    type: Types.REPLY_ACCEPT_TERMS,
    payload: response,
  };
}

export function importDevicesImei(data: ImportDevicesImeiData) {
  return {
    type: Types.IMPORT_DEVICES_IMEI,
    payload: data,
  };
}
