import { formatDateByUserLogged } from '../../../helper/date';
import { LoginAdminUserType } from '../../../types/loginAdminUser';
import { PortalSettingsType } from '../../../types/portalSettings';
import { ReportSiteDateType, SiteType } from '../../../types/reports';

export const convertSitesToReportSiteUrl =
  (convertToDateWithTimezone: (date: string) => string) =>
  (reportsAcc: ReportSiteDateType[], site: SiteType) => {
    if (!site) {
      return reportsAcc;
    }

    const dateOfSite = convertToDateWithTimezone(site.accessedAt);
    const index = reportsAcc.findIndex((report) => report.date === dateOfSite);

    if (index === -1) {
      return [...reportsAcc, { date: dateOfSite, items: [site] }];
    } else {
      const newReportsAcc = [...reportsAcc];
      newReportsAcc[index] = {
        date: newReportsAcc[index].date,
        items: [...newReportsAcc[index].items, site],
      };
      return newReportsAcc;
    }
  };

export const convertByUserLogged =
  (user: LoginAdminUserType, portalSettings: PortalSettingsType) =>
  (date: string) =>
    formatDateByUserLogged(date, user, portalSettings, 'short');
