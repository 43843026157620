import { Box, BoxProps } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { ListMetadata } from '../../types/generic_list';
import Button from '../Button';
import Expand from '../Icons/Expand';

interface PageLoadMoreProps extends BoxProps {
  handleLoadMore: (params?: ListMetadata) => void;
}

const PageLoadMore = ({ handleLoadMore, ...rest }: PageLoadMoreProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt="20px"
      {...rest}
    >
      <Button
        variant="link"
        fontWeight="normal"
        onClick={() => handleLoadMore()}
      >
        <Expand boxSize={6} />
        <FormattedMessage id="global.load_more" />
      </Button>
    </Box>
  );
};

export default PageLoadMore;
