import { Switch, SwitchProps } from '@chakra-ui/react';

interface SwitchButtonProps extends SwitchProps {
  color?: string;
}

const SwitchButton = ({ color, ...rest }: SwitchButtonProps) => {
  return <Switch colorScheme={color || 'primary'} size="lg" {...rest} />;
};

export default SwitchButton;
