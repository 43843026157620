import { Icon } from '@chakra-ui/react';
import React from 'react';

const AndroidIcon = (props) => {
  return (
    <Icon color="android.500" w="30px" h="26px" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="currentColor"
        viewBox="0 0 21 24"
        {...props}
      >
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'currentColor',
            fillOpacity: 1,
          }}
          d="M7.188 13.969c.812 0 1.468.644 1.468 1.437v6.156C8.656 22.355 8 23 7.187 23c-.808 0-1.468-.645-1.468-1.438v-6.156c0-.793.66-1.437 1.468-1.437Zm0 0"
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'currentColor',
            fillOpacity: 1,
          }}
          d="M3.836 6.566c-.004.055-.008.11-.008.164v9.332c0 1.004.813 1.813 1.82 1.813h9.704c1.007 0 1.82-.809 1.82-1.813V6.73c0-.054 0-.109-.008-.164Zm0 0"
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'currentColor',
            fillOpacity: 1,
          }}
          d="M13.813 13.969c.808 0 1.468.644 1.468 1.437v6.156c0 .793-.66 1.438-1.469 1.438-.812 0-1.468-.645-1.468-1.438v-6.156c0-.793.656-1.437 1.469-1.437ZM1.469 7.399c.812 0 1.468.64 1.468 1.433v6.16c0 .793-.656 1.434-1.468 1.434-.813 0-1.469-.64-1.469-1.434v-6.16c0-.793.656-1.434 1.469-1.434Zm18.062 0c.813 0 1.469.64 1.469 1.433v6.16c0 .793-.656 1.434-1.469 1.434-.808 0-1.468-.64-1.468-1.434v-6.16c0-.793.66-1.434 1.468-1.434ZM3.88 5.952C3.922 3.086 6.477.738 9.762.43h1.476c3.285.308 5.84 2.656 5.883 5.523Zm0 0"
        />
        <path
          style={{
            fill: 'none',
            strokeWidth: 3,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: 'currentColor',
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="m74.748 70.02 15.005 26m103.516-26-15.005 26"
          transform="matrix(.1031 0 0 .1008 -3.316 -6.906)"
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#fff',
            fillOpacity: 1,
          }}
          d="M8.527 3.387c0 .398-.336.722-.757.726-.418 0-.758-.324-.762-.722v-.004c0-.403.34-.727.758-.73.418 0 .761.323.761.726Zm5.672 0c0 .398-.34.722-.758.726-.421 0-.761-.324-.761-.722v-.004c-.004-.403.336-.727.758-.73.417 0 .757.323.761.726Zm0 0"
        />
      </svg>
    </Icon>
  );
};

export default AndroidIcon;
