import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { takeLatest, call, put, delay, select } from 'redux-saga/effects';

import { RootState } from '..';

import { POLLING_TIME_REQUEST_MS } from '../../helper';
import {
  NotificationType,
  NotificationStatusCodeEnum,
} from '../../types/notification';
import { ID, LevelTab } from '../../types/util';
import { startReportPolling, stopReportPolling } from '../event';
import {
  listNotifications,
  notificationGetSuccess,
  notificationListInProgressSuccess,
  notificationListSuccess,
  Types,
} from '../notification';
import { uiAddEmpty } from '../ui';
import { api, requestSimple, safe } from './util';

export function* handlePollingReports() {
  while (true) {
    try {
      const notificationsCurrents: NotificationType[] = yield call(
        api,
        'notificationListInProgress'
      );
      if (!notificationsCurrents || notificationsCurrents.length === 0) {
        yield put(listNotifications());
        yield put(stopReportPolling());
      }
      const notificationsPrevious = yield select(
        (state: RootState) => state.notification.notificationsInProgress
      );
      if (!_.isEqual(notificationsPrevious, notificationsCurrents)) {
        yield put(notificationListInProgressSuccess(notificationsCurrents));
        yield put(listNotifications());
      }
      yield delay(POLLING_TIME_REQUEST_MS);
    } catch (e) {
      yield put(stopReportPolling());
    }
  }
}

function* handleListNotifications({ payload }: PayloadAction<LevelTab>) {
  const returnData: NotificationType[] = yield call(
    api,
    'notificationList',
    payload
  );
  const notificationsComplete = returnData.filter(
    (notification) =>
      notification.statusCode !== NotificationStatusCodeEnum.IN_PROGRESS
  );
  const notificationsInProgress = returnData.filter(
    (notification) =>
      notification.statusCode === NotificationStatusCodeEnum.IN_PROGRESS
  );
  yield put(notificationListSuccess(notificationsComplete));
  yield put(notificationListInProgressSuccess(notificationsInProgress));
  if (notificationsInProgress.length > 0) {
    yield put(startReportPolling());
  }
}

function* handleUpdateNotificationRead({ payload: id }: PayloadAction<ID>) {
  yield call(api, 'notificationReadUpdate', id);
  yield put(listNotifications());
}

function* handleGetNotification({ payload: id, type }: PayloadAction<ID>) {
  const notification = yield call(
    requestSimple,
    api,
    'notificationGet',
    type,
    notificationGetSuccess,
    id
  );
  const hasData = notification?.details?.data?.length > 0;

  if (!hasData) {
    yield put(uiAddEmpty(type));
  }
}

export default function* notificationSaga() {
  yield takeLatest(Types.LIST_NOTIFICATION, safe(handleListNotifications));
  yield takeLatest(Types.UPDATE, safe(handleUpdateNotificationRead));
  yield takeLatest(Types.GET_NOTIFICATION, safe(handleGetNotification));
}
