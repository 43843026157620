import { Flex } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../hooks/useRedux';
import {
  policySetAppPermission,
  Types,
} from '../../../../../../../store/policy';
import { hasLoading, uiRemoveEmpty } from '../../../../../../../store/ui';
import {
  PermissionGrantsType,
  PolicyApplicationType,
  PolicyAppPermissionSelectEnum,
} from '../../../../../../../types/policy';
import AlertEmptyData from '../../../../../../AlertEmptyData';
import Select from '../../../../../../Select';
import AppActionAccordionCard from './components/AppActionAccordionCard';
import AppActionAccordionSkeleton from './components/AppActionAccordionSkeleton';
import AppActionHeader from './components/AppActionHeader';

interface AppPermissionsProps {
  selectedApp: PolicyApplicationType;
  handleClose: () => void;
}

const AppPermissions = ({ selectedApp, handleClose }: AppPermissionsProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isLoading = hasLoading(Types.GET_APP_SETTINGS);
  const appCompanionPackageName = useAppSelector(
    (state) => state.portalSettings.portalSettings.appCompanionPackageName
  );
  const isCompanionApp = useMemo(
    () =>
      appCompanionPackageName === selectedApp.companyApplication.packageName,
    []
  );

  const TRANSLATE_KEY = 'edit_policy.app_permissions.select_label';
  const SELECT_OPTIONS = [
    {
      label: intl.formatMessage({
        id: `${TRANSLATE_KEY}.prompt`,
      }),
      value: PolicyAppPermissionSelectEnum.PROMPT,
    },
    {
      label: intl.formatMessage({
        id: `${TRANSLATE_KEY}.grant`,
      }),
      value: PolicyAppPermissionSelectEnum.GRANT,
    },
    {
      label: intl.formatMessage({
        id: `${TRANSLATE_KEY}.deny`,
      }),
      value: PolicyAppPermissionSelectEnum.DENY,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(uiRemoveEmpty(Types.GET_APP_SETTINGS));
    };
  }, []);

  function handleSelectPermissions(
    value: unknown,
    permission: PermissionGrantsType
  ) {
    dispatch(
      policySetAppPermission({ ...permission, permissionPolicy: value })
    );
  }

  return (
    <Flex flexDirection="column" gridGap="3">
      <AppActionHeader
        companyApplication={selectedApp.companyApplication}
        handleClose={handleClose}
        title={'edit_policy.app_permissions.title'}
      />
      {isLoading ? (
        <AppActionAccordionSkeleton />
      ) : (
        <>
          {selectedApp?.permissionGrants?.map((configs) => (
            <AppActionAccordionCard
              title={`${configs.name ? `${configs.name} -` : ''}  ${
                configs.permission
              }`}
              description={configs.description}
              key={configs?.permission}
            >
              <Select
                value={configs.permissionPolicy}
                disabled={isCompanionApp}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSelectPermissions(e.target.value, configs)
                }
              >
                {SELECT_OPTIONS.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </AppActionAccordionCard>
          ))}
          {selectedApp?.permissionGrants?.length === 0 && (
            <AlertEmptyData
              alertKey={Types.GET_APP_SETTINGS}
              text="edit_policy.applications.managed_config.empty_permission"
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default AppPermissions;
