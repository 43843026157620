import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, race, take, fork, put, takeLatest } from 'redux-saga/effects';

import { ListMetadata } from '../../types/generic_list';
import { Types } from '../event';
import { listNotifications } from '../notification';
import { uiAddLoading, uiRemoveLoading } from '../ui';
import {
  handlePollingLocalizationRealtime,
  timeoutLocalization,
} from './locationSaga';
import { handlePollingReports } from './notificationSaga';
import { api, requestSimple, safe } from './util';

function* handlePollingReportsWatcher() {
  while (true) {
    yield take(Types.REPORT_START);
    yield race([call(handlePollingReports), take(Types.REPORT_STOP)]);
  }
}

function* handlePollingLocalizationWatcher() {
  while (true) {
    yield take(Types.LOCALIZATION_START);
    yield put(uiAddLoading(Types.LOCALIZATION_START));
    yield race([
      call(handlePollingLocalizationRealtime),
      take(Types.LOCALIZATION_STOP),
      call(timeoutLocalization),
    ]);
    yield put(uiRemoveLoading(Types.LOCALIZATION_START));
  }
}

function* generateExcel({
  payload,
}: PayloadAction<{
  type: string;
  metadata: ListMetadata;
  filters: Record<string, unknown>;
}>) {
  yield call(
    requestSimple,
    api,
    'generateReport',
    payload.type.concat('_REPORT'),
    createAction(''),
    payload
  );
  yield put(listNotifications());
}

export default function* eventSaga() {
  yield fork(handlePollingReportsWatcher);
  yield fork(handlePollingLocalizationWatcher);
  yield takeLatest(Types.REPORT_GENERATE_EXCEL, safe(generateExcel));
}
