import { Divider, Flex } from '@chakra-ui/react';
import { endOfMonth, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import { policyFreezePeriodSelected } from '../../../../../../../store/policy';
import { FreezePeriodType } from '../../../../../../../types/policy';
import DatePicker from '../../../../../../Datepicker';
import FormControl from '../../../../../../FormControl';
import Delete from '../../../../../../Icons/Delete';
import IconButton from '../../../../../../Icons/IconButton';
import Text from '../../../../../../Text';

interface FreezePeriodProps {
  freezePeriod?: FreezePeriodType;
  indexOfFreeze?: number;
  handleRemovePeriod?: () => void;
}

interface DateValueType {
  dayStart: Date;
  monthStart: Date;
  dayEnd: Date;
  monthEnd: Date;
}

const FreezePeriod = ({
  freezePeriod,
  indexOfFreeze,
  handleRemovePeriod,
}: FreezePeriodProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [dateValue, setDateValue] = useState<DateValueType>({
    dayStart: null,
    monthStart: null,
    dayEnd: null,
    monthEnd: null,
  });

  useEffect(() => {
    if (freezePeriod?.endDate) {
      setDateValue({
        dayStart: new Date(freezePeriod?.startDate),
        monthStart: new Date(freezePeriod?.startDate),
        dayEnd: new Date(freezePeriod?.endDate),
        monthEnd: new Date(freezePeriod?.endDate),
      });
    }
  }, []);

  const handleChangeDate = (partialDateValue: Partial<typeof dateValue>) => {
    const newDataValue = { ...dateValue, ...partialDateValue };
    handlePeriodFilterChange(newDataValue.dayStart, 'startDate');
    handlePeriodFilterChange(newDataValue.dayEnd, 'endDate');
    setDateValue(newDataValue);
  };

  const handlePeriodFilterChange = (date: Date, field: string) => {
    dispatch(
      policyFreezePeriodSelected({
        freezePeriod: date,
        indexOfFreeze,
        field,
      })
    );
  };

  return (
    <Flex gridGap={6} my="6" ml="6">
      <Flex flexDirection="column">
        <Text mb="10x">
          <FormattedMessage id="update_policies.systemUpdate.start" />
        </Text>
        <Flex>
          <FormControl
            w="176px"
            textLabel={
              <FormattedMessage id="update_policies.systemUpdate.month" />
            }
          >
            <DatePicker
              selected={dateValue.monthStart}
              onChange={(value) => {
                handleChangeDate({ monthStart: value, dayStart: null });
              }}
              dateFormat="MMMM"
              showMonthYearPicker
              showTwoColumnMonthYearPicker
              placeholder={intl.formatMessage({
                id: `update_policies.systemUpdate.month`,
              })}
              renderCustomHeader={({}) => <></>}
            />
          </FormControl>
          <FormControl
            w="176px"
            textLabel={
              <FormattedMessage id="update_policies.systemUpdate.day" />
            }
          >
            <DatePicker
              selected={dateValue.dayStart}
              onChange={(value) => handleChangeDate({ dayStart: value })}
              placeholder={intl.formatMessage({
                id: `update_policies.systemUpdate.day`,
              })}
              dateFormat="dd"
              hasValue={!!dateValue?.monthStart}
              readOnly={!dateValue?.monthStart}
              disabled={!dateValue?.monthStart}
              minDate={startOfMonth(dateValue?.monthStart)}
              maxDate={endOfMonth(dateValue?.monthStart)}
              renderCustomHeader={({}) => <></>}
            />
          </FormControl>
        </Flex>
      </Flex>
      <Divider orientation="vertical" />
      <Flex flexDirection="column">
        <Text mb="10px">
          <FormattedMessage id="update_policies.systemUpdate.end" />
        </Text>
        <Flex>
          <FormControl
            w="176px"
            textLabel={
              <FormattedMessage id="update_policies.systemUpdate.month" />
            }
          >
            <DatePicker
              selected={dateValue.monthEnd}
              onChange={(value) => {
                handleChangeDate({ monthEnd: value, dayEnd: null });
              }}
              dateFormat="MMMM"
              showMonthYearPicker
              showTwoColumnMonthYearPicker
              placeholder={intl.formatMessage({
                id: `update_policies.systemUpdate.month`,
              })}
              hasValue={!!dateValue?.monthEnd}
              readOnly={!dateValue?.dayStart}
              disabled={!dateValue?.dayStart}
              renderCustomHeader={({}) => <></>}
            />
          </FormControl>
          <FormControl
            w="176px"
            textLabel={
              <FormattedMessage id="update_policies.systemUpdate.day" />
            }
          >
            <DatePicker
              selected={dateValue.dayEnd}
              onChange={(value) => handleChangeDate({ dayEnd: value })}
              placeholder={intl.formatMessage({
                id: `update_policies.systemUpdate.day`,
              })}
              dateFormat="dd"
              hasValue={!!dateValue?.dayEnd}
              readOnly={!dateValue?.monthEnd}
              disabled={!dateValue?.monthEnd}
              minDate={startOfMonth(dateValue?.monthEnd)}
              maxDate={endOfMonth(dateValue?.monthEnd)}
              renderCustomHeader={({}) => <></>}
            />
          </FormControl>
        </Flex>
      </Flex>

      <IconButton
        aria-label={'remove'}
        alignSelf="flex-end"
        icon={<Delete boxSize={6} color="warning.500" />}
        onClick={handleRemovePeriod}
      />
    </Flex>
  );
};
export default FreezePeriod;
