import { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';

import { QuerysWithFilters } from '../../types/generic_list';
import {
  BackofficeInvoiceType,
  ImportDevicesImeiData,
  InvoiceDeviceType,
  InvoiceType,
  RegisterCustomerData,
  RegisterDevicesImeiData,
  RegisterZeroTouchData,
} from '../../types/invoice';
import {
  Types,
  invoiceListSuccess,
  invoiceGetSuccess,
  invoiceUpdateSuccess,
  backofficeInvoiceListSuccess,
  invoiceRegisterZeroTouchSuccess,
  invoiceRegisterDevicesImeiSuccess,
  invoiceGetImeiInfoSuccess,
  backofficeInvoiceSuccess,
  invoiceImportSuccess,
  invoiceVerifyCustomerAccountSuccess,
  resellerVerifyAccount,
  invoiceApproveSuccess,
  invoiceRejectSuccess,
  getBackofficeInvoice,
  invoiceVerifyCustomerAcceptTermsSuccess,
  invoiceGetAcceptTermsDescriptionSuccess,
  invoiceReplyTermsSuccess,
  getInvoice,
} from '../invoice';
import { api, requestSimple, safe } from './util';

function* handleList({ type }) {
  yield call(requestSimple, api, 'invoiceList', type, invoiceListSuccess);
  yield put(resellerVerifyAccount());
}

function* handleGet({ payload, type }: PayloadAction<InvoiceType['id']>) {
  yield call(
    requestSimple,
    api,
    'invoiceGet',
    type,
    invoiceGetSuccess,
    payload
  );
}

function* handleUpdate({
  payload,
  type,
}: PayloadAction<RegisterZeroTouchData>) {
  yield call(
    requestSimple,
    api,
    'invoiceUpdate',
    type,
    invoiceUpdateSuccess,
    payload.invoiceId,
    payload
  );
}

function* handleRegisterZeroTouch({
  payload,
  type,
}: PayloadAction<RegisterZeroTouchData>) {
  const hasCustomerId = yield handleVerifyCustomerAccount({
    type: Types.VERIFY_CUSTOMER_ACCOUNT,
  });

  if (hasCustomerId) {
    yield call(
      requestSimple,
      api,
      'invoiceRegisterZeroTouch',
      type,
      invoiceRegisterZeroTouchSuccess,
      payload.invoiceId,
      payload
    );
  }
}

function* handleRegisterItemsImei({
  payload,
  type,
}: PayloadAction<RegisterDevicesImeiData>) {
  yield call(
    requestSimple,
    api,
    'invoiceRegisterItemsImei',
    type,
    invoiceRegisterDevicesImeiSuccess,
    payload.invoiceId,
    payload
  );
}

function* handleGetImeiInfo({
  payload,
  type,
}: PayloadAction<InvoiceDeviceType['imei']>) {
  yield call(
    requestSimple,
    api,
    'invoiceGetImeiInfo',
    type,
    invoiceGetImeiInfoSuccess,
    payload
  );
}

function* handleRegisterCustomerId({
  payload,
  type,
}: PayloadAction<RegisterCustomerData>) {
  yield call(
    requestSimple,
    api,
    'resellerRegisterCustomerAccount',
    type,
    undefined,
    payload
  );
}

function* handleCreateCustomerId({
  payload,
  type,
}: PayloadAction<RegisterCustomerData>) {
  yield call(
    requestSimple,
    api,
    'resellerCreateCustomerAccount',
    type,
    undefined,
    payload
  );
}

function* handleVerifyCustomerAccount({ type }: Action) {
  return yield call(
    requestSimple,
    api,
    'resellerVerifyCustomerAccount',
    type,
    invoiceVerifyCustomerAccountSuccess
  );
}

function* handleVerifyCustomerAcceptTerms({ type }: Action) {
  return yield call(
    requestSimple,
    api,
    'resellerVerifyAcceptTerms',
    type,
    invoiceVerifyCustomerAcceptTermsSuccess
  );
}

function* handleGetAcceptTermsDescription({ type }: Action) {
  return yield call(
    requestSimple,
    api,
    'resellerAcceptTermsDescription',
    type,
    invoiceGetAcceptTermsDescriptionSuccess
  );
}

function* handleReplyAcceptTerms({ type, payload }: PayloadAction<boolean>) {
  return yield call(
    requestSimple,
    api,
    'resellerReplyAcceptTerms',
    type,
    invoiceReplyTermsSuccess,
    { reply: payload }
  );
}

function* handleBackofficeList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'backofficeInvoiceList',
    type,
    backofficeInvoiceListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

function* handleImportInvoice({
  payload,
  type,
}: PayloadAction<InvoiceType['accessKey']>) {
  yield call(
    requestSimple,
    api,
    'invoiceImport',
    type,
    invoiceImportSuccess,
    payload
  );
}

function* handleGetBackofficeInvoice({
  payload,
  type,
}: PayloadAction<BackofficeInvoiceType['id']>) {
  yield call(
    requestSimple,
    api,
    'backofficeInvoice',
    type,
    backofficeInvoiceSuccess,
    payload
  );
}

function* handleApproveDevices({
  payload,
  type,
}: PayloadAction<RegisterZeroTouchData>) {
  try {
    yield call(
      requestSimple,
      api,
      'backofficeInvoiceApprove',
      type,
      invoiceApproveSuccess,
      payload.invoiceId,
      payload
    );
  } catch (e) {
    yield put(getBackofficeInvoice(payload.invoiceId));
    throw e;
  }
}

function* handleRejectDevices({
  payload,
  type,
}: PayloadAction<RegisterZeroTouchData>) {
  yield call(
    requestSimple,
    api,
    'backofficeInvoiceReject',
    type,
    invoiceRejectSuccess,
    payload.invoiceId,
    payload
  );
}

function* handleImportDevicesImei({
  payload,
  type,
}: PayloadAction<ImportDevicesImeiData>) {
  yield call(
    requestSimple,
    api,
    'invoiceImportDevicesImei',
    type,
    undefined,
    payload.id,
    payload.file
  );

  yield put(getInvoice(payload.id));
}

export default function* invoiceSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.UPDATE, safe(handleUpdate));
  yield takeLatest(Types.REGISTER_ZERO_TOUCH, safe(handleRegisterZeroTouch));
  yield takeLatest(Types.REGISTER_ITEMS_IMEI, safe(handleRegisterItemsImei));
  yield takeLatest(Types.GET_IMEI_INFO, safe(handleGetImeiInfo));
  yield takeLatest(Types.REGISTER_CUSTOMER_ID, safe(handleRegisterCustomerId));
  yield takeLatest(Types.CREATE_CUSTOMER_ID, safe(handleCreateCustomerId));
  yield takeLatest(Types.BACKOFFICE_LIST, safe(handleBackofficeList));
  yield takeLatest(Types.IMPORT_INVOICE, safe(handleImportInvoice));
  yield takeLatest(
    Types.GET_BACKOFFICE_INVOICE,
    safe(handleGetBackofficeInvoice)
  );
  yield takeLatest(
    Types.VERIFY_CUSTOMER_ACCOUNT,
    safe(handleVerifyCustomerAccount)
  );
  yield takeLatest(Types.APPROVE_DEVICES, safe(handleApproveDevices));
  yield takeLatest(Types.REJECT_DEVICES, safe(handleRejectDevices));
  yield takeLatest(
    Types.VERIFY_CUSTOMER_ACCEPT_TERMS,
    safe(handleVerifyCustomerAcceptTerms)
  );
  yield takeLatest(
    Types.GET_ACCEPT_TERMS_DESCRIPTION,
    safe(handleGetAcceptTermsDescription)
  );
  yield takeLatest(Types.REPLY_ACCEPT_TERMS, safe(handleReplyAcceptTerms));
  yield takeLatest(Types.IMPORT_DEVICES_IMEI, safe(handleImportDevicesImei));
}
