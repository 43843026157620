// Action Types

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { add } from 'date-fns';

import { REALTIME_GEOLOCATION_LIMIT_MINUTES } from '../../helper';
import { sanitizeFilter } from '../../helper/filter';
import { DeviceUserType } from '../../types/deviceUser';
import { ListQueryParameters } from '../../types/generic_list';
import { LocationType } from '../../types/locations';
import { ID } from '../../types/util';

export const Types = {
  LIST_LAST: 'location/LIST_LAST',
  LIST_HISTORIC: 'location/LIST_HISTORIC',
  LIST_GEOLOCALIZATION: 'geolocation/LIST_GEOLOCALIZATION',
  LIST_LOCALIZATION: 'localization/LIST_LOCALIZATION',
  LIST_LOCALIZATION_REALTIME: 'localization/LIST_LOCALIZATION_REALTIME',
  CREATE_LOCALIZATION_REALTIME_ID:
    'localization/CREATE_LOCALIZATION_REALTIME_ID',
  LIST_GEOLOCATION_REVERSE: 'geolocation/LIST_GEOLOCATION_REVERSE',
  POLL_START: 'localization/POLL_START',
  POLL_STOP: 'localization/POLL_STOP',
  POLL_STOP_FOR_TIMEOUT: 'localization/POLL_STOP_FOR_TIMEOUT',
  UPDATE: 'location/UPDATE',
  GET_ADDRESS: 'location/GET_ADDRESS',
  GET_USERS_OPTIONS: 'location/GET_USERS_OPTIONS',
};

// Reducer

interface LocationState {
  locations: LocationType[];
  locationHistories: LocationType[];
  geolocations: LocationType[];
  poolingId?: number;
  dateToStop: Date;
  usersOptions: DeviceUserType[];
}

const initialState: LocationState = {
  locations: [],
  locationHistories: [],
  geolocations: [],
  poolingId: 0,
  dateToStop: new Date(),
  usersOptions: [],
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    locationListSuccess: (state, action: PayloadAction<LocationType[]>) => {
      state.locations = action.payload;
    },
    locationListHistoriesSuccess: (
      state,
      action: PayloadAction<LocationType[]>
    ) => {
      state.locationHistories = action.payload;
    },
    locationGeolocationListSuccess: (
      state,
      action: PayloadAction<LocationType[]>
    ) => {
      state.geolocations = action.payload;
    },
    locationGeolocationReverseSuccess: (
      state,
      action: PayloadAction<Partial<LocationType>>
    ) => {
      state.locationHistories = state.locationHistories.map((item) => {
        if (item.id === action.payload.id) {
          item.address = action.payload.address;
        }
        return item;
      });
    },
    locationUpdateSuccess: (state, action: PayloadAction<LocationType>) => {
      state.locations = state.locations.map((item) => {
        if (item.id === action.payload.id) {
          item.address = action.payload.address;
        }
        return item;
      });
    },
    locationCreateIdSuccess: (state, action: PayloadAction<ID>) => {
      state.dateToStop = add(new Date(), {
        minutes: REALTIME_GEOLOCATION_LIMIT_MINUTES,
      });
      state.poolingId = action.payload;
    },
    clearStoreLocalization: (state) => {
      state.locations = initialState.locations;
    },
    clearStoreGeolocation: (state) => {
      state.geolocations = initialState.geolocations;
    },
    locationGetAddressSuccess: (state, action: PayloadAction<LocationType>) => {
      const indexToUpdate = state.locations.findIndex(
        (location) => location.id === action.payload.id
      );
      state.locations[indexToUpdate] = action.payload;
    },
    getUsersOptionsSuccess: (
      state,
      action: PayloadAction<DeviceUserType[]>
    ) => {
      state.usersOptions = action.payload;
    },
  },
});

export default locationsSlice.reducer;

// Action Creators

export const {
  locationListSuccess,
  locationGeolocationListSuccess,
  locationGeolocationReverseSuccess,
  locationUpdateSuccess,
  locationCreateIdSuccess,
  clearStoreLocalization,
  locationListHistoriesSuccess,
  locationGetAddressSuccess,
  getUsersOptionsSuccess,
  clearStoreGeolocation,
} = locationsSlice.actions;

export function listLastLocation(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_LAST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listlocationHistoric(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_HISTORIC,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listGeolocation(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_GEOLOCALIZATION,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}
export function listGeolocationReverse(
  lat: string,
  lon: string,
  geolocationId: ID,
  date?: LocationType['date']
) {
  return {
    type: Types.LIST_GEOLOCATION_REVERSE,
    payload: { lat, lon, id: geolocationId, date },
  };
}
export function locationUpdateAddress({
  id,
  address,
  date,
}: Partial<LocationType>) {
  return {
    type: Types.UPDATE,
    payload: {
      id,
      address,
      date: sanitizeFilter(date),
    },
  };
}

export function listLocalization(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_LOCALIZATION,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function createLocalizationRealTimeId(id) {
  return {
    type: Types.CREATE_LOCALIZATION_REALTIME_ID,
    payload: id,
  };
}

export function listLocalizationRealTime(queryParameters: ID) {
  return {
    type: Types.LIST_LOCALIZATION_REALTIME,
    payload: queryParameters,
  };
}

export function stopForTimeoutLocalizationPolling() {
  return {
    type: Types.POLL_STOP_FOR_TIMEOUT,
  };
}

export function getAddress(location: LocationType) {
  return {
    type: Types.GET_ADDRESS,
    payload: location,
  };
}

export function getUsersOptions() {
  return {
    type: Types.GET_USERS_OPTIONS,
  };
}
