import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  ConsumptionProfileType,
  GeneralConsumptionType,
} from '../../types/consumptionProfile';
import { QuerysWithFilters } from '../../types/generic_list';
import { ID } from '../../types/util';
import {
  Types,
  consumptionProfileGeneralGetSuccess,
  consumptionProfileGeneralUpdateSuccess,
  consumptionProfileGroupListSuccess,
  consumptionProfileGroupGetSuccess,
  consumptionProfileGroupCreateSuccess,
  consumptionProfileGroupUpdateSuccess,
  consumptionProfileUserListSuccess,
  consumptionProfileUserGetSuccess,
  consumptionProfileUserCreateSuccess,
  consumptionProfileUserUpdateSuccess,
  listConsumptionGroup,
  listConsumptionUser,
} from '../consumptionProfile';
import {
  makeUiAddMetadataSpecific,
  makeUiAddSuccessSpecific,
  selectorMetadata,
} from '../ui';
import {
  safe,
  apiRequestAndSuccess,
  apiRequestListSuccess,
  requestSimple,
  api,
} from './util';

function* handleGeneralGet() {
  yield call(apiRequestAndSuccess, 'consumptionProfileGeneralGet', {
    success: consumptionProfileGeneralGetSuccess,
  });
}

function* handleGeneralUpdate({
  payload,
  type,
}: PayloadAction<GeneralConsumptionType>) {
  yield call(
    requestSimple,
    api,
    'consumptionProfileGeneralUpdate',
    type,
    consumptionProfileGeneralUpdateSuccess,
    payload
  );
}

function* handleList(action: PayloadAction<QuerysWithFilters>) {
  const {
    payload: { queryParameters, filters },
  } = action;
  switch (action.type) {
    case Types.LIST_GROUP:
      yield call(
        apiRequestListSuccess,
        'consumptionProfileGroupList',
        {
          success: consumptionProfileGroupListSuccess,
          metadata: makeUiAddMetadataSpecific(action.type),
        },
        { queryParameters, filters }
      );
      break;
    case Types.LIST_USER:
      yield call(
        apiRequestListSuccess,
        'consumptionProfileUserList',
        {
          success: consumptionProfileUserListSuccess,
          metadata: makeUiAddMetadataSpecific(action.type),
        },
        { queryParameters, filters }
      );
      break;
  }
}

function* handleGet(action: PayloadAction<ID>) {
  const id = action.payload;
  switch (action.type) {
    case Types.GET_GROUP:
      yield call(
        apiRequestAndSuccess,
        'consumptionProfileGroupGet',
        { success: consumptionProfileGroupGetSuccess },
        id
      );
      break;
    case Types.GET_USER:
      yield call(
        apiRequestAndSuccess,
        'consumptionProfileUserGet',
        { success: consumptionProfileUserGetSuccess },
        id
      );
      break;
  }
}

function* handleCreation(action: PayloadAction<ConsumptionProfileType>) {
  const id = action.payload;
  switch (action.type) {
    case Types.CREATE_GROUP:
      yield call(
        apiRequestAndSuccess,
        'consumptionProfileGroupCreate',
        {
          success: consumptionProfileGroupCreateSuccess,
          uiSuccess: makeUiAddSuccessSpecific(action.type),
        },
        id
      );
      break;
    case Types.CREATE_USER:
      yield call(
        apiRequestAndSuccess,
        'consumptionProfileUserCreate',
        {
          success: consumptionProfileUserCreateSuccess,
          uiSuccess: makeUiAddSuccessSpecific(action.type),
        },
        id
      );
      break;
  }
}

function* handleUpdate(action: PayloadAction<ConsumptionProfileType>) {
  const payload = action.payload;
  switch (action.type) {
    case Types.UPDATE_GROUP:
      yield call(
        apiRequestAndSuccess,
        'consumptionProfileGroupUpdate',
        {
          success: consumptionProfileGroupUpdateSuccess,
          uiSuccess: makeUiAddSuccessSpecific(action.type),
        },
        payload.ruleId,
        payload
      );
      break;
    case Types.UPDATE_USER:
      yield call(
        apiRequestAndSuccess,
        'consumptionProfileUserUpdate',
        {
          success: consumptionProfileUserUpdateSuccess,
          uiSuccess: makeUiAddSuccessSpecific(action.type),
        },
        payload.ruleId,
        payload
      );
      break;
  }
}

function* handleDelete(action: PayloadAction<ID>) {
  const id = action.payload;
  switch (action.type) {
    case Types.DELETE_GROUP:
      yield requestSimple(
        api,
        'consumptionProfileGroupDelete',
        action.type,
        undefined,
        id
      );
      const metadataListGroup = yield select(
        selectorMetadata(Types.LIST_GROUP)
      );
      yield put(listConsumptionGroup(metadataListGroup));
      break;
    case Types.DELETE_USER:
      yield requestSimple(
        api,
        'consumptionProfileUserDelete',
        action.type,
        undefined,
        id
      );
      const metadataListUser = yield select(selectorMetadata(Types.LIST_USER));
      yield put(listConsumptionUser(metadataListUser));
      break;
  }
}

export default function* consumptionProfileSaga() {
  yield takeLatest(Types.GET_GENERAL, safe(handleGeneralGet));
  yield takeLatest(Types.UPDATE_GENERAL, safe(handleGeneralUpdate));
  yield takeLatest(Types.LIST_GROUP, safe(handleList));
  yield takeLatest(Types.GET_GROUP, safe(handleGet));
  yield takeLatest(Types.CREATE_GROUP, safe(handleCreation));
  yield takeLatest(Types.UPDATE_GROUP, safe(handleUpdate));
  yield takeLatest(Types.DELETE_GROUP, safe(handleDelete));
  yield takeLatest(Types.LIST_USER, safe(handleList));
  yield takeLatest(Types.GET_USER, safe(handleGet));
  yield takeLatest(Types.CREATE_USER, safe(handleCreation));
  yield takeLatest(Types.UPDATE_USER, safe(handleUpdate));
  yield takeLatest(Types.DELETE_USER, safe(handleDelete));
}
