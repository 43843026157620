import SwitchButton from '../../../../../../../../SwitchButton';
import { CommonProps } from './InputForDynamic';

export default function SwitchForDynamic({
  name,
  onChange,
  value,
  isDisabled,
}: CommonProps) {
  return (
    <SwitchButton
      name={name}
      isChecked={value === 'true' || value === true ? true : false}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
}
