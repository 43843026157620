import { IntlShape } from 'react-intl';
import Swal from 'sweetalert2';

import Alert, { AlertHtml } from '../Alert';

interface GenerateReportAlertProps {
  intl: IntlShape;
  handleCompleteReport: () => void;
  handleFirstPageReport: () => void;
}

const GenerateReportAlert = ({
  intl,
  handleCompleteReport,
  handleFirstPageReport,
}: GenerateReportAlertProps) => {
  const generateCompleteReportAlert = () => {
    Alert({
      onConfirm: () => handleCompleteReport(),
      disabledCancel: true,
      html: (
        <AlertHtml
          irreversible={intl.formatMessage({
            id: 'generate_complete_report_alert.text',
          })}
          text={intl.formatMessage({
            id: 'generate_complete_report_alert.title',
          })}
        />
      ),
      confirmButtonText: intl.formatMessage({
        id: 'generate_complete_report_alert.confirm_button',
      }),
    });
  };

  Alert({
    onConfirm: () => handleFirstPageReport(),
    onCancel: () => generateCompleteReportAlert(),
    cancelReason: Swal.DismissReason.cancel,
    html: (
      <AlertHtml
        irreversible={intl.formatMessage({ id: 'generate_report_alert.title' })}
        text={intl.formatMessage({ id: 'generate_report_alert.sub_title' })}
      />
    ),
    confirmButtonText: intl.formatMessage({
      id: 'generate_report_alert.button.first_page',
    }),
    cancelButtonText: intl.formatMessage({
      id: 'generate_report_alert.button.complete',
    }),
  });
};

export default GenerateReportAlert;
