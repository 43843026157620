import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionItemProps,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Card from '../Card';
import { CardContentProps, CardItemType } from '../CardContent';
import Text from '../Text';

export interface CardContainerProps extends AccordionItemProps {
  title?: string;
  isHidden?: boolean;
  options?: CardItemType[];
  renderChildren?: (props: CardContentProps) => JSX.Element;
  searchWord?: string;
}

const AccordionContainer = ({
  title,
  isHidden,
  options,
  renderChildren: RenderChildren,
  searchWord,
  ...rest
}: CardContainerProps) => {
  if (isHidden) {
    return null;
  }

  return (
    <AccordionItem border="none" boxShadow="base" borderRadius="10px" {...rest}>
      <Card w="100%" p="0">
        <AccordionButton minH="81px" borderRadius="10px">
          <Text
            ml="1%"
            flex="1"
            textAlign="left"
            m={0}
            fontSize={'24px'}
            fontWeight="light"
          >
            <FormattedMessage id={title} />
          </Text>

          <AccordionIcon boxSize={8} color="gray.600" />
        </AccordionButton>

        <AccordionPanel fontSize="sm">
          {RenderChildren &&
            RenderChildren({
              cardItems: options,
              searchWord,
            })}
        </AccordionPanel>
      </Card>
    </AccordionItem>
  );
};

export default AccordionContainer;
