import React, { ReactNode } from 'react';

import Text from '../../Text';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';

interface ModalTemplateProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  textConfirm?: string | ReactNode;
  textCancel?: string | ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

const ModalTemplate = ({
  onConfirm,
  onCancel,
  textCancel,
  textConfirm,
  title,
  children,
  description,
}: ModalTemplateProps) => {
  return (
    <>
      <ModalHeader alignItems="start" px={6} pt={6}>
        <Text color="gray.900" fontWeight="bold" fontSize="22px" m={0}>
          {title}
        </Text>

        {description && (
          <Text color="gray.500" fontWeight="light" fontSize="md" m={0} mt={4}>
            {description}
          </Text>
        )}
      </ModalHeader>
      <ModalBody w="full" alignItems="start" px={6} pb={6}>
        {children}
      </ModalBody>
      {(onConfirm || onCancel || textConfirm || textCancel) && (
        <ModalFooter
          labelCancel={textCancel && textCancel}
          labelConfirm={textConfirm && textConfirm}
          onConfirm={onConfirm && onConfirm}
          onCancel={onCancel && onCancel}
        />
      )}
    </>
  );
};

export default ModalTemplate;
