import { PrivilegeEnum } from '../helper';
import routes from '../routes';
import { useFeature } from './useFeature';
import { useAppSelector } from './useRedux';

export const usePrivilege = () => {
  const user = useAppSelector((state) => state.auth.user);
  const isHelpDeskSession = useAppSelector(
    (state) => state.auth.isHelpDeskSession
  );
  const { handleCheckEnableFeature } = useFeature();
  const privilege = user?.privilege;
  const enterpriseId = !!user?.company?.enterpriseId;

  const company = enterpriseId ? [PrivilegeEnum.COMPANY] : [];
  const group = enterpriseId ? [PrivilegeEnum.GROUP] : [];
  const helpDesk = isHelpDeskSession ? [PrivilegeEnum.HELP_DESK] : [];
  const backoffice = [PrivilegeEnum.BACKOFFICE];
  const carrier =
    privilege === PrivilegeEnum.CARRIER ? [PrivilegeEnum.CARRIER] : [];

  const firstLogin = !enterpriseId;

  /** Returns `true` if the user has any of the privileges passed as a parameter */
  const verifyPrivilege = (...privileges: PrivilegeEnum[]): boolean => {
    const authenticated = privileges.reduce(
      (authenticated, privilegeToVerify, i, array) => {
        if (authenticated) {
          array.splice(1);
          return authenticated;
        }

        return privilege === privilegeToVerify;
      },
      false
    );

    return authenticated;
  };

  const menuItems = handleCheckEnableFeature({
    dashboard: verifyPrivilege(...company, ...group, ...helpDesk),
    companies: verifyPrivilege(...carrier),
    company: verifyPrivilege(...company, ...helpDesk),
    groups: verifyPrivilege(...company, ...group, ...helpDesk),
    users: verifyPrivilege(...company, ...group, ...helpDesk),
    devices: verifyPrivilege(...company, ...group, ...helpDesk),
    zeroTouch: verifyPrivilege(...company, ...backoffice),
    policies: verifyPrivilege(...company, ...helpDesk),
    applications: verifyPrivilege(...company, ...group, ...helpDesk),
    browser: verifyPrivilege(...company, ...group, ...helpDesk),
    messages: verifyPrivilege(...company, ...group, ...helpDesk),
    deviceLocation: verifyPrivilege(...company, ...group, ...helpDesk),
    sharedDocs: verifyPrivilege(...company, ...group, ...helpDesk),
    location: verifyPrivilege(...company, ...group, ...helpDesk),
    config: verifyPrivilege(...company, ...group, ...helpDesk),
    help: verifyPrivilege(...company, ...group, ...helpDesk, ...carrier),
    enterpriseApps: verifyPrivilege(...company, ...helpDesk),
  });

  const menuSubitems = handleCheckEnableFeature({
    company: verifyPrivilege(...company, ...helpDesk),
    companies: verifyPrivilege(...carrier),
    groups: verifyPrivilege(...company, ...group, ...helpDesk),
    deviceUsers: verifyPrivilege(...company, ...group, ...helpDesk),
    policies: verifyPrivilege(...company, ...helpDesk),
    adminUsers: verifyPrivilege(...company, ...group, ...helpDesk),
    browser: verifyPrivilege(...company, ...group, ...helpDesk),
    location: verifyPrivilege(...company, ...group, ...helpDesk),
    config: verifyPrivilege(...company, ...group, ...helpDesk),
    help: verifyPrivilege(...company, ...group, ...helpDesk, ...carrier),
    devices: verifyPrivilege(...company, ...group, ...helpDesk),
    zeroTouch: verifyPrivilege(...company, ...backoffice),
    enterpriseApps: verifyPrivilege(...company, ...helpDesk),
    registerPolicyIOS: verifyPrivilege(...company, ...helpDesk),
  });

  const pages: { [key in keyof typeof routes]?: boolean } =
    handleCheckEnableFeature({
      [routes.home]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk,
        ...carrier
      ),
      [routes.dashboard]: verifyPrivilege(...company, ...group, ...helpDesk),
      [routes.company.info]: verifyPrivilege(...company, ...helpDesk),
      [routes.company.register]: firstLogin,
      [routes.company.validEmail]: firstLogin,
      [routes.company.consumption]: verifyPrivilege(...company, ...helpDesk),
      [routes.company.license]: verifyPrivilege(...company, ...helpDesk),
      [routes.company.callback]:
        firstLogin || verifyPrivilege(...company, ...helpDesk),
      [routes.company.audit]: verifyPrivilege(...company, ...helpDesk),
      [routes.groups.manage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.groups.register]: verifyPrivilege(...company, ...helpDesk),
      [routes.groups.edit]: verifyPrivilege(...company, ...group, ...helpDesk),
      [routes.adminUsers.manage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.adminUsers.register]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.adminUsers.edit]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.sites.reportDate]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.sites.reportUrl]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.application.manage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.application.applicationDeviceUsers]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.application.consumptionHistory]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.application.consumptionHistoryByDeviceUser]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.messages.list]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.messages.details]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.messages.register]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.informes.deviceLocation]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.documents.list]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.documents.details]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.documents.register]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.device.manage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.device.battery]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.device.storage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.device.infos]: verifyPrivilege(...company, ...group, ...helpDesk),
      [routes.device.nonCompliance]: verifyPrivilege(...company, ...helpDesk),
      [routes.device.withoutCommunication]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.policies.register]: verifyPrivilege(...company, ...helpDesk),
      [routes.policies.manage]: verifyPrivilege(...company, ...helpDesk),
      [routes.policies.edit]: verifyPrivilege(...company, ...helpDesk),
      [routes.informes.locationHistory]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.geolocation]: verifyPrivilege(...company, ...group, ...helpDesk),
      [routes.profileConsumption.manage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.profileConsumption.register]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.profileConsumption.edit]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.generalConfig.networkSettings.list]: verifyPrivilege(
        ...company,
        ...helpDesk
      ),
      [routes.generalConfig.networkSettings.edit]: verifyPrivilege(
        ...company,
        ...helpDesk
      ),
      [routes.generalConfig.networkSettings.register]: verifyPrivilege(
        ...company,
        ...helpDesk
      ),
      [routes.generalConfig.manage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      // TODO Revisar e excluir rota velha
      [routes.generalConfig.edit]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      // TODO Revisar e excluir rota velha
      [routes.generalConfig.register]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.applicationConfig.manage]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.applicationConfig.register]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.applicationConfig.history]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.applicationConfig.historyStatus]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.applicationConfig.send]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.support]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk,
        ...carrier
      ),
      [routes.supportRequest]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk
      ),
      [routes.faq]: verifyPrivilege(
        ...company,
        ...group,
        ...helpDesk,
        ...carrier
      ),
      [routes.enterpriseApps.manage]: verifyPrivilege(...company, ...helpDesk),
      [routes.enterpriseApps.googlePlayManaged]: verifyPrivilege(...company),
      [routes.zeroTouch.manageZeroTouch]: verifyPrivilege(...company),
      [routes.zeroTouch.invoices.manage]: verifyPrivilege(...company),
      [routes.zeroTouch.invoices.edit]: verifyPrivilege(...company),
      [routes.zeroTouch.backoffice.manage]: verifyPrivilege(...backoffice),
      [routes.zeroTouch.backoffice.edit]: verifyPrivilege(...backoffice),
      [routes.policies.registerPoliceIOS]: verifyPrivilege(
        ...company,
        ...helpDesk
      ),
      [routes.helpDesk.companiesList]: verifyPrivilege(PrivilegeEnum.HELP_DESK),
      [routes.remoteView]: verifyPrivilege(...company, ...group),

      // [routes.externalApps.list]: verifyPrivilege(...company),
      // [routes.externalApps.details]: verifyPrivilege(...company),
      // [routes.externalApps.register]: verifyPrivilege(...company),
      [routes.carrier.manage]: verifyPrivilege(...carrier),
      [routes.carrier.register]: verifyPrivilege(...carrier),
      [routes.carrier.edit]: verifyPrivilege(...carrier),
      // [routes.remoteApps.list]: verifyPrivilege(
      //   ...company,
      //   ...group,
      //   ...helpDesk
      // ),
      // [routes.remoteApps.register]: verifyPrivilege(
      //   ...company,
      //   ...group,
      //   ...helpDesk
      // ),
      // [routes.remoteApps.details]: verifyPrivilege(
      //   ...company,
      //   ...group,
      //   ...helpDesk
      // ),
    });

  return {
    menuItems,
    menuSubitems,
    pages,
    accessFilterCompany: verifyPrivilege(
      PrivilegeEnum.COMPANY,
      PrivilegeEnum.HELP_DESK
    ),
    accessFilterGroup: verifyPrivilege(
      PrivilegeEnum.COMPANY,
      PrivilegeEnum.GROUP,
      PrivilegeEnum.HELP_DESK
    ),
    isHelpDesk: verifyPrivilege(PrivilegeEnum.HELP_DESK),
  };
};
