import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ChartConsumptionUserType,
  ChartResponseType,
  ConsumptionSemesterType,
  DataConsumptionAppResponseType,
  DevicesLocationTuple,
  NewUsersSemesterType,
  UsageTimeByApplicationsType,
} from '../../types/dashboard';
import { ConsumptionType, DeviceStatusType } from '../../types/device';
import { Autocomplete, LevelTab } from '../../types/util';

// Action Types

export const Types = {
  FILTERS: 'dashboard/FILTERS',
  DASHBOARD: 'dashboard/DASHBOARD',
  LIST_USERS: 'dashboard/LIST_USERS',
  LIST_LICENSES: 'dashboard/LIST_LICENSES',
  LIST_TOTAL_LICENSES: 'dashboard/LIST_TOTAL_LICENSES',
  LIST_DEVICES_MODE_KIOSK: 'dashboard/LIST_DEVICES_MODE_KIOSK',
  LIST_DEVICES_ACTIVE: 'dashboard/LIST_DEVICES_ACTIVE',
  LIST_DEVICES_WITHOUT_COMPLIANCE: 'dashboard/LIST_DEVICES_WITHOUT_COMPLIANCE',
  LIST_DEVICE_WITHOUT_COMMUNICATION:
    'dashboard/LIST_DEVICE_WITHOUT_COMMUNICATION',
  LIST_USAGE_TIME_APPLICATIONS: 'dashboard/LIST_USAGE_TIME_APPLICATIONS',
  LIST_MOST_VISITED_WEBSITES: 'dashboard/LIST_MOST_VISITED_WEBSITES',
  LIST_INVENTORY: 'dashboard/LIST_INVENTORY',
  LIST_DATA_CONSUMPTION_APP: 'dashboard/LIST_DATA_CONSUMPTION_APP',
  LIST_DATA_COMSUNPTION_USER: 'dashboard/LIST_DATA_COMSUNPTION_USER',
  LIST_SMS_CONSUMPTION: 'dashboard/LIST_SMS_CONSUMPTION',
  LIST_DATA_CONSUMPTION: 'dashboard/LIST_DATA_CONSUMPTION',
  LIST_CONSUMPTION_SEMESTER: 'dashboard/LIST_CONSUMPTION_SEMESTER',
  LIST_NEW_USERS_SEMESTER: 'dashboard/LIST_NEW_USERS_SEMESTER',
  LIST_DEVICES_LOCATION: 'dashboard/LIST_DEVICES_LOCATION',
};

export type DashboardLevelSelected = {
  name?: LevelTab;
  value?: Autocomplete;
  roaming?: boolean;
  nameId?: string;
};

interface DashboardState {
  filters: DashboardLevelSelected;
  devicesInventory: ChartResponseType;
  mostVisitedWebsites: ChartResponseType;
  usageTimeByApplications: UsageTimeByApplicationsType;
  usersTotalHeading: number;
  notActivatedLicenses: number;
  totalLicenses: number;
  devicesStatus: DeviceStatusType;
  devicesWithoutCommunication: number;
  consumptionSemester: ConsumptionSemesterType;
  devicesLocation: DevicesLocationTuple[];
  smsConsumption: ConsumptionType;
  dataConsumption: ConsumptionType;
  newUsersSemester: NewUsersSemesterType[];
  dataConsumptionApp: DataConsumptionAppResponseType;
  dataConsumptionUser: ChartConsumptionUserType;
}

const chartInitialValue = {
  total: 0,
  data: [],
};

const initialState: DashboardState = {
  filters: {},
  devicesInventory: chartInitialValue,
  mostVisitedWebsites: chartInitialValue,
  usageTimeByApplications: chartInitialValue,
  usersTotalHeading: 0,
  notActivatedLicenses: 0,
  totalLicenses: 0,
  dataConsumptionApp: chartInitialValue,
  dataConsumptionUser: chartInitialValue,
  devicesStatus: {},
  devicesWithoutCommunication: 0,
  consumptionSemester: null,
  devicesLocation: [],
  smsConsumption: null,
  dataConsumption: null,
  newUsersSemester: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardUpdateFilters: (
      state,
      action: PayloadAction<DashboardLevelSelected>
    ) => {
      state.filters = action.payload;
    },
    dashboardListDevicesStatusSuccess: (
      state,
      action: PayloadAction<DeviceStatusType>
    ) => {
      state.devicesStatus = { ...state.devicesStatus, ...action.payload };
    },
    dashboardListDevicesWithoutCommunicationSuccess: (
      state,
      action: PayloadAction<number>
    ) => {
      state.devicesWithoutCommunication = action.payload;
    },
    dashboardListUsersSuccess: (state, action: PayloadAction<number>) => {
      state.usersTotalHeading = action.payload;
    },
    dashboardListLicensesSuccess: (state, action: PayloadAction<number>) => {
      state.notActivatedLicenses = action.payload;
    },
    dashboardListTotalLicenses: (state, action: PayloadAction<number>) => {
      state.totalLicenses = action.payload;
    },
    dashboardUsageTimeByApplicationsSuccess: (
      state,
      action: PayloadAction<UsageTimeByApplicationsType>
    ) => {
      state.usageTimeByApplications = action.payload;
    },
    dashboardListMostVisitedWebsitesSuccess: (
      state,
      action: PayloadAction<ChartResponseType>
    ) => {
      state.mostVisitedWebsites = action.payload;
    },
    dashboardListDevicesInventorySuccess: (
      state,
      action: PayloadAction<ChartResponseType>
    ) => {
      state.devicesInventory = action.payload;
    },
    dashboardListDataConsumptionAppSuccess: (
      state,
      action: PayloadAction<DataConsumptionAppResponseType>
    ) => {
      state.dataConsumptionApp = action.payload;
    },
    dashboardListDataConsumptionUserSuccess: (
      state,
      action: PayloadAction<ChartConsumptionUserType>
    ) => {
      state.dataConsumptionUser = action.payload;
    },
    dashboardListSmsConsumptionSuccess: (
      state,
      action: PayloadAction<ConsumptionType>
    ) => {
      state.smsConsumption = action.payload;
    },
    dashboardListDataConsumptionSuccess: (
      state,
      action: PayloadAction<ConsumptionType>
    ) => {
      state.dataConsumption = action.payload;
    },
    dashboardListConsumptionSemesterSuccess: (
      state,
      action: PayloadAction<ConsumptionSemesterType>
    ) => {
      state.consumptionSemester = action.payload;
    },
    dashboardListDevicesLocationSuccess: (
      state,
      action: PayloadAction<DevicesLocationTuple[]>
    ) => {
      state.devicesLocation = action.payload;
    },
    dashboardListNewUsersSemesterSuccess: (
      state,
      action: PayloadAction<NewUsersSemesterType[]>
    ) => {
      state.newUsersSemester = action.payload;
    },
    cleanFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export default dashboardSlice.reducer;

// Action Creators

export const {
  dashboardUpdateFilters,
  dashboardListDevicesStatusSuccess,
  dashboardListDevicesWithoutCommunicationSuccess,
  dashboardUsageTimeByApplicationsSuccess,
  dashboardListUsersSuccess,
  dashboardListLicensesSuccess,
  dashboardListMostVisitedWebsitesSuccess,
  dashboardListDevicesInventorySuccess,
  dashboardListDataConsumptionAppSuccess,
  dashboardListDataConsumptionUserSuccess,
  dashboardListConsumptionSemesterSuccess,
  dashboardListSmsConsumptionSuccess,
  dashboardListDataConsumptionSuccess,
  dashboardListDevicesLocationSuccess,
  dashboardListNewUsersSemesterSuccess,
  dashboardListTotalLicenses,
  cleanFilters,
} = dashboardSlice.actions;

export const listDashboardComplete = createAction<DashboardLevelSelected>(
  Types.DASHBOARD
);

export const listDashboardUsers = createAction<DashboardLevelSelected>(
  Types.LIST_USERS
);

export const listDevicesModeKiosk = createAction<DashboardLevelSelected>(
  Types.LIST_DEVICES_MODE_KIOSK
);

export const listDevicesActive = createAction<DashboardLevelSelected>(
  Types.LIST_DEVICES_ACTIVE
);

export const listDevicesWithoutCompliance =
  createAction<DashboardLevelSelected>(Types.LIST_DEVICES_WITHOUT_COMPLIANCE);

export const listDevicesWithoutCommunication =
  createAction<DashboardLevelSelected>(Types.LIST_DEVICE_WITHOUT_COMMUNICATION);

export const listDashboardLicenses = createAction<DashboardLevelSelected>(
  Types.LIST_LICENSES
);

export const listDashboardTotalLicenses = createAction<DashboardLevelSelected>(
  Types.LIST_TOTAL_LICENSES
);

export const listUsageTimeByApplications = createAction<DashboardLevelSelected>(
  Types.LIST_USAGE_TIME_APPLICATIONS
);

export const listMostVisitedWebsites = createAction<DashboardLevelSelected>(
  Types.LIST_MOST_VISITED_WEBSITES
);

export const listDevicesInventory = createAction<DashboardLevelSelected>(
  Types.LIST_INVENTORY
);

export const listDashboardDataConsumptionApp =
  createAction<DashboardLevelSelected>(Types.LIST_DATA_CONSUMPTION_APP);

export const listDashboardDataConsumptionUser =
  createAction<DashboardLevelSelected>(Types.LIST_DATA_COMSUNPTION_USER);
export const listDashboardSmsConsumption = createAction<DashboardLevelSelected>(
  Types.LIST_SMS_CONSUMPTION
);

export const listDashboardDataConsumption =
  createAction<DashboardLevelSelected>(Types.LIST_DATA_CONSUMPTION);

export const listConsumptionSemester = createAction<DashboardLevelSelected>(
  Types.LIST_CONSUMPTION_SEMESTER
);

export const listDevicesLocation = createAction<DashboardLevelSelected>(
  Types.LIST_DEVICES_LOCATION
);

export const listNewUsersSemester = createAction<DashboardLevelSelected>(
  Types.LIST_NEW_USERS_SEMESTER
);
