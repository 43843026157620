import { Size } from 'portal-lib';
import { ActionCreator, AnyAction } from 'redux';

import { DeviceType } from './device';
import { DeviceUserType } from './deviceUser';
import { GroupType } from './group';
import { ID } from './util';

export enum AppActionTab {
  GENERAL = 'general',
  GROUP = 'groups',
  USER = 'users',
}

export interface actionsCreatorsCRUDType {
  list?: { [k in AppActionTab]: ActionCreator<AnyAction> };
  get?: { [k in AppActionTab]: ActionCreator<AnyAction> };
  create?: { [k in AppActionTab]: ActionCreator<AnyAction> };
  update?: { [k in AppActionTab]: ActionCreator<AnyAction> };
  delete?: { [k in AppActionTab]: ActionCreator<AnyAction> };
  apply?: { [k in AppActionTab]: ActionCreator<AnyAction> };
  selected?: { [k in AppActionTab]: ActionCreator<AnyAction> };
  clear?: { [k in AppActionTab]: ActionCreator<AnyAction> };
}

export enum ConsumptionProfileMode {
  LEVEL_UP = 'level_up',
  UNLIMITED = 'unlimited',
  ASSIGNED = 'assigned',
}

export enum OperationalSystemEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export interface RuleType {
  id: ID;
  limit?: number;
  mode: ConsumptionProfileMode;
  unit?: Size;
}

export interface ConsumptionProfileType {
  ruleId?: ID;
  data: RuleType;
  sms: RuleType;
  smsRoaming: RuleType;
  dataRoaming: RuleType;
}

export type GeneralConsumptionType = ConsumptionProfileType;

export interface GroupConsumptionType extends ConsumptionProfileType {
  group?: GroupType;
  operationalSystem?: OperationalSystemEnum;
}
export interface UserConsumptionType extends ConsumptionProfileType {
  device?: DeviceType;
  phoneNumber?: string;
  deviceUser?: DeviceUserType;
  operationalSystem?: OperationalSystemEnum;
}

export enum ConsumptionProfileTab {
  GENERAL = 'general',
  GROUP = 'groups',
  USER = 'users',
}
