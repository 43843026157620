import React from 'react';

import { CardItemType } from '../../../../../../CardContent';
import Input from '../../../../../../Input';
import { intlPolicyPassword, PasswordPolicyChildrenProps } from './index';

export const getAlphanumericPasswordSettings = ({
  passwordPolicies,
  handleChange,
}: PasswordPolicyChildrenProps) => {
  const AlphanumericPasswordList: CardItemType[] = [
    {
      title: `${intlPolicyPassword}.passwordMinimumLength`,
      children: (
        <Input
          inputProps={{
            name: 'passwordMinimumLength',
            value: passwordPolicies?.passwordMinimumLength || '',
            onChange: handleChange,
          }}
        />
      ),
    },
  ];

  return AlphanumericPasswordList;
};
