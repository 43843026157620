import { Flex, SimpleGrid, SimpleGridProps } from '@chakra-ui/layout';
import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import SwitchButton from '../../../../SwitchButton';
import Text from '../../../../Text';

export interface OptionType {
  label: string;
  value: string;
}

interface SwitchListType {
  name: string;
  switchListOptions: OptionType[];
  onChange: (name: string, value: string[]) => void;
  valuesToCompare?: string[];
  simpleGridStyles?: SimpleGridProps;
}

const SwitchList = ({
  switchListOptions,
  valuesToCompare,
  onChange: onChangeProp,
  name,
  simpleGridStyles,
}: SwitchListType) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    const { checked, name } = event?.target;

    let newValues = valuesToCompare;

    if (checked) {
      newValues = [value, ...valuesToCompare];
    } else {
      newValues = valuesToCompare?.filter((item) => item !== value);
    }

    onChangeProp(name, newValues);
  };

  return (
    <SimpleGrid columns={[1]} {...simpleGridStyles}>
      {switchListOptions?.map((option, index) => {
        const isChecked =
          valuesToCompare?.length > 0
            ? valuesToCompare?.some((value) => value === option.value)
            : false;

        return (
          <Flex mb="15px" align="center" key={index}>
            <SwitchButton
              name={name}
              onChange={(event) => onChange(event, option.value)}
              isChecked={isChecked}
            />
            <Text ml="15px">
              <FormattedMessage id={option?.label} />
            </Text>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};

export default SwitchList;
