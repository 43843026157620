import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { ManagementModeEnum } from '../../../../../types/policy';
import Tab from '../../../../Tab';
import Kiosk from '../Android/Kiosk';
import PolicyApps from '../Android/PolicyApps';
import PolicySettings from '../Android/PolicySettings';

const AndroidTab = () => {
  const { policy } = useAppSelector((state) => state.policy);
  const isWorkProfile =
    policy.managementMode === ManagementModeEnum.WORK_PROFILE_ANDROID;

  return (
    <Tabs mt="16" isLazy>
      <TabList mb="10">
        <Tab>
          <FormattedMessage id="update_policies.tab.configuration" />
        </Tab>
        <Tab>
          <FormattedMessage id="update_policies.tab.application" />
        </Tab>
        <Tab hidden={isWorkProfile}>
          <FormattedMessage id="update_policies.tab.kiosk" />
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <PolicySettings />
        </TabPanel>
        <TabPanel>
          <PolicyApps
            playStoreMode={policy?.playStoreMode}
            applications={policy?.applications}
          />
        </TabPanel>
        <TabPanel>
          <Kiosk policy={policy} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AndroidTab;
