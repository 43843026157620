import { Box, Tag } from '@chakra-ui/react';
import React from 'react';

import Heading from '../Heading';
import ManualLink from '../ManualLink';
import OperationalSystemIcons from '../OperationalSystemIcons';
import Text from '../Text';

interface PageTitleProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  contentTag?: React.ReactNode;
  showOperationalSystems?: boolean;
  hideAndroidIcon?: boolean;
  hideIOSIcon?: boolean;
  showManualIcon?: boolean;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  description,
  contentTag,
  showOperationalSystems,
  hideAndroidIcon,
  hideIOSIcon,
  showManualIcon,
}: PageTitleProps) => {
  const hasTag = showOperationalSystems || contentTag;

  return (
    <Box>
      <Heading display="flex">
        {title}
        {hasTag && (
          <Tag
            size="lg"
            variant="subtle"
            color="gray.300"
            m="10px"
            fontSize="xs"
            backgroundColor="transparent"
          >
            {showOperationalSystems && (
              <>
                <OperationalSystemIcons
                  hideIOS={hideIOSIcon}
                  hideAndroid={hideAndroidIcon}
                  size="xl"
                />
              </>
            )}
            {contentTag && contentTag}
          </Tag>
        )}

        {showManualIcon && (
          <Box ml="auto" display="inline">
            <ManualLink />
          </Box>
        )}
      </Heading>
      <Text>{description}</Text>
    </Box>
  );
};

export default PageTitle;
