import { Flex } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../../store/policy';
import {
  AutoDateTimeZoneEnum,
  PolicyType,
  SystemUpdateEnum,
} from '../../../../../../../types/policy';
import CardContent, { CardItemType } from '../../../../../../CardContent';
import FormControl from '../../../../../../FormControl';
import Input from '../../../../../../Input';
import Select from '../../../../../../Select';
import TimePicker from '../../../../../../TimePicker/TimePicker';
import { GetterSettingFn } from '../../../PoliciesInterfaces';
import FreezeSchedule from './FreezeSchedule';

export const intlPolicyOperationalSystem = 'update_policies.systemUpdate';

export const getOperationalSystemSettings: GetterSettingFn = ({
  intl,
  policy,
  handleChange: handleSourceChange,
}) => {
  const dispatch = useAppDispatch();
  const isWindowed = policy?.systemUpdate?.type === SystemUpdateEnum.WINDOWED;
  const TIME_INTERVAL = 30;

  const freezeTypeOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicyOperationalSystem}.select.unspecified`,
      }),
      value: SystemUpdateEnum.SYSTEM_UPDATE_TYPE_UNSPECIFIED,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyOperationalSystem}.select.automatic`,
      }),
      value: SystemUpdateEnum.AUTOMATIC,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyOperationalSystem}.select.windowed`,
      }),
      value: SystemUpdateEnum.WINDOWED,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyOperationalSystem}.select.postpone`,
      }),
      value: SystemUpdateEnum.POSTPONE,
    },
  ];

  const autoDateAndTimeZoneOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicyOperationalSystem}.auto_date_and_time_zone.defined_by_user`,
      }),
      value: AutoDateTimeZoneEnum.AUTO_DATE_AND_TIME_ZONE_USER_CHOICE,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyOperationalSystem}.auto_date_and_time_zone.automatic`,
      }),
      value: AutoDateTimeZoneEnum.AUTO_DATE_AND_TIME_ZONE_ENFORCED,
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    dispatch(
      policySelected({
        systemUpdate: {
          ...policy?.systemUpdate,
          [name]: value,
        },
      })
    );
  };

  const handlePeriodFilterChange = (date: Date, name: string) => {
    dispatch(
      policySelected({
        systemUpdate: {
          ...policy?.systemUpdate,
          [name]: date ? format(date, 'HH:mm') : null,
        },
      })
    );
  };

  const handleFormatStringToTime = (time: string | null) => {
    if (time) {
      const dateToString = new Date().toISOString().split('T')[0];
      return new Date(`${dateToString} ${time}`);
    }
    return null;
  };

  const handleNumberInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;

    const newValue = Number(value);

    if (!Number.isNaN(newValue)) {
      dispatch(
        policySelected({
          [name]: newValue,
        })
      );
    }
  };

  const listOperationalSystem: CardItemType[] = [
    {
      title: `${intlPolicyOperationalSystem}.minimum_api_level.title`,
      description: `${intlPolicyOperationalSystem}.minimum_api_level.description`,
      children: (
        <Input
          inputProps={{
            name: 'minimumApiLevel',
            value: policy?.minimumApiLevel || '',
            onChange: handleNumberInputChange,
          }}
        />
      ),
    },
    {
      title: `${intlPolicyOperationalSystem}.auto_date_and_time_zone.title`,
      description: `${intlPolicyOperationalSystem}.auto_date_and_time_zone.description`,
      children: (
        <Select
          name="autoDateAndTimeZone"
          value={policy?.autoDateAndTimeZone}
          onChange={handleSourceChange}
        >
          {autoDateAndTimeZoneOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicyOperationalSystem}.select.type`,
      description: `${intlPolicyOperationalSystem}.select.type.description`,
      children: (
        <Select
          name="type"
          value={policy?.systemUpdate?.type}
          onChange={handleChange}
        >
          {freezeTypeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicyOperationalSystem}.time`,
      description: `${intlPolicyOperationalSystem}.time.description`,
      children: (
        <Flex gridGap={3}>
          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicyOperationalSystem}.startMinutes`}
              />
            }
          >
            <TimePicker
              disabled={!isWindowed}
              textLabel={intl.formatMessage({
                id: `${intlPolicyOperationalSystem}.time.select`,
              })}
              onChange={(e) => handlePeriodFilterChange(e, 'startMinutes')}
              selected={handleFormatStringToTime(
                policy?.systemUpdate?.startMinutes
              )}
              hasValue={!!policy?.systemUpdate?.startMinutes}
              timeIntervals={TIME_INTERVAL}
            />
          </FormControl>
          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicyOperationalSystem}.endMinutes`}
              />
            }
          >
            <TimePicker
              disabled={!isWindowed}
              textLabel={intl.formatMessage({
                id: `${intlPolicyOperationalSystem}.time.select`,
              })}
              selected={handleFormatStringToTime(
                policy?.systemUpdate?.endMinutes
              )}
              onChange={(e) => handlePeriodFilterChange(e, 'endMinutes')}
              hasValue={!!policy?.systemUpdate?.endMinutes}
              timeIntervals={TIME_INTERVAL}
            />
          </FormControl>
        </Flex>
      ),
    },
    {
      title: `update_policies.freezePeriods`,
      description: `${intlPolicyOperationalSystem}.freeze_period.description`,
    },
  ];

  return listOperationalSystem;
};

interface Props {
  cardItems: CardItemType[];
  policy: PolicyType;
  searchWord?: string;
}

const OperationalSystem = ({ cardItems, policy, searchWord }: Props) => {
  return (
    <>
      <CardContent searchWord={searchWord} cardItems={cardItems} />

      <Flex px={6} justify="center">
        <FreezeSchedule freezePeriods={policy?.systemUpdate?.freezePeriods} />
      </Flex>
    </>
  );
};

export default OperationalSystem;
