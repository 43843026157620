import { Box, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { iconImgToRender } from '../../../helper';
import { sanitizeFilter } from '../../../helper/filter';
import { useAppSelector } from '../../../hooks/useRedux';
import CardHeader from '../../CardHeader';

interface ApplicationHeaderProps {
  intlKey: string;
}

const ApplicationHeader = ({ intlKey }: ApplicationHeaderProps) => {
  const { application, filter } = useAppSelector((state) => state.application);
  const intl = useIntl();

  const showDescription = !!filter.deviceUser || !!filter.group;

  const filters = {
    deviceUser: {
      translation: intl.formatMessage({
        id: `${intlKey}.filter.device_user`,
      }),
      parseValue: (value) =>
        `${value?.name || ''} ${value?.device?.phoneNumber || ''}`,
    },
    group: {
      translation: intl.formatMessage({
        id: `${intlKey}.filter.group`,
      }),
      parseValue: (value) => `${value?.name}`,
    },
  };

  const appliedFilters = useMemo(() => {
    const filterElements = Object.entries(sanitizeFilter(filter)).map(
      ([key]) => {
        return (
          <span key={key}>
            {filter[key] ? filters[key].translation : null}:
            <em>{filter[key] ? filters[key].parseValue(filter[key]) : null}</em>
          </span>
        );
      }
    );
    return filterElements.reduce((accumulator, current, index) => {
      if (index === 0) return current;
      return (
        <>
          {accumulator}, {current}
        </>
      );
    }, <></>);
  }, [filter]);

  return (
    <CardHeader
      description={
        !!showDescription && (
          <Box d="flex" flexDirection="column">
            <Text color="gray.500" fontSize="xs" fontWeight="normal" mt="8px">
              <FormattedMessage id={'history_consumption.header.filter_by'} />
              {appliedFilters}
            </Text>
          </Box>
        )
      }
      iconUrl={iconImgToRender(application?.iconBase64)}
      title={application?.name}
      packageName={application?.packageName}
    />
  );
};

export default ApplicationHeader;
