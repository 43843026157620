import { Box } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import { invoiceDeviceStatusToIntl } from '../../../components/pages/ZeroTouch/utils';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import TableActions from '../../../components/TableActions';
import { routeWithParameters } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useFilter } from '../../../hooks/useFilter';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  listBackofficeInvoices,
  setBackofficeInvoicesMetadata,
  Types,
} from '../../../store/invoice';
import { hasError } from '../../../store/ui';
import { InvoiceDeviceStatusEnum } from '../../../types/invoice';
enum StatusFilterEnum {
  PENDENCY = 'PENDENCY',
  ALL = 'ALL',
}

type StatusFilterType = StatusFilterEnum | InvoiceDeviceStatusEnum;

interface Filters {
  search: string;
  startDate?: Date;
  endDate?: Date;
  zeroTouchPending?: StatusFilterType;
}

const ManageZeroTouchRegister = () => {
  const TODAY = new Date();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { backofficeInvoices, backofficeInvoicesMetadata: metadata } =
    useAppSelector((state) => state.invoice);
  const listApiError = hasError(Types.BACKOFFICE_LIST);
  const [filters, setFilters] = useState<Filters>({
    search: '',
    zeroTouchPending: StatusFilterEnum.PENDENCY,
  });
  const {
    data: paginatedInvoices,
    totalPages,
    totalItems,
  } = useFilter(backofficeInvoices, {
    pagination: {
      metadata,
    },
    filter: (query) =>
      query
        .textSearch(
          ['company.corporateName', 'company.federalCode', 'invoiceNumber'],
          filters.search
        )
        .startDate('createdAt', filters.startDate)
        .endDate('createdAt', filters.endDate)
        .includes(
          'devices',
          'status',
          filters?.zeroTouchPending === StatusFilterEnum.ALL
            ? undefined
            : filters?.zeroTouchPending === StatusFilterEnum.PENDENCY
            ? [
                InvoiceDeviceStatusEnum.WAITING,
                InvoiceDeviceStatusEnum.DISAPPROVE,
              ]
            : [filters?.zeroTouchPending]
        ),
  });

  const backofficeStatusOptions: StatusFilterType[] = [
    InvoiceDeviceStatusEnum.WAITING,
    InvoiceDeviceStatusEnum.DISAPPROVE,
    InvoiceDeviceStatusEnum.REJECT,
    InvoiceDeviceStatusEnum.REGISTERED,
    StatusFilterEnum.ALL,
    StatusFilterEnum.PENDENCY,
  ];

  const backofficeStatusToIntl = {
    ...invoiceDeviceStatusToIntl,
    [StatusFilterEnum.PENDENCY]:
      'manage_zero_touch_register.zero_touch_pending.pendency',
    [StatusFilterEnum.ALL]: 'manage_zero_touch_register.zero_touch_pending.all',
  };

  const apiErrorMessages = {
    500: 'manage_zero_touch_register.error_message.bad_request',
  };

  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'manage_zero_touch_register.column.company',
        }),
        accessor: 'companyName',
      },
      {
        header: intl.formatMessage({
          id: 'manage_zero_touch_register.column.cnpj',
        }),
        accessor: 'companyFederalCode',
      },
      {
        header: intl.formatMessage({
          id: 'manage_zero_touch_register.column.invoice_number',
        }),
        accessor: 'invoiceNumber',
      },
      {
        header: intl.formatMessage({
          id: 'manage_zero_touch_register.column.pending_devices',
        }),
        accessor: 'pendingDevices',
      },
      {
        header: '',
        accessor: 'actions',
      },
    ],
    metadata
  );

  const rows = paginatedInvoices?.map((invoice) => ({
    cells: [
      {
        field: 'companyName',
        value: invoice.company.corporateName,
      },
      {
        field: 'companyFederalCode',
        value: invoice.company.federalCode,
      },
      {
        field: 'invoiceNumber',
        value: invoice.invoiceNumber,
      },
      {
        field: 'pendingDevices',
        value: invoice?.devicesStatusPendent,
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={
                <FormattedMessage id="manage_zero_touch_register.menu_action.details" />
              }
              linkView={routeWithParameters(routes.zeroTouch.backoffice.edit, {
                id: invoice?.id,
              })}
            />
          );
        },
      },
    ],
  }));

  const handleSetFilter = (field: keyof Filters) => (value: unknown) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
    dispatch(setBackofficeInvoicesMetadata({ ...metadata, page: 1 }));
  };

  const handleMetadata = (newMetadata) =>
    dispatch(setBackofficeInvoicesMetadata({ ...metadata, ...newMetadata }));

  useEffect(() => {
    dispatch(listBackofficeInvoices(inFirstPage(metadata)));
  }, []);

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="manage_zero_touch_register.title" />}
        description={
          <FormattedMessage id="manage_zero_touch_register.description" />
        }
      />

      <PageShowToaster
        toasterKeys={[Types.BACKOFFICE_LIST]}
        disabledSuccess
        messageError={
          apiErrorMessages[listApiError?.statusCode] && (
            <FormattedMessage id={apiErrorMessages[listApiError?.statusCode]} />
          )
        }
      />

      <FormContainer>
        <PageFilter gridGap={3}>
          <FormControl
            as={Flex}
            flexDirection="column"
            justifyContent="space-between"
            w="350px"
            textLabel={
              <FormattedMessage id="manage_zero_touch_register.release_date" />
            }
          >
            <Flex w="350px" gridGap={4}>
              <Box w="165px">
                <DatePicker
                  maxDate={filters.endDate || TODAY}
                  hasValue={filters.startDate}
                  selected={filters.startDate}
                  placeholder={intl.formatMessage({
                    id: 'manage_zero_touch_register.start_date',
                  })}
                  onChange={(date) => {
                    handleSetFilter('startDate')(date);
                  }}
                />
              </Box>
              <Box w="165px">
                <DatePicker
                  minDate={filters.startDate}
                  maxDate={TODAY}
                  hasValue={filters.endDate}
                  selected={filters.endDate}
                  placeholder={intl.formatMessage({
                    id: 'manage_zero_touch_register.end_date',
                  })}
                  onChange={(date) => {
                    handleSetFilter('endDate')(date);
                  }}
                />
              </Box>
            </Flex>
          </FormControl>

          <FormControl
            textLabel={
              <FormattedMessage id="manage_zero_touch_register.zero_touch_status" />
            }
          >
            <Select
              maxW="250px"
              name="zeroTouchPending"
              value={filters.zeroTouchPending || ''}
              onChange={(e) =>
                handleSetFilter('zeroTouchPending')(e.target.value)
              }
            >
              {backofficeStatusOptions.map((option) => (
                <option key={`${option}`} value={option}>
                  {intl.formatMessage({
                    id: backofficeStatusToIntl[option],
                  })}
                </option>
              ))}
            </Select>
          </FormControl>
        </PageFilter>

        <Table
          headerColumns={columns}
          rows={rows}
          keyProp={Types.BACKOFFICE_LIST}
          nameTable={intl.formatMessage({
            id: 'manage_zero_touch_register.title',
          })}
          metadata={{
            page: metadata.page,
            pageSize: metadata.pageSize,
            totalPages,
            totalItems: totalItems,
          }}
          handleSort={handleMetadata}
          pageActionsProps={{
            onSearch: handleSetFilter('search'),
            initialSearch: filters.search,
            disabledAllExportsActions: true,
          }}
        />
      </FormContainer>
    </>
  );
};

export default ManageZeroTouchRegister;
