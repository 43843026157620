import { Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { intlPolicySettingsKey } from '.';
import { validateStartsWithLetters } from '../../../../../../helper/validador';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import { CardItemType } from '../../../../../CardContent';
import InputKeywords from '../../../../../InputKeywords';
import SwitchButton from '../../../../../SwitchButton';
import { GetterSettingFn } from '../../PoliciesInterfaces';

export const getUserSettings: GetterSettingFn = ({ policy, handleChange }) => {
  const dispatch = useAppDispatch();

  const handleAddKeyword = (propertyToFill: string, packageName: string) => {
    const newKeywords = [packageName, ...(policy?.[propertyToFill] || [])];

    dispatch(policySelected({ [propertyToFill]: newKeywords }));
  };

  const handleDeleteKeyword = (
    propertyToFill: string,
    deletedKeyword: string
  ) => {
    const newKeywords = (policy?.[propertyToFill] || []).filter(
      (keyword) => keyword !== deletedKeyword
    );

    dispatch(policySelected({ [propertyToFill]: newKeywords }));
  };

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.user.skip_first_use_hints_enabled.title`,
      description: `${intlPolicySettingsKey}.user.skip_first_use_hints_enabled.description`,
      children: (
        <SwitchButton
          name="skipFirstUseHintsEnabled"
          isChecked={policy?.skipFirstUseHintsEnabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.user.set_wallpaper_disabled.title`,
      description: `${intlPolicySettingsKey}.user.set_wallpaper_disabled.description`,
      children: (
        <SwitchButton
          name="setWallpaperDisabled"
          isChecked={policy?.setWallpaperDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.user.set_user_icon_disabled.title`,
      description: `${intlPolicySettingsKey}.user.set_user_icon_disabled.description`,
      children: (
        <SwitchButton
          name="setUserIconDisabled"
          isChecked={policy?.setUserIconDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.user.credentials_config_disabled.title`,
      description: `${intlPolicySettingsKey}.user.credentials_config_disabled.description`,
      children: (
        <SwitchButton
          name="credentialsConfigDisabled"
          isChecked={policy?.credentialsConfigDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.user.permitted_input_methods.title`,
      description: `${intlPolicySettingsKey}.user.permitted_input_methods.description`,
      customStyles: {
        gridGap: 4,
        direction: 'column',
        alignItems: 'inherit',
      },
      children: (
        <Flex justify="center" w="full" mt={2}>
          <Flex w="full" maxW="45rem">
            <InputKeywords
              name="permittedInputMethods"
              keywords={policy?.permittedInputMethods || []}
              handleSubmit={handleAddKeyword}
              onConfirmDelete={handleDeleteKeyword}
              validateKeyword={validateStartsWithLetters}
              validateErrorMessage="update_policies.keyword_error.start_only_with_letters"
              emptyMessage={
                <FormattedMessage
                  id={`${intlPolicySettingsKey}.user.permitted_input_methods.empty_list`}
                />
              }
            />
          </Flex>
        </Flex>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.user.permitted_accessibility_services.title`,
      description: `${intlPolicySettingsKey}.user.permitted_accessibility_services.description`,
      customStyles: {
        gridGap: 4,
        direction: 'column',
        alignItems: 'inherit',
      },
      children: (
        <Flex justify="center" w="full" mt={2}>
          <Flex w="full" maxW="45rem">
            <InputKeywords
              name="permittedAccessibilityServices"
              keywords={policy?.permittedAccessibilityServices || []}
              handleSubmit={handleAddKeyword}
              onConfirmDelete={handleDeleteKeyword}
              validateKeyword={validateStartsWithLetters}
              validateErrorMessage="update_policies.keyword_error.start_only_with_letters"
              emptyMessage={
                <FormattedMessage
                  id={`${intlPolicySettingsKey}.user.permitted_accessibility_services.empty_list`}
                />
              }
            />
          </Flex>
        </Flex>
      ),
    },
  ];

  return listSettings;
};
