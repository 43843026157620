import { DeviceUserType } from './deviceUser';
import { GroupType } from './group';
import { ID } from './util';

export enum ResetPasswordFlagEnum {
  REQUIRE_ENTRY = 'REQUIRE_ENTRY',
  DO_NOT_ASK_CREDENTIALS_ON_BOOT = 'DO_NOT_ASK_CREDENTIALS_ON_BOOT',
  LOCK_NOW = 'LOCK_NOW',
}

export enum DeviceStateEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
  PROVISIONING = 'PROVISIONING',
  AWAIT_ENTERPRISE_ENROLL = 'AWAIT_ENTERPRISE_ENROLL',
}

export interface DeviceType {
  id?: ID;
  name?: string;
  federalCode?: string;
  phoneNumber?: string;
  serialNumber?: string;
  imei?: string;
  iccid?: string;
  manufacturer?: ManufacturerType['manufacturer'];
  model?: ModelType['model'];
  state?: DeviceStateEnum;
  appliedState?: string;
  policyCompliant?: boolean;
  updatedAt?: Date;
  createdAt?: Date;
  enterpriseUpdatedAt?: string;
  lastPolicySyncTime?: string;
  group?: GroupType;
  androidVersion?: string;
  appliedPolicyName?: string;
  policyName?: string;
  policy?: PolicyType;
  freeMemory?: number;
  battery?: number;
  hasNonComplianceDetails?: boolean;
  lastSyncAt?: string;
  deviceUser?: DeviceUserType;
  operationalSystem?: OperationalSystemEnum;
  udid?: string;
  emailAppleBusinessManager?: string;
  lastCommunication?: Date;
  companionVersion?: string;
  permissions?: {
    accessUsageData?: boolean;
    readSms?: boolean;
    ignoreBatteryOptimization?: boolean;
    writeSystemSettings?: boolean;
  };
  kioskAccessToken?: DeviceKioskAccessToken;
}

export interface DeviceKioskAccessToken {
  token: string;
  /** Duration in seconds */
  duration: number;
}

export interface PolicyType {
  id?: ID;
  version?: string;
  name?: string;
  policyType?: string;
  managementMode?: string;
  remoteViewEnabled?: boolean;
}

export interface PasswordsHistoricType {
  password?: number;
  createdAt?: string;
}

export interface ManufacturerType {
  manufacturer: string;
}

export interface InventoryItemType {
  manufacturer?: string;
  count?: number;
}

export interface ModelType {
  model: string;
}

export interface DeviceStatusType {
  active?: number;
  noCompilation?: number;
  modeKiosk?: number;
}
export interface ConsumptionType {
  limit: number;
  used: number;
}

export enum DeviceActionEnum {
  LOCK = 'LOCK',
  DISABLE = 'DISABLE',
  REBOOT = 'REBOOT',
  WIPE = 'WIPE',
  ENABLE = 'ENABLE',
}

export enum OperationalSystemEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export interface BatchDevicesActionsForm {
  id?: ID;
  commandType?: DeviceActionEnum;
  groupIds?: ID[];
  userIds?: ID[];
  companyId?: ID;
  isCompany?: boolean;
}

export const deviceStateToIntl = {
  [DeviceStateEnum.ACTIVE]: 'devices.value.active',
  [DeviceStateEnum.DISABLED]: 'devices.value.disabled',
  [DeviceStateEnum.DELETED]: 'devices.value.deleted',
  [DeviceStateEnum.PROVISIONING]: 'devices.value.provisioning',
  [DeviceStateEnum.AWAIT_ENTERPRISE_ENROLL]:
    'devices.value.await_enterprise_enroll',
};
