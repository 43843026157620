import { Box, Flex } from '@chakra-ui/react';
import _ from 'lodash';
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { intlPolicySettingsKey } from '../..';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/useRedux';
import {
  listEnterpriseAppsToModal,
  Types,
} from '../../../../../../../../store/enterpriseApps';
import { uiRemoveMetadata } from '../../../../../../../../store/ui';
import { ChoosePrivateKeyRuleType } from '../../../../../../../../types/policy';
import Button from '../../../../../../../Button';
import FormContainer from '../../../../../../../FormContainer';
import FormControl from '../../../../../../../FormControl';
import Input from '../../../../../../../Input';
import InputKeywords from '../../../../../../../InputKeywords';
import Modal, { ModalBody, ModalHeader } from '../../../../../../../Modal';
import Text from '../../../../../../../Text';

interface Props {
  isOpen: boolean;
  editRule?: ChoosePrivateKeyRuleType;
  onClose: () => void;
  onAdd: (data: ChoosePrivateKeyRuleType) => void;
  onEdit: (data: ChoosePrivateKeyRuleType) => void;
}

interface FormData {
  privateKeyAlias: string;
  urlPattern: string;
  packageNames: string[];
}

export default function AddPrivateKeyModal({
  isOpen,
  editRule,
  onClose,
  onAdd,
  onEdit,
}: Props) {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { enterpriseAppsModal } = useAppSelector(
    (state) => state.enterpriseApps
  );
  const { policy } = useAppSelector((state) => state.policy);
  const [formData, setFormData] = useState<FormData>({
    privateKeyAlias: '',
    urlPattern: '',
    packageNames: [],
  });
  const isEdit = useMemo(() => !!editRule, [editRule]);
  const aliasError = useMemo(() => {
    return !!policy.choosePrivateKeyRules?.some(
      (rule) =>
        rule.privateKeyAlias === formData.privateKeyAlias &&
        (isEdit ? rule.privateKeyAlias !== editRule.privateKeyAlias : true)
    );
  }, [formData.privateKeyAlias, isEdit]);

  const disableSave = useMemo(() => {
    const hasDataToSave = !!formData.privateKeyAlias && !!formData.urlPattern;

    if (isEdit && !aliasError) {
      return _.isEqual(editRule, formData) || !hasDataToSave;
    }

    return aliasError || !hasDataToSave;
  }, [isEdit, aliasError, formData]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddPackageName = (
    name: string,
    newPackageName: FormData['packageNames'][0]
  ) => {
    setFormData((prev) => ({
      ...prev,
      [name]: [newPackageName, ...prev[name]],
    }));
  };

  const handleDeletePackageName = (
    name: string,
    deletePackageName: FormData['packageNames'][0]
  ) => {
    setFormData((prev) => ({
      ...prev,
      [name]: prev[name].filter(
        (packageName) => packageName !== deletePackageName
      ),
    }));
  };

  const handleSubmit = () => {
    isEdit ? onEdit(formData) : onAdd(formData);
    onClose();
  };

  useEffect(() => {
    dispatch(listEnterpriseAppsToModal({}));
    return () => {
      dispatch(uiRemoveMetadata(Types.LIST));
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        privateKeyAlias: '',
        urlPattern: '',
        packageNames: [],
      });
    }
    if (isOpen && isEdit) {
      setFormData(editRule);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} modalWidth="750px">
      <ModalHeader>
        <Text w="full" m={0} fontWeight="bold">
          <FormattedMessage
            id={`${intlPolicySettingsKey}.safety.choose_private_key_rule.add_modal.title`}
          />
        </Text>
      </ModalHeader>

      <ModalBody>
        <FormContainer w="full">
          <Flex direction="column" gridGap={1}>
            <FormControl
              textLabel={intl.formatMessage({
                id: `${intlPolicySettingsKey}.safety.choose_private_key_rule.add_modal.private_key_alias`,
              })}
            >
              <Input
                inputProps={{
                  name: 'privateKeyAlias',
                  value: formData.privateKeyAlias,
                  onChange: handleInputChange,
                }}
              />

              <Box
                color="warning.500"
                fontSize="sm"
                fontWeight="bold"
                h="21px"
                ml="1"
              >
                {aliasError && (
                  <FormattedMessage
                    id={`${intlPolicySettingsKey}.safety.choose_private_key_rule.add_modal.same_alias_error`}
                  />
                )}
              </Box>
            </FormControl>

            <FormControl
              textLabel={intl.formatMessage({
                id: `${intlPolicySettingsKey}.safety.choose_private_key_rule.add_modal.url_pattern`,
              })}
              mb="21px"
            >
              <Input
                inputProps={{
                  name: 'urlPattern',
                  value: formData.urlPattern,
                  onChange: handleInputChange,
                }}
              />
            </FormControl>

            <FormControl
              textLabel={intl.formatMessage({
                id: `${intlPolicySettingsKey}.safety.choose_private_key_rule.add_modal.package_names`,
              })}
              mb="21px"
            >
              <InputKeywords
                name="packageNames"
                keywords={formData.packageNames}
                maxItems={false}
                onConfirmDelete={(name, packageName: string) =>
                  handleDeletePackageName(name, packageName)
                }
                handleSubmit={(prop, option) =>
                  handleAddPackageName(prop, option.packageName)
                }
                isAutocomplete
                autocompleteProps={{
                  options: enterpriseAppsModal
                    ?.filter(
                      (app) => !formData.packageNames.includes(app.packageName)
                    )
                    .map((app) => ({
                      ...app,
                      name: `${app.name} - ${app.packageName}`,
                    })),
                }}
                listContainerStyle={{
                  maxH: '22vh',
                  overflowY: 'auto',
                  p: 0.5,
                }}
              />
            </FormControl>

            <Flex w="full" justify="flex-end" gridGap={4}>
              <Button variant="outline" onClick={onClose} minW="150px">
                {intl.formatMessage({ id: 'global.cancel' })}
              </Button>

              <Button
                onClick={handleSubmit}
                minW="150px"
                isDisabled={disableSave}
              >
                {intl.formatMessage({
                  id: `${intlPolicySettingsKey}.safety.choose_private_key_rule.add_modal.save`,
                })}
              </Button>
            </Flex>
          </Flex>
        </FormContainer>
      </ModalBody>
    </Modal>
  );
}
