import { Box, Flex } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { isValidGmail } from '../../../../../../../helper/email';
import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../../store/policy';
import {
  DeveloperSettingEnum,
  EncryptionPolicyEnum,
  PermissionPolicyEnum,
  UntrustedAppsPolicyEnum,
  UsbDataAccessEnum,
} from '../../../../../../../types/policy';
import { CardItemType } from '../../../../../../CardContent';
import CreatableSelectAutoComplete from '../../../../../../CreatableSelectAutoComplete';
import Input from '../../../../../../Input';
import Select from '../../../../../../Select';
import SwitchButton from '../../../../../../SwitchButton';
import { defaultAccountTypesOptions } from '../../../policiesHelpers';
import { GetterSettingFn } from '../../../PoliciesInterfaces';
import { intlPolicySettingsKey } from '../index';
import ChoosePrivateKeyRule from './ChoosePrivateKeyRule';

export const getSafetySettings: GetterSettingFn = ({
  handleChange,
  intl,
  policy,
}) => {
  const dispatch = useAppDispatch();

  const policyAccountTypesValue =
    policy?.accountTypesWithManagementDisabled?.map((accountType) => {
      return {
        label: accountType,
        value: accountType,
      };
    }) || [];

  const handleAdvancedSecurityOverridesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event?.target;
    dispatch(
      policySelected({
        advancedSecurityOverrides: {
          ...policy.advancedSecurityOverrides,
          [name]: value,
        },
      })
    );
  };

  const handleFrpAdminEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target;
    dispatch(
      policySelected({
        frpAdminEmails: value,
      })
    );
  };

  const handleAccountTypeSelected = (newAccountTypes) => {
    const fillAccountsType = newAccountTypes.map(
      (accountType) => accountType.value
    );

    dispatch(
      policySelected({
        accountTypesWithManagementDisabled: fillAccountsType,
      })
    );
  };

  const untrustedAppsOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.untrusted_apps.select.disallow_install`,
      }),
      value: UntrustedAppsPolicyEnum.DISALLOW_INSTALL,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.untrusted_apps.select.allow_install`,
      }),
      value: UntrustedAppsPolicyEnum.ALLOW_INSTALL_DEVICE_WIDE,
    },
  ];

  const developerSettingsOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.developer_settings.select.developer_settings_disabled`,
      }),
      value: DeveloperSettingEnum.DEVELOPER_SETTINGS_DISABLED,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.developer_settings.select.developer_settings_allowed`,
      }),
      value: DeveloperSettingEnum.DEVELOPER_SETTINGS_ALLOWED,
    },
  ];

  const encryptionPolicyOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.encryption_policy.select.enabled_without_password`,
      }),
      value: EncryptionPolicyEnum.ENABLED_WITHOUT_PASSWORD,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.encryption_policy.select.enabled_with_password`,
      }),
      value: EncryptionPolicyEnum.ENABLED_WITH_PASSWORD,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.encryption_policy.select.disabled`,
      }),
      value: EncryptionPolicyEnum.ENCRYPTION_POLICY_UNSPECIFIED,
    },
  ];

  const permissionSettingsOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.default_permission_policy.select.prompt`,
      }),
      value: PermissionPolicyEnum.PROMPT,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.default_permission_policy.select.grant`,
      }),
      value: PermissionPolicyEnum.GRANT,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.default_permission_policy.select.deny`,
      }),
      value: PermissionPolicyEnum.DENY,
    },
  ];

  const usbDataAccessOptions = [
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.usb_data_access.select.allow_usb_data_transfer`,
      }),
      value: UsbDataAccessEnum.ALLOW_USB_DATA_TRANSFER,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.usb_data_access.select.disallow_usb_file_transfer`,
      }),
      value: UsbDataAccessEnum.DISALLOW_USB_FILE_TRANSFER,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicySettingsKey}.safety.usb_data_access.select.disallow_usb_data_transfer`,
      }),
      value: UsbDataAccessEnum.DISALLOW_USB_DATA_TRANSFER,
    },
  ];

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.safety.modify_accounts_disabled.title`,
      description: `${intlPolicySettingsKey}.safety.modify_accounts_disabled.description`,
      children: (
        <SwitchButton
          name="modifyAccountsDisabled"
          isChecked={policy?.modifyAccountsDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.account_types_with_management_disabled.title`,
      description: `${intlPolicySettingsKey}.safety.account_types_with_management_disabled.description`,
      customStyles: {
        alignItems: 'inherit',
      },
      children: (
        <CreatableSelectAutoComplete
          isMulti
          name="accountTypesWithManagementDisabled"
          options={defaultAccountTypesOptions}
          value={policyAccountTypesValue}
          onChange={handleAccountTypeSelected}
          customStyles={{
            w: '450px',
          }}
          placeholder={
            <FormattedMessage
              id={`${intlPolicySettingsKey}.user.account_types_with_management_disabled.placeholder`}
            />
          }
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.reset_block.title`,
      description: `${intlPolicySettingsKey}.safety.reset_block.description`,
      children: (
        <SwitchButton
          name="factoryResetDisabled"
          isChecked={policy?.factoryResetDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.frp_admin_emails.title`,
      description: `${intlPolicySettingsKey}.safety.frp_admin_emails.description`,
      children: (
        <Flex flexDir="column" mt={4} w="full">
          <Input
            inputProps={{
              name: 'frpAdminEmails',
              value: policy?.frpAdminEmails || '',
              onChange: handleFrpAdminEmailChange,
            }}
          />
          <Box
            color="warning.500"
            fontSize="sm"
            fontWeight="bold"
            h="21px"
            ml="1"
          >
            {policy?.frpAdminEmails && !isValidGmail(policy.frpAdminEmails) && (
              <FormattedMessage id="global.valid_g_email" />
            )}
          </Box>
        </Flex>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.sd_card_block.title`,
      description: `${intlPolicySettingsKey}.safety.sd_card_block.description`,
      children: (
        <SwitchButton
          name="mountPhysicalMediaDisabled"
          isChecked={policy?.mountPhysicalMediaDisabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.usb_data_access.title`,
      description: `${intlPolicySettingsKey}.safety.usb_data_access.description`,
      children: (
        <Select
          name="usbDataAccess"
          value={policy?.usbDataAccess}
          onChange={handleChange}
          minWidth="450px"
        >
          {usbDataAccessOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.untrusted_app.title`,
      description: `${intlPolicySettingsKey}.safety.untrusted_app.description`,
      children: (
        <Select
          name="untrustedAppsPolicy"
          value={policy?.advancedSecurityOverrides?.untrustedAppsPolicy}
          onChange={handleAdvancedSecurityOverridesChange}
          minWidth="450px"
        >
          {untrustedAppsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.default_permission_policy.title`,
      description: `${intlPolicySettingsKey}.safety.default_permission_policy.description`,
      children: (
        <Select
          name="defaultPermissionPolicy"
          value={policy?.defaultPermissionPolicy}
          onChange={handleChange}
          minWidth="450px"
        >
          {permissionSettingsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.developer_settings.title`,
      description: `${intlPolicySettingsKey}.safety.developer_settings.description`,
      children: (
        <Select
          name="developerSettings"
          value={policy?.advancedSecurityOverrides?.developerSettings}
          onChange={handleAdvancedSecurityOverridesChange}
          minWidth="450px"
        >
          {developerSettingsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.encryption_policy.title`,
      description: `${intlPolicySettingsKey}.safety.encryption_policy.description`,
      children: (
        <Select
          name="encryptionPolicy"
          value={policy?.encryptionPolicy}
          onChange={handleChange}
          minWidth="450px"
        >
          {encryptionPolicyOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.private_key_selection_enabled.title`,
      description: `${intlPolicySettingsKey}.safety.private_key_selection_enabled.description`,
      children: (
        <SwitchButton
          name="privateKeySelectionEnabled"
          isChecked={policy?.privateKeySelectionEnabled}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety.choose_private_key_rule.title`,
      description: `${intlPolicySettingsKey}.safety.choose_private_key_rule.description`,
      childrenItem: {
        children: <ChoosePrivateKeyRule />,
      },
    },
  ];

  return listSettings;
};
