import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { isPolicyIOSSupervised } from '../../../../../store/policy';
import Tab from '../../../../Tab';
import PolicySettings from '../IOS/PolicySettings';
import { Profile } from '../IOS/Profile';
import Sites from '../IOS/Sites';

const IOSTab = () => {
  const { policy } = useAppSelector((state) => state.policy);
  const isSupervised = useMemo(
    () => isPolicyIOSSupervised(policy),
    [policy.managementMode]
  );

  return (
    <Tabs mt="16" isLazy defaultIndex={isSupervised ? 2 : 0}>
      <TabList mb="10">
        <Tab hidden={isSupervised}>
          <FormattedMessage id="update_policies.tab.configuration" />
        </Tab>

        <Tab hidden={isSupervised}>
          <FormattedMessage id="update_policies.tab.web_sites" />
        </Tab>

        <Tab hidden={!isSupervised}>
          <FormattedMessage id="update_policies.tab.profile" />
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <PolicySettings />
        </TabPanel>

        <TabPanel>
          <Sites policy={policy} />
        </TabPanel>

        <TabPanel>
          <Profile />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default IOSTab;
