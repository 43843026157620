import React, { Fragment } from 'react';

import { OperationalSystemEnum } from '../../../../../types/policy';
import AndroidTab from './AndroidTab';
import IOSTab from './IOSTab';

interface PolicyTabsProps {
  tabToRender: OperationalSystemEnum;
}

const PolicyTabs = ({ tabToRender }: PolicyTabsProps) => {
  const TabsToRenderByOperationalSystem = {
    [OperationalSystemEnum.ANDROID]: AndroidTab,
    [OperationalSystemEnum.IOS]: IOSTab,
  };

  const PolicyTabToRender =
    TabsToRenderByOperationalSystem[tabToRender] || Fragment;

  return <PolicyTabToRender />;
};

export default PolicyTabs;
