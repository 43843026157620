import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { DeviceUserType } from '../../types/deviceUser';
import { QuerysWithFilters } from '../../types/generic_list';
import {
  Types,
  deviceUserListSuccess,
  deviceUserGetSuccess,
  deviceUserEditSuccess,
  deviceUserFilterListSuccess,
} from '../deviceUser';
import { safe, api, requestList, requestSimple } from './util';

function* handleList({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'deviceUserList', type, deviceUserListSuccess, {
    queryParameters,
    filters,
  });
}

function* handleGet({ type, payload }: PayloadAction<DeviceUserType['id']>) {
  yield call(
    requestSimple,
    api,
    'deviceUserGet',
    type,
    deviceUserGetSuccess,
    payload
  );
}

function* handleEdit({ type, payload }: PayloadAction<DeviceUserType>) {
  yield call(
    requestSimple,
    api,
    'deviceUserEdit',
    type,
    deviceUserEditSuccess,
    payload.id,
    payload
  );
}

function* handleListFilter({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(
    requestSimple,
    api,
    'deviceUserSelect',
    type,
    deviceUserFilterListSuccess,
    {
      queryParameters,
      filters,
    }
  );
}

export default function* userSaga() {
  yield takeLatest(Types.LIST, safe(handleList));
  yield takeLatest(Types.GET, safe(handleGet));
  yield takeLatest(Types.EDIT, safe(handleEdit));
  yield takeLatest(Types.LIST_FILTER, safe(handleListFilter));
}
