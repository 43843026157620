import { Flex, Grid, GridItem, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { usePlan } from '../../../../../../../../hooks/usePlan';
import { useAppDispatch } from '../../../../../../../../hooks/useRedux';
import { policyToggleBlockCategory } from '../../../../../../../../store/policy';
import {
  PolicyApplicationType,
  WebsiteCategoryEnum,
} from '../../../../../../../../types/policy';
import SwitchButton from '../../../../../../../SwitchButton';
import Text from '../../../../../../../Text';
import AppActionAccordionCard from '../components/AppActionAccordionCard';

type ErrorKey = 'planMaxItems';

interface CategoryAccordionCardType {
  selectedAppConfig: PolicyApplicationType;
  isBrowserApp?: boolean;
}

const CategoryAccordionCard = ({
  selectedAppConfig,
  isBrowserApp,
}: CategoryAccordionCardType) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { plan } = usePlan();
  const [errorKey, setErrorKey] = useState<ErrorKey>();

  const errorKeyToIntl: Record<ErrorKey, string> = {
    planMaxItems: 'edit_policy.app_config.plan_max_website_categories',
  };

  const categoryList = [
    'edit_policy.applications.managed_config.block_categories.adult_content',
    'edit_policy.applications.managed_config.block_categories.alcoholic',
    'edit_policy.applications.managed_config.block_categories.business',
    'edit_policy.applications.managed_config.block_categories.chat',
    'edit_policy.applications.managed_config.block_categories.email',
    'edit_policy.applications.managed_config.block_categories.educational',
    'edit_policy.applications.managed_config.block_categories.entertainment',
    'edit_policy.applications.managed_config.block_categories.file_shares',
    'edit_policy.applications.managed_config.block_categories.forum',
    'edit_policy.applications.managed_config.block_categories.gambling',
    'edit_policy.applications.managed_config.block_categories.games',
    'edit_policy.applications.managed_config.block_categories.news',
    'edit_policy.applications.managed_config.block_categories.proxies',
    'edit_policy.applications.managed_config.block_categories.search_engine',
    'edit_policy.applications.managed_config.block_categories.shopping',
    'edit_policy.applications.managed_config.block_categories.social_media',
    'edit_policy.applications.managed_config.block_categories.sports',
    'edit_policy.applications.managed_config.block_categories.tabaco',
    'edit_policy.applications.managed_config.block_categories.technology',
    'edit_policy.applications.managed_config.block_categories.travel',
    'edit_policy.applications.managed_config.block_categories.violence',
    'edit_policy.applications.managed_config.block_categories.jobs',
    'edit_policy.applications.managed_config.block_categories.health',
  ];

  const categories = new Map([
    [
      'edit_policy.applications.managed_config.block_categories.adult_content',
      WebsiteCategoryEnum.ADULT_CONTENT,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.alcoholic',
      WebsiteCategoryEnum.ALCOHOLIC,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.business',
      WebsiteCategoryEnum.BUSINESS,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.chat',
      WebsiteCategoryEnum.CHAT,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.email',
      WebsiteCategoryEnum.EMAIL,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.educational',
      WebsiteCategoryEnum.EDUCATIONAL,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.entertainment',
      WebsiteCategoryEnum.ENTERTAINMENT,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.file_shares',
      WebsiteCategoryEnum.FILE_SHARES,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.forum',
      WebsiteCategoryEnum.FORUM,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.gambling',
      WebsiteCategoryEnum.GAMBLING,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.games',
      WebsiteCategoryEnum.GAMES,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.news',
      WebsiteCategoryEnum.NEWS,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.proxies',
      WebsiteCategoryEnum.PROXIES,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.search_engine',
      WebsiteCategoryEnum.SEARCH_ENGINE,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.shopping',
      WebsiteCategoryEnum.SHOPPING,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.social_media',
      WebsiteCategoryEnum.SOCIAL_MEDIA,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.sports',
      WebsiteCategoryEnum.SPORTS,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.tabaco',
      WebsiteCategoryEnum.TABACO,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.technology',
      WebsiteCategoryEnum.TECHNOLOGY,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.travel',
      WebsiteCategoryEnum.TRAVEL,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.violence',
      WebsiteCategoryEnum.VIOLENCE,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.jobs',
      WebsiteCategoryEnum.JOBS,
    ],
    [
      'edit_policy.applications.managed_config.block_categories.health',
      WebsiteCategoryEnum.HEALTH,
    ],
  ]);

  useEffect(() => {
    if (isBrowserApp && plan?.maxBlockWebsiteCategories) {
      let newErrorKey: ErrorKey;

      if (
        selectedAppConfig?.blockWebsiteCategories?.length >=
        plan?.maxBlockWebsiteCategories
      ) {
        newErrorKey = 'planMaxItems';
      } else {
        newErrorKey = undefined;
      }

      setErrorKey(newErrorKey);
    }
  }, [selectedAppConfig.blockWebsiteCategories]);

  return (
    <AppActionAccordionCard
      title={selectedAppConfig?.labelBlockCategories?.title}
      description={selectedAppConfig?.labelBlockCategories?.description}
      inputContainerWidth="100%"
    >
      <Box
        color="warning.500"
        fontSize="sm"
        fontWeight="bold"
        h="21px"
        m="0"
        mb={2}
      >
        {errorKey && intl.formatMessage({ id: errorKeyToIntl[errorKey] })}
      </Box>

      <Grid templateColumns="repeat(4, 1fr)">
        {categoryList?.map((category, index) => {
          const queryWord = categories?.get(category);

          const isChecked = selectedAppConfig?.blockWebsiteCategories?.some(
            (value) => value.websiteCategory === queryWord
          );

          return (
            <GridItem mb="15px" key={index}>
              <Flex align="center">
                <SwitchButton
                  name="isBlocked"
                  isChecked={isChecked}
                  isDisabled={!isChecked && !!errorKey}
                  onChange={() => {
                    dispatch(
                      policyToggleBlockCategory({
                        isChecked,
                        websiteCategory: queryWord,
                      })
                    );
                  }}
                />
                <Text ml="15px">
                  <FormattedMessage id={category} />
                </Text>
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
    </AppActionAccordionCard>
  );
};

export default CategoryAccordionCard;
