import { InfoOutlineIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ErrorsMessagesTypes } from '../../helper/error';
import { camelToSnakeCase } from '../../helper/replace';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { hasError, uiRemoveError, uiScrollToTop } from '../../store/ui';
import PageShowToaster from '../PageShowToaster';

interface PageErrorsType extends BoxProps {
  toasterKeys: string[];
  translateKey: string;
  messageError?: React.ReactNode;
}

const PageErrors = ({
  toasterKeys,
  translateKey,
  messageError,
  ...rest
}: PageErrorsType) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const allErrors = useAppSelector((state) => state.ui.errors);

  const toasterKey = useMemo(
    () => toasterKeys.find((key) => allErrors?.[key]) || '',
    [toasterKeys, allErrors]
  );
  const error = hasError(toasterKey);

  useEffect(() => {
    return () => {
      dispatch(uiRemoveError(toasterKey));
    };
  }, [toasterKey]);

  const [stateTimed, setStateTimed] = useState<{
    show: boolean;
    messages?: ErrorsMessagesTypes;
  }>({
    show: false,
  });

  const scrollToTop = stateTimed.show;

  useEffect(() => {
    if (scrollToTop) {
      dispatch(uiScrollToTop(scrollToTop));
    }
  }, [scrollToTop, stateTimed.messages]);

  useEffect(() => {
    if (!error || (error && !error?.messages)) {
      setStateTimed({
        show: false,
      });
    }

    if (!!error?.messages) {
      setStateTimed({
        show: true,
        messages: error?.messages,
      });
    }
  }, [error]);

  const handleSelectCamelCaseOrSnakeCaseTranslateKey = (
    initialPartialTranslateKey: string,
    camelCaseTranslateKey: string,
    snakeCaseTranslateKey: string
  ) => {
    const camelCaseFullTranslateKey = initialPartialTranslateKey.concat(
      '.',
      camelCaseTranslateKey
    );
    const snakeCaseFullTranslateKey = initialPartialTranslateKey.concat(
      '.',
      snakeCaseTranslateKey
    );

    const outputTranslate = intl.formatMessage({
      id: camelCaseFullTranslateKey,
      defaultMessage: camelCaseFullTranslateKey,
    });

    const camelCaseTranslateKeyExists =
      outputTranslate !== camelCaseFullTranslateKey;

    return camelCaseTranslateKeyExists
      ? camelCaseFullTranslateKey
      : snakeCaseFullTranslateKey;
  };

  return (
    <>
      <PageShowToaster
        toasterKeys={toasterKeys}
        messageError={messageError}
        disabledSuccess
      />

      {stateTimed.show && (
        <Box
          border="2px"
          borderColor="warning.500"
          borderRadius="10px"
          mb="3%"
          w="40%"
          {...rest}
        >
          <Flex
            p="22px"
            color="warning.500"
            justify="space-between"
            align="center"
            gridGap="2"
          >
            <Box>
              {stateTimed?.messages &&
                Object.entries(stateTimed?.messages).map(
                  ([fieldWithIndexArray, messages]) => {
                    const field = fieldWithIndexArray.replace(/\.\d+/g, '.arr');
                    const snakeCaseField = camelToSnakeCase(field);
                    const finalTranslateKey =
                      handleSelectCamelCaseOrSnakeCaseTranslateKey(
                        translateKey,
                        field,
                        snakeCaseField
                      );

                    return (
                      <Box key={field} size="15px">
                        {messages?.map((message, index) => {
                          return (
                            <Flex
                              align="flex-start"
                              mb="4px"
                              mt="4px"
                              key={index}
                            >
                              <Flex align="center" height="24px">
                                <Icon
                                  w="1rem"
                                  h="1rem"
                                  as={WarningTwoIcon}
                                  mr="10px"
                                  color="warning.600"
                                />
                              </Flex>
                              <Text>
                                <FormattedMessage id={finalTranslateKey} /> -{' '}
                                {message}
                              </Text>
                            </Flex>
                          );
                        })}
                      </Box>
                    );
                  }
                )}
            </Box>
            <Icon
              w="45px"
              h="45px"
              as={InfoOutlineIcon}
              mr="10px"
              color="warning.600"
            />
          </Flex>
        </Box>
      )}
    </>
  );
};

export default PageErrors;
