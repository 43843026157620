import {
  Heading as HeadingChakra,
  HeadingProps as HeadingPropsChakra,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface HeadingProps extends HeadingPropsChakra {
  children: ReactNode;
}

const Heading = ({ children, ...rest }: HeadingProps) => {
  return (
    <HeadingChakra as="h1" variant="h1" {...rest}>
      {children}
    </HeadingChakra>
  );
};

export default Heading;
