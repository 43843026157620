import { CloseIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { es, enUS, ptBR } from 'date-fns/esm/locale';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import { useAppSelector } from '../../hooks/useRedux';
import Date from '../Icons/Date';
import Input from '../Input';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ autoComplete = undefined, ...props }) => {
  const { language } = useAppSelector((state) => state.auth.user);

  const changeLanguage = {
    ['pt-br']: ptBR,
    ['en']: enUS,
    ['es']: es,
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Input
      autoComplete={autoComplete}
      testId={props?.testId}
      rightElement={
        <Flex>
          {!props.disableClose && props.hasValue && (
            <CloseIcon
              cursor="pointer"
              onClick={() => props.onChange(null)}
              color="black.500"
              h="10px"
              w="10px"
            />
          )}
        </Flex>
      }
      leftElement={
        <Flex>
          <Date boxSize={6} color="gray.500" onClick={onClick} />
        </Flex>
      }
      inputProps={{
        onClick: onClick,
        onChange: onClick,
        value: value,
        ref: ref,
        background: props.disabled && 'transparent',
        pointerEvents: props.disabled && 'none',
        opacity: props.disabled && 0.3,
        placeholder: props.placeholder && props.placeholder,
        name: props?.name,
      }}
      onClick={onClick}
    >
      {value}
    </Input>
  ));

  return (
    <ReactDatePicker
      dateFormat="dd/MM/yyyy"
      locale={changeLanguage[language]}
      customInput={<CustomInput />}
      autoComplete={autoComplete}
      {...props}
    />
  );
};

export default DatePicker;
