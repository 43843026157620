import { SettingsIcon, TimeIcon } from '@chakra-ui/icons';
import { Flex, Image } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { iconImgToRender } from '../../../../../../helper';
import { handleSortTable, useSorting } from '../../../../../../helper/sort';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useRedux';
import {
  getPolicyAppSettings,
  policyApplicationRemove,
  policyApplicationsAdd,
  policyApplicationSelected,
  policyApplicationsMetadata,
  policySelected,
  policySetApplication,
  Types,
} from '../../../../../../store/policy';
import { uiAddEmpty, uiRemoveEmpty } from '../../../../../../store/ui';
import { EnterpriseOriginEnum } from '../../../../../../types/enterpriseApps';
import {
  InstallTypeEnum,
  PlayStoreModeEnum,
  PolicyApplicationType,
  PolicyCompanyApplicationType,
} from '../../../../../../types/policy';
import { AlertDelete } from '../../../../../Alert';
import { CustomTooltip } from '../../../../../CustomTooltip';
import { IconBadge } from '../../../../../IconBadge';
import Config from '../../../../../Icons/Config';
import Delete from '../../../../../Icons/Delete';
import StoreIcon from '../../../../../Icons/Store';
import Switch from '../../../../../Icons/Swtich';
import { PageActionsProps } from '../../../../../PageActions/interfaces';
import SwitchButton from '../../../../../SwitchButton';
import Table from '../../../../../Table';
import TableActions from '../../../../../TableActions';
import MenuItem from '../../../../../TableActions/MenuItem';
import alertAppBlockSIM from './alertAppBlockSIM';
import CardMode from './CardMode';
import InstallType, { installTypeFieldName } from './InstallType';
import ModalAddManualSystemApps from './ModalAddManualSystemApps';
import { ModalBlockTime } from './ModalBlockTime';
import ModalListEnterpriseApps, {
  ModalEnterpriseAppsModeEnum,
} from './ModalListEnterpriseApps';
import AppAdvancedConfig from './TableActions/AppAdvancedConfig';
import AppConfig from './TableActions/AppConfig';
import AppPermissions from './TableActions/AppPermissions';
import { handleCreateApplicationObject } from './utils';

interface PolicyAppsProps {
  applications?: PolicyApplicationType[];
  playStoreMode?: PlayStoreModeEnum;
}

type RenderApplications =
  | 'APPLICATION_TABLE'
  | 'SETTINGS'
  | 'ADVANCED_SETTINGS'
  | 'PERMISSIONS';

enum RenderApplicationsEnum {
  'APPLICATION_TABLE' = 'APPLICATION_TABLE',
  'SETTINGS' = 'SETTINGS',
  'ADVANCED_SETTINGS' = 'ADVANCED_SETTINGS',
  'PERMISSIONS' = 'PERMISSIONS',
}

export const intlPolicyAppsKey = 'update_policies.applications';

const visibleFieldName = 'visible';

const PolicyApps = ({ applications, playStoreMode }: PolicyAppsProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    applicationsMetadata: metadata,
    selectedApp,
    policy,
  } = useAppSelector((state) => state.policy);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const [search, setSearch] = useState('');
  const [isOpenModalEnterpriseApps, setIsOpenModalEnterpriseApps] =
    useState<ModalEnterpriseAppsModeEnum>(null);
  const [isModalAddManualSystemAppsOpen, setIsModalAddManualSystemAppsOpen] =
    useState(null);
  const [isModalBlockTimeOpen, setIsModalBlockTimeOpen] = useState(false);
  const [applicationsToRender, setApplicationsToRender] =
    useState<RenderApplications>(RenderApplicationsEnum.APPLICATION_TABLE);

  const actionTableDisabledByOriginApplication: Record<
    EnterpriseOriginEnum,
    boolean
  > = {
    [EnterpriseOriginEnum.SYSTEM]: true,
    [EnterpriseOriginEnum.MANUAL]: true,
    [EnterpriseOriginEnum.WEB]: false,
    [EnterpriseOriginEnum.PARTICULAR]: false,
    [EnterpriseOriginEnum.PLAYSTORE]: false,
    [EnterpriseOriginEnum.EXTERNAL]: false,
  };

  const DATAMOB_APPLICATION_PACKAGE_NAME =
    portalSettings?.appCompanionPackageName;

  const BLOCK_SIM_CARD_APPLICATION_PACKAGE_NAME =
    portalSettings?.appBlockSimCardPackageName;

  const SECURITY_BROWSER_APPLICATION_PACKAGE_NAME =
    portalSettings?.appBrowserPackageName;

  const REMOTE_VIEW_APPLICATION_PACKAGE_NAME =
    portalSettings?.appRemoteViewPackageName;

  const appId = selectedApp?.companyApplication?.id;

  const isRemoveDisabled = (application: PolicyApplicationType) => {
    return (
      DATAMOB_APPLICATION_PACKAGE_NAME ===
        application.companyApplication.packageName ||
      BLOCK_SIM_CARD_APPLICATION_PACKAGE_NAME ===
        application.companyApplication.packageName ||
      application.companyApplication.packageName ===
        REMOTE_VIEW_APPLICATION_PACKAGE_NAME ||
      application.companyApplication.packageName ===
        SECURITY_BROWSER_APPLICATION_PACKAGE_NAME
    );
  };

  const verifyBlockTimeDisabled = (application: PolicyApplicationType) => {
    return (
      DATAMOB_APPLICATION_PACKAGE_NAME ===
        application.companyApplication.packageName ||
      BLOCK_SIM_CARD_APPLICATION_PACKAGE_NAME ===
        application.companyApplication.packageName ||
      application.companyApplication.packageName ===
        REMOTE_VIEW_APPLICATION_PACKAGE_NAME
    );
  };

  const currentApplicationData = useMemo(() => {
    const firstPageIndex = (metadata.page - 1) * metadata.pageSize;
    const lastPageIndex = firstPageIndex + metadata.pageSize;

    const IS_INSTALL_TYPE = metadata?.sortingProperty === 'installType';
    const applicationsCopy = applications ? [...applications] : [];
    const listApps = applicationsCopy
      ?.sort((appA, appB) => {
        const propertyA = IS_INSTALL_TYPE
          ? appA[metadata.sortingProperty]
          : appA.companyApplication[metadata.sortingProperty];
        const propertyB = IS_INSTALL_TYPE
          ? appB[metadata.sortingProperty]
          : appB.companyApplication[metadata.sortingProperty];
        return handleSortTable(
          metadata.sortingDirection,
          propertyA?.toLowerCase(),
          propertyB?.toLowerCase()
        );
      })
      ?.filter((app) =>
        search.length > 0
          ? app?.companyApplication?.name
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            app?.companyApplication?.packageName
              ?.toLowerCase()
              .includes(search.toLowerCase())
          : true
      );

    return listApps?.slice(firstPageIndex, lastPageIndex);
  }, [metadata, search, applications]);

  const columnsToHidden = ['visible'];

  useEffect(() => {
    if (currentApplicationData?.length === 0) {
      dispatch(uiAddEmpty(Types.GET));
    } else {
      dispatch(uiRemoveEmpty(Types.GET));
    }
  }, [currentApplicationData]);

  useEffect(() => {
    if (appId && policy?.id)
      dispatch(
        getPolicyAppSettings({
          id: policy.id,
          packageName: selectedApp.companyApplication.packageName,
        })
      );
  }, [appId]);

  const handlePlayStoreModeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(policySelected({ [event.target.name]: event.target.value }));
  };

  const handleApplicationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    application: PolicyApplicationType
  ) => {
    const { name, value, checked, type } = event?.target;

    const isCheckBoxComponent = type === 'checkbox';

    const isSameApplication =
      selectedApp?.companyApplicationId === application?.companyApplicationId;
    const applicationToModify = isSameApplication
      ? { ...selectedApp, ...application }
      : application;

    if (name === installTypeFieldName && value === InstallTypeEnum.BLOCKED) {
      dispatch(
        policyApplicationSelected({
          ...applicationToModify,
          [name]: value,
          [visibleFieldName]: false,
        })
      );

      return;
    }

    handleApplicationByFields(
      applicationToModify,
      name,
      isCheckBoxComponent ? checked : value
    );
  };

  function handleApplicationByFields(
    application: PolicyApplicationType,
    name: string,
    value: unknown
  ) {
    dispatch(
      policyApplicationSelected({
        ...application,
        [name]: value,
      })
    );
  }

  const handleAddApplicationsToList = (
    companyApplications: PolicyCompanyApplicationType[]
  ) => {
    const installTypeByPlayStoreMode = {
      [PlayStoreModeEnum.WHITELIST]: InstallTypeEnum.AVAILABLE,
      default: InstallTypeEnum.FORCE_INSTALLED,
    };

    const installTypeByOriginApplication = {
      [EnterpriseOriginEnum.SYSTEM]: InstallTypeEnum.KIOSK_MODE,
      [EnterpriseOriginEnum.MANUAL]: InstallTypeEnum.KIOSK_MODE,
      default: (playStoreMode) =>
        installTypeByPlayStoreMode[playStoreMode] ||
        installTypeByPlayStoreMode.default,
    };

    if (companyApplications) {
      const applicationList: PolicyApplicationType[] = companyApplications.map(
        (companyApplication) => {
          return handleCreateApplicationObject(
            companyApplication,
            installTypeByOriginApplication[companyApplication.origin] ||
              installTypeByOriginApplication.default(playStoreMode),
            policy.kioskCustomLauncherEnabled
          );
        }
      );

      dispatch(policyApplicationsAdd([...applicationList]));
    }
  };

  const handleMetadata = (newMetadata) => {
    dispatch(
      policyApplicationsMetadata({
        ...metadata,
        ...newMetadata,
      })
    );
  };

  const handleSetRenderApplications = (
    tabToRender: RenderApplications,
    application?: PolicyApplicationType
  ) => {
    if (application) {
      dispatch(policySetApplication({ ...selectedApp, ...application }));
    }
    setApplicationsToRender(tabToRender);
  };

  const handleClearRenderApplications = () => {
    setApplicationsToRender(RenderApplicationsEnum.APPLICATION_TABLE);
  };

  const handleRemoveApp = (application: PolicyApplicationType) => {
    const isAppBlockSIM =
      application.companyApplication.packageName ===
      portalSettings?.appBlockSimCardPackageName;

    if (isAppBlockSIM) {
      alertAppBlockSIM(intl, isAppBlockSIM);
    }
    dispatch(policyApplicationRemove(application));
  };

  const handleOpenBlockTimes = (application: PolicyApplicationType) => {
    dispatch(policySetApplication(application));

    setIsModalBlockTimeOpen(true);
  };

  const isDatamobApplication = (packageName: string): boolean => {
    const packages: string[] = [
      BLOCK_SIM_CARD_APPLICATION_PACKAGE_NAME,
      SECURITY_BROWSER_APPLICATION_PACKAGE_NAME,
      REMOTE_VIEW_APPLICATION_PACKAGE_NAME,
      DATAMOB_APPLICATION_PACKAGE_NAME,
    ];

    return packages.includes(packageName);
  };

  const columns = useSorting(
    [
      {
        header: '',
        accessor: 'icon',
      },
      {
        header: intl.formatMessage({
          id: `${intlPolicyAppsKey}.table.column.name_app`,
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: `${intlPolicyAppsKey}.table.column.package_name`,
        }),
        accessor: 'packageName',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: `${intlPolicyAppsKey}.table.column.origin`,
        }),
        accessor: 'origin',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: `${intlPolicyAppsKey}.table.column.install_type`,
        }),
        accessor: 'installType',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: `${intlPolicyAppsKey}.table.column.show_in_kiosk_mode`,
        }),
        accessor: 'visible',
      },
      {
        header: '',
        accessor: 'blockTime',
      },
      {
        header: '',
        accessor: 'actions',
      },
    ].filter((column) =>
      policy.kioskCustomLauncherEnabled
        ? column
        : !columnsToHidden.includes(column.accessor)
    ),
    metadata
  );

  const data = currentApplicationData?.map((application) => {
    const isBlockTimeDisabled = verifyBlockTimeDisabled(application);

    return {
      key: String(application?.companyApplication?.id),
      cells: [
        {
          field: 'icon',
          value: (
            <Image
              w="28px"
              src={iconImgToRender(application?.companyApplication?.iconBase64)}
            />
          ),
          isExportHidden: true,
        },
        {
          field: 'name',
          value: application?.companyApplication?.name,
        },
        {
          field: 'packageName',
          value: application?.companyApplication?.packageName,
        },
        {
          field: 'origin',
          value: intl.formatMessage({
            id: `enterprise_apps.origin.${application?.companyApplication?.origin}`,
          }),
        },
        {
          field: 'installType',
          value: (
            <InstallType
              application={application}
              onChangeType={handleApplicationChange}
            />
          ),
        },
        {
          field: 'visible',
          value: (
            <SwitchButton
              name={visibleFieldName}
              isChecked={application.visible}
              onChange={(e) => handleApplicationChange(e, application)}
              isDisabled={application.installType === InstallTypeEnum.BLOCKED}
            />
          ),
        },
        {
          field: 'blockTime',
          value: (
            <CustomTooltip
              isDisabled={isBlockTimeDisabled}
              label={intl.formatMessage({
                id: `${intlPolicyAppsKey}.table.blockTime`,
              })}
            >
              <Flex>
                <IconBadge
                  showBadge={!!application?.workTimes?.length}
                  flexProps={{
                    cursor: isBlockTimeDisabled ? 'not-allowed' : 'pointer',
                    onClick: () =>
                      !isBlockTimeDisabled && handleOpenBlockTimes(application),
                  }}
                >
                  <TimeIcon
                    boxSize={5}
                    color={isBlockTimeDisabled && 'gray.300'}
                  />
                </IconBadge>
              </Flex>
            </CustomTooltip>
          ),
        },
        {
          value: '',
          field: 'actions',
          transform: () => (
            <TableActions
              moreItems={
                <>
                  <MenuItem
                    icon={<Config boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage
                        id={`${intlPolicyAppsKey}.table_action.settings`}
                      />
                    }
                    onClick={() =>
                      handleSetRenderApplications(
                        RenderApplicationsEnum.SETTINGS,
                        application
                      )
                    }
                    isDisabled={
                      actionTableDisabledByOriginApplication[
                        application?.companyApplication?.origin
                      ]
                    }
                  />
                  <MenuItem
                    icon={<Switch boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage
                        id={`${intlPolicyAppsKey}.table_action.permissions`}
                      />
                    }
                    onClick={() =>
                      handleSetRenderApplications(
                        RenderApplicationsEnum.PERMISSIONS,
                        application
                      )
                    }
                    isDisabled={
                      actionTableDisabledByOriginApplication[
                        application?.companyApplication?.origin
                      ] ||
                      isDatamobApplication(
                        application.companyApplication.packageName
                      )
                    }
                  />
                  <MenuItem
                    icon={<Config boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage
                        id={`${intlPolicyAppsKey}.table_action.advance_settings`}
                      />
                    }
                    onClick={() =>
                      handleSetRenderApplications(
                        RenderApplicationsEnum.ADVANCED_SETTINGS,
                        application
                      )
                    }
                    isDisabled={
                      actionTableDisabledByOriginApplication[
                        application?.companyApplication?.origin
                      ]
                    }
                  />
                  <MenuItem
                    color="warning.500"
                    icon={<Delete boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage
                        id={`${intlPolicyAppsKey}.table_action.remove_app`}
                      />
                    }
                    onClick={() =>
                      AlertDelete(intl, {
                        onConfirm: () => handleRemoveApp(application),
                      })
                    }
                    isDisabled={isRemoveDisabled(application)}
                  />
                </>
              }
            />
          ),
        },
      ].filter((cell) =>
        policy.kioskCustomLauncherEnabled
          ? cell
          : !columnsToHidden.includes(cell.field)
      ),
    };
  });

  function renderApplications() {
    if (applicationsToRender === RenderApplicationsEnum.SETTINGS) {
      return (
        <AppConfig
          selectedApp={selectedApp}
          handleCloseSettings={handleClearRenderApplications}
        />
      );
    }

    if (applicationsToRender === RenderApplicationsEnum.ADVANCED_SETTINGS) {
      return (
        <AppAdvancedConfig
          selectedApp={selectedApp}
          handleClose={handleClearRenderApplications}
          handleChange={handleApplicationChange}
          handleChangeByFields={handleApplicationByFields}
        />
      );
    }

    if (
      applicationsToRender === RenderApplicationsEnum.PERMISSIONS &&
      !isDatamobApplication(selectedApp.companyApplication.packageName)
    ) {
      return (
        <AppPermissions
          selectedApp={selectedApp}
          handleClose={handleClearRenderApplications}
        />
      );
    }

    const actionButtonsToRender: Partial<PageActionsProps> =
      policy.kioskCustomLauncherEnabled
        ? {
            menuActionButton: {
              title: `${intlPolicyAppsKey}.table.button_label`,
              items: [
                {
                  title: `${intlPolicyAppsKey}.menu_action_button.system`,
                  onClick: () =>
                    setIsOpenModalEnterpriseApps(
                      ModalEnterpriseAppsModeEnum.ENTERPRISE_SYSTEM_APPS
                    ),
                  icon: <SettingsIcon />,
                },
                {
                  title: `${intlPolicyAppsKey}.menu_action_button.store`,
                  onClick: () =>
                    setIsOpenModalEnterpriseApps(
                      ModalEnterpriseAppsModeEnum.ENTERPRISE_APPS
                    ),
                  icon: <StoreIcon />,
                },
                {
                  title: `${intlPolicyAppsKey}.menu_action_button.manual`,
                  onClick: () => setIsModalAddManualSystemAppsOpen(true),
                  icon: <SettingsIcon />,
                },
              ],
            },
          }
        : {
            actionButtons: [
              {
                id: ModalEnterpriseAppsModeEnum.ENTERPRISE_APPS,
                label: intl.formatMessage({
                  id: `${intlPolicyAppsKey}.table.button_label`,
                }),
                onClick: () =>
                  setIsOpenModalEnterpriseApps(
                    ModalEnterpriseAppsModeEnum.ENTERPRISE_APPS
                  ),
              },
            ],
          };

    return (
      <>
        <CardMode
          mode={playStoreMode}
          onChangeMode={handlePlayStoreModeChange}
        />
        {currentApplicationData && (
          <Table
            keyProp={Types.GET}
            headerColumns={columns}
            rows={data}
            metadata={metadata}
            isLoadMore
            handleSort={handleMetadata}
            pageActionsProps={{
              ...actionButtonsToRender,
              onSearch: setSearch,
              disabledAllExportsActions: true,
            }}
          />
        )}
      </>
    );
  }

  return (
    <Flex flexDirection="column" gridGap="6">
      {renderApplications()}

      {isOpenModalEnterpriseApps && (
        <ModalListEnterpriseApps
          isOpen={!!isOpenModalEnterpriseApps}
          onClose={() => setIsOpenModalEnterpriseApps(null)}
          handleAddApplicationsToList={handleAddApplicationsToList}
          applications={applications}
          modalMode={isOpenModalEnterpriseApps}
        />
      )}

      {isModalAddManualSystemAppsOpen && (
        <ModalAddManualSystemApps
          isOpen={!!isModalAddManualSystemAppsOpen}
          onClose={() => setIsModalAddManualSystemAppsOpen(null)}
          handleAddManualApplicationToList={handleAddApplicationsToList}
          applications={applications}
        />
      )}

      {isModalBlockTimeOpen && (
        <ModalBlockTime
          isOpen={!!isModalBlockTimeOpen}
          onClose={() => setIsModalBlockTimeOpen(null)}
        />
      )}
    </Flex>
  );
};

export default PolicyApps;
