import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import { EnterpriseOriginEnum } from '../../../../../../types/enterpriseApps';
import {
  InstallTypeEnum,
  PolicyApplicationType,
} from '../../../../../../types/policy';
import Select from '../../../../../Select';

interface InstallTypeProps {
  application?: PolicyApplicationType;
  onChangeType?: (
    event: ChangeEvent<HTMLInputElement>,
    application?: PolicyApplicationType
  ) => void;
}

export const installTypeFieldName = 'installType';

const InstallType = ({ application, onChangeType }: InstallTypeProps) => {
  const intl = useIntl();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const isEnterpriseApplicationSystem =
    application.companyApplication.origin === EnterpriseOriginEnum.SYSTEM;

  const isCompanionApplication =
    portalSettings?.appCompanionPackageName ===
    application.companyApplication.packageName;

  const isBlockSimApplication =
    portalSettings?.appBlockSimCardPackageName ===
    application.companyApplication.packageName;

  const isRemoteViewApplication =
    application.companyApplication.packageName ===
    portalSettings?.appRemoteViewPackageName;

  const isApplicationManual =
    application.companyApplication.origin === EnterpriseOriginEnum.MANUAL;

  const isSelectDisabled =
    isCompanionApplication ||
    isBlockSimApplication ||
    isEnterpriseApplicationSystem ||
    isRemoteViewApplication ||
    isApplicationManual;

  let selectOptions = [];

  const defaultSelectOptions = [
    {
      label: intl.formatMessage({
        id: 'update_policies.applications.installation_type.available',
      }),
      value: InstallTypeEnum.AVAILABLE,
    },
    {
      label: intl.formatMessage({
        id: 'update_policies.applications.installation_type.preinstalled',
      }),
      value: InstallTypeEnum.PREINSTALLED,
    },
    {
      label: intl.formatMessage({
        id: 'update_policies.applications.installation_type.force_installed',
      }),
      value: InstallTypeEnum.FORCE_INSTALLED,
    },
    {
      label: intl.formatMessage({
        id: 'update_policies.applications.installation_type.blocked',
      }),
      value: InstallTypeEnum.BLOCKED,
    },
  ];

  const selectOptionsForCompanion = [
    {
      label: intl.formatMessage({
        id: 'update_policies.applications.installation_type.required_for_setup',
      }),
      value: InstallTypeEnum.REQUIRED_FOR_SETUP,
    },
  ];

  const selectOptionsForSystemApp = [
    {
      label: intl.formatMessage({
        id: 'update_policies.applications.installation_type.kiosk.system_app',
      }),
      value: InstallTypeEnum.KIOSK_MODE,
    },
  ];

  if (isBlockSimApplication || isCompanionApplication) {
    selectOptions = selectOptionsForCompanion;
  } else if (isEnterpriseApplicationSystem || isApplicationManual) {
    selectOptions = selectOptionsForSystemApp;
  } else {
    selectOptions = defaultSelectOptions;
  }

  return (
    <Select
      id={application?.companyApplication?.id}
      maxW="220px"
      defaultValue={application.installType}
      onChange={(e) => onChangeType(e, application)}
      name={installTypeFieldName}
      isDisabled={isSelectDisabled}
    >
      {selectOptions.map((type) => (
        <option key={type.value} value={type.value}>
          {type.label}
        </option>
      ))}
    </Select>
  );
};

export default InstallType;
