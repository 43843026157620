import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface ModalHeaderProps extends ChakraModalHeaderProps {
  children?: ReactNode;
}

const ModalHeader = ({ children, ...rest }: ModalHeaderProps) => {
  return (
    <ChakraModalHeader
      d="flex"
      flexDirection="column"
      alignItems="center"
      {...rest}
    >
      {children}
    </ChakraModalHeader>
  );
};

export default ModalHeader;
