import _ from 'lodash';
import { ChangeEvent, memo, useCallback } from 'react';

import { DEBOUNCE_TIME } from '../../../../../../../../helper';
import { useAppDispatch } from '../../../../../../../../hooks/useRedux';
import {
  fillBundleField,
  policySetAppConfiguration,
  policySetKioskManagedConfiguration,
} from '../../../../../../../../store/policy';
import {
  ManageConfigurationInputTypeEnum,
  ManagedConfigurationsType,
} from '../../../../../../../../types/policy';
import AppActionAccordionCard from '../components/AppActionAccordionCard';
import { DynamicInputApplications } from './DynamicInputApplications';
import {
  CommonProps,
  CustomOnChangeValue,
} from './DynamicInputApplications/InputForDynamic';

interface AccordionCardComponentType {
  selectedAppConfig: ManagedConfigurationsType;
  inputContainerWidth?: string;
  value?: string;
  isKiosk?: boolean;
  index?: number;
  planAlert?: string;
  isDisabled?: boolean;
}

const AccordionCardComponent = ({
  selectedAppConfig,
  inputContainerWidth,
  value,
  isKiosk,
  index,
  planAlert,
  isDisabled,
}: AccordionCardComponentType) => {
  const dispatch = useAppDispatch();
  const isBool =
    selectedAppConfig.type === ManageConfigurationInputTypeEnum.BOOL;
  const isMulti =
    selectedAppConfig.type === ManageConfigurationInputTypeEnum.MULTISELECT;

  const handleInputChange: CommonProps['onChange'] = (event, fieldType) => {
    if (isMulti || fieldType === ManageConfigurationInputTypeEnum.MULTISELECT) {
      const { name, value } = event as CustomOnChangeValue;

      setSearchFilterDebounced(value, name);

      return;
    }

    const { value, checked, name, type } = (
      event as ChangeEvent<HTMLInputElement>
    )?.target;
    const valueToInput =
      isBool || type === 'checkbox' ? checked.toString() : value;
    setSearchFilterDebounced(valueToInput, name);
  };
  const setSearchFilterDebounced = useCallback(
    _.debounce(
      (valueToSet: boolean | string | (string | number)[], name: string) => {
        const fnToDispatch = isKiosk
          ? policySetKioskManagedConfiguration
          : policySetAppConfiguration;

        let appConfigToDispatch = _.cloneDeep(selectedAppConfig);

        if (
          selectedAppConfig.type === ManageConfigurationInputTypeEnum.BUNDLE ||
          selectedAppConfig.type ===
            ManageConfigurationInputTypeEnum.BUNDLE_ARRAY
        ) {
          appConfigToDispatch = _.set(
            _.cloneDeep(appConfigToDispatch),
            name,
            valueToSet
          );

          appConfigToDispatch = {
            ...appConfigToDispatch,
            value: fillBundleField(
              selectedAppConfig.nestedProperties,
              appConfigToDispatch.value as ManagedConfigurationsType[],
              appConfigToDispatch.type
            ),
          };

          dispatch(fnToDispatch(appConfigToDispatch));

          return;
        }

        dispatch(
          fnToDispatch({
            ...appConfigToDispatch,
            value: valueToSet,
          })
        );
      },
      DEBOUNCE_TIME
    ),
    [selectedAppConfig]
  );

  return (
    <AppActionAccordionCard
      title={selectedAppConfig?.title}
      description={selectedAppConfig?.description}
      isRadio={isBool}
      inputContainerWidth={inputContainerWidth}
      hasNestedProperties={!!selectedAppConfig.nestedProperties}
      planAlert={planAlert}
    >
      <DynamicInputApplications
        handleChange={handleInputChange}
        name={selectedAppConfig?.key}
        value={value || selectedAppConfig?.value}
        type={selectedAppConfig?.type}
        entries={selectedAppConfig?.entries}
        nestedProperties={selectedAppConfig?.nestedProperties}
        inputContainerWidth={inputContainerWidth}
        bundlePath={`[${index}]`}
        firstLevel
        isDisabled={isDisabled}
      />
    </AppActionAccordionCard>
  );
};

export const AppConfigAccordionCard = memo(AccordionCardComponent);
