import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

import { AppDispatch, RootState } from '../store';
import { Plan } from '../types/loginAdminUser';
import { usePlan } from './usePlan';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatchByPlan = (featurePlanKey: keyof Plan) => {
  const { hasFeaturePlan } = usePlan({ featurePlanKey });

  if (hasFeaturePlan) {
    return useDispatch<AppDispatch>();
  }

  return (action) => action;
};
