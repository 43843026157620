import { Box } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { formatBytesTo } from 'portal-lib';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import MenuList from '../../../components/pages/Applications/MenuList';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import { iconImgToRender } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  applicationDeviceUserClear,
  applicationSetFilter,
  listApplications,
  Types,
} from '../../../store/application';
import {
  deviceUserSelected,
  listDeviceUserToFilter,
} from '../../../store/deviceUser';
import { generateExcel } from '../../../store/event';
import { listGroupsToFilter } from '../../../store/group';
import { selectorMetadata } from '../../../store/ui';
import { DeviceUserType } from '../../../types/deviceUser';
import { GroupType } from '../../../types/group';

const ManageApplications = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { applications, filter } = useAppSelector((state) => state.application);
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));
  const [filterSearch, setFilterSearch] = useState('');

  const allFilters = {
    groupId: filter?.group?.id,
    userId: filter?.deviceUser?.id,
    search: filterSearch,
  };

  useEffect(() => {
    dispatch(listGroupsToFilter());
    dispatch(listDeviceUserToFilter());
    dispatch(applicationDeviceUserClear());
  }, []);

  useEffect(() => {
    dispatch(listApplications(inFirstPage(metadata), allFilters));
  }, [filter, filterSearch]);

  const handleFilterGroup = (group: GroupType) => {
    dispatch(applicationSetFilter({ group }));
  };
  const handleFilterGroupChange = (value) => {
    dispatch(listGroupsToFilter({ search: value }));
  };
  const handleFilterDeviceUser = (deviceUser: DeviceUserType) => {
    dispatch(applicationSetFilter({ deviceUser }));
  };
  const handleFilterDeviceUserChange = (value) => {
    dispatch(deviceUserSelected({ search: value }));
  };
  const handleMetadata = (value) => {
    dispatch(listApplications({ ...metadata, ...value }, allFilters));
  };

  const columns = useSorting(
    [
      {
        header: '',
        accessor: 'icon',
      },
      {
        header: intl.formatMessage({
          id: 'application_manage.column.name',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'application_manage.column.quantity',
        }),
        accessor: 'quantity',
        canSort: true,
      },

      {
        header: intl.formatMessage({
          id: 'application_manage.column.consumption',
        }),
        accessor: 'consumption',
        canSort: true,
      },

      {
        header: intl.formatMessage({
          id: 'application_manage.column.time',
        }),
        accessor: 'time',
        canSort: true,
      },
    ],
    metadata
  );
  const data = applications.map((application) => ({
    cells: [
      {
        field: 'icon',
        value: (
          <Image w={'28px'} src={iconImgToRender(application?.iconBase64)} />
        ),
        isExportHidden: true,
      },
      {
        field: 'name',
        value: application.name,
      },
      {
        field: 'quantity',
        value: application.quantity,
      },
      {
        field: 'consumption',
        value: formatBytesTo({ bytes: application.consumption }),
      },
      {
        field: 'time',
        value: application.time,
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <MenuList
              navigationProps={{
                deviceUserId: filter?.deviceUser?.id,
                applicationName: application?.name,
              }}
              showApplicationDeviceUsersItem
              showConsumptionHistory
            />
          );
        },
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };

  return (
    <Box>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="application_manage.title" />}
        description={<FormattedMessage id="application_manage.sub_title" />}
      />
      <FormContainer>
        <PageFilter>
          <FormControl
            textLabel={<FormattedMessage id="application_manage.group" />}
          >
            <SelectAutocomplete
              options={groupsToFilter}
              value={filter.group}
              onChange={handleFilterGroup}
              onInputChange={handleFilterGroupChange}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="application_manage.users" />}
          >
            <SelectAutocomplete
              options={devicesUsersToFilter}
              value={filter.deviceUser}
              onChange={handleFilterDeviceUser}
              onInputChange={handleFilterDeviceUserChange}
              getOptionLabel={(option) =>
                `${option.name || ''} ${option.device.phoneNumber || ''}`
              }
            />
          </FormControl>
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={columns}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        pageActionsProps={{
          initialSearch: filterSearch,
          onSearch: setFilterSearch,
          handleExportAllToExcel,
        }}
        keyProp={Types.LIST}
        nameTable={`${intl.formatMessage({ id: 'application_manage.title' })}`}
      />
    </Box>
  );
};

export default ManageApplications;
