import { IntlShape } from 'react-intl';

import { DEFAULT_LANGUAGE } from './index';

export const validatorUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const validatorDefaultMessages = (intl: IntlShape) => ({
  required: intl.formatMessage({ id: 'form.field_required' }),
  email: intl.formatMessage({ id: 'form.field_email' }),
  alpha_num_dash_space: intl.formatMessage({
    id: 'form.field_alpha_num_dash_space',
  }),
  default: intl.formatMessage({ id: 'form.field_default' }),
  integer: intl.formatMessage({ id: 'form.integer' }),
});

export function languageValidator(
  externalLanguage,
  localLanguage,
  language = {}
) {
  const localesAvailable = Object.keys(language);
  const localLanguageToReturn = localesAvailable.includes(localLanguage)
    ? localLanguage
    : DEFAULT_LANGUAGE;
  return externalLanguage || language[localLanguageToReturn];
}

export function validateStartsWithLetters(str: string) {
  return /^[A-Za-z].*/.test(str);
}
