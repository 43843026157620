import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import {
  ManagementModeEnum,
  PasswordPolicyScopePropertyEnum,
} from '../../../../../../types/policy';
import CardContent from '../../../../../CardContent';
import PolicyAccordions from '../../components/PolicyAccordions';
import PolicyFilterSettings from '../../components/PolicyFilterSettings';
import { handleAlphabeticalSort } from '../../policiesHelpers';
import {
  GetterSettingFn,
  PolicyAccordionProps,
} from '../../PoliciesInterfaces';
import { handleFilterAccordionSettingsIntlKeys } from '../../utils';
import { getHardwareSettings } from './HardwareSettings';
import { getLocalizationSettings } from './LocalizationSettings';
import { getLockScreenSettings } from './LockScreen';
import { getNetworkSettings } from './NetworkSettings';
import OperationalSystem, {
  getOperationalSystemSettings,
} from './OperationalSystem';
import { getPasswordPolicySettings } from './PasswordPolicySettings';
import { getRemoteViewSettings } from './RemoteViewSettings';
import { getSafetySettings } from './SafetySettings';
import { getUserSettings } from './UserSettings';
import { getVpnSettings } from './VpnSettings';
import { getWorkSettings } from './WorkSettings';

export interface AccordionSettingsProps {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeByFields?: (
    name: string,
    value: unknown,
    checked?: boolean
  ) => void;
}

export const intlPolicySettingsKey = 'update_policies.settings.accordion';

const PolicySettings = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { policy } = useAppSelector((state) => state.policy);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const [filter, setFilter] = useState<string>('');

  const isWorkProfile =
    policy.managementMode === ManagementModeEnum.WORK_PROFILE_ANDROID;

  const accordionItems: PolicyAccordionProps[] = [
    {
      title: `${intlPolicySettingsKey}.hardware`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getHardwareSettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.localization`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getLocalizationSettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.network`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getNetworkSettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.${
        isWorkProfile ? 'password_policy.device' : 'password'
      }`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction((params) =>
        getPasswordPolicySettings({
          ...params,
          passwordScope: PasswordPolicyScopePropertyEnum.SCOPE_DEVICE,
        })
      ),
    },
    {
      title: `${intlPolicySettingsKey}.safety`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getSafetySettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.operational_system`,
      renderChildren: (props) => (
        <OperationalSystem {...props} policy={policy} />
      ),
      options: handlePropagatePropsToFunction(getOperationalSystemSettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.user`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getUserSettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.lock_screen`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getLockScreenSettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.vpn`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getVpnSettings),
      isHidden: isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.password_policy.work_profile`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction((params) =>
        getPasswordPolicySettings({
          ...params,
          passwordScope: PasswordPolicyScopePropertyEnum.SCOPE_PROFILE,
        })
      ),
      isHidden: !isWorkProfile,
    },
    {
      title: `${intlPolicySettingsKey}.remote_view`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getRemoteViewSettings),
      isHidden: !portalSettings.allowRemoteView,
    },
    {
      title: `${intlPolicySettingsKey}.work`,
      renderChildren: CardContent,
      options: handlePropagatePropsToFunction(getWorkSettings),
    },
  ]
    .filter((accordion) =>
      filter
        ? accordion?.options.some(
            (option) =>
              !option.isHidden &&
              handleFilterAccordionSettingsIntlKeys(
                [
                  option?.title,
                  option?.description,
                  option?.childrenItem?.title,
                  option?.childrenItem?.description,
                ],
                filter,
                intl
              )
          )
        : accordion
    )
    .sort((accordionItemA, accordionItemB) =>
      handleAlphabeticalSort(accordionItemA, accordionItemB, intl)
    );

  function handleSettingsChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked, value } = event?.target;
    handleSettingsByFields(name, value, checked);
  }

  function handleSettingsByFields(
    name: string,
    value: unknown,
    checked?: boolean
  ) {
    dispatch(
      policySelected({
        [name]: !!value ? value : checked,
      })
    );
  }

  function handlePropagatePropsToFunction(fn: GetterSettingFn) {
    return fn({
      handleChange: handleSettingsChange,
      handleChangeByFields: handleSettingsByFields,
      policy,
      intl,
    });
  }

  return (
    <Flex gridGap="6" flexDirection="column">
      <PolicyFilterSettings setFilter={setFilter} />
      <PolicyAccordions accordionList={accordionItems} searchWords={filter} />
    </Flex>
  );
};

export default PolicySettings;
