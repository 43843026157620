import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import {
  DeviceType,
  InventoryItemType,
  ManufacturerType,
  PasswordsHistoricType,
  ModelType,
  ResetPasswordFlagEnum,
  BatchDevicesActionsForm,
  DeviceKioskAccessToken,
} from '../../types/device';
import { ListQueryParameters } from '../../types/generic_list';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  LIST: 'device/LIST',
  GET: 'device/GET',
  CREATE: 'device/CREATE',
  EDIT: 'device/EDIT',
  LIST_MANUFACTURER: 'device/LIST_MANUFACTURER',
  LIST_INVENTORY: 'device/LIST_INVENTORY',
  LIST_MODEL: 'device/LIST_MODEL',
  LIST_NO_COMMUNICATION: 'devicesWithoutCommunication/LIST_NO_COMMUNICATION',
  ACTION_REMOVE: 'device/ACTION_REMOVE',
  ACTION_DISABLE: 'device/ACTION_DISABLE',
  ACTION_ENABLE: 'device/ACTION_ENABLE',
  ACTION_NEWPASSWORD: 'device/ACTION_NEWPASSWORD',
  ACTION_LISTPASSWORDS: 'device/ACTION_LISTPASSWORDS',
  ACTION_REMOVE_SCREEN_BLOCK: 'device/ACTION_REMOVE_SCREEN_BLOCK',
  ACTION_CHANGE_DEVICE_PASSWORD: 'device/ACTION_CHANGE_DEVICE_PASSWORD',
  SYNC_DEVICE: 'device/SYNC_DEVICE',
  ACTION_LOCK: 'device/ACTION_LOCK',
  ACTION_REBOOT: 'device/ACTION_REBOOT',
  EDIT_POLICY: 'device/EDIT_POLICY',
  VERIFY_BATCH_DEVICES_ACTIONS: 'device/VERIFY_BATCH_DEVICES_ACTIONS',
  BATCH_DEVICES_ACTIONS: 'device/BATCH_DEVICES_ACTIONS',
  GET_KIOSK_ACCESS_TOKEN: 'device/GET_KIOSK_ACCESS_TOKEN',
};

// Reducer

interface DeviceState {
  devices: DeviceType[];
  device: DeviceType;
  manufacturers: ManufacturerType[];
  models: ModelType[];
  inventory: InventoryItemType[];
  passwordsHistoric: PasswordsHistoricType[];
  devicesWithoutCommunication: DeviceType[];
  hasCommandInProcess: boolean;
}

const initialState: DeviceState = {
  devices: [],
  device: {},
  passwordsHistoric: [],
  manufacturers: [],
  inventory: [],
  models: [],
  devicesWithoutCommunication: [],
  hasCommandInProcess: null,
};

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    deviceCreateSuccess: (state, action: PayloadAction<DeviceType>) => {
      state.device = action.payload;
    },
    deviceListSuccess: (state, action: PayloadAction<DeviceType[]>) => {
      state.devices = action.payload;
    },
    deviceManufacturersListSuccess: (
      state,
      action: PayloadAction<ManufacturerType[]>
    ) => {
      state.manufacturers = action.payload;
    },
    deviceInventoryListSuccess: (
      state,
      action: PayloadAction<InventoryItemType[]>
    ) => {
      state.inventory = action.payload;
    },

    deviceModelsListSuccess: (state, action: PayloadAction<ModelType[]>) => {
      state.models = action.payload;
    },
    deviceGetSuccess: (state, action: PayloadAction<DeviceType>) => {
      state.device = action.payload;
    },
    deviceEditSuccess: (state, action: PayloadAction<DeviceType>) => {
      state.device = action.payload;
    },
    deviceActionListPasswordsSuccess: (
      state,
      action: PayloadAction<PasswordsHistoricType[]>
    ) => {
      state.passwordsHistoric = action.payload;
    },
    deviceListWithoutCommunicationSuccess: (
      state,
      action: PayloadAction<DeviceType[]>
    ) => {
      state.devicesWithoutCommunication = action.payload;
    },
    deviceEditInfo: (state, action: PayloadAction<DeviceType>) => {
      state.device = { ...state.device, ...action.payload };
    },
    deviceVerifyCommandInProcess: (state, action: PayloadAction<boolean>) => {
      state.hasCommandInProcess = action.payload;
    },
    deviceGetKioskAccessTokenSuccess: (
      state,
      action: PayloadAction<DeviceKioskAccessToken>
    ) => {
      state.device = { ...state.device, kioskAccessToken: action.payload };
    },
  },
});

export default deviceSlice.reducer;

// Action Creators

export const {
  deviceCreateSuccess,
  deviceListSuccess,
  deviceGetSuccess,
  deviceEditSuccess,
  deviceManufacturersListSuccess,
  deviceInventoryListSuccess,
  deviceModelsListSuccess,
  deviceActionListPasswordsSuccess,
  deviceListWithoutCommunicationSuccess,
  deviceEditInfo,
  deviceVerifyCommandInProcess,
  deviceGetKioskAccessTokenSuccess,
} = deviceSlice.actions;

export function listDevices(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function getDevice(id: ID) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function createDevice(data) {
  return {
    type: Types.CREATE,
    payload: data,
  };
}

export function editDevice(data) {
  return {
    type: Types.EDIT,
    payload: data,
  };
}

export function editDevicePolicy(data: {
  deviceId: ID;
  policy: { policyId: ID };
}) {
  return {
    type: Types.EDIT_POLICY,
    payload: data,
  };
}

export function changeDevicePassword(data: {
  deviceId: ID;
  newPassword?: string;
  resetPasswordFlags?: ResetPasswordFlagEnum[];
}) {
  return {
    type: Types.ACTION_CHANGE_DEVICE_PASSWORD,
    payload: data,
  };
}

export function listDeviceManufacturer(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_MANUFACTURER,
    payload: { filters: sanitizeFilter(filters) },
  };
}
export function listDeviceInventory() {
  return {
    type: Types.LIST_INVENTORY,
    payload: {},
  };
}
export function listDeviceModel(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_MODEL,
    payload: { filters: sanitizeFilter(filters) },
  };
}
export function removeDevice(idDevice: ID) {
  return {
    type: Types.ACTION_REMOVE,
    payload: idDevice,
  };
}

export function disableDevice(idDevice: ID) {
  return {
    type: Types.ACTION_DISABLE,
    payload: idDevice,
  };
}

export function enableDevice(idDevice: ID) {
  return {
    type: Types.ACTION_ENABLE,
    payload: idDevice,
  };
}

export function newPasswordDevice(idDevice: ID) {
  return {
    type: Types.ACTION_NEWPASSWORD,
    payload: idDevice,
  };
}

export function removeScreenBlockDevice(idDevice: ID) {
  return {
    type: Types.ACTION_REMOVE_SCREEN_BLOCK,
    payload: idDevice,
  };
}

export function listPasswordHistoric(idDevice: ID) {
  return {
    type: Types.ACTION_LISTPASSWORDS,
    payload: idDevice,
  };
}

export function listDevicesWithoutCommunication(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST_NO_COMMUNICATION,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function syncPolicy() {
  return {
    type: Types.SYNC_DEVICE,
  };
}

export function turnOffScreen(idDevice: ID) {
  return {
    type: Types.ACTION_LOCK,
    payload: idDevice,
  };
}

export function restartDevice(id: ID) {
  return {
    type: Types.ACTION_REBOOT,
    payload: id,
  };
}

export function verifyBatchDevicesActions() {
  return {
    type: Types.VERIFY_BATCH_DEVICES_ACTIONS,
  };
}

export function batchDevicesActions(data: BatchDevicesActionsForm) {
  return {
    type: Types.BATCH_DEVICES_ACTIONS,
    payload: data,
  };
}

export function getKioskAccessToken(id: ID) {
  return {
    type: Types.GET_KIOSK_ACCESS_TOKEN,
    payload: id,
  };
}
