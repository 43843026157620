import XMLParser, { XMLElement } from 'react-xml-parser';

export const transformFileToText = async (
  file: File
): Promise<string | ArrayBuffer> => {
  return new Promise(async (resolve) => {
    try {
      const reader = new FileReader();

      await reader.readAsText(file);

      reader.onload = (e) => {
        try {
          const renderData = e.target.result;

          return resolve(renderData);
        } catch (error) {
          return resolve(null);
        }
      };
    } catch (error) {
      return resolve(error);
    }
  });
};

export const getXMLProp = async (
  file: File,
  prop: string
): Promise<XMLElement> => {
  return new Promise(async (resolve) => {
    try {
      const reader = new FileReader();

      await reader.readAsText(file);

      reader.onload = (e) => {
        try {
          const renderData = e.target.result;

          if (renderData) {
            const xml = new XMLParser().parseFromString(renderData as string);
            const value = xml?.getElementsByTagName(prop)[0] || null;

            if (value) {
              return resolve(value);
            }

            return resolve(value);
          }

          return resolve(null);
        } catch (error) {
          return resolve(null);
        }
      };
    } catch (error) {
      return resolve(error);
    }
  });
};

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    try {
      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Content = base64String.split(',')[1];

        resolve(base64Content);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      reader.onerror = () => {
        reject(error);
      };
    }
  });
}

export function base64ToFile(
  base64String: string,
  filename: string,
  mimeType: string
): File {
  // Decode base64 string to binary characters
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });
  return new File([blob], filename, { type: mimeType });
}

export function fileToFormData(name: string, file: File) {
  const formData = new FormData();

  formData.append(name, file || '');

  return formData;
}
