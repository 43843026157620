import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { Flex, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

import { isHiddenColumn } from './hiddenColumn';
import { HeaderProps } from './TableInterfaces';

const TableHeaderComponent: React.FC<HeaderProps> = ({
  headers,
  onSort,
}: HeaderProps) => {
  return (
    headers && (
      <Thead>
        <Tr>
          {headers.map((headerColumn, index) => {
            const {
              accessor,
              header,
              canSort,
              isSorted,
              isSortedDesc,
              isExpandedField,
              isExportField,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              isExportHidden,
              transform,
              ...chakraProps
            } = headerColumn;

            if (isHiddenColumn(accessor, isExpandedField || isExportField))
              return;

            return (
              <Th
                {...chakraProps}
                key={index}
                onClick={() => canSort && onSort && onSort(headerColumn)}
                cursor={headerColumn.canSort && onSort ? 'pointer' : 'default'}
              >
                <Flex align={'center'} gridColumnGap={1}>
                  {transform?.() || header}
                  {headerColumn.canSort && (
                    <Flex>
                      <ArrowUpIcon
                        color={
                          isSorted && !isSortedDesc ? 'gray.500' : 'gray.600'
                        }
                      />
                      <ArrowDownIcon
                        color={
                          isSorted && isSortedDesc ? 'gray.500' : 'gray.600'
                        }
                      />
                    </Flex>
                  )}
                </Flex>
              </Th>
            );
          })}
        </Tr>
      </Thead>
    )
  );
};

export default TableHeaderComponent;
