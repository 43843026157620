import {
  IconButton as IconButtonChakra,
  IconButtonProps as IconButtonTypeChakra,
} from '@chakra-ui/react';

interface IconButtonProps extends IconButtonTypeChakra {
  color?: string;
}
const IconButton = ({ color, ...rest }: IconButtonProps) => (
  <IconButtonChakra
    bgColor={color || 'transparent'}
    _hover={{ opacity: '50%' }}
    {...rest}
  />
);

export default IconButton;
