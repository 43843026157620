import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FIRST_METADATA } from '../../helper';
import { ListMetadata } from '../../types/generic_list';
import { NotificationType } from '../../types/notification';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  UPDATE: 'notifications/UPDATE',
  LIST_NOTIFICATION: 'notifications/LIST_NOTIFICATION',
  GET_NOTIFICATION: 'notifications/GET_NOTIFICATION',
};

interface NotificationState {
  notifications: NotificationType[];
  notificationsInProgress: NotificationType[];
  notification: NotificationType;
  notificationDetailsMetadata: ListMetadata;
}

const initialState: NotificationState = {
  notifications: [],
  notificationsInProgress: [],
  notification: null,
  notificationDetailsMetadata: FIRST_METADATA,
};

function updateNotificationState(
  state: NotificationState,
  action: PayloadAction<NotificationType>
) {
  state.notification = action.payload;
  state.notificationDetailsMetadata = {
    ...state.notificationDetailsMetadata,
    totalItems: action?.payload?.details?.data?.length,
  };
}

export const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationListSuccess: (
      state,
      action: PayloadAction<NotificationType[]>
    ) => {
      state.notifications = action.payload;
    },
    notificationListInProgressSuccess: (
      state,
      action: PayloadAction<NotificationType[]>
    ) => {
      state.notificationsInProgress = action.payload;
    },
    notificationGetSuccess: (
      state,
      action: PayloadAction<NotificationType>
    ) => {
      updateNotificationState(state, action);
    },
    notificationDetailsMetadata: (
      state,
      action: PayloadAction<ListMetadata>
    ) => {
      state.notificationDetailsMetadata = action.payload;
    },
    clearNotification: (state) => {
      state.notification = initialState.notification;
      state.notificationDetailsMetadata =
        initialState.notificationDetailsMetadata;
    },
  },
});

export default notificationsSlice.reducer;

// Action Creators

export const {
  notificationListSuccess,
  notificationListInProgressSuccess,
  notificationGetSuccess,
  notificationDetailsMetadata,
  clearNotification,
} = notificationsSlice.actions;

export function listNotifications() {
  return {
    type: Types.LIST_NOTIFICATION,
  };
}

export function updateNotificationRead(id: ID) {
  return {
    type: Types.UPDATE,
    payload: id,
  };
}

export function getNotification(id: ID) {
  return {
    type: Types.GET_NOTIFICATION,
    payload: id,
  };
}
