import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeFilter } from '../../helper/filter';
import { DeviceUserFilter, DeviceUserType } from '../../types/deviceUser';
import { ListQueryParameters } from '../../types/generic_list';

// Action Types

export const Types = {
  LIST: 'deviceUser/LIST',
  SELECT: 'deviceUser/SELECT',
  GET: 'deviceUser/GET',
  EDIT: 'deviceUser/EDIT',
  TOASTER_USER: 'user/TOASTER',
  LIST_FILTER: 'deviceUser/LIST_FILTER',
};

// Reducer

interface DeviceUserState {
  deviceUsers: DeviceUserType[];
  deviceUser: DeviceUserType;
  devicesUsersToFilter: DeviceUserType[];
  filter: DeviceUserFilter;
}

const initialState: DeviceUserState = {
  deviceUsers: [],
  deviceUser: {},
  devicesUsersToFilter: [],
  filter: {},
};

export const deviceUserSlice = createSlice({
  name: 'deviceUsers',
  initialState,
  reducers: {
    deviceUserSelected: (
      state,
      action: PayloadAction<Partial<DeviceUserType>>
    ) => {
      state.deviceUser = { ...state.deviceUser, ...action.payload };
    },
    deviceUserSelectedClear: (state) => {
      state.deviceUser = initialState.deviceUser;
    },
    deviceUserListSuccess: (state, action: PayloadAction<DeviceUserType[]>) => {
      state.deviceUsers = action.payload;
    },
    deviceUserFilterListSuccess: (
      state,
      action: PayloadAction<DeviceUserType[]>
    ) => {
      state.devicesUsersToFilter = action.payload;
    },
    deviceUserGetSuccess: (state, action: PayloadAction<DeviceUserType>) => {
      state.deviceUser = action.payload;
    },
    deviceUserEditSuccess: (state, action: PayloadAction<DeviceUserType>) => {
      state.deviceUser = action.payload;
    },
    deviceUserSetFilter: (
      state,
      action: PayloadAction<Partial<DeviceUserFilter>>
    ) => {
      state.filter = action.payload;
    },
  },
});

export default deviceUserSlice.reducer;

// Action Creators

export const {
  deviceUserSelected,
  deviceUserSelectedClear,
  deviceUserListSuccess,
  deviceUserFilterListSuccess,
  deviceUserGetSuccess,
  deviceUserEditSuccess,
  deviceUserSetFilter,
} = deviceUserSlice.actions;

export function listDeviceUsers(
  queryParameters: ListQueryParameters,
  filters?: Record<string, unknown>
) {
  return {
    type: Types.LIST,
    payload: { queryParameters, filters: sanitizeFilter(filters) },
  };
}

export function listDeviceUserToFilter(filters?: Record<string, unknown>) {
  return {
    type: Types.LIST_FILTER,
    payload: { filters: sanitizeFilter(filters) },
  };
}

export function getDeviceUser(id: DeviceUserState['deviceUser']['id']) {
  return {
    type: Types.GET,
    payload: id,
  };
}

export function editDeviceUser(data) {
  return {
    type: Types.EDIT,
    payload: data,
  };
}
