import { Box, useRadioGroup } from '@chakra-ui/react';
import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import RadioButton from '../../../components/RadioButton';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import TableActions from '../../../components/TableActions';
import Text from '../../../components/Text';
import { PrivilegeEnum, routeWithParameters } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  deleteAdminUser,
  listAdminUsers,
  Types,
} from '../../../store/adminUser';
import { generateExcel } from '../../../store/event';
import { listGroupsToFilter } from '../../../store/group';
import { selectorMetadata } from '../../../store/ui';
import { AdminUserType } from '../../../types/adminUser';
import { ListMetadata } from '../../../types/generic_list';
import { Autocomplete } from '../../../types/util';

type radioTabs = 'company' | 'group_manager';

const ManageAdminUsers = () => {
  const { accessFilterCompany, accessFilterGroup } = usePrivilege();

  const dispatch = useAppDispatch();
  const { adminUsers } = useAppSelector((state) => state.adminUser);
  const { user } = useAppSelector((state) => state.auth);

  const {
    user: { company, privilege },
  } = useAppSelector((state) => state.auth);
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));

  const [selectedTab, setSelectedTab] = useState<radioTabs>();
  const [selectFilter, setSelectFilter] = useState<Autocomplete>(
    privilege !== PrivilegeEnum.GROUP && company
  );
  const [selectInputFilter, setSelectInputFilter] = useState<string>();

  const [searchFilter, setSearchFilter] = useState('');

  const privilegeIdByTab = (selectedTab: radioTabs) =>
    selectedTab === 'company' ? PrivilegeEnum.COMPANY : PrivilegeEnum.GROUP;

  const allFilters = {
    search: searchFilter,
    privilege: privilegeIdByTab(selectedTab),
    groupId: selectedTab === 'group_manager' ? selectFilter?.id : undefined,
  };

  const intl = useIntl();

  const radioDefaultValue = useMemo(() => {
    return accessFilterCompany ? 'company' : 'group_manager';
  }, [accessFilterCompany, accessFilterGroup]);

  useEffect(() => {
    setSelectedTab(radioDefaultValue);
  }, [radioDefaultValue]);

  useEffect(() => {
    dispatch(listAdminUsers(inFirstPage(metadata), allFilters));
  }, [selectedTab, selectFilter, searchFilter]);

  useEffect(() => {
    if (selectedTab === 'company' && accessFilterCompany) {
      setSelectFilter(company);
    }
    if (selectedTab === 'group_manager' && accessFilterGroup) {
      dispatch(listGroupsToFilter({ search: selectInputFilter }));
    }
  }, [selectedTab, selectInputFilter]);

  const handlePagination = (newPagination: Partial<ListMetadata>) => {
    dispatch(listAdminUsers({ ...metadata, ...newPagination }, allFilters));
  };

  const ruleOptions: Array<{ id: radioTabs; label: string }> = [];
  if (accessFilterCompany) {
    ruleOptions.push({
      id: 'company',
      label: intl.formatMessage({
        id: 'manage_admin.filter.company',
      }),
    });
  }
  if (accessFilterGroup) {
    ruleOptions.push({
      id: 'group_manager',
      label: intl.formatMessage({
        id: 'manage_admin.filter.group_manager',
      }),
    });
  }

  const changeRadio = (value: radioTabs) => {
    setSelectedTab(value);
    setSelectFilter(value === 'company' ? company : null);
  };

  const { getRadioProps } = useRadioGroup({
    name: 'ruleOptions',
    defaultValue: radioDefaultValue,
    onChange: changeRadio,
  });

  const handleDeleteAdminUser = (
    id: AdminUserType['id'],
    privilege: PrivilegeEnum
  ) => {
    dispatch(deleteAdminUser({ id, privilege }));
  };

  const renderSelectFilter = () => {
    let selectData: {
      title: string;
      selectList: Autocomplete[];
      disabled?: boolean;
    } = { title: '', selectList: [] };

    if (selectedTab === 'group_manager') {
      selectData = {
        title: intl.formatMessage({
          id: 'manage_admin.filter.title_group_manager',
        }),
        selectList: groupsToFilter,
      };
    } else {
      selectData = {
        title: intl.formatMessage({
          id: 'manage_admin.filter.title_company',
        }),
        selectList: [company],
        disabled: true,
      };
    }

    return (
      <FormControl textLabel={selectData.title}>
        <SelectAutocomplete
          placeholder={intl.formatMessage({
            id: 'manage_admin.filter.placeholder',
          })}
          options={selectData.selectList}
          value={selectFilter}
          disabled={selectData.disabled}
          onInputChange={(input) => setSelectInputFilter(input)}
          onChange={(value: Autocomplete) => setSelectFilter(value)}
        />
      </FormControl>
    );
  };

  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'manage_admin.column.name',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_admin.column.adminID',
        }),
        accessor: 'federalCode',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_admin.column.email',
        }),
        accessor: 'email',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_admin.column.gtm',
        }),
        accessor: 'timezone',
      },
      {
        header: intl.formatMessage({
          id: 'manage_admin.column.language',
        }),
        accessor: 'language',
      },
      {
        header: '',
        accessor: 'actions',
      },
    ],
    metadata
  );

  const data = adminUsers.map((adminUser) => ({
    cells: [
      {
        field: 'name',
        value: adminUser.name,
      },
      {
        field: 'federalCode',
        value: adminUser.federalCode,
      },
      {
        field: 'email',
        value: adminUser.email,
      },
      {
        field: 'timezone',
        value: adminUser.timezone,
      },
      {
        field: 'language',
        value: adminUser.language,
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={intl.formatMessage({
                id: 'manage_admin.entity',
              })}
              linkEdit={routeWithParameters(routes.adminUsers.edit, {
                id: adminUser.id,
              })}
              openDestroy={
                user.privilege === 'group'
                  ? undefined
                  : () =>
                      handleDeleteAdminUser(adminUser.id, adminUser.privilege)
              }
              disableDestroy={user.id === adminUser.id}
            />
          );
        },
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={intl.formatMessage({
          id: 'manage_admin.title',
        })}
        description={intl.formatMessage({
          id: 'manage_admin.description',
        })}
      />
      <PageShowToaster
        toasterKeys={[Types.CREATE]}
        messageSuccess={intl.formatMessage({
          id: 'manage_admin.toaster.success',
        })}
        disabledError
      />
      <PageShowToaster
        toasterKeys={[Types.EDIT]}
        messageSuccess={intl.formatMessage({
          id: 'manage_admin.toaster.success.edit',
        })}
        disabledError
      />
      <PageShowToaster
        toasterKeys={[Types.EDIT_WITH_EMAIL]}
        messageSuccess={intl.formatMessage({
          id: 'manage_admin.toaster.success.edit_with_email',
        })}
        disabledError
      />
      <PageShowToaster
        toasterKeys={[Types.DELETE]}
        messageSuccess={intl.formatMessage({
          id: 'manage_admin.toaster.success.delete',
        })}
        disabledError
      />
      <FormContainer>
        <PageFilter gridColumnGap={4}>
          <Box d="flex" flexDirection="column" alignItems="top">
            <Text
              color="gray.500"
              fontSize="sm"
              fontWeight="400"
              m="0 0 10px 0"
            >
              <FormattedMessage id="manage_admin.filter.title" />
            </Text>
            <Box d="flex" flexDirection="row" w="100%">
              {ruleOptions.map((option) => {
                const radio = getRadioProps({
                  value: option.id,
                });
                return (
                  <RadioButton key={option.id} {...radio}>
                    {option.label}
                  </RadioButton>
                );
              })}
            </Box>
          </Box>

          <Box flex="1">{renderSelectFilter()}</Box>
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={columns}
        rows={data}
        handleSort={handlePagination}
        metadata={metadata}
        keyProp={Types.LIST}
        pageActionsProps={{
          initialSearch: searchFilter,
          onSearch: setSearchFilter,
          handleExportAllToExcel,
          linkNew: routes.adminUsers.register,
        }}
        nameTable={`${intl.formatMessage({ id: 'manage_admin.title' })}`}
      />
    </>
  );
};

export default ManageAdminUsers;
