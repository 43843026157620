export enum InvoiceDeviceStatusEnum {
  WAITING = 'WAITING',
  REJECT = 'REJECT',
  APPROVED = 'APPROVED',
  /** Disapproved by Zero Touch */
  DISAPPROVE = 'DISAPPROVE',
  REGISTERED = 'REGISTERED',
  NOT_REGISTERED = 'NOT_REGISTERED',
}

export interface InvoiceDeviceType {
  manufacturer?: string;
  model?: string;
  status?: InvoiceDeviceStatusEnum;
  imei?: string;
  description?: string;
  deviceTimestamp?: string;
  productId?: string;
  observation?: string;
  deviceId?: string;
  statusError?: string;
}

export interface InvoiceItemType {
  id?: string;
  code?: string;
  manufacturer?: string;
  model?: string;
  description?: string;
  imei?: string;
}

export interface InvoiceType {
  id?: string;
  createdAt?: string;
  invoiceNumber?: number;
  accessKey?: string;
  imei?: string;
  devicesCount?: number;
  devicesWithImei?: number;
  status?: boolean;
  devices?: InvoiceDeviceType[];
  items: InvoiceItemType[];
}
export interface BackofficeInvoiceType {
  id?: string;
  invoiceNumber?: number;
  accessKey?: string;
  devicesCount?: number;
  devicesStatusPendent?: number;
  devicesWithImei?: number;
  status?: boolean;
  createdAt?: string;
  company: BackofficeInvoiceCompany;
  devices?: InvoiceDeviceType[];
}

interface BackofficeInvoiceCompany {
  id;
  federalCode;
  corporateName;
  zeroTouchResellerId;
}

export interface RegisterZeroTouchData {
  invoiceId: string;
  devices: InvoiceDeviceType[];
}

export interface RegisterDevicesImeiData {
  invoiceId: string;
  items: InvoiceItemType[];
}

export interface RegisterCustomerData {
  email?: string;
  customerId?: string;
  token?: string;
}

export type BackofficeInvoiceResponseType = BackofficeInvoiceType[];

export interface InvoiceResponseType {
  customerId?: string;
  invoices?: InvoiceType[];
}

export interface InvoiceDeviceImeiInfoResponse {
  imei?: string;
  manufacturer?: string;
  model?: string;
}

export interface ImportDevicesImeiData {
  file?: FormData;
  id?: string;
}
