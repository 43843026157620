import { Flex } from '@chakra-ui/layout';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { AccordionSettingsProps, intlPolicySettingsKey } from '../..';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/useRedux';
import { listNetworksOptionsForSelect } from '../../../../../../../../store/network';
import { policySelected } from '../../../../../../../../store/policy';
import {
  NetworkSecurityEnum,
  NetworkSettingsType,
} from '../../../../../../../../types/network';
import InputKeywords from '../../../../../../../InputKeywords';

interface AddWifiSettingsProps extends AccordionSettingsProps {
  isDisabled?: boolean;
}

export const AddWifiSettings = ({ isDisabled }: AddWifiSettingsProps) => {
  const intl = useIntl();
  const { policy } = useAppSelector((state) => state.policy);
  const dispatch = useAppDispatch();
  const { networksOptionsForSelect } = useAppSelector((state) => state.network);

  const handleAddSetting = (prop: string, newSetting: NetworkSettingsType) => {
    dispatch(
      policySelected({
        [prop]: [newSetting, ...(policy?.[prop] || [])],
      })
    );
  };

  const handleDeleteSetting = (
    prop: string,
    deleteSetting: NetworkSettingsType
  ) => {
    dispatch(
      policySelected({
        [prop]: (policy?.[prop] || []).filter(
          (network) => network.id !== deleteSetting.id
        ),
      })
    );
  };

  useEffect(() => {
    dispatch(listNetworksOptionsForSelect({}));
  }, []);

  return (
    <Flex minW="450px" w="full">
      <InputKeywords
        name="policyNetworks"
        keywords={policy?.policyNetworks || []}
        handleSubmit={handleAddSetting}
        onConfirmDelete={(prop, network) =>
          handleDeleteSetting(prop, network as NetworkSettingsType)
        }
        getKeywordLabel={(keyword: NetworkSettingsType) =>
          `${keyword.name} - ${keyword.ssid} - ${
            keyword.security === NetworkSecurityEnum.NONE
              ? intl.formatMessage({
                  id: 'network_settings_details.security.none',
                })
              : keyword.security
          }`
        }
        isAutocomplete
        autocompleteProps={{
          options: networksOptionsForSelect?.filter(
            (optionNetwork) =>
              !(policy?.policyNetworks || []).find(
                (stateNetwork) => stateNetwork.id === optionNetwork.id
              )
          ),
          disabled: isDisabled,
        }}
        emptyMessage={intl.formatMessage({
          id: `${intlPolicySettingsKey}.wifi.empty_message`,
        })}
      />
    </Flex>
  );
};
