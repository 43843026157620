import { Flex } from '@chakra-ui/react';
import { endOfDay, format, startOfDay } from 'date-fns';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { intlPolicySettingsKey } from '.';
import { DATE_OF_TODAY } from '../../../../../../helper';
import { handleFormatStringToTime } from '../../../../../../helper/date';
import { featurePlanKeys } from '../../../../../../helper/plan';
import { usePlan } from '../../../../../../hooks/usePlan';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../store/policy';
import { CardItemType } from '../../../../../CardContent';
import FormControl from '../../../../../FormControl';
import Select from '../../../../../Select';
import SwitchButton from '../../../../../SwitchButton';
import TimePicker from '../../../../../TimePicker/TimePicker';
import { GetterSettingFn } from '../../PoliciesInterfaces';
import { dayOfWeekOptions } from './utils';

export const getWorkSettings: GetterSettingFn = ({
  policy,
  handleChange,
  intl,
}) => {
  const dispatch = useAppDispatch();
  const { hasFeaturePlan: allowWorkSettings } = usePlan({
    featurePlanKey: featurePlanKeys.allowWorkSettings,
  });

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event?.target;

    dispatch(
      policySelected({
        [name]: Number(value),
      })
    );
  };

  const handlePeriodFilterChange = (date: Date, name: string) => {
    dispatch(
      policySelected({
        [name]: date ? format(date, 'HH:mm:ss') : null,
      })
    );
  };

  const listSettings: CardItemType[] = [
    {
      title: `${intlPolicySettingsKey}.work.lock_apps_outside_working_hours.title`,
      description: `${intlPolicySettingsKey}.work.lock_apps_outside_working_hours.description`,
      isDisabled: !allowWorkSettings,
      tooltipLabel: intl.formatMessage({ id: 'global.plan_warning' }),
      children: (
        <SwitchButton
          name="lockAppsOutsideWorkingHours"
          isChecked={policy?.lockAppsOutsideWorkingHours}
          onChange={handleChange}
        />
      ),
    },
    {
      title: `${intlPolicySettingsKey}.work.days_of_work.title`,
      description: `${intlPolicySettingsKey}.work.days_of_work.description`,
      isHidden: !policy?.lockAppsOutsideWorkingHours,
      children: (
        <Flex gridGap={3} w="full">
          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.work.start_day_of_work`}
              />
            }
          >
            <Select
              value={policy?.startDayOfWork || ''}
              name="startDayOfWork"
              onChange={handleSelectChange}
            >
              {dayOfWeekOptions.map((option) => (
                <option
                  disabled={option.value > policy?.endDayOfWork}
                  key={option.value}
                  value={option.value}
                >
                  {intl.formatMessage({ id: option.label })}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.work.end_day_of_work`}
              />
            }
          >
            <Select
              value={policy?.endDayOfWork || ''}
              name="endDayOfWork"
              onChange={handleSelectChange}
            >
              {dayOfWeekOptions.map((option) => (
                <option
                  disabled={option.value < policy?.startDayOfWork}
                  key={option.value}
                  value={option.value}
                >
                  {intl.formatMessage({ id: option.label })}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
      ),
    },
    {
      title: `${intlPolicySettingsKey}.work.work_time.title`,
      description: `${intlPolicySettingsKey}.work.work_time.description`,
      isHidden: !policy?.lockAppsOutsideWorkingHours,
      children: (
        <Flex gridGap={3}>
          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.work.start_working_time`}
              />
            }
            textInfo={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.work.work_time.tooltip`}
              />
            }
          >
            <TimePicker
              textLabel={intl.formatMessage({
                id: `${intlPolicySettingsKey}.work.work_time.placeholder`,
              })}
              onChange={(e) => handlePeriodFilterChange(e, 'startWorkingTime')}
              selected={handleFormatStringToTime(policy?.startWorkingTime)}
              hasValue={!!policy?.startWorkingTime}
              minTime={startOfDay(DATE_OF_TODAY)}
              maxTime={
                handleFormatStringToTime(policy?.endWorkingTime) ||
                endOfDay(DATE_OF_TODAY)
              }
              excludeTimes={[handleFormatStringToTime(policy?.endWorkingTime)]}
            />
          </FormControl>

          <FormControl
            textLabel={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.work.end_working_time`}
              />
            }
            textInfo={
              <FormattedMessage
                id={`${intlPolicySettingsKey}.work.work_time.tooltip`}
              />
            }
          >
            <TimePicker
              textLabel={intl.formatMessage({
                id: `${intlPolicySettingsKey}.work.work_time.placeholder`,
              })}
              selected={handleFormatStringToTime(policy?.endWorkingTime)}
              onChange={(e) => handlePeriodFilterChange(e, 'endWorkingTime')}
              hasValue={!!policy?.endWorkingTime}
              maxTime={endOfDay(DATE_OF_TODAY)}
              minTime={
                handleFormatStringToTime(policy?.startWorkingTime) ||
                startOfDay(DATE_OF_TODAY)
              }
              excludeTimes={[
                handleFormatStringToTime(policy?.startWorkingTime),
              ]}
            />
          </FormControl>
        </Flex>
      ),
    },
  ];

  return listSettings;
};
