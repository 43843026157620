import { useStyleConfig } from '@chakra-ui/react';
import React, { Fragment } from 'react';

import { OperationalSystemEnum } from '../../../types/device';
import AndroidIcon from '../Android';
import IOS from '../IOS';

interface OperationalSystemIconProps {
  operationalSystem?: OperationalSystemEnum;
}

const OperationalSystemIcon = ({
  operationalSystem,
}: OperationalSystemIconProps) => {
  const style = useStyleConfig('operationalSystemIcon');

  const iconToRenderByOperationalSystem = {
    [OperationalSystemEnum.ANDROID]: AndroidIcon,
    [OperationalSystemEnum.IOS]: IOS,
  };

  const IconToRender = iconToRenderByOperationalSystem[operationalSystem];

  return IconToRender ? <IconToRender {...style} /> : <Fragment />;
};

export default OperationalSystemIcon;
