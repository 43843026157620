import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../../components/Card';
import ApplicationHeader from '../../../components/pages/Applications/ApplicationHeader';
import MonthlyClusterBarChart from '../../../components/pages/Applications/ConsumptionHistory/MonthlyClusterChart';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import { newMetadata } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  applicationDeviceUserSet,
  applicationSet,
  listApplicationConsumptionHistory,
  Types,
} from '../../../store/application';
import { hasLoading } from '../../../store/ui';

const ConsumptionHistory = () => {
  const dispatch = useAppDispatch();
  const { consumptionHistorical, deviceUser, filter } = useAppSelector(
    (state) => state.application
  );
  const { deviceUserId, applicationName } =
    useParams<{ deviceUserId: string; applicationName: string }>();
  const isLoading = hasLoading(Types.LIST_APPLICATION_CONSUMPTION_HISTORY);
  const intl = useIntl();

  const history = useHistory();
  const previousLocation: { from?: string } = history.location.state;
  const showDeviceUserTable =
    `/application-device-users/${applicationName}` === previousLocation?.from;

  const columns = useSorting(
    [
      {
        accessor: 'group',
        canSort: true,
        header: intl.formatMessage({ id: 'company_consumption.column.group' }),
      },
      {
        accessor: 'device_user',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.device_user',
        }),
      },
      {
        accessor: 'phone',
        canSort: true,
        header: intl.formatMessage({ id: 'company_consumption.column.phone' }),
      },
      {
        accessor: 'package_name',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.package_name',
        }),
      },
      {
        accessor: 'consumption',
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.consumption',
        }),
      },
    ],
    newMetadata()
  );

  const data = deviceUser
    ? [
        {
          cells: [
            {
              field: 'group',
              value: deviceUser.group,
            },
            {
              field: 'device_user',
              value: deviceUser.user,
            },
            {
              field: 'phone',
              value: deviceUser.phoneNumber,
            },
            {
              field: 'package_name',
              value: deviceUser.packageName,
            },
            {
              field: 'consumption',
              value: deviceUser.consumption,
            },
          ],
        },
      ]
    : [];

  useEffect(() => {
    if (!deviceUserId || !showDeviceUserTable) return;

    dispatch(applicationDeviceUserSet(Number(deviceUserId)));
  }, [deviceUserId, showDeviceUserTable]);

  useEffect(() => {
    dispatch(applicationSet(applicationName));
  }, [applicationName]);

  useEffect(() => {
    dispatch(
      listApplicationConsumptionHistory(
        {
          applicationName,
          userId: deviceUserId ?? '',
        },
        filter
      )
    );
  }, [applicationName, deviceUserId]);

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="history_consumption.title" />}
        description={
          <FormattedMessage
            id={
              showDeviceUserTable
                ? 'history_consumption.history_user.description'
                : 'history_consumption.description'
            }
          />
        }
      />
      <ApplicationHeader intlKey="company_consumption" />
      {!!deviceUser && showDeviceUserTable && (
        <Box mt="2%">
          <Table headerColumns={columns} rows={data} disabledActions />
        </Box>
      )}
      <Card mt="2%">
        <MonthlyClusterBarChart
          data={consumptionHistorical}
          isLoading={isLoading}
        />
      </Card>
    </>
  );
};

export default ConsumptionHistory;
