import { Box } from '@chakra-ui/react';

import { hasEmpty } from '../../store/ui';
import TableAlert from '../TableAlert';

interface AlertEmptyDataType {
  alertKey: string;
  text?: string;
}

const AlertEmptyData = ({ alertKey, text }: AlertEmptyDataType) => {
  const isEmpty = hasEmpty(alertKey);

  return (
    <Box mt="5px">
      <TableAlert alertKey={alertKey} isEmpty={isEmpty} text={text} />
    </Box>
  );
};

export default AlertEmptyData;
