import { AuthState } from '../store/auth';
import { storageKey } from '../store/persister';
import { PortalSettingsState } from '../store/portalSettings';

interface StorageUser {
  auth: AuthState;
  portalSettings: PortalSettingsState;
}

export const handleDiscordLogger = (error: Error) => {
  const disableLog = process.env.REACT_APP_ENABLE_LOG === 'false';

  if (disableLog) return;

  const channelUrl =
    'https://discord.com/api/webhooks/1129488597033168907/WIcbeKIgpmVkjqv1raGpjo1VJ01fV31BAKWiaqZDT4972GZpn9vIvCbEUCrL2P6_5a3C';

  const storePersister: StorageUser = JSON.parse(
    localStorage.getItem(storageKey)
  );

  const messageObj = {
    content: error.message,
    embeds: [
      {
        title: error?.name || 'Unknown error name',
        color: 16711680,
        description: error?.stack || 'Unknown error stack',
        fields: [
          {
            name: 'URL',
            value: window?.location?.href || 'Unknown window location href',
            inline: true,
          },
          {
            name: 'User email',
            value: storePersister?.auth?.user?.email || '',
            inline: true,
          },
          {
            name: 'Enterprise ID',
            value: storePersister?.auth?.user?.company?.enterpriseId || '',
            inline: false,
          },
          {
            name: 'PortalSettings',
            value: JSON.stringify(storePersister?.portalSettings || ''),
            inline: false,
          },
        ],
      },
    ],
  };

  fetch(channelUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(messageObj),
  });
};
