import _ from 'lodash';
import React, { useCallback } from 'react';

import { DEBOUNCE_TIME } from '../../../../../helper';
import Search from '../../../../Icons/Search';
import Input from '../../../../Input';

interface PolicyFilterSettingsProps {
  setFilter: (filter: string) => void;
}

const PolicyFilterSettings = ({ setFilter }: PolicyFilterSettingsProps) => {
  const setSearchDebounced = useCallback(
    _.debounce((value) => setFilter(value), DEBOUNCE_TIME),
    []
  );

  return (
    <Input
      inputProps={{
        'data-testid': 'search-input',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchDebounced(e.target.value),
        w: '376px',
      }}
      leftElement={<Search boxSize={6} color="gray.600" />}
    />
  );
};

export default PolicyFilterSettings;
