import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Card from '../Card';
import LeftArrowCircleIcon from '../Icons/LeftArrowCircle';

interface CardHeaderProps extends BoxProps {
  description?: ReactNode;
  iconUrl?: string;
  title: string;
  packageName?: string;
  disableGoBack?: boolean;
}

const CardHeader = ({
  description,
  iconUrl,
  title,
  packageName,
  disableGoBack,
  ...rest
}: CardHeaderProps) => {
  const style = useStyleConfig('CardHeader');
  const history = useHistory();

  return (
    <Card p="6" mb="8" {...rest}>
      <Flex alignItems="center" justify="space-between">
        <Flex>
          {iconUrl ? (
            <Image
              src={iconUrl}
              w="40px"
              h="40px"
              mr="20px"
              objectFit="contain"
            />
          ) : null}
          <Text fontSize="2xl" fontWeight="bold">
            {title}
          </Text>
          {packageName && (
            <>
              <Divider orientation="vertical" h="32px" mx="4" />
              <Text fontSize="2xl">{packageName}</Text>
            </>
          )}
        </Flex>
        {!disableGoBack && (
          <Button
            variant="link"
            leftIcon={<LeftArrowCircleIcon boxSize={8} color="primary.500" />}
            onClick={() => history.goBack()}
            color="primary.500"
          >
            <FormattedMessage id="global.back" />
          </Button>
        )}
      </Flex>
      {title && description ? (
        <Box m="16px 0 16px">
          <Divider orientation="horizontal" __css={{ ...style['Divider'] }} />
        </Box>
      ) : null}
      {description ? description : null}
    </Card>
  );
};

export default CardHeader;
