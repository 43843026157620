import {
  KioskBarStatusEnum,
  KioskConfigAccessEnum,
  KioskErrorMessagesEnum,
  KioskNavigateButtonEnum,
  KioskPowerButtonEnum,
  KioskTelephoneServicesButtonEnum,
} from '../../../../../../types/policy';

export const navigateButtonsOptions = [
  {
    value: KioskNavigateButtonEnum.NAVIGATION_ENABLED,
    label: 'edit_policy.kiosk.active',
  },
  {
    value: KioskNavigateButtonEnum.NAVIGATION_DISABLED,
    label: 'edit_policy.kiosk.blocked',
  },
  {
    value: KioskNavigateButtonEnum.HOME_BUTTON_ONLY,
    label: 'edit_policy.kiosk.home_only',
  },
];
export const powerButtonOptions = [
  {
    value: KioskPowerButtonEnum.POWER_BUTTON_BLOCKED,
    label: 'edit_policy.kiosk.blocked',
  },
  {
    value: KioskPowerButtonEnum.POWER_BUTTON_AVAILABLE,
    label: 'edit_policy.kiosk.available',
  },
];
export const errorMessagesOptions = [
  {
    value: KioskErrorMessagesEnum.ERROR_AND_WARNINGS_ENABLED,
    label: 'edit_policy.kiosk.active',
  },
  {
    value: KioskErrorMessagesEnum.ERROR_AND_WARNINGS_MUTED,
    label: 'edit_policy.kiosk.muted',
  },
];
export const barStatusOptions = [
  {
    value: KioskBarStatusEnum.NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED,
    label: 'edit_policy.kiosk.notify_info_system',
  },
  {
    value: KioskBarStatusEnum.SYSTEM_INFO_ONLY,
    label: 'edit_policy.kiosk.sytem_only',
  },
  {
    value: KioskBarStatusEnum.NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED,
    label: 'edit_policy.kiosk.none',
  },
];
export const configAccessOptions = [
  {
    value: KioskConfigAccessEnum.SETTINGS_ACCESS_ALLOWED,
    label: 'edit_policy.kiosk.released',
  },
  {
    value: KioskConfigAccessEnum.SETTINGS_ACCESS_BLOCKED,
    label: 'edit_policy.kiosk.blocked',
  },
];

export const telephoneServicesOptions = [
  {
    value: KioskTelephoneServicesButtonEnum.NAVIGATION_ENABLED,
    label: 'edit_policy.kiosk.telephone_services.active',
  },
  {
    value: KioskTelephoneServicesButtonEnum.NAVIGATION_DISABLED,
    label: 'edit_policy.kiosk.telephone_services.user_defined',
  },
];
