import { DeviceType } from './device';
import { ID } from './util';

export enum EnterpriseOriginEnum {
  PLAYSTORE = 'PLAYSTORE',
  PARTICULAR = 'PARTICULAR',
  WEB = 'WEB',
  SYSTEM = 'SYSTEM',
  MANUAL = 'MANUAL',
  EXTERNAL = 'EXTERNAL',
}

export interface EnterpriseAppsType {
  id?: ID;
  name?: string;
  origin?: EnterpriseOriginEnum;
  packageName?: string;
  iconBase64?: string;
  iconData?: string;
}

export interface SelectedAppData {
  packageName?: string;
}

export interface EnterpriseDeviceType extends DeviceType {
  manufacturer: string;
}
