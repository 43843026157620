import { matchPath } from 'react-router';

import { routeWithParameters } from './index';

export const handleOpenExternalWindow = (
  route: string,
  parameter?: Record<string, string | number>
) => {
  return window.open(
    parameter ? routeWithParameters(route, parameter) : route,
    '_blank'
  );
};

export const customMatch = (actualPath: string, comparePath: string) => {
  if (actualPath === comparePath) return true;

  const separator = '/';

  const actualArr = actualPath.split(separator);
  const compareArr = comparePath.split(separator);

  if (actualArr.length !== compareArr.length) return false;

  return !!matchPath(actualPath, { path: comparePath });
};
