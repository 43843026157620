import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Action Types

export const Types = {
  PRIVACY_POLICY: 'term/PRIVACY_POLICY',
  TERMS_OF_USAGE: 'term/TERMS_OF_USAGE',
};

// Reducer

interface TermState {
  privacyPolicy: string;
  termsOfUsage: string;
}

const initialState: TermState = {
  privacyPolicy: null,
  termsOfUsage: null,
};

export const termSlice = createSlice({
  name: 'term',
  initialState,
  reducers: {
    termPrivacyPolicySuccess: (
      state,
      action: PayloadAction<{ privacyPolicy: string }>
    ) => {
      state.privacyPolicy = action.payload.privacyPolicy;
    },
    termTermsOfUsageSuccess: (
      state,
      action: PayloadAction<{ termsOfUsage: string }>
    ) => {
      state.termsOfUsage = action.payload.termsOfUsage;
    },
  },
});

export default termSlice.reducer;

// Action Creators

export const { termPrivacyPolicySuccess, termTermsOfUsageSuccess } =
  termSlice.actions;

export const listPrivacyPolicy = createAction(Types.PRIVACY_POLICY);

export const listTermsOfUsage = createAction(Types.TERMS_OF_USAGE);
