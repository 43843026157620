import { Box, Flex } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import { debounce } from 'lodash';
import plistReader from 'plist';
import { ChangeEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';

import { transformFileToText } from '../../../../../../../helper/file';
import ButtonFileUpload from '../../../../../../ButtonFileUpload';
import Input from '../../../../../../Input';
import {
  ProfileFileErrorEnum,
  profileFileErroToIntl,
} from './profileFileErrors';

interface Props {
  onChange: (file: File) => void;
  onChangeName: (newName: string) => void;
  profileName: string;
}

export default function ProfileFileInput({
  onChange,
  profileName,
  onChangeName,
}: Props) {
  const intl = useIntl();

  const [error, setError] = useState<ProfileFileErrorEnum>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (!(file.name.split('.')[1] === 'mobileconfig')) {
      setError(ProfileFileErrorEnum.INVALID_FORMAT);
      return;
    }

    try {
      const fileText = (await transformFileToText(file)) as string;

      const { PayloadDisplayName } = plistReader.parse(fileText) as {
        PayloadDisplayName?: string;
      };

      if (!PayloadDisplayName)
        return setError(ProfileFileErrorEnum.INVALID_MOBILECONFIG);

      // Success case
      onChange(file);
      onChangeName(PayloadDisplayName);
      setError(null);
      setSuccess(true);
      debounce(() => setSuccess(false), 2000)();
    } catch (error) {
      setError(ProfileFileErrorEnum.INVALID_MOBILECONFIG);
    }
  };

  return (
    <Flex direction="column" gridGap={2} w="full">
      <Tooltip
        hasArrow
        arrowSize={8}
        bg="success.500"
        padding="2"
        borderRadius="5"
        placement="bottom-start"
        isOpen={success}
        label={intl.formatMessage({
          id: 'register_policies.ios_supervised.profile_input.success',
        })}
      >
        <Flex gridGap={6} w="full">
          <Input
            inputProps={{
              isDisabled: true,
              name: 'profileName',
              value: profileName,
              placeholder: intl.formatMessage({
                id: 'register_policies.ios_supervised.profile_input.placeholder',
              }),
            }}
          />

          <ButtonFileUpload
            uploadTitle={intl.formatMessage({
              id: 'register_policies.ios_supervised.upload_button',
            })}
            inputProps={{
              name: 'ios_supervised_profile',
              onChange: handleChangeFile,
              accept: '.mobileconfig',
              value: '',
            }}
          />
        </Flex>
      </Tooltip>

      <Box color="warning.500" fontSize="sm" fontWeight="bold" h="21px" m="0">
        {error &&
          intl.formatMessage({
            id: profileFileErroToIntl[error] || profileFileErroToIntl.default,
          })}
      </Box>
    </Flex>
  );
}
