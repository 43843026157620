export enum ErrorEnum {
  FORBIDDEN = 'Forbidden',
}
export interface ErrorResponse {
  error?: ErrorEnum;
  message?: string;
  statusCode?: number;
}

export interface ErrorsMessagesTypes {
  [field: string]: string[];
}

export class ErrorToaster extends Error {
  keyIntl?: string;
  messages?: ErrorsMessagesTypes;

  constructor(keyIntl: string) {
    super();
    this.keyIntl = keyIntl;
  }
}

export class ErrorGeneric extends Error {
  message: ErrorResponse['message'];

  constructor(message: string) {
    super();
    this.message = message;
  }
}
