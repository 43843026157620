import { Flex } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import PageErrors from '../../../components/PageErrors';
import PolicyTabs from '../../../components/pages/Policies/EditPolicies/PolicyTabs';
import SkeletonTabs from '../../../components/pages/Policies/EditPolicies/PolicyTabs/SkeletonTabs';
import { policyErrorToIntl } from '../../../components/pages/Policies/EditPolicies/utils';
import FormPolicy from '../../../components/pages/Policies/RegisterPolicy/FormPolicy';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import WarningIfExitRoute from '../../../components/WarningIfExitRoute';
import { isValidGmail } from '../../../helper/email';
import { usePlan } from '../../../hooks/usePlan';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  getPolicy,
  policySelectedClear,
  Types,
  policySelected,
  updatePolicy,
  policySetApplication,
  getPolicyIos,
  isPolicyAndroid,
  fillUpdateAndroidPolicy,
  isPolicyIOSSupervised,
  fillIOSSupervisedPolicy,
  updateIOSSupervisedPolicy,
} from '../../../store/policy';
import { hasError, hasSomeLoading } from '../../../store/ui';
import {
  OperationalSystemEnum,
  PolicyIOSSupervisedFormDataType,
  PolicyType,
} from '../../../types/policy';
import { managementModeToIntl } from '../RegisterPolicy';

export const operationalSystemToIntl = {
  [OperationalSystemEnum.IOS]: 'register_policies.ios',
  [OperationalSystemEnum.ANDROID]: 'register_policies.android',
};

const EditPolicies = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { isHelpDesk } = usePrivilege();

  const { policy, copyPolicy, disablePolicySave } = useAppSelector(
    (state) => state.policy
  );
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const operationalSystem = policy?.operationalSystem;

  const policyIsEqual = _.isEqual(policy, copyPolicy);
  const isSubmitLoading = hasSomeLoading([
    Types.UPDATE,
    Types.UPDATE_IOS_SUPERVISED,
  ]);
  const isLoading = hasSomeLoading([Types.GET, Types.GET_IOS]);
  const isIOSSupervisedError = hasError(Types.UPDATE_IOS_SUPERVISED);
  const { plan } = usePlan();
  const disableSave = Object.values(disablePolicySave).some((value) => value);
  const disablePrimary =
    disableSave ||
    isLoading ||
    policyIsEqual ||
    isHelpDesk ||
    (policy?.frpAdminEmails?.length > 0 &&
      !isValidGmail(policy?.frpAdminEmails));

  const entityToUpdate = {
    [OperationalSystemEnum.IOS]: 'iosPolicy',
    [OperationalSystemEnum.ANDROID]: 'androidPolicy',
  };

  useEffect(() => {
    if (id) {
      dispatch(getPolicy(parseInt(id)));
    }
    if (!id) {
      dispatch(getPolicyIos());
    }
    return () => {
      dispatch(policySelectedClear());
      dispatch(policySetApplication(null));
    };
  }, [id]);

  const handlePrimary = () => {
    let newPolicy: PolicyType | PolicyIOSSupervisedFormDataType = policy;

    if (Boolean(isPolicyIOSSupervised(policy))) {
      newPolicy = fillIOSSupervisedPolicy(policy);

      dispatch(
        updateIOSSupervisedPolicy({ id: policy.id, formData: newPolicy })
      );

      return;
    }

    if (isPolicyAndroid(policy)) {
      newPolicy = fillUpdateAndroidPolicy(policy, portalSettings, plan);
    }

    dispatch(
      updatePolicy({
        operationalSystem,
        id: policy.id,
        name: policy?.name,
        [entityToUpdate[operationalSystem]]: newPolicy,
      })
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(policySelected({ [e.target.name]: e.target.value }));
  };

  const handleSecondary = () => {
    history.push(routes.policies.manage);
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="update_policies.title" />}
        description={<FormattedMessage id="update_policies.description" />}
      />
      <PageErrors
        w="full"
        toasterKeys={[Types.UPDATE, Types.UPDATE_IOS_SUPERVISED, Types.GET]}
        translateKey={'update_policies'}
        messageError={
          isIOSSupervisedError?.message &&
          intl.formatMessage({
            id: policyErrorToIntl[isIOSSupervisedError?.message],
          })
        }
      />
      <PageShowToaster
        toasterKeys={[Types.UPDATE, Types.UPDATE_IOS_SUPERVISED]}
        disabledError
        messageSuccess={
          <FormattedMessage id="update_policies.message_success" />
        }
      />
      <FormPolicy
        labelPrimary={<FormattedMessage id={'update_policies.button.save'} />}
        handlePrimary={handlePrimary}
        disabledPrimary={disablePrimary}
        labelSecondary={<FormattedMessage id="global.cancel" />}
        handleSecondary={handleSecondary}
        loadingPrimary={isSubmitLoading}
      >
        <Flex>
          <FormControl
            textLabel={<FormattedMessage id="register_policies.name" />}
          >
            <Input
              inputProps={{
                name: 'name',
                value: policy?.name || '',
                width: '350px',
                onChange: handleInputChange,
                placeholder: intl.formatMessage({
                  id: 'register_policies.name',
                }),
              }}
              isLoading={isLoading}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="update_policies.policyType" />}
          >
            <Input
              inputProps={{
                value: managementModeToIntl[policy?.managementMode]
                  ? `${intl.formatMessage({
                      id: `${managementModeToIntl[policy?.managementMode]}`,
                    })} `
                  : '',
                width: '350px',
                placeholder: intl.formatMessage({
                  id: 'update_policies.policyType',
                }),
                disabled: true,
              }}
              isLoading={isLoading}
            />
          </FormControl>
        </Flex>
      </FormPolicy>

      {isLoading ? (
        <SkeletonTabs />
      ) : (
        <PolicyTabs tabToRender={operationalSystem} />
      )}

      <WarningIfExitRoute
        preventExit={!policyIsEqual}
        message={intl.formatMessage({ id: 'update_policies.warning_exit' })}
      />
    </>
  );
};

export default EditPolicies;
