import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RemoteDeviceType } from '../../types/remoteView';

// Action Types

export const Types = {
  SET_DEVICE: 'remoteView/SET_DEVICE',
};

// Reducer

interface remoteViewState {
  remoteDevice: RemoteDeviceType;
}

const initialState: remoteViewState = {
  remoteDevice: null,
};

export const remoteViewSlice = createSlice({
  name: 'remoteView',
  initialState,
  reducers: {
    setRemoteViewDevice: (state, action: PayloadAction<RemoteDeviceType>) => {
      state.remoteDevice = action.payload;
    },
  },
});

export default remoteViewSlice.reducer;

// Action Creators

export const { setRemoteViewDevice } = remoteViewSlice.actions;
