import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  Button as ChakraButton,
  MenuItem,
  MenuButtonProps,
} from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { FormattedMessage } from 'react-intl';

type VariantType = 'solid' | 'outline' | 'ghost';

interface ItemType {
  title?: string;
  icon?: ReactJSXElement;
  onClick?: () => void;
  customMenuItem?: ReactJSXElement;
}

export interface MenuActionButtonProps extends MenuButtonProps {
  title?: string;
  variant?: VariantType;
  items?: ItemType[];
}

const MenuActionButton = ({
  title,
  variant = 'outline',
  items,
  ...rest
}: MenuActionButtonProps) => {
  const hasItems = items?.length > 0;

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={ChakraButton}
        variant={variant}
        rightIcon={<ChevronDownIcon />}
        colorScheme="primary"
        h="45px"
        {...rest}
      >
        <FormattedMessage id={title} />
      </MenuButton>
      {hasItems ? (
        <MenuList>
          {items.map(
            (menuItem) =>
              menuItem?.customMenuItem || (
                <MenuItem
                  color="gray.900"
                  onClick={menuItem?.onClick}
                  icon={menuItem?.icon}
                  key={menuItem?.title}
                >
                  {menuItem?.title && <FormattedMessage id={menuItem?.title} />}
                </MenuItem>
              )
          )}
        </MenuList>
      ) : null}
    </Menu>
  );
};

export default MenuActionButton;
