import { BoxProps, Box, Divider, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

import Button from '../../../Button';
import Card from '../../../Card';

interface FormPolicyProps extends BoxProps {
  children: React.ReactNode;
  labelPrimary?: string | React.ReactNode;
  handlePrimary?: () => void;
  disabledPrimary?: boolean;
  labelSecondary?: string | React.ReactNode;
  handleSecondary?: () => void;
  labelFilter?: string | React.ReactNode;
  loadingPrimary?: boolean;
  primaryWidth?: string;
}

const FormPolicy: React.FC<FormPolicyProps> = ({
  labelPrimary,
  handlePrimary,
  disabledPrimary,
  labelSecondary,
  handleSecondary,
  loadingPrimary,
  children,
  primaryWidth,
  ...rest
}: FormPolicyProps) => {
  const style = useStyleConfig('FormContainer');

  return (
    <Card {...rest} paddingY={'8'}>
      <Box
        d="flex"
        flexDirection="row"
        alignItems={'end'}
        justifyContent={'space-between'}
      >
        {children}
        <Box d="flex" flexDirection="row" alignItems="center">
          {handleSecondary && (
            <Button variant="ghost" onClick={handleSecondary} h="40px">
              {labelSecondary}
            </Button>
          )}
          {handlePrimary && handleSecondary && (
            <Divider
              orientation="vertical"
              h="22px"
              ml="4%"
              __css={{ ...style['divider'] }}
            />
          )}
          {handlePrimary && (
            <Button
              h="40px"
              w={primaryWidth || '176px'}
              disabled={disabledPrimary}
              onClick={handlePrimary}
              isLoading={loadingPrimary}
            >
              {labelPrimary}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default FormPolicy;
