export function isValidEmail(email: string) {
  if (email?.length > 0) {
    return regexEmail.test(email);
  }

  return false;
}

export function isValidGmail(email: string) {
  if (email?.length > 0) {
    return regexGmail.test(email);
  }

  return false;
}

export const regexEmail = /\S+@\S+\.\S+/;

export const regexGmail =
  /^[a-z0-9]+(?!.*(?:\+{2,}|\-{2,}|\.{2,}))(?:[\.+\-]{0,1}[a-z0-9])*@gmail\.com$/;
