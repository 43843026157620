import * as XLSX from 'xlsx';

import { AppDispatch } from '../store';
import { generateExcel } from '../store/event';
import { ListMetadata } from '../types/generic_list';

type ExportExcelType = {
  dataInfo?: unknown[];
  name: string;
  columns?: string[];
};

export const exportInExcel = ({ name, columns, dataInfo }: ExportExcelType) => {
  const opts = columns?.length > 0 ? { header: columns } : undefined;

  const createBook = XLSX.utils.book_new();
  const convertToSheet = XLSX.utils.json_to_sheet(dataInfo || [], opts);

  XLSX.utils.book_append_sheet(createBook, convertToSheet);

  XLSX.writeFile(createBook, `${name || ''}.xlsx`);
};

export const exportInClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const exportInClipboardFromIdWithExecCommand = (idElement: string) => {
  const elTable = document.getElementById(idElement);

  let range: Range, sel: Selection;

  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    sel.removeAllRanges();

    try {
      range.selectNodeContents(elTable);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(elTable);
      sel.addRange(range);
    }

    document.execCommand('copy');
  }

  sel.removeAllRanges();
};

export const cleanFiltersToExportToExcel = (
  filters: Record<string, unknown>
) => {
  return Object.keys(filters).reduce((prevFilter, key) => {
    if (filters[key] === '') {
      return prevFilter;
    }

    if (filters[key] === null) {
      return prevFilter;
    }

    return {
      ...prevFilter,
      [key]: filters[key],
    };
  }, {});
};

export const handleExportAllToExcel = (
  dispatch: AppDispatch,
  type: string,
  metadata: ListMetadata,
  filters: Record<string, unknown>
) => {
  dispatch(
    generateExcel({
      type,
      metadata,
      filters: cleanFiltersToExportToExcel(filters),
    })
  );
};
