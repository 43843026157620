import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Card from '../../../../../../../Card';
import Heading from '../../../../../../../Heading';
import Text from '../../../../../../../Text';

interface AccordionCardProps {
  title?: string;
  description?: string;
  children?: ReactNode;
  inputContainerWidth?: string;
  isRadio?: boolean;
  hasNestedProperties?: boolean;
  planAlert?: string;
}

const AppActionAccordionCard = ({
  description,
  title,
  children,
  inputContainerWidth,
  isRadio,
  hasNestedProperties,
  planAlert,
}: AccordionCardProps) => {
  return (
    <Accordion allowMultiple>
      <Card w="100%" p="0">
        <AccordionItem border="none" boxShadow="base" borderRadius="10px">
          <Heading as="h2">
            <AccordionButton h="60px" borderRadius="10px">
              <AccordionIcon boxSize={8} color="gray.600" />

              <Text ml="1%" flex="1" textAlign="left" m={0}>
                {title}
              </Text>
            </AccordionButton>
          </Heading>
          <AccordionPanel fontSize="sm">
            <Flex
              flexDirection={isRadio ? 'row' : 'column'}
              justifyContent={isRadio ? 'space-between' : 'flex-start'}
              p="6"
            >
              {planAlert && (
                <Box color="gray.500" mb={4} fontStyle="italic">
                  {planAlert}
                </Box>
              )}

              <Box mb={!isRadio && '6'} color="gray.500">
                {description}
              </Box>

              <Box
                w={
                  !hasNestedProperties &&
                  (isRadio ? 'auto' : inputContainerWidth || '50%')
                }
                ml={isRadio && '6'}
              >
                {children}
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Card>
    </Accordion>
  );
};

export default AppActionAccordionCard;
