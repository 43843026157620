import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { AdminUserType } from '../../types/adminUser';
import { CompanyType } from '../../types/company';
import { LoginAdminUserType, LoginFormType } from '../../types/loginAdminUser';
import { HistoryState } from '../../types/route';
import { ID } from '../../types/util';

// Action Types

export const Types = {
  LOGIN: 'auth/LOGIN',
  LOGOUT: 'auth/LOGOUT',
  RECOVER_PASSWORD: 'auth/RECOVER_PASSWORD',
  CHANGE_SESSION: 'auth/CHANGE_SESSION',
  LOGIN_CONFIRMATION: 'auth/LOGIN_CONFIRMATION',
  RESEND_TOKEN: 'auth/RESEND_TOKEN',
  REFRESH_SESSION: 'auth/REFRESH_SESSION',
};

// Reducer

export interface AuthState {
  isLogged: boolean;
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  accessTokenCreateAt: number;
  user: LoginAdminUserType;
  helpDeskAccess?: { accessToken?: string; user?: LoginAdminUserType };
  isHelpDeskSession?: boolean;
  keepLogged: boolean;
  recovery: Record<string, unknown>;
  loginLanguage?: string;
  formConfirmation?: Partial<LoginFormType>;
}

export const initialState: AuthState = {
  isLogged: false,
  accessToken: null,
  refreshToken: null,
  expiresIn: null,
  accessTokenCreateAt: null,
  helpDeskAccess: undefined,
  isHelpDeskSession: false,
  user: undefined,
  keepLogged: false,
  recovery: {},
  formConfirmation: null,
};

function handleSetupSession(
  state: AuthState,
  action: PayloadAction<Partial<AuthState>>
) {
  state.isLogged = true;
  state.accessToken = action.payload.accessToken;
  state.refreshToken = action.payload.refreshToken;
  state.expiresIn = action.payload.expiresIn;
  state.accessTokenCreateAt = new Date().getTime();
  state.user = action.payload.user;
  state.keepLogged = action.payload.keepLogged;
  state.isHelpDeskSession = initialState.isHelpDeskSession;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authLoginSuccess: (state, action: PayloadAction<Partial<AuthState>>) => {
      handleSetupSession(state, action);
    },
    authPersisterSuccess: (state) => {
      state.isLogged = true;
    },
    authHelpDeskLoginSuccess: (
      state,
      action: PayloadAction<Partial<AuthState>>
    ) => {
      state.helpDeskAccess = {
        user: action.payload.user,
        accessToken: action.payload.accessToken,
      };
    },
    authHelpDeskSelectCompanySuccess: (
      state,
      action: PayloadAction<Partial<AuthState>>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.user.company = {
        ...action.payload.user.company,
        name: action.payload.user.company.corporateName,
      };
      state.isHelpDeskSession = true;
    },

    authLoginError: (state) => {
      state.isLogged = false;
      state.accessToken = initialState.accessToken;
    },
    authLogout: (state) => {
      state.isLogged = initialState.isLogged;
      state.accessToken = initialState.accessToken;
      state.user = initialState.user;
      state.helpDeskAccess = initialState.helpDeskAccess;
    },
    authLogoutError: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
    authSetEnterpriseId: (state, action: PayloadAction<CompanyType>) => {
      state.user.company = {
        ...action.payload,
        name: action.payload?.corporateName || action.payload?.name,
      };
    },
    authUpdateUserLanguage: (state, action: PayloadAction<AdminUserType>) => {
      state.user = { ...state.user, ...action.payload };
    },
    changeLoginLanguage: (state, action: PayloadAction<string>) => {
      state.loginLanguage = action.payload;
    },
    authHelpDeskClear: (state) => {
      state.user = state.helpDeskAccess.user;
      state.accessToken = state.helpDeskAccess.accessToken;
      state.isHelpDeskSession = initialState.isHelpDeskSession;
    },
    authRefreshToken: (state, action: PayloadAction<Partial<AuthState>>) => {
      state.expiresIn = action.payload.expiresIn;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      //TODO: improvement receive this prop accessTokenCreateAt from backend
      state.accessTokenCreateAt = new Date().getTime();
    },
    authChangeExpiresIn: (
      state,
      action: PayloadAction<Partial<AuthState['expiresIn']>>
    ) => {
      state.expiresIn = action.payload;
    },
    authSetEmailConfirmation: (
      state,
      action: PayloadAction<Partial<LoginFormType>>
    ) => {
      const { email, keepLogged } = action.payload;
      state.formConfirmation = {
        email,
        keepLogged,
      };
    },
  },
});

export default authSlice.reducer;

// Action Creators

export const {
  authLoginSuccess,
  authPersisterSuccess,
  authHelpDeskLoginSuccess,
  authHelpDeskClear,
  authHelpDeskSelectCompanySuccess,
  authLoginError,
  authLogout,
  authLogoutError,
  authSetEnterpriseId,
  authUpdateUserLanguage,
  changeLoginLanguage,
  authSetEmailConfirmation,
  authRefreshToken,
  authChangeExpiresIn,
} = authSlice.actions;

export const login = createAction<LoginFormType>(Types.LOGIN);

export const logout = createAction<HistoryState>(Types.LOGOUT);

export const recover = createAction<{ email: string; url: string }>(
  Types.RECOVER_PASSWORD
);

export const changeSession = createAction<{ companyId: ID }>(
  Types.CHANGE_SESSION
);

export const loginConfirmation = (data: LoginFormType) => {
  return {
    payload: data,
    type: Types.LOGIN_CONFIRMATION,
  };
};

export const resendToken = createAction<{ email: string }>(Types.RESEND_TOKEN);

export const refreshSession = createAction(Types.REFRESH_SESSION);

// Selectors
export const getToken = (state: RootState) => state.auth.accessToken;
export const getRefreshToken = (state: RootState) => state.auth.refreshToken;
