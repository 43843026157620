import { Header } from '../components/Table/TableInterfaces';
import { SortingQueryParameters } from '../types/generic_list';

type TableDirectionType = 'DESC' | 'ASC';

export const useSorting = (
  column: Header[],
  sortingParameters: SortingQueryParameters
) => {
  return column.map((cell) => ({
    ...cell,
    isSorted: sortingParameters.sortingProperty === cell.accessor,
    isSortedDesc: sortingParameters.sortingDirection === 'DESC',
  }));
};

export const handleSortTable = (
  direction: TableDirectionType,
  propertyA,
  propertyB
) => {
  if (direction === 'ASC') {
    if (propertyA < propertyB) {
      return -1;
    }
    if (propertyA > propertyB) {
      return 1;
    }
  } else {
    if (propertyA < propertyB) {
      return 1;
    }
    if (propertyA > propertyB) {
      return -1;
    }
  }
  return 0;
};
