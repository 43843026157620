import { AnyAction } from 'redux';

import {
  authHelpDeskClear,
  authHelpDeskSelectCompanySuccess,
  authLogout,
  authPersisterSuccess,
  authRefreshToken,
  authSetEnterpriseId,
  AuthState,
  authUpdateUserLanguage,
} from './auth';
import {
  CompanyState,
  setRegisterToken,
  Types,
  urlCallbackSuccess,
  initialState as initialCompany,
} from './company';
import { PortalSettingsState } from './portalSettings';

export const storageKey = 'store-datamob';
const registerKey = 'register-datamob';
const helpDeskKey = 'help-desk-datamob';

const isActionToBePersisted = (action: AnyAction) =>
  action.type === authPersisterSuccess.type ||
  action.type === authSetEnterpriseId.type ||
  action.type === authUpdateUserLanguage.type ||
  action.type === authRefreshToken.type;

const isActionInRegister = (action: AnyAction) =>
  action.type === urlCallbackSuccess.type ||
  action.type === setRegisterToken.type;

const isActionInHelpDesk = (action: AnyAction) =>
  action.type === authHelpDeskSelectCompanySuccess.type ||
  action.type === authHelpDeskClear.type;

type StatePersisted = {
  auth: AuthState;
  portalSettings: PortalSettingsState;
  company: CompanyState;
};

export const persister =
  ({ getState }: { getState: () => StatePersisted }) =>
  (next) =>
  (action: AnyAction) => {
    const result = next(action);

    if (isActionToBePersisted(action)) {
      const { auth, portalSettings } = getState();
      const stateToStore = JSON.stringify({
        auth,
        portalSettings,
      });
      const keepLogin = auth.keepLogged;

      if (keepLogin) {
        localStorage.setItem(storageKey, stateToStore);
      } else {
        sessionStorage.setItem(storageKey, stateToStore);
        localStorage.removeItem(storageKey);
      }
    }

    if (isActionInRegister(action)) {
      const {
        company: { enterpriseRegister },
      } = getState();

      if (action.type === urlCallbackSuccess.type) {
        localStorage.setItem(registerKey, JSON.stringify(enterpriseRegister));
      }
    }

    if (isActionInHelpDesk(action)) {
      const { auth, portalSettings } = getState();
      const stateToStore = JSON.stringify({
        auth,
        portalSettings,
      });

      sessionStorage.setItem(helpDeskKey, stateToStore);
    }

    if (action.type === Types.COMPANY_REGISTER_ENTERPRISE) {
      localStorage.removeItem(registerKey);
    }

    if (action.type === authLogout.type) {
      localStorage.removeItem(storageKey);
      [storageKey, helpDeskKey].forEach((keyToRemove) => {
        sessionStorage.removeItem(keyToRemove);
      });
    }

    return result;
  };

export const rehydrate = () => {
  const state =
    getStorageInJson(sessionStorage, storageKey) ||
    getStorageInJson(localStorage, storageKey) ||
    {};

  const stateRegister = getStorageInJson(localStorage, registerKey) || {};
  const stateHelpDesk = getStorageInJson(sessionStorage, helpDeskKey) || {};

  return {
    ...state,
    ...stateHelpDesk,
    company: {
      ...state.company,
      ...initialCompany,
      enterpriseRegister: stateRegister || state.company?.enterpriseRegister,
    },
  };
};

export function getStorageInJson(
  storage: typeof localStorage | typeof sessionStorage,
  key: string
) {
  const registerPersisted = storage.getItem(key);
  return registerPersisted && JSON.parse(registerPersisted);
}
