import { ReactNode } from 'react';

import Text from '../../../../../../Text';

export const Header = ({ children }: { children: ReactNode }) => {
  return (
    <Text m={0} fontWeight="medium" fontSize="md" color="gray.900">
      {children}
    </Text>
  );
};
