import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Flex, FlexProps } from '@chakra-ui/react';
import React, {
  ComponentProps,
  FormEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertDelete } from '../Alert';
import Button from '../Button';
import Delete from '../Icons/Delete';
import IconButton from '../Icons/IconButton';
import Input from '../Input';
import SelectAutocomplete from '../SelectAutocomplete';
import Text from '../Text';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeywordString = string[] | Record<string, any>[];
type Keyword<IsAutocomplete> = IsAutocomplete extends false
  ? KeywordString[0]
  : Record<string, string>;

interface InputKeywordsProps<IsAutocomplete> {
  keywords: KeywordString;
  isDisabled?: boolean;
  handleSubmit: (
    propertyToFill: string,
    newKeyword: Keyword<IsAutocomplete>
  ) => void;
  onConfirmDelete: (
    propertyToFill: string,
    deletedKeyword: KeywordString[0]
  ) => void;
  emptyMessage?: ReactNode;
  maxItems?: number | false;
  name: string;
  isAutocomplete?: IsAutocomplete;
  autocompleteProps?: Partial<ComponentProps<typeof SelectAutocomplete>> &
    Pick<ComponentProps<typeof SelectAutocomplete>, 'options'>;
  listContainerStyle?: FlexProps;
  HeaderComponent?: JSX.Element;
  validateKeyword?: (keyword: Keyword<IsAutocomplete>) => boolean;
  validateErrorMessage?: string;
  getKeywordLabel?: (keyword: KeywordString[0]) => string;
  keywordMapKey?: string;
}

interface KeywordErrorType {
  isDuplicated?: boolean;
  isNotValid?: boolean;
}

const InputKeywords = <IsAutocomplete extends true | false>({
  keywords,
  isDisabled,
  handleSubmit,
  onConfirmDelete,
  emptyMessage,
  maxItems = 3,
  name,
  isAutocomplete = false as IsAutocomplete,
  autocompleteProps,
  listContainerStyle,
  HeaderComponent: InputComponent,
  validateKeyword,
  validateErrorMessage,
  getKeywordLabel,
  keywordMapKey,
}: InputKeywordsProps<IsAutocomplete>): JSX.Element => {
  const [value, setValue] = useState<Keyword<IsAutocomplete>>();
  const [errorKeyword, setErrorKeyword] = useState<KeywordErrorType>({
    isDuplicated: false,
    isNotValid: false,
  });
  const [showAll, setShowAll] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();

  const handleInputSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!isAutocomplete) inputRef.current.focus();

    handleSubmit(name, value);
    setValue('' as Keyword<IsAutocomplete>);
  };

  const handleValidateKeywords = () => {
    if (isAutocomplete) return;
    if (!value) return;

    const hasKeyword = keywords?.some(
      (keyword) => keyword?.toLowerCase() === (value as string).toLowerCase()
    );

    setErrorKeyword({
      isDuplicated: hasKeyword,
      isNotValid: validateKeyword && value ? !validateKeyword(value) : false,
    });
  };

  useEffect(() => {
    handleValidateKeywords();
  }, [value, keywords]);

  const ErrorMessageComponent = ({
    hasError,
    errorMessage,
  }: {
    hasError: boolean;
    errorMessage: string;
  }): JSX.Element =>
    hasError && (
      <Text mb={0} color="warning.500">
        <FormattedMessage id={errorMessage} />
      </Text>
    );

  return (
    <>
      <Flex flexDirection="column" w="full">
        <form onSubmit={handleInputSubmit}>
          <Flex marginBottom="1rem">
            {InputComponent || (
              <>
                <Flex w="full" flexDirection="column">
                  {isAutocomplete && (
                    <SelectAutocomplete
                      onChange={(value) =>
                        setValue(value as Keyword<IsAutocomplete>)
                      }
                      value={value}
                      {...autocompleteProps}
                    />
                  )}
                  {!isAutocomplete && (
                    <Input
                      isDisabled={isDisabled}
                      inputProps={{
                        value: value || '',
                        onChange: (e) => setValue(e.target.value),
                        ref: inputRef,
                      }}
                    />
                  )}

                  <ErrorMessageComponent
                    hasError={errorKeyword.isDuplicated}
                    errorMessage="input_keywords.error.key_existing_alert"
                  />

                  <ErrorMessageComponent
                    hasError={errorKeyword.isNotValid}
                    errorMessage={
                      validateErrorMessage ||
                      'input_keywords.error.key_not_valid_alert'
                    }
                  />
                </Flex>

                <Button
                  variant="solid"
                  fontWeight="normal"
                  disabled={
                    isDisabled ||
                    errorKeyword.isNotValid ||
                    errorKeyword.isDuplicated ||
                    !value
                  }
                  type="submit"
                  ml="3%"
                >
                  <FormattedMessage id="global.add" />
                </Button>
              </>
            )}
          </Flex>
        </form>

        <Flex direction="column" gridGap="1rem" {...listContainerStyle}>
          {!keywords.length && (
            <Flex width="100%" justifyContent="center">
              <Text m={0} fontStyle="italic">
                {emptyMessage || (
                  <FormattedMessage id="global.generic_not_found" />
                )}
              </Text>
            </Flex>
          )}

          {keywords
            ?.slice(
              0,
              maxItems
                ? showAll
                  ? keywords.length
                  : maxItems
                : keywords.length
            )
            ?.map((keyword: KeywordString[0], index) => (
              <Flex
                width="100%"
                alignItems="center"
                py={2}
                px={1.5}
                borderRadius="md"
                boxShadow="base"
                key={
                  keywordMapKey
                    ? keyword[keywordMapKey] || index
                    : keyword || index
                }
              >
                <Flex width="100%" color="gray.500">
                  {getKeywordLabel?.(keyword) || keyword}
                </Flex>

                <IconButton
                  aria-label="delete"
                  ml=".5rem"
                  isDisabled={isDisabled}
                  onClick={() =>
                    AlertDelete(intl, {
                      onConfirm: () => onConfirmDelete(name, keyword),
                    })
                  }
                  icon={<Delete boxSize={6} color="warning.500" />}
                />
              </Flex>
            ))}

          {keywords.length > maxItems && maxItems && (
            <>
              <Flex
                width="full"
                justifyContent="center"
                alignItems="center"
                gridGap=".5rem"
                cursor="pointer"
                onClick={() => setShowAll(!showAll)}
                color="primary.500"
                fontWeight="bold"
              >
                {showAll ? (
                  <>
                    <MinusIcon />

                    <FormattedMessage id="global.show_less" />
                  </>
                ) : (
                  <>
                    <AddIcon />

                    <FormattedMessage id="global.show_all" />
                  </>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default InputKeywords;
