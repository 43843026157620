import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { RootState } from '..';

import {
  convertByUserLogged,
  convertSitesToReportSiteUrl,
} from '../../components/pages/Reports/utils';
import { ListPayload, QuerysWithFilters } from '../../types/generic_list';
import { ReportSiteDateType, SiteType } from '../../types/reports';
import {
  Types,
  reportsSitesDateListSuccess,
  reportsSitesDateHistoryListSuccess,
  reportsSitesUrlListSuccess,
  reportsSitesDateListClean,
} from '../reports';
import { uiAddEmpty, uiAddMetadata, uiRemoveEmpty } from '../ui';
import { api, safe, requestList } from './util';

function* sitesDateListFirst({ payload }: PayloadAction<QuerysWithFilters>) {
  yield put(reportsSitesDateListClean());
  yield call(sitesDateList, { payload, type: Types.SITES_DATE });
}

function* sitesDateList({
  payload: { queryParameters, filters },
  type,
}: PayloadAction<QuerysWithFilters>) {
  const { items, ...pagination }: ListPayload<SiteType> = yield call(
    api,
    'sitesDateList',
    { ...queryParameters, ...filters }
  );

  if (items.length > 0) {
    const sitesDatesList: ReportSiteDateType[] = yield select(
      (state: RootState) => state.reports.sitesDate
    );

    const user = yield select((state: RootState) => state.auth.user);
    const portalSettings = yield select(
      (state: RootState) => state.portalSettings
    );
    /*
        SiteType[] to ReportSiteDateType[]
      */
    const allReports: ReportSiteDateType[] = items.reduce(
      convertSitesToReportSiteUrl(convertByUserLogged(user, portalSettings)),
      sitesDatesList
    );

    yield put(reportsSitesDateListSuccess(allReports));
    yield put(reportsSitesDateHistoryListSuccess(items));
    yield put(uiAddMetadata({ [type]: pagination }));
    yield put(uiRemoveEmpty(type));
  } else {
    yield put(uiAddEmpty(type));
  }
}

function* sitesList({
  type,
  payload: { queryParameters, filters },
}: PayloadAction<QuerysWithFilters>) {
  yield call(requestList, api, 'sitesList', type, reportsSitesUrlListSuccess, {
    queryParameters,
    filters,
  });
}

export default function* reportsSaga() {
  yield takeLatest(Types.SITES_DATE, safe(sitesDateList));
  yield takeLatest(Types.SITES_DATE_FIRST, safe(sitesDateListFirst));
  yield takeLatest(Types.SITES_LIST, safe(sitesList));
}
