import { Box } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import {
  hasError,
  hasSuccess,
  uiRemoveError,
  uiRemoveSuccess,
  uiScrollToTop,
} from '../../store/ui';
import Toaster from '../Toaster';

interface PageToasterProps {
  toasterKeys?: string[];
  messageSuccess?: React.ReactNode;
  messageError?: React.ReactNode;
  disabledError?: boolean;
  disabledSuccess?: boolean;
}

export type stateTimed = {
  type: 'success' | 'error';
  show: boolean;
  message?: React.ReactNode;
};

const PageShowToaster: React.FC<PageToasterProps> = ({
  toasterKeys,
  messageSuccess,
  messageError,
  disabledError,
  disabledSuccess,
}: PageToasterProps) => {
  const dispatch = useAppDispatch();
  const allErrors = useAppSelector((state) => state.ui.errors);
  const allSuccess = useAppSelector((state) => state.ui.success);
  const intl = useIntl();

  const toasterKey = useMemo(
    () =>
      toasterKeys.find(
        (key) =>
          (!disabledSuccess && allSuccess?.[key]) ||
          (!disabledError &&
            (allErrors?.[key]?.message || allErrors?.[key]?.keyIntl))
      ) || '',
    [toasterKeys, allErrors, allSuccess]
  );
  const success = hasSuccess(toasterKey);
  const error = hasError(toasterKey);
  const showError = !!(error?.message || error?.keyIntl);

  const [stateTimed, setStateTimed] = useState<stateTimed>({
    type: 'success',
    show: false,
  });

  const scrollToTop = stateTimed.show || showError;

  useEffect(() => {
    if (scrollToTop) {
      dispatch(uiScrollToTop(scrollToTop));
    }
  }, [scrollToTop]);

  useEffect(() => {
    if (toasterKey) {
      return () => {
        dispatch(uiRemoveError(toasterKey));
      };
    }
  }, [toasterKey]);

  useEffect(() => {
    if (success) {
      dispatch(uiRemoveSuccess(toasterKey));
    }
  }, [success]);

  useEffect(() => {
    if (!!success && !disabledSuccess) {
      setStateTimed({
        type: 'success',
        show: true,
        message:
          messageSuccess ||
          intl.formatMessage({ id: 'success.generic_success' }),
      });
    }
  }, [success]);

  return (
    <>
      {stateTimed.show ? (
        <Box mt="3%">
          <Toaster
            open={stateTimed.show}
            onClose={() => setStateTimed({ type: 'success', show: false })}
            type={stateTimed.type}
          >
            {stateTimed.message}
          </Toaster>
        </Box>
      ) : null}
      {showError ? (
        <Box mt="3%">
          <Toaster
            open={showError}
            onClose={() => dispatch(uiRemoveError(toasterKey))}
            type={'error'}
          >
            {error?.keyIntl
              ? intl.formatMessage({ id: error.keyIntl })
              : messageError || error.message}
          </Toaster>
        </Box>
      ) : null}
    </>
  );
};

export default PageShowToaster;
