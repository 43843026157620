import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import { PlayStoreModeEnum } from '../../../../../../types/policy';
import CardContent from '../../../../../CardContent';
import Select from '../../../../../Select';
import { intlPolicyAppsKey } from './index';

interface CardModeProps {
  mode?: PlayStoreModeEnum;
  onChangeMode?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CardMode = ({ mode, onChangeMode }: CardModeProps) => {
  const { policy } = useAppSelector((state) => state.policy);
  const intl = useIntl();

  const IS_KIOSK_ACTIVE = policy?.kioskCustomLauncherEnabled;

  const options = [
    {
      label: intl.formatMessage({
        id: `${intlPolicyAppsKey}.card_mode.whitelist`,
      }),
      value: PlayStoreModeEnum.WHITELIST,
    },
    {
      label: intl.formatMessage({
        id: `${intlPolicyAppsKey}.card_mode.blacklist`,
      }),
      value: PlayStoreModeEnum.BLACKLIST,
    },
  ];

  const selectOptions = IS_KIOSK_ACTIVE
    ? [
        {
          label: intl.formatMessage({
            id: `${intlPolicyAppsKey}.card_mode.kiosk`,
          }),
          value: PlayStoreModeEnum.KIOSK,
        },
      ]
    : options;

  return (
    <CardContent
      cardItems={[
        {
          title: `${intlPolicyAppsKey}.card_mode.title`,
          description: `${intlPolicyAppsKey}.card_mode.description`,
          children: (
            <Select
              name="playStoreMode"
              value={IS_KIOSK_ACTIVE ? PlayStoreModeEnum.KIOSK : mode}
              isDisabled={IS_KIOSK_ACTIVE}
              onChange={onChangeMode}
            >
              {selectOptions.map((modeOption) => (
                <option key={modeOption.value} value={modeOption.value}>
                  {modeOption.label}
                </option>
              ))}
            </Select>
          ),
        },
      ]}
    />
  );
};

export default CardMode;
