import routes from '../routes';
import { PrivilegeEnum } from './index';

export interface hasAuthorizationResponse {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export function handleAuthorizationByRoute(
  privilege: PrivilegeEnum,
  currentPage: string
): hasAuthorizationResponse {
  const authorizationAllowed = {
    create: true,
    read: true,
    update: true,
    delete: true,
  };
  const authorizationHelpDesk = {
    create: false,
    read: true,
    update: false,
    delete: false,
  };

  const authorizationByPrivilege = {
    [PrivilegeEnum.COMPANY]: authorizationAllowed,
    [PrivilegeEnum.GROUP]: authorizationAllowed,
    [PrivilegeEnum.HELP_DESK]: authorizationHelpDesk,
  };

  const blockedRouteByAuthorization = {
    [routes.company.info]: authorizationByPrivilege[privilege],
    [routes.generalConfig.manage]: authorizationByPrivilege[privilege],
    [routes.adminUsers.register]: authorizationByPrivilege[privilege],
    [routes.adminUsers.edit]: authorizationByPrivilege[privilege],
    [routes.messages.register]: authorizationByPrivilege[privilege],
    [routes.documents.register]: authorizationByPrivilege[privilege],
    [routes.geolocation]: authorizationByPrivilege[privilege],
    [routes.informes.deviceLocation]: authorizationByPrivilege[privilege],
    [routes.informes.locationHistory]: authorizationByPrivilege[privilege],
    [routes.groups.register]: authorizationByPrivilege[privilege],
    [routes.device.infos]: authorizationByPrivilege[privilege],
    [routes.policies.register]: authorizationByPrivilege[privilege],
    [routes.profileConsumption.manage]: authorizationByPrivilege[privilege],
    [routes.profileConsumption.register]: authorizationByPrivilege[privilege],
    [routes.profileConsumption.edit]: authorizationByPrivilege[privilege],
    [routes.profileConsumption.groups.register]:
      authorizationByPrivilege[privilege],
    [routes.profileConsumption.groups.edit]:
      authorizationByPrivilege[privilege],
    [routes.profileConsumption.users.register]:
      authorizationByPrivilege[privilege],
    [routes.profileConsumption.users.edit]: authorizationByPrivilege[privilege],
  };

  return blockedRouteByAuthorization[currentPage] || authorizationAllowed;
}
