import { Tbody, Td, Tr } from '@chakra-ui/react';

import TableAlert from '../TableAlert';

interface AlertEmptyTable {
  isEmpty?: boolean;
  keyProp?: string;
}

const AlertEmptyTable = ({ isEmpty, keyProp }: AlertEmptyTable) => {
  return (
    <Tbody>
      <Tr>
        <Td>
          <TableAlert isEmpty={isEmpty} alertKey={keyProp} />
        </Td>
      </Tr>
    </Tbody>
  );
};

export default AlertEmptyTable;
