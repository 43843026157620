import { all } from 'redux-saga/effects';

import adminUserSaga from './adminUserSaga';
import applicationSaga from './applicationSaga';
import auditSaga from './auditSaga';
import authSaga from './authSaga';
import companySaga from './companySaga';
import consumptionProfileSaga from './consumptionProfileSaga';
import dashboardSaga from './dashboardSaga';
import deviceInfoSaga from './deviceInfoSaga';
import deviceSaga from './deviceSaga';
import deviceUserSaga from './deviceUserSaga';
import documentSaga from './documentSaga';
import enterpriseAppsSaga from './enterpriseAppsSaga';
import eventSaga from './eventSaga';
import externalAppSaga from './externalAppSaga';
import groupSaga from './groupSaga';
import helpSaga from './helpSaga';
import invoiceSaga from './invoiceSaga';
import locationSaga from './locationSaga';
import messageSaga from './messageSaga';
import networkSaga from './networkSaga';
import notificationSaga from './notificationSaga';
import planSaga from './planSaga';
import policySaga from './policySaga';
import remoteAppsSaga from './remoteAppsSaga';
import reportsSaga from './reportsSaga';
import termSaga from './termSaga';

export default function* rootSaga() {
  yield all([
    adminUserSaga(),
    auditSaga(),
    applicationSaga(),
    authSaga(),
    companySaga(),
    consumptionProfileSaga(),
    dashboardSaga(),
    deviceInfoSaga(),
    deviceSaga(),
    deviceUserSaga(),
    documentSaga(),
    eventSaga(),
    enterpriseAppsSaga(),
    groupSaga(),
    helpSaga(),
    invoiceSaga(),
    locationSaga(),
    messageSaga(),
    networkSaga(),
    notificationSaga(),
    planSaga(),
    policySaga(),
    reportsSaga(),
    termSaga(),
    externalAppSaga(),
    remoteAppsSaga(),
  ]);
}
