import { Flex } from '@chakra-ui/layout';
import { ChangeEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import { intlPolicySettingsKey } from '../..';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/useRedux';
import { policySelected } from '../../../../../../../../store/policy';
import FormControl from '../../../../../../../FormControl';
import Input from '../../../../../../../Input';

export default function Automatic() {
  const { policy } = useAppSelector((state) => state.policy);
  const dispatch = useAppDispatch();

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(
      policySelected({
        recommendedGlobalProxy: {
          ...(policy?.recommendedGlobalProxy || {}),
          pacUri: e.target.value,
        },
      })
    );
  };

  return (
    <Flex w="full">
      <FormControl
        w="full"
        textLabel={
          <FormattedMessage
            id={`${intlPolicySettingsKey}.network.recommended_global_proxy.pac_uri`}
          />
        }
      >
        <Input
          inputProps={{
            value: policy?.recommendedGlobalProxy?.pacUri || '',
            name: 'pacUri',
            onChange: handleInputChange,
          }}
        />
      </FormControl>
    </Flex>
  );
}
