export enum NotificationStatusCodeEnum {
  COMPLETE = 'complete',
  IN_PROGRESS = 'inProgress',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum NotificationImportTitleEnum {
  IMPORT_GROUP = 'IMPORT_GROUP',
}

export enum NotificationTitleEnum {
  admin_user = 'admin_user',
  device_user = 'device_user',
  policy = 'policy',
  company_application = 'company_application',
  device = 'device',
  application = 'application',
  group = 'group',
  group_users = 'group_users',
  license = 'license',
  message = 'message',
  recipient_message = 'recipient_message',
  document = 'document',
  recipient_document = 'recipient_document',
  site = 'site',
  site_history = 'site_history',
  consumption_data = 'consumption_data',
  consumption_sms = 'consumption_sms',
  consumption_limit_group = 'consumption_limit_group',
  consumption_limit_user = 'consumption_limit_user',
  website_limits_company = 'website_limits_company',
  website_limits_group = 'website_limits_group',
  device_without_communication = 'device_without_communication',
  localization_history = 'localization_history',
  last_location = 'last_location',
  invoice = 'invoice',
  invoice_details = 'invoice_details',
  network = 'network',
  audit = 'audit',
  non_compliance = 'non_compliance',
  install = 'install',
  recipient_install = 'recipient_install',
  commands = 'commands',
  carrier_companies_list = 'carrier_companies_list',
}

type NotificationTitleType = NotificationTitleEnum &
  NotificationImportTitleEnum;

export enum NotificationTypeCodeEnum {
  REPORT = 'report',
  IMPORT = 'import',
  COMMAND = 'command',
}

export interface NotificationDetailsType {
  data: Record<string, unknown>[];
}

export interface NotificationType {
  id: string;
  visualized: boolean;
  typeCode: NotificationTypeCodeEnum;
  statusCode: NotificationStatusCodeEnum;
  title?: NotificationTitleType;
  data?: string;
  createdAt: string;
  updatedAt?: string;
  details?: NotificationDetailsType;
}
